import uniKeyWordTheme from '../../helpers/wordSets/themes/unikey';
import orlandoSceneTheme from '../../helpers/wordSets/themes/orlandoScene';
import {
  getRandomNumBetween,
  DbEntity,

  IModelIncludeOpts,
  graphQLMutation,
  createWord,
  updateWord,
  EWordType,
  ThemeSet,
  ISetOfWords,
  themes,
  // nounSet,

} from '../../internal';

export interface IWord {
  text?: string,
  type?: number,
  doubleValue?: string,
  skipped?: string,
  bombText?: string,
  adjusted?: string,
  teamId?: string,
  roundId?: string,
  createdAt?: string
}

const scrambleWordFromDeveloper = (word) => {
  return word.split('').sort(() => Math.random() > 0.5 ? 1 : -1).join('');
}
export class Word extends DbEntity {


  static allowedGameWords?: Set<string>;

  text: string;
  type?: number;
  doubleValue?: string;
  skipped?: string;
  bombText?: string;
  adjusted?: string;
  teamId?: string;
  roundId?: string;
  createdAt?: string;

  constructor(opts?: IWord) {
    super(opts);
    this.text = opts?.text ?? 'not provided';
    this.type = opts?.type;
    this.doubleValue = opts?.doubleValue;
    this.skipped = opts?.skipped;
    this.bombText = opts?.bombText;
    this.adjusted = opts?.adjusted;
    this.teamId = opts?.teamId;
    this.roundId = opts?.roundId;
    this.createdAt = opts?.createdAt;
  }

  isDoubleValue(): boolean {
    return this.doubleValue === 'true';
  }

  isSkipped(): boolean {
    return this.skipped === 'true';
  }

  isBombWord(): boolean {
    return !!this.bombText;
  }

  isManuallyAdjusted(): boolean {
    return this.adjusted === 'true';
  }

  async forApi(opts?: IModelIncludeOpts): Promise<any> {
    const superJson = await super.forApi();
    var json = {
      ...superJson,
      text: this.text,
      adjusted: this.adjusted,
      skipped: this.skipped,
      teamId: this.teamId,
      roundId: this.roundId,
      createdAt: this.createdAt ?? Date.now()
    };
    return json;
  }

  static async saveNew(wordToSave: Word): Promise<string> {
    // console.log('[save] create word')
    const wordEntity = await wordToSave!.forApi({ save: true })
    await graphQLMutation(createWord, wordEntity);
    return wordEntity.id;
  }

  static async saveUpdate(wordToSave: Word, allowUpdateDoubleValue?: boolean): Promise<string> {
    // console.log('[save] update word')
    // never update the double value setting on the word. set on creation only.
    const wordEntity = await wordToSave!.forApi({ save: true, withoutDoubleValue: !allowUpdateDoubleValue })
    await graphQLMutation(updateWord, wordEntity);
    return wordEntity.id;
  }

  static async getRandomWord(usedTwIndexes: number[], wordSetsAllowed: string[], customWorkBank: string[]) {
    if (!this.allowedGameWords) {
      // if the allowed game words has not been calculated 
      // on this client, calculate it before moving forward
      this.buildAllowedGameWords(wordSetsAllowed, customWorkBank);
    }

    const availWordList = Array.from(this.allowedGameWords!);

    // if we've seen nearly all the words available
    // to this game, reset the seen words
    if (availWordList.length <= usedTwIndexes.length + 20) {
      console.warn('You have exhausted the list of available words. Resetting seen word list. May encounter duplicates from this point on. ')
      usedTwIndexes = [];
    }

    var nextPossibleWordIndex;
    do { // search until we find a word index not yet used in this game
      nextPossibleWordIndex = getRandomNumBetween(0, availWordList.length);
    } while (usedTwIndexes.indexOf(nextPossibleWordIndex) >= 0)

    // add the word index to the list of seen words
    usedTwIndexes.push(nextPossibleWordIndex);

    var randWord = `${availWordList[nextPossibleWordIndex].toLowerCase()}`;
    return process.env.NODE_ENV === 'development' ? scrambleWordFromDeveloper(randWord) : randWord;
  }

  static buildAllowedGameWords(wordSetsIdsAllowed: string[], customWordBank: string[]) {

    const completeWordSet = themes.reduce((gameWords: Set<string>, themedWordSet: ThemeSet) => {
      gameWords = themedWordSet.addAllowedWords(gameWords, wordSetsIdsAllowed);
      return gameWords;
    }, new Set<string>([]));

    // unikey game -- only if gamecode is 'unikey'
    if (window.location.pathname.indexOf('unikey') != -1) {
      uniKeyWordTheme.addAllowedWords(completeWordSet, wordSetsIdsAllowed);
      orlandoSceneTheme.addAllowedWords(completeWordSet, wordSetsIdsAllowed);
    }

    // add any custom words to the very end of the list
    customWordBank.forEach((customTarget: string) => {
      completeWordSet.add(customTarget);
    });

    this.allowedGameWords = completeWordSet;
    console.log(`finished building allowed words. total set size: ${this.allowedGameWords?.size} `);
  }
}
