import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);

export const medium = new Set([
  'deal',
  'debit',
  'demand',
  'department',
  'director',
  'discount',
  'dismiss',
  'down payment',
  'download',
  'duties',
  'duty',
  'economics',
  'efficiency',
  'employ',
  'employee',
  'employer',
  'employment',
  'entrepreneur',
  'equipment',
  'estimate',
  'executive',
  'expenses',
  'export',
  'facility',
  'factory',
  'fax',
  'finance',
  'financial',
  'framework',
  'fund',
  'goods',
  'graph',
  'gross',
  'growth',
  'guidebook',
  'headquarters',
  'high',
  'hire',
  'hours',
  'import',
  'income',
  'insurance',
  'intern',
  'interview',
  'inventory',
  'invest',
  'investment',
  'invoice',
  'labor',
  'laborer',
  'laptop',
  'lead',
  'lease',
  'leave',
  'letterhead',
  'liability',
  'loan',
  'log-in',
  'loss',
  'lucrative',
  'mailbox',
  'manage',
  'management',
  'manager',
  'market',
  'marketing',
  'meeting',
  'memo',
  'merchandise',
  'merchant',
  'monopoly',
  'motherboard',
  'mouse pad',
  'negotiate',
  'net',
  'net worth',
  'network',
  'notebook',
  'notice',
  'occupation',
  'offer',
  'offline',
  'online',
  'opportunity',
  'organization',
  'outgoing',
  'overdraft',
  'overhead',
  'owner',
  'packing list',
  'paperweight',
  'partner',
  'password',
  'payment',
  'perk',
  'personnel',
  'plan',
  'policy',
  'portfolio',
  'position',
  'presentation',
  'president',
  'price',
  'product',
  'production',
  'profit',
  'profitable',
  'promotion',
  'proposal',
  'prospects',
  'proxy',
  'purchase order',
  'purchasing',
  'quarter',
  'quit',
  'rank',
  'receipt',
  'recruit',
  'recruiter',
  'refund',
  'resign',
  'retail',
  'retailer',
  'retire',
  'résumé',
  'risk',
  'salary',
  'sales tax',
  'salesman',
  'sale',
  'saleswoman',
  'secretary',
  'seller',
  'sell',
  'service',
  'shareholder',
  'ship',
  'shipment',
  'shipping',
  'shop',
  'sick leave',
  'sign',
  'signature',
  'spreadsheet',
  'staff',
  'statement',
  'stock',
  'stockholder',
  'strike',
  'success',
  'supervisor',
  'supply',
  'target',
  'tax',
  'terms',
  'trade',
  'trade-off',
  'trainee',
  'transaction',
  'treasurer',
  'treasury',
  'trend',
  'typeface',
  'typewriter',
  'unemployment',
  'union',
  'upgrade',
  'upload',
  'username',
  'money',
  'fire',
  'job',
  'office',
  'open',
  'order',
  'pay',
  'vacancy',
  'vacation time',
  'vice-president',
  'video conference',
  'volume',
  'warranty',
  'wastebasket',
  'wholesale',
  'win-win',
  'withdraw',
  'work',
  'worker',
  'workroom',
  'workspace',
  'accountant',
  'accounting',
  'accounts',
  'ads',
  'advertise',
  'affordable',
  'agenda',
  'agreement',
  'benefits',
  'board',
  'bond',
  'bonus',
  'bookkeeping',
  'borrow',
  'boss',
  'bottom line',
  'break even',
  'briefcase',
  'budget',
  'business',
  'business card',
  'buy',
  'buyer',
  'calculate',
  'capital',
  'career',
  'cargo',
  'charge',
  'clause',
  'client',
  'close',
  'cold call',
  'commerce',
  'commercial',
  'commission',
  'company',
  'competition',
  'compromise',
  'consumer',
  'contract',
  'copyright',
  'corner office',
  'corporate',
  'corporation',
  'cost',
  'coupon',
  'credit',
  'credit card',
  'cubicle',
  'currency',
  'customer',
  'database',
  'deadline'
]);

const hard = new Set([
  'collateral',
  'capitalist',
  'chairman',
  'chairwoman',
  'commodity',
  'accruals',
  'arbitration',
  'bill of lading',
  'waybill',
  'wholesaler',
  'yield',
  'unit cost',
  'venture',
  'superintendent',
  'tariff',
  'prime rate',
  'principal',
  'deflation',
  'distribution',
  'diversify',
  'dividend',
  'economical',
  'economy of scale',
  'figures',
  'foreman',
  'freight',
  'headhunter',
  'incentive',
  'income tax',
  'inflation',
  'interest rate',
  'mainframe',
  'niche',
  'no-win',
  'board of directors',
  'negotiation',
]);

export default new ThemeSet('Business',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);
