import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "sky",
  "fire",
  "air",
  "fall",
  "ice",
  "cloud",
  "cold",
  "heat",
  "mist",
  "rain",
  "thunder",
  "warm",
  "wind",
]);

const medium = new Set([
  "accumulation",
  "advisory",
  "air pollution",
  "air pressure",
  "almanac",
  "atmosphere",
  "aurora",
  "autumn",
  "avalanche",
  "balmy",
  "barometer",
  "black ice",
  "blizzard",
  "blustery",
  "breeze",
  "calm",
  "cell",
  "climate",
  "climatology",
  "cloudy",
  "cold front",
  "cold snap",
  "cold wave",
  "compass",
  "condensation",
  "cumulonimbus cloud",
  "cumulus cloud",
  "current",
  "cyclone",
  "degree",
  "tropical depression",
  "dew",
  "dew point",
  "disturbance",
  "downpour",
  "downwind",
  "drift",
  "drizzle",
  "drought",
  "dry",
  "dust storm",
  "emergency radio",
  "evaporation",
  "eye of the storm",
  "wall",
  "fair",
  "flash flood",
  "flood",
  "flurry",
  "fog",
  "fog bank",
  "forecast",
  "freeze",
  "freezing rain",
  "front",
  "frost",
  "scale",
  "funnel cloud",
  "global warming",
  "effect",
  "ground fog",
  "gust",
  "hail",
  "halo",
  "haze",
  "heat index",
  "heat wave",
  "high",
  "humid",
  "humidity",
  "hurricane",
  "hurricane season",
  "ice age",
  "ice storm",
  "icicle",
  "jet stream",
  "Kelvin",
  "knot",
  "landfall",
  "lightning",
  "low",
  "meteorologist",
  "meteorology",
  "moisture",
  "monsoon",
  "muggy",
  "outlook",
  "overcast",
  "ozone",
  "partly cloudy",
  "polar",
  "pollutant",
  "precipitation",
  "pressure",
  "radar",
  "radiation",
  "rain gauge",
  "rainbands",
  "rainbow",
  "ridge",
  "sandstorm",
  "scattered",
  "sea breeze",
  "shower",

  "sleet",
  "slush",
  "smog",
  "smoke",
  "snow",
  "snow flurry",
  "snowfall",
  "snowflake",
  "snowstorm",
  "spring",
  "steam",
  "storm surge",
  "stratosphere",
  "sunrise",
  "sunset",
  "surge",
  "swell",
  "temperature",
  "thaw",
  "thermal",
  "thermometer",
  "thunderstorm",
  "tornado",
  "tsunami",
  "alley",
  "trace",
  "turbulence",
  "twilight",
  "twister",
  "typhoon",
  "unstable",
  "vapor",
  "visibility",
  "vortex",
  "warning",
  "watch",
  "water",
  "earthquake",
  "eruption",
  "lava",
  "Tectonic plates",
  "volcano",
  "cycle",
  "weather",
  "weather balloon",
  "whiteout",
  "wild fire",
  "wind chill",
]);

const hard = new Set([

])


export default new ThemeSet('Weather',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);