import { ThemeSet, EDifficulty } from '../index';


export const easyAdj = new Set([
  'blue',
  'full',
  'recent',
  'wild',
  'tight',
  'perfect',
  'wooden',
  'plastic',
  'fresh',
  'green',
  'steady',
  'giant',
  'cheap',
  'gusty',
  'dusty',
  'little',
  'wicked',
  'red',
  'scared',
]);

export const medAdj = new Set([
  'successful',
  'abashed',
  'elastic',
  'fortunate',
  'skillful',
  'hideous',
  'marked',
  'hysterical',
  'dazzle',
  'imperfect',
  'romantic',
  'noiseless',
  'coward',
  'ashamed',
  'different',
  'dangerous',
]);

export const hardAdj = new Set([

]);


export const proAdj = new Set([

]);


export default new ThemeSet('Base Adjectives',
  easyAdj,
  medAdj,
  hardAdj,
  proAdj,
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);