import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  "astronomy",
  "atom",
  "beaker",
  "biochemistry",
  "biology",
  "botany",
  "Bunsen burner",
  "cell",
  "chemical",
  "chemistry",
  "climate",
  "climatologist",
  "control",
  "data",
  "datum",
  "electricity",
  "element",
  "energy",
  "evolution",
  "experiment",
  "fact",
  "fossil",
  "funnel",
  "genetics",
  "geology",
  "geophysics",
  "glassware",
  "graduated cylinder",
  "gravity",
  "hypothesis",
  "immunology",
  "lab",
  "laboratory",
  "laws",
  "magnetism",
  "mass",
  "matter",
  "measure",
  "meteorologist",
  "meteorology",
  "microbiologist",
  "microbiology",
  "microscope",
  "mineral",
  "mineralology",
  "molecule",
  "motion",
  "observe",
  "observatory",
  "organism",
  "particle",
  "Petri dish",
  "phase",
  "physics",
  "mechanics",
  "radiology",
  "research",
  "retort",
  "scale",
  "science",
  "scientist",
  "telescope",
  "temperature",
  "test tube",
  "theory",
  "thermometer",
  "tissue",
  "variable",
  "virologist",
  "volcanology",
  "volume",
  "flask",
  "weather",
  "weigh",
  "zoology",
  "thruster",
  "autopilot",
  "self-driving",
  "placebo",
  "REM sleep",
  "big bang",
  "black hole",
  "Wavelength",
  "Acceleration",
  "Acid",
  "Attribute",
  "Friction",
  "Habitat",
  "Herbivore",
  "Hypothesis",
  "Solution",
  "Trial",
  "Uniform",
  "Variable",
  "Vapor",
  "Velocity",
  "Compose",
  "Conductor",
  "Decelerate",
  "Emit",
  "Evolve",
  "Gauge",
  "Humidity",
  "Mass",
])

const hard = new Set([
  "seismology",
  "ornithology",
  "paleontology",
  "pipette",
  "entomology",
  "absolute zero",
  "agoraphobia",
  "algorithm",
  "bandwidth",
  "capacitor",
  "alternating current",
  "Cambrian Explosion",
  "centripetal force",
  "chemosynthesis",
  "cognitive dissonance",
  "complementarity",
  "cryptography",
  "cyanobacterium",
  "cyclone",
  "echolocation",
  "endorphin",
  "Fibonacci sequence",
  "fission",
  "game theory",
  "genome",
  "gluon",
  "heliocentrism",
  "imaginary number",
  "interferon",
  "ischemia",
  "isomer",
  "junk DNA",
  "krill",
  "logic gate",
  "magnetosphere",
  "megabyte",
  "melanoma",
  "mitochondrion",
  "monotreme",
  "Munchausen syndrome",
  "nanotube",
  "Neanderthal",
  "orbital",
  "nosocomial infection",
  "obligate",
  "pahoehoe",
  "Pavlovian",
  "photoelectric",
  "photon",
  "quantum mechanics",
  "radiometric dating",
  "refraction",
  "retrovirus",
  "sociobiology",
  "solar wind",
  "superposition",
  "theory",
  "tidal force",
  "time dilation",
  "Universal Time",
  "wave function",
  "zero",
  "Abundant",
  "Adhere",
  "Aerobic respiration",
  "Altitude",
  "Analyze",
  "Anecdote",
  "Anticipate",
  "Assert",
  "Burgeon",
  "Carbohydrate",
  "Catalyst",
  "Chlorophyll",
  "Coherent",
  "Correspond",
  "Combustion",
  "Compressibility",
  "Conduction and conductivity",
  "Contour",
  "Consecutive",
  "Characteristic",
  "Contradict",
  "Correlate",
  "Critical",
  "Deposition",
  "Determine",
  "Diffusion time",
  "Distinguish",
  "Dissoluble",
  "Ecology",
  "Efficient",
  "Electron",
  "Emerge",
  "Emulsion",
  "Equilibrium",
  "Engage",
  "Erode or Erosion",
  "Exhibit",
  "Fermentation",
  "Fuse",
  "Given",
  "Imminent",
  "Incendiary",
  "Incremental",
  "Indigenous",
  "Infer",
  "Infrared radiation",
  "Impervious",
  "Insoluble",
  "Insulator",
  "Interval",
  "Ion",
  "Kinetic energy",
  "Latter",
  "Notion",
  "Neutron",
  "Obsolete",
  "Organic matter",
  "Osmosis",
  "Omit",
  "Precipitation",
  "Parasite",
  "pH",
  "Photosynthesis",
  "Precede",
  "Principle",
  "Proficient",
  "Prolong",
  "Proportionality",
  "Protein",
  "Proton",
  "Radioactive decay",
  "Redundant",
  "Relevant",
  "Residual",
  "Specific gravity",
  "Starch",
  "Substrate",
  "Viscosity",
])



export default new ThemeSet('Science',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);