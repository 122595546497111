import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-grid-system';
import { useParams, useLocation, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import ReactTooltip from 'react-tooltip';
import {
  Button,
  FormInput
} from 'shards-react'

import {
  ConditionalRender,
  EGamePhases,
  TeamSelect,
  Game,
  Team,
  SpectateWordEntry,
  WritePhase,
  VerbalGuess,
  VerbalCaptain,
  VerbalReady,
  VerbalCheck,
  SpectateVerbal,
  VotePhase,
  GameResults,
  GameContext,
  IGameContext,
  graphQLSubscription,
  onGameChange,
} from '../internal';


interface IProps {
  // gameCode: number,
}

export const GameRoom: FC<IProps> = ({ }) => {
  var { state: { game, myTeam, isPlayerClueGiver, oppTeam, clientSync }, dispatch } = useContext(GameContext);
  var { search } = useLocation();
  var [overwritePhase, setOverwritePhase] = useState('');
  var [newRoundTimeoutRef, setNewRoundTimeoutRef] = useState<any>(null);
  var [forceEnableNewRoundBtn, setForceEnableNewRoundBtn] = useState<boolean>(false);

  const initiateStartNewRound = async () => {
    setForceEnableNewRoundBtn(false);
    const myNewRoundTimerRequest = Date.now();
    await game!.preStartNewRound(myNewRoundTimerRequest);
    const timeoutCanceller = setTimeout(async () => {
      await game!.attemptStartNewRound(myNewRoundTimerRequest, clientSync);
      setForceEnableNewRoundBtn(true);
    }, Game.preNewRoundWait)
    setNewRoundTimeoutRef(timeoutCanceller);

    dispatch({ type: 'modifyGame', game });
  };

  // overwrite the phase
  useEffect(() => {
    var foundPhaseParam = search.match(/\?.*phase=([^\&]*)/)?.[1];
    if (foundPhaseParam) {
      setOverwritePhase(foundPhaseParam!);
    }
    return () => {
      if (newRoundTimeoutRef) {
        clearTimeout(newRoundTimeoutRef);
      }
    }
  }, [search])

  useEffect(() => {
    var timeoutRef = setTimeout(() => {
      console.log('rebuilding tooltip refs')
      ReactTooltip.rebuild();
    }, 2400)
    return () => {
      clearTimeout(timeoutRef);
    }
  }, [game?.phase, myTeam?.id])

  useEffect(() => {
    if (game?.getMyTeam) {

      // console.log(`setting up phase subscription for game: ${game.id}`);

      // setup subscription for myTeam's incoming cws
      var gameSubscription = graphQLSubscription(onGameChange, { id: game.id }, handleGameChange);
      return () => gameSubscription.unsubscribe();
    } else {
      console.log('no game id yet. skipping subscription')
    }
  }, [game?.id])

  const isGuessPhaseForMyTeam = () => {
    if (myTeam?.isRedTeam() && game?.phase === EGamePhases.redGuess) {
      return true;
    } else if (myTeam?.isBlueTeam() && game?.phase === EGamePhases.blueGuess) {
      return true;
    } else {
      return false;
    }
  }

  const handleGameChange = async ({ onChangeGame }) => {
    game!.updateFromSub(onChangeGame, clientSync);
    dispatch({ type: 'modifyGame', game });
  }

  return (
    <div className={classnames('game-container', myTeam?.getStyleName())}>

      <ConditionalRender visible={!!game && !game?.selectedTeam}>
        <TeamSelect />
      </ConditionalRender>

      <ConditionalRender visible={!!game?.selectedTeam}>

        <ConditionalRender visible={overwritePhase == 'team' || game?.phase === EGamePhases.unknown}>

          <Row justify="center">
            {/* spacer */}
            <Col xs={12} className={classnames('vert-spacer')}></Col>
            <Col xs={10} sm={8} md={7} lg={7} className="join-text">
              <h4 className={classnames('text-center')}>
                <p className="game-modes-text">Current game mode{(game?.modes?.length ?? 0) > 1 ? 's' : ''}: {game?.modes?.length == 0 ? 'Standard' : game?.getPrettyGameModeNames?.().join(', ')}</p>
              </h4>
            </Col>
            
          </Row>
          
          <Row justify="center">
            {/* <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col> */}
          

            <Col xs={12} sm={5} md={4}>
              <Button
                className={classnames('huge', 'green')}
                onClick={initiateStartNewRound}
                disabled={!!game?.newRoundRequest && !forceEnableNewRoundBtn}>
                <>
                  <ConditionalRender visible={!!game?.newRoundRequest && !forceEnableNewRoundBtn}>
                    <Loader
                      type="Oval"
                      color="#fff"
                      height={100}
                      width={100}
                    />
                  </ConditionalRender>

                  <ConditionalRender visible={!(!!game?.newRoundRequest && !forceEnableNewRoundBtn)}>
                    Start Round
                  </ConditionalRender>

                </>
              </Button>
            </Col>
          </Row>

          <div className="slide-in-message how-to-play-link">
            <Link to={`/pages/howToPlay`}>
              <b>How To Play</b>
            </Link>
          </div>

          <Row justify="center">    
            <Col xs={10} sm={8} md={7} lg={7} className="join-text">
              <h4 className={classnames('text-center')}>
                <div className="game-mode-line"><div className="game-mode-name"><strong>Game Mode: BOMB WORDS</strong></div>Avoid the partially-visible Bomb word or get hit with a penalty</div>
                <div className="game-mode-line"><div className="game-mode-name"><strong>Game Mode: EARN TIME</strong></div>Get additional time for each word guessed correctly</div>
                <div className="game-mode-line"><div className="game-mode-name"><strong>Game Mode: SINGLE TARGET</strong></div>Only one word at a time is shown to the captain</div>
                <div className="game-mode-line"><div className="game-mode-name"><strong>Game Mode: DOUBLE VALUE WORDS</strong></div>One hidden word each round is worth double points</div>
              </h4>
              {/* <h4 className={classnames('text-center')}>Try a special game mode next time for extra mayhem!</h4> */}
            </Col>

          </Row>
        </ConditionalRender>

        <ConditionalRender visible={overwritePhase == 'write' || game?.phase === EGamePhases.cwEntry}>
          <ConditionalRender visible={game?.selectedTeam?.name !== 'spectator'}>
            <WritePhase />
          </ConditionalRender>
          <ConditionalRender visible={game?.selectedTeam?.name === 'spectator'}>
            <SpectateWordEntry />
          </ConditionalRender>
        </ConditionalRender>

        <ConditionalRender visible={overwritePhase == 'vote' || game?.phase === EGamePhases.voting}>
          <ConditionalRender visible={game?.selectedTeam?.name !== 'spectator'}>
            <VotePhase />
          </ConditionalRender>
          <ConditionalRender visible={game?.selectedTeam?.name === 'spectator'}>
            <SpectateWordEntry />
          </ConditionalRender>
        </ConditionalRender>

        {/* Pre Guess */}
        <ConditionalRender visible={overwritePhase == 'preRedGuess' || game?.phase === EGamePhases.preRedGuess || game?.phase === EGamePhases.preBlueGuess}>
          <VerbalReady />
        </ConditionalRender>

        {/*  Guess Phase */}
        <ConditionalRender visible={overwritePhase == 'redGuess' || game?.phase === EGamePhases.redGuess || game?.phase === EGamePhases.blueGuess}>
          {/* Spectator View */}
          <ConditionalRender visible={game?.selectedTeam?.name === 'spectator'}>
            <SpectateVerbal />
          </ConditionalRender>

          {/* player views */}
          <ConditionalRender visible={game?.selectedTeam?.name !== 'spectator'}>
            {/* Guess Team and Clue Giver */}
            <ConditionalRender visible={isGuessPhaseForMyTeam() && isPlayerClueGiver}>
              <VerbalCaptain />
            </ConditionalRender>
            {/* Guess Team and Not Clue Giver */}
            <ConditionalRender visible={isGuessPhaseForMyTeam() && !isPlayerClueGiver}>
              <VerbalGuess />
            </ConditionalRender>

            {/* Not Guess Team */}
            <ConditionalRender visible={!isGuessPhaseForMyTeam()}>
              <VerbalCheck />
            </ConditionalRender>
          </ConditionalRender>
        </ConditionalRender>

        <ConditionalRender visible={overwritePhase == 'results' || game?.phase === EGamePhases.results}>
          <GameResults />
        </ConditionalRender>
      </ConditionalRender>

      {/* padding bottom for mobile */}
      <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>
    </div>
  )
}
