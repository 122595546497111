import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  'attack',
  'battle',
  'beach',
  'cannon',
  'coins',
  'compass',
  'escape',
  'fear',
  'fight',
  'flag',
  'gold',
  'gun',
  'island',
  'land',
  'map',
  'ocean',
  'parrot',
  'pirate',
  'scar',
  'ship',
  'shore',
  'sword',
  'trade',
]);

const medium = new Set([
  'abandon',
  'adventure',
  'ahoy',
  'anchor',
  'arms',
  'ashore',
  'assault',
  'aye-aye',
  'bandanna',
  'bandit',
  'barbaric',
  'barrel',
  'behead',
  'bounty',
  'brawl',
  'brutal',
  'buccaneer',
  'capsize',
  'captain',
  'capture',
  'cargo',
  'cave',
  'challenge',
  'chest',
  'coast',
  'coastline',
  'corpse',
  'course',
  'crew',
  'criminal',
  'crook',
  'cruel',
  'curse',
  'cutlass',
  'cutthroat',
  'dagger',
  'danger',
  'daring',
  'deck',
  'deck hands',
  'desert island',
  'dishonest',
  'earring',
  'evil',
  'explore',
  'eye patch',
  'first mate',
  'fleet',
  'fortune',
  'gear',
  'greed',
  'gunner',
  'haul',
  'heist',
  'high seas',
  'hijack',
  'gunpowder',
  'hold',
  'hook',
  'horizon',
  'hostile',
  'hull',
  'hurricane',
  'illegal',
  'infamous',
  'jewels',
  'kidnap',
  'kill',
  'knife',
  'land-ho',
  'landlubber',
  'lash',
  'lawless',
  'legend',
  'lookout',
  'loot',
  'merchant',
  'musket',
  'mutiny',
  'nautical',
  'navigate',
  'old salt',
  'outcasts',
  'overboard',
  'pegleg',
  'pistol',
  'plank',
  'plunder',
  'predatory',
  'prowl',
  'quarters',
  'quest',
  'raid',
  'ransack',
  'rat',
  'rations',
  'realm',
  'reckoning',
  'revenge',
  'revolt',
  'riches',
  'rigging',
  'roam',
  'rob',
  'robber',
  'rope',
  'rudder',
  'rum',
  'ruthless',
  'sabotage',
  'sail',
  'sailing',
  'sailor',
  'seas',
  'seaweed',
  'silver',
  'skull and bones',
  'spoils',
  'steal',
  'swagger',
  'thief',
  'thug',
  'tides',
  'torture',
  'treasure',
  'treasure island',
  'truce',
  'unlawful',
  'vessel',
  'villain',
  'violence',
  'violent',
  'walk the plank',
  'weapons',
  'X marks the spot',
  'yo-ho-ho',
])

const hard = new Set([
  'ruffian',
  'scalawag',
  'scurvy',
  'sextant',
  'shipmate',
  'shiver-me-timbers',
  'skiff',
  'swab the deck',
  'swashbuckling',
  'thievery',
  'treachery',
  'unscrupulous',
  'vandalize',
  'vanquish',
  'vicious',
  'vile',
  'yellow fever',
  'armada',
  'bandolier',
  'boatswain',
  'galleon',
  'gangplank',
  'fearsome',
  'doubloon',
  'confiscate',
  'contraband',
  'conquest',
  'corsair',
  'ferocious',
  'gibbet',
  'Jolly Roger',
  'keel',
  'keelhaul',
  'notorious',
  'Long John Silver',
  'lucre',
  'maggot',
  'malaria',
  'marauder',
  'mariner',
  'maroon',
  'mast',
  'lore',
  'mates',
  'mayhem',
  'menace',
  'parley',
  'pillage',
  'quartermaster',
])



export default new ThemeSet('Pirates',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });