// custom hooks
import { SfxManager } from './audio';

export * from './audio';
export * from './themeSet';
export * from './useMap';
export * from './useSet';
export * from './colorTheme';

export * from './wordSets/themes';

export const sfx = new SfxManager();
// set the volume lower
sfx.volume(SfxManager.defaultVolume);

export const getRandomNumBetween = (min: number, max: number) => {
  // console.log(`getting rand num between: ${min} - ${max}`);
  return Math.floor(Math.random() * (max - min)) + min;
}
