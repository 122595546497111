import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import {
  ConditionalRender,
  Footer,
  Header
} from '../../internal';

interface IChangeItem {
  date: string,
  version: string,
  name: string,
  desc: string
}

const noteableChanges: IChangeItem[] = [
  {
    date: 'Nov 2023',
    version: '1.21.3',
    name: 'Updated Superheroes Themed Word Set',
    desc: 'Added more words to the Superheroes themed word set.',
  },{
    date: 'August 2023',
    version: '1.21.2',
    name: 'Highlighting Donation Button on Round End Pages',
    desc: 'Since I\'m losing money every month keeping this game alive, I decided to make the donation button a little more prominent. ...Awkward...',
  },
  {
    date: 'August 2023',
    version: '1.21.1',
    name: 'New Themed Word Sets Added',
    desc: 'New word set added to the word bank with theme: Shapes.',
  },
  {
    date: 'August 2023',
    version: '1.21.0',
    name: 'Changed Featured Game Modes',
    desc: 'Featured game modes changed to only "Double Value"',
  },
  {
    date: 'March 2022',
    version: '1.20.1',
    name: 'Word Theme Previews',
    desc: 'From the create a game menu, a preview of words in the word themes display while hovering over the word count.',
  },
  {
    date: 'March 2022',
    version: '1.20.0',
    name: 'Help Defaults to Open the first round',
    desc: 'The help windows will open automatically the first round unless the user has clicked them off. They will close automatically on the second round unless the user has kept them open.',
  },
  {
    date: 'March 2022',
    version: '1.19.7',
    name: 'Skipped Words Show in Spectate View',
    desc: 'Previously when a word was skipped (when "Single Target" game mode is active), the skipped word did not display in the spectator view. Now it displays as expected.',
  },
  {
    date: 'Feb 2022',
    version: '1.19.5',
    name: 'Rotated Featured Game Modes',
    desc: 'Updated the Featured Game Modes to be: Double Value, Earn Time & Single Target.',
  },
  {
    date: 'Feb 2022',
    version: '1.19.4',
    name: 'New Themed Word Sets Added',
    desc: 'New word sets added to the word bank with themes: Medical, Spa Day.',
  },
  {
    date: 'Jan 2022',
    version: '1.19.2',
    name: 'Changed Featured Game Modes & Updated Sports Word Sets',
    desc: 'Updated the Featured Game Modes to be: Double Value & Sacrifice. The Sports Word sets now are split into Med/Hard sets with Med enabled by default.',
  },
  {
    date: 'Dec 2021',
    version: '1.19.0',
    name: 'Added Supporters Page',
    desc: 'New Supporters Page to say thank you to individual supporters.',
  },
  {
    date: 'Dec 2021',
    version: '1.18.7',
    name: 'New Themed Word Sets Added',
    desc: 'New word sets added to the word bank with themes: Hanukkah, Winter.',
  },
  {
    date: 'Nov 2021',
    version: '1.18.4',
    name: 'More Visibility into the next Game Phase and Sacrifice',
    desc: 'Now the game header shows the Next Game Phase and the Ready-up page shows the opposite team\'s sacrifice status',
  },
  {
    date: 'Nov 2021',
    version: '1.18.1',
    name: 'New Themed Word Set Added',
    desc: 'New word set added to the word bank with theme: Thanksgiving.',
  },
  {
    date: 'Nov 2021',
    version: '1.18.0',
    name: 'New Gameplay Mode: "Sacrifice"',
    desc: 'When enabled, your team may choose to sacrifce 1 game point to gain extra time in the guessing phase.'
  },
  {
    date: 'Nov 2021',
    version: '1.17.8',
    name: 'Time Adjustments for Earn Time Mode',
    desc: 'Higher default time for the initial guess clock (45s -> 55s) and less time earned after each word (20s -> 15s).',
  },
  {
    date: 'Oct 2021',
    version: '1.17.6',
    name: 'Bug Fix: Banned Word Count',
    desc: 'Fixed an edge-case bug which caused more than the 16 allowed banned words to be usable during the guess phase.',
  },
  {
    date: 'Oct 2021',
    version: '1.17.3',
    name: 'New Themed Word Sets Added',
    desc: 'New word sets added to the word bank with themes: Halloween, Autumn.',
  },
  {
    date: 'Oct 2021',
    version: '1.17.1',
    name: 'Slide-in messages',
    desc: 'Some minor UI adjustments to add messaging in the game lobby and game rooms.',
  },
  {
    date: 'Oct 2021',
    version: '1.17.0',
    name: 'Time Bonus adjustment ',
    desc: 'When playing with Earn Time and Single Target modes, you will now earn 75% of the bonus time when you skip a word (up from 0% when skipping previously)',
  },
  {
    date: 'Oct 2021',
    version: '1.16.1',
    name: 'New Themed Word Set Added',
    desc: 'New word sets added to the word bank with themes: TV Shows, Medieval Era',
  },
  {
    date: 'Sept 2021',
    version: '1.16.0',
    name: 'Server-Client Time Synchronization',
    desc: 'Previously, the phase timer was dependent on the system clock. If the client\'s system clock was off (you know who you are!) it caused problems with the game phases ending abruptly. Now the clients are brought into sync during game phases to keep the phase clock closely aligned across all clients.',
  },
  {
    date: 'Sept 2021',
    version: '1.15.3',
    name: 'Word Count Displays in Lobby',
    desc: 'Now a counter shows the number of words that will be used for the game based on enabled word sets and custom words.',
  },
  {
    date: 'Sept 2021',
    version: '1.15.2',
    name: 'New Themed Word Set Added',
    desc: 'New word sets added to the word bank with themes: Biology. Also added another difficulty (Pro) for words most people have no business knowing, but would be fun for groups with specific knowledge sets. Ex: Playing with a group of Geneticists!',
  },
  {
    date: 'Sept 2021',
    version: '1.15.0',
    name: 'Custom Word Bank',
    desc: 'The game creator can now add a list of custom target words to be used during the game.'
  },
  {
    date: 'August 2021',
    version: '1.14.1',
    name: 'Restore In-Game Help',
    desc: 'Lots of games ending at Phase 1 Round 1, so in game help buttons have been added back to game header in hopes of aiding players who skipped the rules.'
  },
  {
    date: 'August 2021',
    version: '1.14.0',
    name: 'Game Mode Modification: "No Skip"',
    desc: '"No Skip" Game Mode changed to "Single Target" which presents Target Words one-at-a-time to the Captain (similar to previous mode), but allows the Captain to skip the word if they want to pass.'
  },
  {
    date: 'August 2021',
    version: '1.13.0',
    name: 'Two New Gameplay Options: "Earn Time" & "No Skip"',
    desc: 'The "Earn Time" Mode starts a team with less time during the guessing phase, but time is added with each Target Word guessed. "No Skip" Mode allow the game creator to force all captains to give clues one-by-one in the order they are presented.'
  },
  {
    date: 'August 2021',
    version: '1.12.6',
    name: 'Game Mode Modification: "Bomb Words"',
    desc: 'Exploded Bombs Subtract 2 points now instead of the previous 3 point deduction.'
  },
  {
    date: 'August 2021',
    version: '1.12.5',
    name: 'New Themed Word Added',
    desc: 'Added additional words to the Sports Themed word set. Now including more Olympic sports and famous Olympians.'
  },
  {
    date: 'July 2021',
    version: '1.12.3',
    name: 'Double-click Bug Fix',
    desc: 'Double clicking on the "Ready" button could advance the game past the desired guessing phase causing the team to lose a possible turn. This issue is now fixed.'
  },
  {
    date: 'July 2021',
    version: '1.12.0',
    name: 'Dark Mode',
    desc: 'Adds Dark Mode and Theme Toggle. Burn your retina no more!'
  },
  {
    date: 'July 2021',
    version: '1.11.1',
    name: 'Fixed Word Association Bug',
    desc: 'Fixed bug around word associations not showing up in the write phase.'
  },
  {
    date: 'July 2021',
    version: '1.11.0',
    name: 'Difficulty Gauge + New Themed Word Sets',
    desc: 'Added a difficulty gauge in game lobby to show a visual for how difficult the game will be based on the word sets selected. Added new word sets added to the word bank with themes: Carnival, Clothing, Movies'
  },
  {
    date: 'July 2021',
    version: '1.10.0',
    name: 'Sound Effects',
    desc: 'Adds sound effects to game actions. ticking timers, earned points, round begin, bomb explosions, double words, etc. "Sound effects obtained from https://www.zapsplat.com." Thanks ZapSplat!'
  },
  {
    date: 'June 2021',
    version: '1.9.1',
    name: 'New Themed Word Sets Added + Sizing Improvements',
    desc: 'New word sets added to the word bank with themes: Business, Candy, Pirates, School, Musical Instruments. Also improved sizing and layout for games with long custom game codes. '
  },
  {
    date: 'June 2021',
    version: '1.9.0',
    name: 'Easy, Medium, Hard Word Sets',
    desc: 'Split all word themes into 3 possible sub categories: easy, medium, hard sets for more difficulty control.'
  },
  {
    date: 'June 2021',
    version: '1.8.0',
    name: 'Extra Explosive Bomb Words',
    desc: 'Game mode modification. When a bomb word is clicked, it now causes the turn to end immediatley in addition to the previous effect of - 3 points to the guessing team. This makes this game mode more unique and hopefully more fun!'
  },
  {
    date: 'June 2021',
    version: '1.7.7',
    name: 'Fixed Small Screen Size Bug',
    desc: 'Resolved the issue causing the step-by-step images falling off the end of the screen in the how-to-play page.'
  },
  {
    date: 'June 2021',
    version: '1.7.6',
    name: 'Feeling Generous?',
    desc: 'Added a "Support the Developer" page with a buy-me-a-coffee link and a crypto address for dogecoin deposits. Send me $DOGE!'
  },
  {
    date: 'June 2021',
    version: '1.7.2',
    name: 'New Themed Word Sets Added',
    desc: 'New word sets added to the word bank with themes: Geography, College Sports, NFL, NBA, MLS, NHL, & MLB.'
  },
  {
    date: 'June 2021',
    version: '1.7.0',
    name: 'New Gameplay Option: "BOMB Words"',
    desc: 'When BOMB Word mode is enabled, the lowest voted of the 16 banned words becomes a bomb. It is partially visible to the guessing team, but accidentally mention it and their score will explode! Bombs subtract 2 points from the guessing team.'
  },
  {
    date: 'May 2021',
    version: '1.6.4',
    name: 'New Themed Word Sets Added',
    desc: 'New word sets added to the word bank with themes: Weather, Superheroes.'
  },
  {
    date: 'May 2021',
    version: '1.6.2',
    name: 'Added "How to Play" guide',
    desc: 'New page added with step-by-step instructions explaining how to play the game.'
  },
  {
    date: 'May 2021',
    version: '1.6.1',
    name: 'New Themed Word Sets Added',
    desc: 'New word sets added to the word bank with themes: Resturants, Companies, Orlando Scene. Also split out more word sets into basic (Pop) and advanced (Adv) words.'
  },
  {
    date: 'May 2021',
    version: '1.6.0',
    name: 'Top 16 Indicator',
    desc: 'Now during the voting phase, the top 16 words based on vote count are marked with a "top 16" icon to show they are guaranteed to be one of your team\'s Banned Words. Any words that are tied for the last few spots are marked with a smaller & faded version of the icon.'
  },
  {
    date: 'May 2021',
    version: '1.5.2',
    name: 'New Themed Word Sets Added',
    desc: 'New word sets added to the word bank with themes: Nintendo, Star Wars, Christmas, Disney.'
  },
  {
    date: 'May 2021',
    version: '1.5.0',
    name: 'Word Set Toggles and Tracking used Target Words',
    desc: 'When creating a game, the available themed word sets can be toggled on/off to customize the target words in your game. Also, target words used will not be recycled in the same game until nearly all available words are exhausted.'
  },
  {
    date: 'April 2021',
    version: '1.4.1',
    name: 'New Themed Word Sets Added',
    desc: 'New word sets added to the word bank with themes: Food, Harry Potter, Animals.'
  },
  {
    date: 'April 2021',
    version: '1.4.0',
    name: 'Adjust Round Score',
    desc: 'Scores can be adjusted in the Round Results screen by clicking one of the words and confirming that you intend to mark it as earned or not earned. A (+/-) indicator shows on the words that have been manually edited in this way.'
  },
  {
    date: 'April 2021',
    version: '1.3.3',
    name: 'Prevent Empty Round Creation',
    desc: 'Fixed a bug which allowed multiple new game rounds to be created when "New Round" is clicked from multiple devices at once.'
  },
  {
    date: 'April 2021',
    version: '1.3.2',
    name: 'New Themed Word Sets Added',
    desc: 'New word sets added to the word bank with themes: Military, Containers, Time.'
  },
  {
    date: 'April 2021',
    version: '1.3.0',
    name: 'New Gameplay Options: Double Point Words',
    desc: 'Double point word gameplay now avaiable! When selected as a gameplay option, one of the target words will be a double point word. The captain and ther team will not see which word is worth double points until it is guessed. Additionally, the top voted Banned Word is worth double points as well. (in the event of a vote tie, one of the top voted words is selected as the double point baned word).'
  },
  {
    date: 'April 2021',
    version: '1.2.3',
    name: 'New Themed Word Sets Added',
    desc: 'New word sets added to the word bank with themes: Spring, Summer, Birthday, Camping, Cooking, Human Body & Science.'
  },
  {
    date: 'March 2021',
    version: '1.2.1',
    name: 'Coming Soon and Recent Changes Pages',
    desc: 'Added pages for recent additions and future features so you can see what\'s going on.',
  },
  {
    date: 'March 2021',
    version: '1.2.0',
    name: 'Merge Duplicate Banned Words',
    desc: 'Exact matches of Banned Word entries are merged before the "top 16" are selected for the guessing phases. Votes across duplicate Banned Words are combined so no vote goes uncounted.'
  },
  {
    date: 'March 2021',
    version: '1.1.2',
    name: 'New Themed Word Set Added: History',
    desc: 'Historical figures and historical event themed words added to word bank.'
  },
  {
    date: 'March 2021',
    version: '1.1.0',
    name: 'Multi-Round Support',
    desc: 'Games now track scores across multiple rounds. Results screen shows round-by-round breakdown.'
  },
  {
    date: 'March 2021',
    version: '1.0.2',
    name: 'New Themed Word Set Added: St Patricks Day',
    desc: 'St Patrick\'s day themed set added to word bank.'
  },
  {
    date: 'March 2021',
    version: '1.0.1',
    name: 'Votes Matter',
    desc: 'Fixed a bug where the votes cast were essentially not used in determining the "top 16" Banned Words.'
  },
  {
    date: 'March 2021',
    version: '1.0.0',
    name: 'Word Bank B-B-Beefed!',
    desc: '2000+ words added to the bank of target words.'
  },
  {
    date: 'March 2021',
    version: '0.10.0',
    name: 'New Spectator View',
    desc: 'Special view added for spectator mode. Ideal for screensharing while playing with a group.'
  },
  {
    date: 'March 2021',
    version: '0.9.0',
    name: 'Initial release',
    desc: 'Banned Words Online initial release. Game on!'
  },
];

interface IProps {

}

export const RecentChanges: FC<IProps> = ({ }) => {

  const changesTableItem = (changeItem: IChangeItem, i: number) => (
    <Row justify="around" className={classnames('pages-row-item')} key={`change-item=${i}`}>
      <Col xs={3} md={2}>
        <Row>
          <Col xs={12}><h4>{changeItem.version}</h4></Col>
          <Col xs={12}>{changeItem.date}</Col>
        </Row>
      </Col>
      <Col xs={7} md={8}>
        <Row>
          <Col xs={12}><h4>{changeItem.name}</h4></Col>
          <Col xs={12}>{changeItem.desc}</Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <>
      <Header />
      <Container>
        <div className="pages-container">
          <Row justify="around" className={classnames('recent-changes-container')}>
            <Col xs={12}>
              <h2>Recent Updates</h2>
              <h4>A list of noteable improvements I've made to Banned Words Online since initially releasing in March of 2021.</h4>
              <h4>Want to see what's on the horizon? <Link to='/pages/future'>Check out what features are coming soon here.</Link></h4>
            </Col>
            <Col xs={12}>

              <Row justify="around" debug>
                <Col xs={3} md={2}><h4>Version</h4></Col>
                <Col xs={7} md={8}><h4>Description</h4></Col>
              </Row>

              {noteableChanges.map(changesTableItem)}
            </Col>
          </Row >
        </div>
      </Container >
      <Footer />
    </>
  )
}
