import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import { zeroPad } from 'react-countdown';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip'
import {
  FaPlusCircle,
  FaMinusCircle,
  FaBomb,
  FaSkull,
} from "react-icons/fa";

import {
  Button,
  FormInput
} from 'shards-react'

import {
  ConditionalRender,
  Game,
  Team,
  TW,
  BW,
  Word,
  Round,
  GameContext,
  graphQLQuery,
  getTeamForRoundResults,
  getFullGameResultsByCode,
  useMap
} from '../../internal';


interface IProps {
}

export const GameResults: FC<IProps> = ({ }) => {

  var { state: { game, redTeam, blueTeam }, dispatch } = useContext(GameContext)
  var [roundVisibility, setRoundVisiblity, guessedUpdator] = useMap(new Map<number, boolean>());
  var [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (game?.getMyTeam) {
      assembleGameResults();
    }
  }, [game?.id, game?.updater])

  const assembleGameResults = async () => {
    setLoading(true);
    // label the teams for spectator lookup
    const teamLookupMap = game?.teams?.reduce((teamDict: any, currTeam: Team): any => {
      if (!teamDict[currTeam.name]) {
        teamDict[currTeam.name] = currTeam;
      };
      return teamDict;
    }, {});
    // fetch the data for this view so that we're never without it
    var red = await graphQLQuery(getTeamForRoundResults, 'getTeam', { id: teamLookupMap[Game.redTeamName]?.id })
    var redT = new Team(red);

    // get the opposing team's Ttw
    var blue = await graphQLQuery(getTeamForRoundResults, 'getTeam', { id: teamLookupMap[Game.blueTeamName]?.id })
    var blueT = new Team(blue);

    // initially set only the most recent round to visible
    blueT?.rounds?.forEach((_: Round, roundNum: number, arr: Round[]) => {
      roundVisibility.set(roundNum, roundNum === 0);
    });
    setRoundVisiblity(roundVisibility);

    dispatch({ type: 'setRedTeam', team: redT })
    dispatch({ type: 'setBlueTeam', team: blueT })

    setLoading(false);
  };

  const toggleRoundVisibility = (roundNumber: number) => {
    const currVisibility = roundVisibility.get(roundNumber);
    roundVisibility.set(roundNumber, !currVisibility);
    setRoundVisiblity(roundVisibility);
    ReactTooltip.rebuild();
  }

  const confirmToggleBwEarned = async (bw: BW) => {
    const confirmed = window.confirm(`Are you sure you want to toggle the ${bw.mentioned ? 'earned' : 'unearned'} ${bw.doubleValue ? 'double-point' : ''} ${bw.isBombWord() ? 'BOMB' : ''} Banned Word \"${bw.text}\"? This will change the game score.`);
    if (confirmed) {
      setLoading(true);
      bw.adjustMentioned();
      await Word.saveUpdate(bw);
      // notify all player clients to refetch the game results after a change
      await game?.triggerUpdater();
    }
  }

  const confirmToggleTwEarned = async (tw: TW) => {
    const confirmed = window.confirm(`Are you sure you want to toggle the ${tw.guessed ? 'earned' : 'unearned'} ${tw.doubleValue ? 'double-point' : ''} Target Word \"${tw.text}\"? This will change the game score.`);
    if (confirmed) {
      setLoading(true);
      tw.adjustGuessed();
      await Word.saveUpdate(tw);
      // notify all player clients to refetch the game results after a change
      await game?.triggerUpdater();
    }
  }

  const displayTeamTws = (t: Team, roundNum: number) => (
    <Row justify="start" className={classnames('teams-guess-phase-tw', t?.getStyleName())}>
      {/* sacrificed ? */}
      <ConditionalRender visible={!!t?.rounds?.[roundNum].sacrificed}>
        <Col xs={12} key={`team-${t?.name}-sacrificed-indicator`}>
          <Button
            className={classnames('word-button', 'tw', 'active')}>
            <>
              <Row>
                <Col xs={12}>
                  <div className="word-text">Sacrificed!</div>
                </Col>
              </Row>
              {/* Sacrifice Logo */}
              <div className="bomb-word-indicator-container" data-tip data-for="sacrifice">
                <div className={classnames('bomb-word-indicator')}>
                  <FaSkull />
                </div>
              </div>
            </>
          </Button>
        </Col>
      </ConditionalRender>
      {/* tws */}
      {t?.rounds?.[roundNum]?.tws?.map((tw: TW, i: number) => (
        <Col lg={6} xxl={4} key={`team-${t?.name}-tw-${tw.id}`}>
          <Button className={classnames('word-button', 'tw', t?.getStyleName(), { 'active': tw.guessed!, 'skipped': tw.isSkipped() })}
            onClick={() => confirmToggleTwEarned(tw)} >
            <>
              <Row>
                <Col xs={12}>
                  <div className="word-text">{tw.text}</div>
                </Col>
              </Row>
              <ConditionalRender visible={tw?.isDoubleValue()}>
                <div className="double-value-word-flag-container" data-tip data-for="double-value">
                  <div className={classnames('double-value-word-flag')}>
                  </div>
                </div>
              </ConditionalRender>
              <ConditionalRender visible={tw?.isManuallyAdjusted()}>
                <div className={classnames('adjusted-word-indicator')}>
                  <ConditionalRender visible={!!tw?.guessed}>
                    <FaPlusCircle />
                  </ConditionalRender>
                  <ConditionalRender visible={!tw?.guessed}>
                    <FaMinusCircle />
                  </ConditionalRender>
                </div>
              </ConditionalRender>
            </>

          </Button>
        </Col>
      ))}
    </Row>
  );

  const displayTeamBws = (t: Team, roundNum: number) => (
    <Row justify="start" className={classnames('teams-guess-phase-bw', t?.getStyleName())}>
      {/* bws */}
      {t?.rounds?.[roundNum]?.bws?.map((bw: BW, i: number) => (
        <Col lg={6} xxl={4} key={`team-${t?.name}-bw-${bw.id}`}>
          <Button className={classnames('word-button', 'bw', t.getStyleName(), { 'active': bw.mentioned! })}
            onClick={() => confirmToggleBwEarned(bw)} >
            <>
              <Row>
                <Col xs={12}>
                  <div className="word-text">{bw.text}</div>
                </Col>
              </Row>
              {/* Double value */}
              <ConditionalRender visible={bw?.isDoubleValue()}>
                <div className="double-value-word-flag-container" data-tip data-for="double-value">
                  <div className={classnames('double-value-word-flag')}>
                  </div>
                </div>
              </ConditionalRender>
              {/* Bomb */}
              <ConditionalRender visible={bw?.isBombWord()}>
                <div className="bomb-word-indicator-container" data-tip data-for="bomb">
                  <div className={classnames('bomb-word-indicator')}>
                    <FaBomb />
                  </div>
                </div>
              </ConditionalRender>
              {/* Adjusted */}
              <ConditionalRender visible={bw?.isManuallyAdjusted()}>
                <div className={classnames('adjusted-word-indicator')} data-tip="Manually Adjusted">
                  <ConditionalRender visible={!!bw?.mentioned}>
                    <FaPlusCircle />
                  </ConditionalRender>
                  <ConditionalRender visible={!bw?.mentioned}>
                    <FaMinusCircle />
                  </ConditionalRender>
                </div>
              </ConditionalRender>
            </>
          </Button>
        </Col>
      ))}
    </Row>
  );

  // this is so innefficient its not even funny. 
  // TODO: fix it.
  const displayTeamScoreHeader = (t: Team, o: Team, specificRound?: number) => {
    const oppScoreObj = o?.getScore(specificRound);
    const pointsAgainst = oppScoreObj?.bombsHit * Game.bombDeduction;

    const myScoreObj = t?.getScore(specificRound);
    return (
      <Col xs={6} key={`team-results-${t?.name}`}>
        <Row justify="center" align="start">
          <div className={classnames('score-number', t?.getStyleName(), { 'for-round': specificRound !== undefined, 'loading': loading })}>
            <ConditionalRender visible={loading && specificRound === undefined}>
              <Loader
                type="Oval"
                color="#fff"
                height={55}
                width={55}
              />
            </ConditionalRender>
            <ConditionalRender visible={!loading || specificRound !== undefined}>
              <ConditionalRender visible={pointsAgainst > 0}>
                <h5 className="bomb-score-explanation" data-tip data-for="bomb">
                  {zeroPad(myScoreObj?.points)} - {/* visual subtract */}
                  <ConditionalRender visible={oppScoreObj?.bombsHit > 1}>
                    {oppScoreObj?.bombsHit}
                  </ConditionalRender>
                  <FaBomb />
                </h5>
              </ConditionalRender>

              <ConditionalRender visible={myScoreObj?.numSacrificed > 0}>
                <h5 className="sacrifice-score-explanation" data-tip data-for="sacrifice">
                  {zeroPad(myScoreObj?.points)} - 
                  {/* <ConditionalRender visible={oppScoreObj?.numSacrificed > 1}>
                    {oppScoreObj?.numSacrificed}
                  </ConditionalRender>  */}
                  <FaSkull />
                </h5>
              </ConditionalRender>
              {zeroPad(myScoreObj?.points - pointsAgainst - myScoreObj?.numSacrificed) ?? 0}
            </ConditionalRender>
          </div>
        </Row>
        {/* show no round is specified or if the round is open and it is not the most recent round */}
        <ConditionalRender visible={specificRound === undefined || (roundVisibility.get(specificRound) && specificRound > 0)}>
          <Row>
            <Col xs={12}>
              <h2 className={classnames('text-center')}>{t?.name}</h2>
            </Col>
          </Row>
        </ConditionalRender >
      </Col>
    );
  }

  return (
    <div className={classnames('game-results-container')}>

      {/* call for feedback */}
      <ConditionalRender visible={(game?.code?.indexOf('unikey') ?? -1) === -1}>
        <div className="slide-in-message game-results-feedback-link" data-multiline data-tip="Make a suggestion, report an issue, <br/>or share your experience.">
          Have feedback? <a href="https://twitter.com/BannedWordsFun" target="_blank">Send the developer a message.</a>
        </div>
      </ConditionalRender>

      <ConditionalRender visible={!!blueTeam}>

        {/* Each team's score header */}
        <Row justify="center" className={classnames()}>
          <Hidden xs sm>
            <Col xs={12}>
              <h2 className={classnames('text-center', 'descriptor-row', 'game')}>Game Score</h2>
            </Col>
          </Hidden>
          {displayTeamScoreHeader(redTeam!, blueTeam!)}
          {displayTeamScoreHeader(blueTeam!, redTeam!)}
        </Row>

        {blueTeam?.rounds?.map((_: Round, roundNumber: number) => (
          <Row className={classnames('round-results-container', 'descriptor-row-round')} key={`round-num-${roundNumber}`}>
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <h2 className={classnames('text-center', 'descriptor-row', 'round')}
                    onClick={() => toggleRoundVisibility(roundNumber)}>Round {(blueTeam?.rounds?.length ?? 0) - roundNumber}</h2>
                </Col>
                {displayTeamScoreHeader(redTeam!, blueTeam!, roundNumber)}
                {displayTeamScoreHeader(blueTeam!, redTeam!, roundNumber)}
              </Row>
              {/* round details visible */}
              <ConditionalRender visible={roundVisibility.get(roundNumber)}>
                {/* One Guess Phase */}
                <Row className={classnames('teams-guess-phase-results', redTeam?.getStyleName())}>
                  {/* redTeam's Tws  */}

                  <Col xs={6}>
                    {displayTeamTws(redTeam!, roundNumber)}
                  </Col>
                  {/* blueTeam's BWs */}

                  <Col xs={6}>
                    {displayTeamBws(blueTeam!, roundNumber)}
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>
                </Row>

                {/* Another Guess Phase */}
                <Row className={classnames('teams-guess-phase-results', blueTeam?.getStyleName())}>
                  {/* blueTeam's BWs */}
                  <Col xs={6}>
                    {displayTeamBws(redTeam!, roundNumber)}
                  </Col>
                  {/* redTeam's Tws  */}
                  <Col xs={6}>
                    {displayTeamTws(blueTeam!, roundNumber)}
                  </Col>
                </Row>
              </ConditionalRender>

            </Col>
          </Row>
        ))}
      </ConditionalRender>

    </div>
  )
}
