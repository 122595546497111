import {
  Word,
  IWord,
  IModelIncludeOpts,
  EWordType,
  CW
  // IVote
} from '../../internal';


export interface IBWord extends IWord {
  selected?: string
}

export class BW extends Word {
  mentioned: boolean;

  constructor(opts: IBWord) {
    super(opts);
    this.mentioned = opts.selected === 'true';
    this.type = EWordType.bw;
  }

  markAsMentioned() {
    this.mentioned = true;
    return this;
  }

  adjustMentioned() {
    this.mentioned = !this.mentioned;
    this.adjusted = this.isManuallyAdjusted() ? 'false' : 'true';
    return this;
  }

  async forApi(opts?: IModelIncludeOpts): Promise<any> {
    const superJson = await super.forApi(opts);
    var json = {
      ...superJson,
      bombText: this.bombText,
      doubleValue: this.doubleValue,
      selected: this.mentioned ? 'true' : 'false',
      type: EWordType.bw
    }
    if (opts?.withoutDoubleValue) {
      delete json.doubleValue;
    }

    return json;
  }

  static async saveConvertFromCw(cwToConvert: CW, asDoubleValueWord?: boolean, asBombWord?: boolean): Promise<any> {
    var bwJson = await cwToConvert.forApi();
    if (asDoubleValueWord) {
      // gql limitation. better boolean
      // support using strings for now. :(
      bwJson.doubleValue = 'true';
    }
    if (asBombWord) {
      const bwChars = bwJson.text.split('');
      bwJson.bombText = `${bwChars.shift()}${bwChars.map(() => '*').join('')}`;
    }
    var newBw = new BW(bwJson);
    return await Word.saveUpdate(newBw, asDoubleValueWord);
  }
}
