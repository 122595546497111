import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  'Yoga',
  'Fitness',
  'Bathing',
  'Nail Polish',
  'Spa',
  'Gym',
  'Relax',
  'Peace'
])

const medium = new Set([
  'Detox',
  'Stress Relief',
  'Weight Loss',
  'Deep Sleep',
  'Sauna',
  'Waxing',
  'Facial',
  'Retreat',
  'Therapy',
  'Pampering',
  'Massage',
  'Essential Oils',
  'Blood Pressure',
  'Hot Stone Massage',
  'Botox',
  'Day Spa',
  'Manicure',
  'Exfoliation',
  'Eyebrow Threading',
  'Pedicure',
  'Green Tea',
  'Hair Extensions',
  'Jacuzzi',
  'Kickboxing',
  'Lip Wax',
  'Makeup',
  'Meditation',
  'Metabolism',
  'Mindfulness',
  'Power Walking',
  'Resort',
  'Spinning',
  'Spin Class',
  'Yoga',
  'Pilates',
  'Ultrasound',
  'Vegan',
  'Vegetarian',
  'Wellness',
  'Weight Training',
  'Whirlpool',
  'Zen',
]);


const hard = new Set([
  'Deep Tissue Massage',
  'Cold Plunge',
  'Eyebrow Wax',
  'steam baths',
  'Body wraps',
  'herbal compounds',
  'Holistic',
  'Aromatherapy',
  'Reflexology',
  'Hydrotherapy',
  'Acupressure',
  'Cupping Therapy',
  'Chemical Peels',
  'Dry brushing',
  'Hypoallergenic',
  'Anti-Aging',
  'Bikini Wax',
  'Swedish Massage',
  'Tranquility',
])


export default new ThemeSet('Spa Day',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);