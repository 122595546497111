/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGame = /* GraphQL */ `
  mutation CreateGame(
    $input: CreateGameInput!
    $condition: ModelGameConditionInput
  ) {
    createGame(input: $input, condition: $condition) {
      id
      code
      phase
      phaseEndTime
      voteMs
      writeMs
      guessMs
      earnTimeMs
      modes
      teams {
        items {
          id
          name
          gameId
          roundId
          createdAt
          updatedAt
        }
        nextToken
      }
      updater
      newRoundRequest
      customTargets
      usedTargets
      wordSetsOn
      location
      createdAt
      updatedAt
    }
  }
`;
export const updateGame = /* GraphQL */ `
  mutation UpdateGame(
    $input: UpdateGameInput!
    $condition: ModelGameConditionInput
  ) {
    updateGame(input: $input, condition: $condition) {
      id
      code
      phase
      phaseEndTime
      voteMs
      writeMs
      guessMs
      earnTimeMs
      modes
      teams {
        items {
          id
          name
          gameId
          roundId
          createdAt
          updatedAt
        }
        nextToken
      }
      updater
      newRoundRequest
      customTargets
      usedTargets
      wordSetsOn
      location
      createdAt
      updatedAt
    }
  }
`;
export const deleteGame = /* GraphQL */ `
  mutation DeleteGame(
    $input: DeleteGameInput!
    $condition: ModelGameConditionInput
  ) {
    deleteGame(input: $input, condition: $condition) {
      id
      code
      phase
      phaseEndTime
      voteMs
      writeMs
      guessMs
      earnTimeMs
      modes
      teams {
        items {
          id
          name
          gameId
          roundId
          createdAt
          updatedAt
        }
        nextToken
      }
      updater
      newRoundRequest
      customTargets
      usedTargets
      wordSetsOn
      location
      createdAt
      updatedAt
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      round {
        id
        bws {
          nextToken
        }
        cws {
          nextToken
        }
        tws {
          nextToken
        }
        gameId
        teamId
        scoreAdjustment
        createdAt
        updatedAt
      }
      rounds {
        items {
          id
          gameId
          teamId
          scoreAdjustment
          createdAt
          updatedAt
        }
        nextToken
      }
      gameId
      roundId
      createdAt
      updatedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      round {
        id
        bws {
          nextToken
        }
        cws {
          nextToken
        }
        tws {
          nextToken
        }
        gameId
        teamId
        scoreAdjustment
        createdAt
        updatedAt
      }
      rounds {
        items {
          id
          gameId
          teamId
          scoreAdjustment
          createdAt
          updatedAt
        }
        nextToken
      }
      gameId
      roundId
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      round {
        id
        bws {
          nextToken
        }
        cws {
          nextToken
        }
        tws {
          nextToken
        }
        gameId
        teamId
        scoreAdjustment
        createdAt
        updatedAt
      }
      rounds {
        items {
          id
          gameId
          teamId
          scoreAdjustment
          createdAt
          updatedAt
        }
        nextToken
      }
      gameId
      roundId
      createdAt
      updatedAt
    }
  }
`;
export const createRound = /* GraphQL */ `
  mutation CreateRound(
    $input: CreateRoundInput!
    $condition: ModelRoundConditionInput
  ) {
    createRound(input: $input, condition: $condition) {
      id
      bws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      cws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      tws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      gameId
      teamId
      scoreAdjustment
      createdAt
      updatedAt
    }
  }
`;
export const updateRound = /* GraphQL */ `
  mutation UpdateRound(
    $input: UpdateRoundInput!
    $condition: ModelRoundConditionInput
  ) {
    updateRound(input: $input, condition: $condition) {
      id
      bws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      cws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      tws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      gameId
      teamId
      scoreAdjustment
      sacrificed
      createdAt
      updatedAt
    }
  }
`;
export const deleteRound = /* GraphQL */ `
  mutation DeleteRound(
    $input: DeleteRoundInput!
    $condition: ModelRoundConditionInput
  ) {
    deleteRound(input: $input, condition: $condition) {
      id
      bws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      cws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      tws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      gameId
      teamId
      scoreAdjustment
      createdAt
      updatedAt
    }
  }
`;
export const createWord = /* GraphQL */ `
  mutation CreateWord(
    $input: CreateWordInput!
    $condition: ModelWordConditionInput
  ) {
    createWord(input: $input, condition: $condition) {
      id
      text
      type
      selected
      skipped
      doubleValue
      bombText
      votes {
        items {
          id
          wordId
          teamId
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          cwId
          twId
          createdAt
          updatedAt
        }
        nextToken
      }
      teamId
      roundId
      adjusted
      createdAt
      updatedAt
    }
  }
`;
export const updateWord = /* GraphQL */ `
  mutation UpdateWord(
    $input: UpdateWordInput!
    $condition: ModelWordConditionInput
  ) {
    updateWord(input: $input, condition: $condition) {
      id
      text
      type
      selected
      skipped
      doubleValue
      bombText
      votes {
        items {
          id
          wordId
          teamId
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          cwId
          twId
          createdAt
          updatedAt
        }
        nextToken
      }
      teamId
      roundId
      adjusted
      createdAt
      updatedAt
    }
  }
`;
export const deleteWord = /* GraphQL */ `
  mutation DeleteWord(
    $input: DeleteWordInput!
    $condition: ModelWordConditionInput
  ) {
    deleteWord(input: $input, condition: $condition) {
      id
      text
      type
      selected
      skipped
      doubleValue
      bombText
      votes {
        items {
          id
          wordId
          teamId
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          cwId
          twId
          createdAt
          updatedAt
        }
        nextToken
      }
      teamId
      roundId
      adjusted
      createdAt
      updatedAt
    }
  }
`;
export const createWordAssociation = /* GraphQL */ `
  mutation CreateWordAssociation(
    $input: CreateWordAssociationInput!
    $condition: ModelWordAssociationConditionInput
  ) {
    createWordAssociation(input: $input, condition: $condition) {
      id
      cwId
      twId
      tw {
        id
        text
        type
        selected
        skipped
        doubleValue
        bombText
        votes {
          nextToken
        }
        associations {
          nextToken
        }
        teamId
        roundId
        adjusted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateWordAssociation = /* GraphQL */ `
  mutation UpdateWordAssociation(
    $input: UpdateWordAssociationInput!
    $condition: ModelWordAssociationConditionInput
  ) {
    updateWordAssociation(input: $input, condition: $condition) {
      id
      cwId
      twId
      tw {
        id
        text
        type
        selected
        skipped
        doubleValue
        bombText
        votes {
          nextToken
        }
        associations {
          nextToken
        }
        teamId
        roundId
        adjusted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteWordAssociation = /* GraphQL */ `
  mutation DeleteWordAssociation(
    $input: DeleteWordAssociationInput!
    $condition: ModelWordAssociationConditionInput
  ) {
    deleteWordAssociation(input: $input, condition: $condition) {
      id
      cwId
      twId
      tw {
        id
        text
        type
        selected
        skipped
        doubleValue
        bombText
        votes {
          nextToken
        }
        associations {
          nextToken
        }
        teamId
        roundId
        adjusted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVote = /* GraphQL */ `
  mutation CreateVote(
    $input: CreateVoteInput!
    $condition: ModelVoteConditionInput
  ) {
    createVote(input: $input, condition: $condition) {
      id
      wordId
      teamId
      createdAt
      updatedAt
    }
  }
`;
export const updateVote = /* GraphQL */ `
  mutation UpdateVote(
    $input: UpdateVoteInput!
    $condition: ModelVoteConditionInput
  ) {
    updateVote(input: $input, condition: $condition) {
      id
      wordId
      teamId
      createdAt
      updatedAt
    }
  }
`;
export const deleteVote = /* GraphQL */ `
  mutation DeleteVote(
    $input: DeleteVoteInput!
    $condition: ModelVoteConditionInput
  ) {
    deleteVote(input: $input, condition: $condition) {
      id
      wordId
      teamId
      createdAt
      updatedAt
    }
  }
`;
export const createTimeSync = /* GraphQL */ `
  mutation CreateTimeSync(
    $input: CreateTimeSyncInput!
    $condition: ModelTimeSyncConditionInput
  ) {
    createTimeSync(input: $input, condition: $condition) {
      id
      clientUTC
      createdAt
      updatedAt
    }
  }
`;
export const updateTimeSync = /* GraphQL */ `
  mutation UpdateTimeSync(
    $input: UpdateTimeSyncInput!
    $condition: ModelTimeSyncConditionInput
  ) {
    updateTimeSync(input: $input, condition: $condition) {
      id
      clientUTC
      createdAt
      updatedAt
    }
  }
`;
export const deleteTimeSync = /* GraphQL */ `
  mutation DeleteTimeSync(
    $input: DeleteTimeSyncInput!
    $condition: ModelTimeSyncConditionInput
  ) {
    deleteTimeSync(input: $input, condition: $condition) {
      id
      clientUTC
      createdAt
      updatedAt
    }
  }
`;
