import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "backyard",
  "basketball hoop",
  "bench",
  "birdbath",
  "doghouse",
  "driveway",
  "edger",
  "fence",
  "flower",
  "garage",
  "garbage can",
  "garden",
  "garden maze",
  "gate",
  "grass",
  "land",
  "lawn",
  "pond",
  "pool",
  "rocks",
  "shovel",
  "sod",
  "soil",
  "swimming pool",
  "swing",
  "trampoline",
  "trash can",
  "tree",
  "walkway",
  "weeds",
  "welcome mat",
  "yard",
]);

const medium = new Set([
  "back porch",
  "back stoop",
  "bulbs",
  "bush",
  "carport",
  "compost bin",
  "downspout",
  "flower garden",
  "flowerbed",
  "front porch",
  "front stoop",
  "fruit tree",
  "gutter",
  "hammock",
  "hedges",
  "herb garden",
  "hose",
  "hummingbird",
  "lawnmower",
  "orchard",
  "path",
  "patio",
  "potted plant",
  "planter",
  "porch",
  "rake",
  "shed",
  "shrub",
  "sprinkler",
  "stoop",
  "terrace",
  "vegetable garden",
  "vine",
  "wading pool",
  "mulch",
  "gravel",
  "tiki torch",
  "rope swing",
  "tire swing",
]);

const hard = new Set([

]);


export default new ThemeSet('Backyard',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);