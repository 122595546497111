export * from './graphql'
export * from './round'
export * from './team'
export * from './game'
export * from './lobby'
export * from './timesync'

export interface IGqlCollection<T> {
    items: T[],
    nextToken: string
}