import { Promise } from 'bluebird'

import {
  ITimeSync,
  IModelIncludeOpts,
  DbEntity,
  graphQLMutation,
  graphQLQuery,
  createTimeSync,
  getTimeSync
} from '../internal';

export class TimeSync extends DbEntity {
  clientTime?: Date;
  clientZone?: string;
  serverTime?: Date;
  serverZone?: string;
  ping: number;
  offset: number;

  constructor(opts?: ITimeSync) {
    super(opts);
    this.clientTime = new Date(opts?.clientUTC ?? '');
    this.serverTime = new Date(opts?.createdAt ?? '');
    this.ping = 0;
    this.offset = 0;

    if (opts?.clientUTC) {
      var roundTripTime = Date.now() - this.clientTime.getTime();
      this.ping = Math.round(roundTripTime/2);
      this.offset = this.serverTime.getTime() - this.clientTime.getTime() - this.ping;
      console.log(`ping: ${this.ping} offset: ${this.offset}`)
    }
  }

  static async findById(id: string) {
    try {
      const sync: ITimeSync = await graphQLQuery(getTimeSync, 'getTimeSync', {
        id
      });
      return new TimeSync(sync);
    } catch (err) {
      console.log(err);
    }
  }

  async forApi(opts?: IModelIncludeOpts) {
    const superJson = await super.forApi(opts);
    var toApi = {
      ...superJson,
      clientUTC: new Date().toISOString()
    };

    return toApi;
  }

  static async saveNew(): Promise<string> {
    // console.log('[save] create game');
    const timeToSave = new TimeSync();
    const timeEntity = await timeToSave!.forApi()
    await graphQLMutation(createTimeSync, timeEntity)
    return timeEntity.id!;
  }

}