import { ThemeSet, EDifficulty } from '../../index';

// list from here: https://www.thegamegal.com/wp-content/uploads/2011/11/Pictionary-Words-Medium.pdf

const easy = new Set([
  "horse",
  "door",
  "round",
  "baseball",
  "queen",
  "hockey",
  "beach",
  "dog",
  "frog",
  "cake",
  "circus",
  "mailman",
  "bicycle",
  "toast",
  "rug",
  "owl",
  "pajamas",
  "bathroom",
  "school",
  "soda",
  "ticket",
  "hopscotch",
  "popsicle",
  "muffin",
  "sunflower",
  "sunburn",
  "puzzle",
  "gumball",
  "elf",
  "lamp",
  "grill",
  "glue stick",
  "dollar",
  "puppet",
  "dentist",
  "monster",
])

export const medium = new Set([
  "song",
  "trip",
  "backbone",
  "bomb",
  "treasure",
  "garbage",
  "park",
  "pirate",
  "ski",
  "state",
  "whistle",
  "palace",
  "coal",
  "dominoes",
  "photograph",
  "computer",
  "aircraft",
  "hot",
  "salt and pepper",
  "key",
  "iPad",
  "whisk",
  "lawnmower",
  "mattress",
  "pinwheel",
  "battery",
  "cowboy",
  "password",
  "skate",
  "electricity",
  "lightsaber",
  "thief",
  "teapot",
  "deep",
  "spring",
  "nature",
  "shallow",
  "outside",
  "America",
  "roller",
  "blading",
  "gingerbread",
  "man",
  "bowtie",
  "half",
  "spare",
  "wax",
  "light bulb",
  "platypus",
  "music",
  "sailboat",
  "popsicle",
  "brain",
  "birthday cake",
  "skirt",
  "knee",
  "pineapple",
  "tusk",
  "sprinkler",
  "money",
  "spool",
  "lighthouse",
  "doormat",
  "face",
  "flute",
  "snowball",
  "purse",
  "gate",
  "suitcase",
  "stomach",
  "doghouse",
  "scale",
  "peach",
  "newspaper",
  "watering can",
  "hook",
  "beaver",
  "french fries",
  "beehive",
  "artist",
  "flagpole",
  "camera",
  "hair dryer",
  "mushroom",
  "toe",
  "pretzel",
  "TV",
  "quilt",
  "chalk",
  "dollar",
  "chin",
  "swing",
  "garden",
  "boot",
  "cello",
  "rain",
  "clam",
  "pelican",
  "stingray",
  "fur",
  "blowfish",
  "rainbow",
  "happy",
  "fist base",
  "storm",
  "mitten",
  "easel",
  "nail",
  "sheep",
  "stoplight",
  "coconut",
  "crib",
  "hippopotamus",
  "ring",
  "seesaw",
  "plate",
  "fishing",
  "pole",
  "bell pepper",
  "front porch",
  "cheek",
  "video",
  "camera",
  "washing machine",
  "telephone",
  "silverware",
  "barn",
  "snowflake",
  "bib",
  "flashlight",
  "skirt",
  "top hat",
  "swimming pool",
  "tusk",
  "radish",
  "peanut",
  "peanut butter",
  "spool",
  "poodle",
  "potato",
  "face",
  "shark",
  "fang",
  "snowball",
  "waist",
  "spoon",
  "gate",
  "bottle",
  "mail",
  "sheep",
  "lobster",
  "ice",
  "crib",
  "lawn mower",
  "bubble",
  "seesaw",
  "pencil",
  "cheeseburger",
  "hopscotch",
  "corner",
  "cheek",
  "popcorn",
  "telephone",
  "yo-yo",
  "seahorse",
  "snowflake",
  "spine",
  "desk",
  "taxi cab",
  "standing ovation",
  "alarm clock",
  "flagpole",
  "money",
  "wallet",
  "ballpoint pen",
  "wedding ring",
  "spy",
  "baby-sitter",
  "acne",
  "bib",
  "piece",
  "pawn",
  "astronaut",
  "tennis",
  "shoes",
  "blue jeans",
  "twig",
  "outer space",
  "banister",
  "batteries",
  "doghouse",
  "campsite",
  "plumber",
  "bedbug",
  "throne",
  "tiptoe",
  "log",
  "mute",
  "pogo stick",
  "stoplight",
  "ceiling fan",
  "bedspread",
  "bite",
  "stove",
  "windmill",
  "nightmare",
  "stripe",
  "spring",
  "wristwatch",
  "eat",
  "matchstick",
  "bobsled",
  "bonnet",
  "flock",
  "sprinkler",
  "living room",
  "laugh",
  "snuggle",
  "sneeze",
  "bud",
  "headache",
  "slam dunk",
  "saddle",
  "ironing board",
  "bathroom scale",
  "kiss",
  "shopping cart",
  "shipwreck",
  "funny",
  "glide",
  "candlestick",
  "grandfather",
  "rocket",
  "home movies",
  "seesaw",
  "rollerblades",
  "smog",
  "goblin",
  "coach",
  "claw",
  "cloud",
  "shelf",
  "recycle",
  "front porch",
  "earache",
  "robot",
  "foil",
  "rib",
  "robe",
  "crumb",
  "paperback",
  "hurdle",
  "rattle",
  "fetch",
  "date",
  "iPod",
  "dance",
  "cello",
  "flute",
  "dock",
  "prize",
  "brass",
  "firefighter",
  "huddle",
  "easel",
  "pigpen",
  "bowtie",
  "bunk bed",
  "fiddle",
  "baseboards",
  "letter opener",
  "photographer",
  "magic",
  "Old Spice",
]);

const hard = new Set([

])



export default new ThemeSet('Pictionary',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);