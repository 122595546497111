import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  'bagpipe',
  'banshee',
  'celebrate',
  'Celtic',
  'clover',
  'coins',
  'Dublin',
  'emerald',
  'Isle',
  'fortune',
  'four-leaf clover',
  'Gaelic',
  'gold',
  'good',
  'luck',
  'green',
  'harp',
  'holiday',
  'Ireland',
  'jig',
  'legend',
  'leprechaun',
  'limerick',
  'luck',
  'magic',
  'magical',
  'March',
  'mischief',
  'mischievous',
  'Patrick',
  'patron',
  'pot of gold',
  'potato',
  'rainbow',
  'Saint Patrick',
  'seventeenth',
  'shamrock',
  'tradition',
  'walking stick',
  'beer',
  'jamison',
  'famine',
  'parade',
  'Notre Dame',
  'Guiness',
  'Chicago',
  'Shepherds Pie',
  'Cabbage',
  'Beef Stew',
  'Irish Coffee',
  'Corned Beef and Cabbage',
  'Bangers & Mash',
  'Car Bomb',
  'Liam Neeson',
  'Conor McGregor',
  'Conan',
  'Bono'
]);

const hard = new Set([

])



export default new ThemeSet('The Irish',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);