import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "airplane",
  "balloon",
  "bicycle",
  "bike",
  "car",
  "pilot",
  "plane",
  "police car",
  "race car",
  "rocket",
  "sailboat",
  "school bus",
])

const medium = new Set([
  "aircraft carrier",
  "armored car",
  "ambulance",
  "automobile",
  "baby carriage",
  "battleship",
  "biplane",
  "blimp",
  "boat",
  "bobsled",
  "bomber",
  "boxcar",
  "broomstick",
  "bullet train",
  "cab",
  "cabin cruiser",
  "cable car",
  "caboose",
  "camper",
  "canoe",
  "cargo ship",
  "cart",
  "chairlift",
  "chariot",
  "chopper",
  "clunker",
  "coach",
  "convertible",
  "conveyor belt",
  "coupe",
  "covered wagon",
  "crane",
  "crop duster",
  "cruise ship",
  "cruiser",
  "delivery van",
  "dirt bike",
  "driver",
  "electric car",
  "elevator",
  "engine",
  "escalator",
  "express train",
  "ferry",
  "fire engine",
  "fireboat",
  "fishing boat",
  "flatbed truck",
  "forklift",
  "four-door",
  "four-wheel drive",
  "galleon",
  "garbage truck",
  "glider",
  "go-cart",
  "golf cart",
  "gondola",
  "hang glider",
  "hatchback",
  "haul",
  "hay wagon",
  "hearse",
  "helicopter",
  "hot rod",
  "hot-air balloon",
  "houseboat",
  "hovercraft",
  "hull",
  "bridge",
  "golden gate bridge",
  "hybrid",
  "hydroplane",
  "ice boat",
  "jeep",
  "jet",
  "jet pack",
  "jet ski",
  "jumbo jet",
  "kayak",
  "life raft",
  "lifeboat",
  "light rail",
  "limo",
  "limousine",
  "litter",
  "locomotive",
  "low-rider",
  "magic carpet",
  "minibus",
  "minivan",
  "model T",
  "monorail",
  "moped",
  "motor",
  "motor home",
  "motorboat",
  "motorcycle",
  "mountain bike",
  "oar",
  "ocean liner",
  "off-road vehicle",
  "outboard motor",
  "paddle",
  "parachute",
  "passenger",
  "patrol car",
  "pedal boat",
  "pickup truck",
  "power boat",
  "propeller",
  "pumper truck",
  "punt",
  "push cart",
  "racing car",
  "raft",
  "railroad",
  "railway",
  "ride",
  "riverboat",
  "roadster",
  "tunnel",
  "terminal",
  "grand central station",
  "rover",
  "rowboat",
  "rudder",
  "RV",
  "sail",
  "satellite",
  "sedan",
  "subway",
  "suv",
  "tire",
  "truck",
]);


const hard = new Set([
  "amphibious vehicle",
  "catamaran",
  "clipper ship",
  "eighteen-wheeler",
  "elevated railroad",
  "landing craft",
  "minesweeper",
  "oil tanker",
  "outrigger canoe",
  "paddlewheeler",
  "ragtop",
  "rickshaw",
  "runabout",
  "brooklyn bridge",
])


export default new ThemeSet('Transportation',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);