import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  "McDonald's",
  "Starbucks",
  "Chick-fil-A",
  "Taco Bell",
  "Burger King",
  "Subway",
  "Wendy's",
  "Dunkin'",
  "Domino's",
  "Panera Bread",
  "Pizza Hut",
  "Chipotle",
  "Sonic ",
  "KFC",
  "Olive Garden",
  "Applebee's",
  "Panda Express",
  "Arby's",
  "Popeyes",
  "Little Caesars",
  "Dairy Queen",
  "Buffalo Wild Wings",
  "Chili's",
  "IHOP",
  "Texas Roadhouse",
  "Denny's",
  "Papa John's",
  "Outback Steakhouse",
  "Red Lobster",
  "Cracker Barrel",
  "The Cheesecake Factory",
  "Jimmy John's",
  "Golden Corral",
  "Five Guys ",
  "Red Robin",
  "Waffle House",
  "Jersey Mike's",
  "TGI Fridays",
  "In-N-Out Burger",
  "Steak 'n Shake",
  "P.F. Chang's",
  "Krispy Kreme",
  "Hooters",
  "Del Taco",
  "Firehouse Subs",
  "Ruby Tuesday",
  "Carrabba's",
  "Marco's Pizza",
  "Shake Shack",
  "California Pizza Kitchen",
  "Baskin-Robbins",
  "Bonefish Grill",
  "White Castle",
  "Tropical Smoothie Cafe",
  "Dave & Buster's",
  "Auntie Anne's",
  "First Watch",
  "Checkers",
  "Einstein Bros.",
  "Boston Market",
  "Smoothie King",
  "Mellow Mushroom",
  "Miller's Ale House",
  "Chuck E. Cheese's",
  "Bahama Breeze",
]);

const hard = new Set([

])



export default new ThemeSet('Resturants',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);