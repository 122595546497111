import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);


const medium = new Set([
  "September 11th",
  "Vietnam War",
  "Iraq War",
  "JFK Assassination",
  "Moon Landing",
  "berlin wall",
  "cvil rights movement",
  "cvil war",
  "Gettysburg Address",
  "great recession",
  "challenger explosion",
  "The cold war",
  "revolutionary war",
  "Declaration of Independence",
  "Louisiana Purchase",
  "great depression",
  "Watergate",
  "World War 1",
  "World War 2",
  "war on terror",
  "Boston Tea Party",
  "Holocaust",
  "pearl harbor",
  "selma",
  "occupy wall street",
  "womens march",
  "potato famine"
]);

const hard = new Set([

]);



export default new ThemeSet('Historical Events',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);