import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  'muscle',
  'bone',
  'lungs',
  'nose',
  'brain',
  'stomach',
  'organ',
  'skin',
  'heart',
  'Doctor',
  'Medicine',
  'pill',
  'Heart rate',
  'Operating room',
  'surgery',
  'cure',
  'drug',
  'medication',
  'pill',
  'prescription',
  'capsule',
  'injection',
  'lotion',
  'potion',
  'X-ray',
  'stitches'
])

const medium = new Set([
  'Acute',
  'Biopsy',
  'Chronic',
  'Defibrillator',
  'Epidermis',
  'Fracture',
  'Gland',
  'Inpatient',
  'Outpatient',
  'Relapse',
  'Sutures',
  'Transplant',
  'Vaccine',
  'Physical therapy',
  'emergency room',
  'Blood pressure',
  'Body mass index',
  'life support',
  'Blood work',
  'Electrolytes',
  'psychiatric ward',
  'Prescription',
  'injection',
  'antibiotic',
  'remedy',
  'dose',
  'elixir',
  'medicament',
  'ointment',
  'physic',
  'sedative',
  'tablet',
  'consciousness',
  'Ultrasound',
  'Remission',
  'Biopsy',
  'Blood swab',
  'Heart Attack',
  'tweezers',
  'scissors',
  'Syringe',
  'Needle',
  'Sanitize',
  'swab',
  'sterile',
  'serum',
  'balm',
]);


const hard = new Set([
  'anesthesia',
  'Abrasion',
  'Abscess',
  'Benign',
  'Contusion',
  'Edema',
  'Embolism',
  'Hypertension',
  'Malignant',
  'Prognosis',
  'pharmaceutical',
  'anesthetic',
  'antidote',
  'antiseptic',
  'antitoxin',
  'inoculation',
  'liniment',
  'salve',
  'Blood culture',
  'Fusion',
  'Glucose test',
  'Intubation',
  'spinal tap',
  'Mastectomy',
  'Hypertension',
  'Hypotension',
  'Intravenous',
])


const pro = new Set([
  'Appendectomy',
  'Hysterectomy',
  'Occult blood screen',
  'Coronary bypass',
  'Dialysis',
  'Lumbar puncture',
  'Electrocardiogram',
  'Lesion',
  'Malignant',
  'Myocardial infarction',
  'Sepsis',
  'Thrombosis',
  'forceps',
  'Arthrocentesis',
  'Arthroscopy',
  'Sialocele,',
  'Urethrostomy',
  'Amputation',
  'Pyometra',
  'Prepuceplasty',
  'Thyroidectomy',
  'Eye Enucleation,',
  'Endoscopy',
  'Episioplasty',
  'Cystoscopy',
  'Cystotomy',
  'Colonoscopy',
  'Neuter',
  'Arthrocentesis',
])


export default new ThemeSet('Medical',
  easy,
  medium,
  hard,
  pro,
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);