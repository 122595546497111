import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "Star Wars",
  "Spider-Man",
  "King Kong",
  "The Lord of the Rings",
  "Monsters, Inc.",
  "The Grinch",
  "Titanic",
  "Toy Story",
  "Forrest Gump",
  "Jurassic Park",
  "Finding Dory",
  "Shrek",
  "Finding Nemo",
  "The Lion King",
  "Buzz Lightyear",
  "Mr Potato Head",
  "Slinky Dog",
  "Little Bo Peep",
  "Ariel",
  "Charlotte's Web",
  "Lilo and Stitch",
  "The Iron Giant",
  "The Lego Movie",
  "Mary Poppins",
  "The Muppet",
  "Elf",
])

const medium = new Set([
  "The Princess Bride",
  "Napoleon Dynamite",
  "Remember the Titans",
  "Willy Wonka and the Chocolate Factory",
  "The Nightmare Before Christmas",
  "The Sound of Music",
  "How to Train Your Dragon",
  "The Wizard of Oz",
  "Al's Toy Barn",
  "Despicable Me",
  "Inside Out",
  "Joker",
  "Rocketman",
  "A Star Is Born",
  "Dunkirk",
  "La La Land",
  "Mad Max",
  "The Revenant",
  "Ex Machina",
  "Spectre",
  "Whiplash",
  "Interstellar",
  "The Great Gatsby",
  "Life of Pi",
  "Lincoln",
  "Skyfall",
  "Inception",
  "The Social Network",
  "Toy Story",
  "Alice in Wonderland",
  "Avatar",
  "Star Trek",
  "The Dark Knight",
  "WALL-E",
  "Atonement",
  "Ratatouille",
  "Happy Feet",
  "Chicago",
  "Moulin Rouge!",
  "Pearl Harbor",
  "Traffic",
  "Tarzan",
  "Men in Black",
  "Fargo",
  "Braveheart",
  "Apollo 13",
  "Pocahontas",
  "The Piano",
  "Philadelphia",
  "Mrs. Doubtfire",
  "Dracula",
  "My Cousin Vinny",
  "Terminator",
  "JFK",
  "Ghost",
  "Goodfellas",
  "American Dream",
  "Batman",
  "A Quiet Place",
  "Avengers: Endgame",
  "Black Panther",
  "Jurassic World",
  "Guardians of the Galaxy",
  "Iron Man",
  "The Avengers",
  "Avatar",
  "Transformers",
  "The Dark Knight",
  "Spider-Man",
  "Pirates of the Caribbean",
  "Harry Potter",
  "Men in Black",
  "Titanic",
  "Independence Day",
  "Batman Forever",
  "Batman Returns",
  "Terminator",
  "Ghost",
  "Batman",
  "Back to the Future",
  "Ghostbusters",
  "Top Gun",
  "E.T.",
  "Superman",
  "Moulin Rouge!",
  "Chicago",
  "The Pianist",
  "Brokeback Mountain",
  "The Blind Side",
  "Life of Pi",
  "Gravity",
  "Toy Story 3",
  "Whiplash",
  "The Martian",
  "Finding Neverland",
]);



const hard = new Set([
  "Grease",
  "Beverly Hills",
  "A Beautiful Mind",
  "Gangs of New York",
  "Lost in Translation",
  "Seabiscuit",
  "Million Dollar Baby",
  "The Departed",
  "Babel",
  "Little Miss Sunshine",
  "No Country for Old Men",
  "Atonement",
  "Juno",
  "There Will Be Blood",
  "Slumdog Millionaire",
  "Benjamin Button",
  "Frost/Nixon",
  "The Hurt Locker",
  "District 9",
  "Inglourious Basterds",
  "The King's Speech",
  "127 Hours",
  "Black Swan",
  "Inception",
  "The Social Network",
  "True Grit",
  "Winter's Bone",
  "The Descendants",
  "The Help",
  "Hugo",
  "Moneyball",
  "Argo",
  "Django Unchained",
  "Lincoln",
  "Les Misérables",
  "Silver Linings Playbook",
  "Zero Dark Thirty",
  "12 Years a Slave",
  "American Hustle",
  "Captain Phillips",
  "Dallas Buyers Club",
  "The Wolf of Wall Street",
  "American Sniper",
  "Boyhood",
  "The Grand Budapest Hotel",
  "The Imitation Game",
  "The Theory of Everything",
  "Spotlight",
  "The Big Short",
  "Brooklyn",
  "Mad Max: Fury Road",
  "The Revenant",
  "Moonlight",
  "Arrival",
  "Hacksaw Ridge",
  "Hell or High Water",
  "Hidden Figures",
  "La La Land",
  "Lion",
  "Manchester by the Sea",
  "The Shape of Water",
  "Darkest Hour",
  "Dunkirk",
  "Get Out",
  "Lady Bird",
  "Phantom Thread",
  "The Post",
  "Green Book",
  "Black Panther",
  "Bohemian Rhapsody",
  "Roma",
  "A Star Is Born",
  "Parasite",
  "1917",
  "Ford v Ferrari",
  "The Irishman",
  "Jojo Rabbit",
  "Joker",
  "Marriage Story"
])







export default new ThemeSet('Movies',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);