import React, { FC, useState, useEffect } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { useParams, Link } from "react-router-dom";
import { FaQuestionCircle } from 'react-icons/fa'
import { PieChart } from 'react-minimal-pie-chart';
import ReactTooltip from 'react-tooltip';

import {
  Button,
  FormInput,
  FormTextarea,
  FormCheckbox
} from 'shards-react'

import {
  useMap,
  useSet,
  ISetOfWords,
  MultiSync,
  Game,
  Word,
  Footer,
  ConditionalRender,
  ELobbyStates,
  EGameMode,
  ThemeSet,
  themes,
  getRandomNumBetween,
  EDifficulty,
} from '../internal';

interface IChartData {
  title: string,
  value: number,
  color: string
}

interface IProps {

}

export const Lobby: FC<IProps> = ({ }) => {
  // setup the initial game modes map for the useMap hook
  const initialGameModesMap = new Map<EGameMode, boolean>();
  initialGameModesMap.set(EGameMode.doubleValueWords, true);
  initialGameModesMap.set(EGameMode.oneAtATime, true);
  // initialGameModesMap.set(EGameMode.earnTime, true);
  // initialGameModesMap.set(EGameMode.sacrifice, true);
  // initialGameModesMap.set(EGameMode.bombWords, true);

  const [lobbySelectionState, setLobbySelectionState] = useState<ELobbyStates>(ELobbyStates.landing)
  const [currDesiredGame, setDesiredGameState] = useState<Game>();
  const [currCreateGameCode, setCreateGameCode] = useState<string>('');
  const [currCustomWordBank, setCurrCustomWordBank] = useState<string[]>([]);
  const [currCreateGameVoteSeconds, setCreateGameVoteSeconds] = useState<number>(Game.defaultVoteMs / 1000);
  const [currCreateGameGuessSeconds, setCreateGameGuessSeconds] = useState<number>((initialGameModesMap.has(EGameMode.earnTime) ? Game.defaultEarnTimeStartMs : Game.defaultGuessMs)  / 1000);
  const [currCreateGameWriteSeconds, setCreateGameWriteSeconds] = useState<number>(Game.defaultWriteMs / 1000);
  const [currCreateGameEarnTimeSeconds, setCreateGameEarnTimeSeconds] = useState<number>(Game.defaultEarnTimeAdditionMs / 1000);
  const [currCreateGameModes, setCreateGameModes, gameModeUpdator] = useMap(initialGameModesMap);
  const [currOnWordSets, setOnWordSets, themesUpdator] = useSet(new Set<string>([]));
  const [currEnabledWordCount, setEnabledThemeSetWordCount] = useState<number>(0);
  const [currJoinGameCode, setJoinGameCode] = useState<string>('');
  let history = useHistory();

  const [difficultyChartData, setDifficultyChartData] = useState<IChartData[]>([]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [lobbySelectionState]);
  
  // Sets the initially enabled word sets
  useEffect(() => {
    var enabledWordCount = 0;
    const initiallyOnWordSets = themes.reduce((onSets: Set<string>, currTheme: ThemeSet) => {
      if (!currTheme[EDifficulty.Easy].disabledByDefault && currTheme[EDifficulty.Easy].words.size > 0) {
        onSets.add(currTheme[EDifficulty.Easy].id);
        enabledWordCount += currTheme[EDifficulty.Easy].words.size;
      }
      if (!currTheme[EDifficulty.Medium].disabledByDefault && currTheme[EDifficulty.Medium].words.size > 0) {
        onSets.add(currTheme[EDifficulty.Medium].id);
        enabledWordCount += currTheme[EDifficulty.Medium].words.size;
      }
      if (!currTheme[EDifficulty.Hard].disabledByDefault && currTheme[EDifficulty.Hard].words.size > 0) {
        onSets.add(currTheme[EDifficulty.Hard].id);
        enabledWordCount += currTheme[EDifficulty.Hard].words.size;
      }
      if (!currTheme[EDifficulty.Pro].disabledByDefault && currTheme[EDifficulty.Pro].words.size > 0) {
        onSets.add(currTheme[EDifficulty.Pro].id);
        enabledWordCount += currTheme[EDifficulty.Pro].words.size;
      }
      return onSets;

    }, new Set<string>());

    setEnabledThemeSetWordCount(enabledWordCount);
    setOnWordSets(initiallyOnWordSets);
    calcDifficulty([...initiallyOnWordSets]);
  }, []);
  
  const setupCreateGameView = () => {
    var blankGame = new Game(new MultiSync()).newEntity();
    setDesiredGameState(blankGame);
    setCreateGameCode(blankGame.code);
    setLobbySelectionState(ELobbyStates.createGame);
  }

  const createNewGame = async () => {
    try {
      currDesiredGame!.wordSetsOn = [...currOnWordSets];
      currDesiredGame!.customTargets = currCustomWordBank;
      currDesiredGame!.modes = [...currCreateGameModes.entries?.()].reduce?.((modesArray: EGameMode[], currModeMapEntry: any) => {
        if (currModeMapEntry![1]) {
          // if the mode boolean is true (index-1),
          // then add the mode name (index-0) to the
          // list for the game model's modes array
          modesArray.push(currModeMapEntry![0]);
        }
        return modesArray;
      }, []) ?? [];
      
      await Game.saveNew(currDesiredGame!)
      // send user to the game page
      history.push(`/${currDesiredGame!.code}`);
      return;
    } catch (e) {
      console.log(e)
      return
    }
  }

  const changeCustomWords = (customInput: string) => {
    const individualWords = customInput.split(',').reduce((customBank: string[], word: string) => {
      const trimmedWord = word.trim();
      if (trimmedWord.length > 0) {
        customBank.push(trimmedWord);
      }
      return customBank; 
    }, [])
    setCurrCustomWordBank(individualWords);
  }

  const changeCreateGameCode = (code: string) => {
    const normalizedCode = code.toLowerCase().replace(/[^\w\d\-]/g, '-');

    setCreateGameCode(normalizedCode);
    currDesiredGame?.setCode(normalizedCode);
  }

  const changeGuessSeconds = (val: number) => {
    setCreateGameGuessSeconds(val);
    currDesiredGame!.guessMs = val * 1000;
  }

  const changeVoteSeconds = (val: number) => {
    setCreateGameVoteSeconds(val);
    currDesiredGame!.voteMs = val * 1000;
  }

  const changeWriteSeconds = (val: number) => {
    setCreateGameWriteSeconds(val);
    currDesiredGame!.writeMs = val * 1000;
  }

  const changeEarnTimeBonusSeconds = (val: number) => {
    setCreateGameEarnTimeSeconds(val);
    currDesiredGame!.earnTimeMs = val * 1000;
  }

  const divideBySeconds = (ms: number = 0) => {
    return ms / 1000;
  }

  const setupJoinGameView = () => {
    setLobbySelectionState(ELobbyStates.joinGame);

  }

  const updateJoinGameCode = (code: string) => {
    return setJoinGameCode(code.toLowerCase().replace(/[^\w\d\-]/g, '-'));
  }

  const attemptJoinGame = () => {
    history.push(`/${currJoinGameCode}`);
  }

  const handlePossibleEnterKey = (action: string, event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (action === 'join') {
        attemptJoinGame()
      } else if (action === 'create') {
        createNewGame()
      }
    }
  }

  const isGameModeActive = (mode: EGameMode) => {
    return currCreateGameModes.get(mode);
  }

  const toggleGameMode = (mode: EGameMode) => {
    const modeWasActive = isGameModeActive(mode);
    const modeNowActive = !modeWasActive;
    currCreateGameModes.set(mode, modeNowActive);
    setCreateGameModes(currCreateGameModes);

    // if we're changing to or from the earnTime game mode,
    // we should adjust the default times for the game type
    if (mode === EGameMode.earnTime) {
      if (modeNowActive) {
        changeGuessSeconds(Game.defaultEarnTimeStartMs / 1000);
      } else {
        changeGuessSeconds(Game.defaultGuessMs / 1000);
      }
    }
  }

  const calcDifficulty = (enabledSets: string[]) => {
    // calculate the difficulty based on the allowed words
    // (possibly change it to store allowed vs off-word sets)
    // and populate the difficultyChartData to update the chart
    const difficultyCounts = enabledSets.reduce((emhCounts: any, currSetName: string) => {
      const emh = currSetName.match(/.*-(\w*)$/)?.[1] ?? 'unknown';
      emhCounts[emh]++;
      return emhCounts;
    }, { [EDifficulty.Easy]: 0, [EDifficulty.Medium]: 0, [EDifficulty.Hard]: 0, [EDifficulty.Pro]: 0, unknown: 0 });

    setDifficultyChartData([
      { title: 'Easy', value: difficultyCounts[EDifficulty.Easy] / enabledSets.length, color: 'lightgreen' },
      { title: 'Medium', value: difficultyCounts[EDifficulty.Medium] / enabledSets.length, color: '#e6e600' },
      { title: 'Hard', value: difficultyCounts[EDifficulty.Hard] / enabledSets.length, color: 'orange' },
      { title: 'Pro', value: difficultyCounts[EDifficulty.Pro] / enabledSets.length, color: '#ec3f0b' },
      { title: 'Custom', value: difficultyCounts[EDifficulty.Unknown] / enabledSets.length, color: 'grey' },
    ]);
  }

  const toggleWordTheme = (themeName: string, numWordsInTheme: number) => {
    if (currOnWordSets.has(themeName)) {
      setEnabledThemeSetWordCount(currEnabledWordCount - numWordsInTheme);
      currOnWordSets.delete(themeName);
    } else {
      currOnWordSets.add(themeName);
      setEnabledThemeSetWordCount(currEnabledWordCount + numWordsInTheme);
    }
    const wordSetsOnArray = [...currOnWordSets];
    currDesiredGame!.wordSetsOn = wordSetsOnArray;

    calcDifficulty(wordSetsOnArray);
    setOnWordSets(currOnWordSets);
  }


  const toggleAllByDifficulty = (diff: EDifficulty) => {
    const shouldToggleDifficultyOff = [...currOnWordSets].some((setId: string) => {
      // console.log('checking ' + setId);
      return setId.indexOf(`-${diff}`) >= 0;
    });
    // console.log(`should toggle ${diff} ${shouldToggleDifficultyOff ? 'on' : 'off'}`)
    var wordCountChange = 0;
    themes.forEach((currTheme: ThemeSet) => {
      if (currOnWordSets.has(currTheme[diff].id)) {
        if (shouldToggleDifficultyOff) {
          currOnWordSets.delete(currTheme[diff].id);
          wordCountChange -= currTheme[diff].words.size;
        }
      } else {
        if (!shouldToggleDifficultyOff && currTheme[diff].words.size > 0) {
          currOnWordSets.add(currTheme[diff].id);
          wordCountChange += currTheme[diff].words.size;
        }
      }
    });
    setEnabledThemeSetWordCount(currEnabledWordCount + wordCountChange);
    setOnWordSets(currOnWordSets);
    calcDifficulty([...currOnWordSets]);
  }

  const buildToggleableWordSetButtonsFromDifficulties = (theme: ThemeSet, index: number) => {
    return [EDifficulty.Easy, EDifficulty.Medium, EDifficulty.Hard, EDifficulty.Pro].map((diff: EDifficulty) => {
      const wordsInSet = [...theme[diff].words.values()];
      return (
        <ConditionalRender visible={theme[diff].words.size !== 0 && !theme.hidden} key={`theme-${theme[diff].id}-check`}>
          <Col sm={6} lg={4} xxl={3}
            className={classnames('text-center')}
            key={`theme-${theme[diff].name}-${index}`}>
            <Button
              className={classnames('theme-toggle-btn', 'one-hundo', { 'available-theme': currOnWordSets.has(theme[diff].id) })}
              onClick={() => toggleWordTheme(theme[diff].id, theme[diff].words.size)}>
              <>
                <FormCheckbox
                  className="one-hundo"
                  small
                  checked={currOnWordSets.has(theme[diff].id)} />
                <ConditionalRender visible={theme[diff].showAsNew === true}>
                  <div className={classnames('new-theme')}>NEW</div>
                </ConditionalRender>
                <span className={classnames('theme-toggle-text')}>{theme[diff].name}</span>
                <span className={classnames('theme-difficulty', `${theme[diff].difficulty}`)}>{ThemeSet.tagFromDifficulty(theme[diff].difficulty)}</span>
                <span className={classnames('theme-set-word-count')} 
                data-multiline data-place="top" 
                data-tip={`
                  ${theme[diff].name} - ${ThemeSet.tagFromDifficulty(theme[diff].difficulty)}<br/>
                  -------------<br/>
                  ${wordsInSet[getRandomNumBetween(0, theme[diff].words.size / 2)]?.toLowerCase()}<br/>
                  ${wordsInSet[getRandomNumBetween(0, theme[diff].words.size / 2)]?.toLowerCase()}<br/>
                  (and ${theme[diff].words.size - 2} more)
                `}>
                  ({theme[diff].words.size})
                </span>
              </>
            </Button>
          </Col>
        </ConditionalRender>
      );
    });
  };

  return (
    <div className={classnames('lobby-container')}>
      <h5 className={classnames('header-banner',)}><Link to="/pages/support">Enjoying the game? Please consider donating :)</Link></h5>
      <div className={classnames('above-fold')}>
        <Row justify="center">
          <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>
          <Col xs={12}>
            <h2 className={classnames('text-center')}>Welcome to Banned Words Online!</h2>

            <p className={classnames('text-center')}>Play Banned Words on multiple devices.</p>
            <h4 className="text-center seo-description">Banned Words is a free online word game. Remote play with friends on multiple devices or play in person!</h4>
          </Col>
        </Row>

        <div className="slide-in-message video-recommendation" data-multiline data-tip="Join a video session with friends <br/>like Google meet or Zoom and <br/> screenshare the spectator view.">
          Remote play best with video!
        </div>

        <ConditionalRender visible={lobbySelectionState === ELobbyStates.landing}>
          <Row justify="center">
            <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>
            {/* welcome view */}
            <Col xs={10} sm={5} md={4}>
              <Button
                className={classnames('huge')}
                onClick={setupCreateGameView}>
                Create a Game
              </Button>
            </Col>
            <Col xs={10} sm={5} md={4}>
              <Button
                className={classnames('huge')}
                onClick={setupJoinGameView}>
                Join a Game
              </Button>
            </Col>
          </Row>
        </ConditionalRender>

        <ConditionalRender visible={lobbySelectionState === ELobbyStates.landing}>

          <Row justify="center">
            <Col xs={10} sm={6} md={4} lg={3} xxl={2}>
              <Button
                className={classnames('not-huge')}
                onClick={() => history.push(`/pages/howToPlay`)}>
                <b>How To Play</b>
              </Button>
            </Col>
          </Row>

        </ConditionalRender>

        {/* create game view */}
        <ConditionalRender visible={lobbySelectionState === ELobbyStates.createGame}>
          <Row justify="center">
            <Col xs={10} sm={6}>
              <FormInput
                className={classnames('huge')}
                placeholder="Enter a new Game Code"
                value={currCreateGameCode}
                autoFocus={true}
                onKeyUp={handlePossibleEnterKey.bind(null, 'create')}
                onChange={(ev) => { changeCreateGameCode(ev.target.value) }} />
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={10} sm={5} md={4}>
              <Button
                className={classnames('huge', 'green')}
                onClick={createNewGame}>
                Start!
              </Button>
            </Col>
            <Col xs={10} sm={5} md={4}>
              <Button
                className={classnames('huge')}
                onClick={() => setLobbySelectionState(ELobbyStates.landing)}>
                Back
              </Button>
            </Col>
          </Row>
          {/* Game options */}
          <Row justify="center" className="game-option">
            {/* Game Timing opions */}
            <Col xs={10} sm={5} lg={4}>
              <Row>
                <Col xs={12}>
                  <h4 className={classnames('text-center')}>Write Phase (seconds)</h4>
                  <FormInput
                    className="one-hundo"
                    type="number"
                    value={currCreateGameWriteSeconds}
                    onChange={(ev) => { changeWriteSeconds(ev.target.value) }} />
                </Col>
              </Row>
              <Row justify="center" className="game-option">
                <Col xs={12}>
                  <h4 className={classnames('text-center')}>Vote Phase (seconds)</h4>
                  <FormInput
                    className="one-hundo"
                    type="number"
                    value={currCreateGameVoteSeconds}
                    onChange={(ev) => { changeVoteSeconds(ev.target.value) }} />
                </Col>
              </Row>
              <Row justify="center" className="game-option">
                <Col xs={12}>
                  <h4 className={classnames('text-center')}>Guess Phase (seconds)</h4>
                  <FormInput
                    className="one-hundo"
                    type="number"
                    value={currCreateGameGuessSeconds}
                    onChange={(ev) => { changeGuessSeconds(ev.target.value) }} />
                </Col>
              </Row>
              {/* setting for additional time in earn-time game mode*/}
              <ConditionalRender visible={isGameModeActive(EGameMode.earnTime)}>
                <Row justify="center" className="game-option">
                  <Col xs={12}>
                    <h4 className={classnames('text-center')}>Earned Time Bonus (seconds)</h4>
                    <FormInput
                      className="one-hundo"
                      type="number"
                      value={currCreateGameEarnTimeSeconds}
                      onChange={(ev) => { changeEarnTimeBonusSeconds(ev.target.value) }} />
                  </Col>
                </Row>
              </ConditionalRender>
            </Col>

            {/* Game Modes */}
            <Col sm={5} lg={4}>
              <Row justify="center" className="game-option">
                <Col>
                  <h3 className={classnames('text-center')}>Optional Game Modes</h3>
                  <h5 className={classnames('text-center sub-title')}><em>All modes are compatible with one another.</em></h5>
                </Col>
                {/* Double Points Mode */}
                <Col xs={12}  className={initialGameModesMap.has(EGameMode.doubleValueWords) ? 'featured-game-mode' : ''}>
                  <FormCheckbox
                    className="one-hundo"
                    small
                    checked={!!currCreateGameModes.get(EGameMode.doubleValueWords)}
                    onChange={() => toggleGameMode(EGameMode.doubleValueWords)}>
                    <h4 className="game-option-title">Double Point Words</h4>
                    <span className="attn-indicator">REWARDING</span>
                    <p className="game-option-description">Each round, the <strong>highest</strong> voted <em>Banned Word</em> and one random <em>Target Word</em> will be worth <b>double points</b>. The guessing team cannot see which <em>Target Word</em> is worth double until it is guessed.</p>
                  </FormCheckbox>
                </Col>

                {/* Bomb Words Mode */}
                <Col xs={12}  className={initialGameModesMap.has(EGameMode.bombWords) ? 'featured-game-mode' : ''}>
                  <FormCheckbox
                    className="one-hundo"
                    small
                    checked={!!currCreateGameModes.get(EGameMode.bombWords)}
                    onChange={() => toggleGameMode(EGameMode.bombWords)}>
                    <h4 className="game-option-title">Bomb Words</h4>
                    <span className="attn-indicator">STRATEGIC</span>
                    <p className="game-option-description">Each round, one of the <em>Banned Words</em> becomes a <em>BOMB Word</em>. The guessing team can see the first letter of the <em>BOMB Word</em> during the Guessing Phase, but when it is mentioned, the bomb will explode causing their turn to end immediately and 2 of their points to be destroyed! Watch out, B-B-BOMB!</p>
                  </FormCheckbox>
                </Col>

                {/* Sacrifice Mode */}
                <Col xs={12}  className={initialGameModesMap.has(EGameMode.sacrifice) ? 'featured-game-mode' : ''}>
                  <FormCheckbox
                    className="one-hundo"
                    small
                    checked={!!currCreateGameModes.get(EGameMode.sacrifice)}
                    onChange={() => toggleGameMode(EGameMode.sacrifice)}>
                    <h4 className="game-option-title">Sacrifice</h4>
                    {/* <span className="new-indicator">NEW</span> <span className="attn-indicator">RISKY</span> */}
                    <p className="game-option-description">Before each team's guessing phase, you can choose to sacrifice 1 point in order to get an additional {Game.defaultSacrificeBonusMs/1000} seconds on the clock.</p>
                  </FormCheckbox>
                </Col>

                {/* Earn Time Mode */}
                <Col xs={12}  className={initialGameModesMap.has(EGameMode.earnTime) ? 'featured-game-mode' : ''}>
                  <FormCheckbox
                    className="one-hundo"
                    small
                    checked={!!currCreateGameModes.get(EGameMode.earnTime)}
                    onChange={() => toggleGameMode(EGameMode.earnTime)}>
                    <h4 className="game-option-title">Earn Time</h4>
                    {/* <span className="new-indicator">NEW</span> */} <span className="attn-indicator">TENSE</span>
                    <p className="game-option-description">Start with less time in the guessing phase, but earn more time with each <em>Target Word</em> guessed. Earned time can be configured with the "Earned Time Bonus" setting.</p>
                  </FormCheckbox>
                </Col>

                {/* In Order Mode */}
                <Col xs={12}  className={initialGameModesMap.has(EGameMode.oneAtATime) ? 'featured-game-mode' : ''}>
                  <FormCheckbox
                    className="one-hundo"
                    small
                    checked={!!currCreateGameModes.get(EGameMode.oneAtATime)}
                    onChange={() => toggleGameMode(EGameMode.oneAtATime)}>
                    <h4 className="game-option-title">Single Target</h4>
                    {/* <span className="new-indicator">NEW</span> */} <span className="attn-indicator">CHALLENGING</span>
                    <p className="game-option-description">Only one <em>Target Word</em> at a time is presented to the Captain. When guessed, the next Target Word is revealed. The Captain may choose to skip a word, but may not come back to it once skipped.</p>
                  </FormCheckbox>
                </Col>


              </Row>
            </Col>

            {/* Manage Themed Word Sets */}
            <Col sm={10} xl={8}>
              <Row justify="center" className="game-option">
                <Col xs={12}>
                  <h3 className={classnames('text-center')}>
                    <span className="attn-indicator">{currEnabledWordCount}</span>
                    <span>Themed Target Word Sets</span>
                  </h3>
                </Col>

                <Col xs={12}>
                  <p>Toggle the available themed word sets to customize your game. Target Words will be generated from the enabled word sets.</p>
                </Col>

                {/* num theme sets off warnings */}
                <Col xs={12}>
                  <ConditionalRender visible={currOnWordSets.size < 12}>
                    <p className="text-center">For the best experience, it is recommended to play with more themed word sets enabled.</p>
                    <p className="text-center">Playing with fewer word sets means you are more likely to encounter the same category multiple times and see duplicate words.</p>
                  </ConditionalRender>
                </Col>

              </Row>

              <Row>
                <Col xs={12}>
                  <hr />
                </Col>

                <Col sm={8} offset={{ sm: 2 }}>
                  <Row>
                    <Col xs={4}>
                      <Button
                        className="one-hundo"
                        onClick={() => toggleAllByDifficulty(EDifficulty.Easy)}>
                        Toggle Easy
                      </Button>
                    </Col>
                    <Col xs={4}>
                      <Button
                        className="one-hundo"
                        onClick={() => toggleAllByDifficulty(EDifficulty.Medium)}>
                        Toggle Medium
                      </Button>
                    </Col>
                    <Col xs={4}>
                      <Button
                        className="one-hundo"
                        onClick={() => toggleAllByDifficulty(EDifficulty.Hard)}>
                        Toggle Hard
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col sm={2}>
                  <Row>
                    <Col xs={12}>
                      <div className="donut-chart-container small">
                        {/* Insert difficult donut chart here */}
                        <PieChart
                          data={difficultyChartData}
                          lineWidth={50}
                          paddingAngle={0}
                          animate={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12}>
                  <hr />
                </Col>
              </Row>

              <Row>
                {/* theme by theme */}
                {themes.map(buildToggleableWordSetButtonsFromDifficulties)}
              </Row>
            </Col>

            {/* Custom Word Set */}
            <Col sm={10} xl={8}>
              <Row justify="center" className="game-option">
                <Col xs={12}>
                  <h3 className={classnames('text-center')}>
                    <span className="attn-indicator">{currCustomWordBank.length} of {currEnabledWordCount + currCustomWordBank.length}</span>
                    <span>Custom Target Words</span>
                  </h3>
                </Col>

                <Col xs={12}>
                  <FormTextarea
                    className="one-hundo"
                    placeholder={'Optionally, add a custom list of words to use during your game. Separate each word with a comma.'}
                    onChange={(ev) => { changeCustomWords(ev.target.value) }} />
                </Col>
              </Row>
            </Col>

          </Row>
        </ConditionalRender>

        {/* join game view */}
        <ConditionalRender visible={lobbySelectionState === ELobbyStates.joinGame}>
          <Row justify="center">
            <Col xs={10} sm={6}>
              <FormInput
                className={classnames('huge')}
                value={currJoinGameCode}
                placeholder="Game Code"
                autoFocus={true}
                onKeyUp={handlePossibleEnterKey.bind(null, 'join')}
                onChange={(ev) => updateJoinGameCode(ev.target.value)} />
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={10} sm={5} md={4}>
              <Button
                className={classnames('huge', 'green')}
                onClick={attemptJoinGame}>
                Join
              </Button>
            </Col>
            <Col xs={10} sm={5} md={4}>
              <Button
                className={classnames('huge')}
                onClick={() => setLobbySelectionState(ELobbyStates.landing)}>
                Back
              </Button>
            </Col>
          </Row>
        </ConditionalRender>
      </div>
      <ReactTooltip effect="solid"/>
      <Footer />
    </div >
  )
}
