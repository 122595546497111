import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  'Star Wars',
  'Galaxy',
  'Spaceship',
  'Spacecraft',
  'Jedi',
  'Lightsaber',
  'Planet',
  'The Empire',
  'Rebel Alliance',
  'Stormtrooper',
  'The Force',
  'The Dark Side',
  'Droid',
  'Padawan',
  'trilogy',
  'Prequel',
  'Clones',
  'Millennium Falcon',
  'Ewok',
  'Wookie',
  'Sith',
  'Yoda',
  'The Force',
  'Telekinesis',
  'Death Star',
  'Podrace',
  'Luke Skywalker',
  'Clone Wars',
  'Anakin Skywalker',
  'Darth Vader',
  'Luke Skywalker	',
  'Princess Leia',
  'The Empire Strikes Back',
  'Return of the Jedi',
  'Revenge of the Sith',
  'A New Hope',
  'Boba Fett',
  'Jabba the Hutt',
  'C3-PO',
  'R2-D2',
  'Chewbacca',
  'Obi-Wan Kenobi',
  'Mark Hamill',
  'Carrie Fisher',
  'Harrison Ford',
  'Han Solo',
])

const hard = new Set([
  'Leia Organa',
  'James Earl Jones',
  'The Mandalorian',
  'The Force Awakens',
  'The Last Jedi',
  'The Rise of Skywalker',
  'Jango Fett',
  'Mace Windu',
  'Lando Calrissian',
  'Wedge Antilles',
  'Rogue Squadron',
  'The Phantom Menace',
  'Attack of the Clones',
  'Padmé Amidala',
  'Uncle Owen',
  'Count Dooku',
  'Sebulba',
  'Admiral Holdo',
  'Mon Mothma',
  'Poe Dameron',
  'General Grievous',
  'Qui-Gon Jinn',
  'Admiral Ackbar',
  'Finn',
  'Chirrut Îmwe',
  'Emperor Palpatine',
  'Darth Sidious',
  'Grand Moff Tarkin',
  'Greedo',
  'Darth Maul',
  'BB-8',
  'Ben Solo',
  'Kylo Ren',
  'Mando',
  'The child',
  'Babu Frik',
  'Grogu',
  'Naboo',
  'Coruscant',
  'Kamino',
  'Mustafar',
  'Tatooine',
  'Hoth',
  'Dagobah',
  'Endor',
]);




export default new ThemeSet('Star Wars',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);