import {
  Word,
  IWord,
  IModelIncludeOpts,
  EWordType
} from '../../internal';


export interface ITWord extends IWord {
  selected?: string
}

export class TW extends Word {
  guessed?: boolean;

  constructor(opts?: ITWord) {
    super(opts);
    this.guessed = opts?.selected === 'true';
  }

  markAsGuessed() {
    this.guessed = true;
    return this;
  }

  markAsSkipped() {
    this.skipped = 'true';
    return this;
  }

  adjustGuessed() {
    // toggle earned and adjusted
    this.guessed = !this.guessed;
    this.adjusted = this.isManuallyAdjusted() ? 'false' : 'true';
    return this;
  }

  async forApi(opts?: IModelIncludeOpts): Promise<ITWord> {
    const superJson = await super.forApi(opts);
    var json = {
      ...superJson,
      doubleValue: this.doubleValue,
      selected: this.guessed ? 'true' : 'false',
      type: EWordType.tw
    }

    if (opts?.withoutDoubleValue) {
      delete json.doubleValue;
    }
    return json;
  }

}
