import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "ankle",
  "arm",
  "blood",
  "body",
  "chest",
  "hand",
  "head",
  "heart",
  "leg",
  "nose",
  "neck",
  "mouth",
  "tooth",
]);

const medium = new Set([
  "abdomen",
  "Adam's apple",
  "anatomy",
  "appendix",
  "arch",
  "artery",
  "back",
  "big toe",
  "bladder",
  "blood vessels",
  "bone",
  "brain",
  "calf",
  "cheek",
  "chin",
  "circulatory system",
  "collar bone",
  "digestive system",
  "ear",
  "elbow",
  "eye",
  "eyebrow",
  "eyelashes",
  "eyelid",
  "face",
  "femur",
  "filling",
  "foot",
  "forehead",
  "gums",
  "heel",
  "hip",
  "humerus",
  "immune system",
  "index finger",
  "intestines",
  "jaw",
  "kidney",
  "knee",
  "ligament",
  "lip",
  "liver",
  "lungs",
  "molar",
  "muscle",
  "nail",
  "nerves",
  "nostril",
  "organs",
  "palm",
  "pelvis",
  "pinky",
  "pupil",
  "radius",
  "respiratory system",
  "scalp",
  "senses",
  "shoulder",
  "shoulder blade",
  "skeleton",
  "skin",
  "skull",
  "sole",
  "spine",
  "spleen",
  "stomach",
  "teeth",
  "thigh",
  "throat",
  "thumb",
  "toe",
  "toenail",
  "tongue",
  "tonsils",
  "torso",
  "vein",
  "vertebra",
  "waist",
  "blood cells",
  "wrist",
]);

const hard = new Set([

]);


export default new ThemeSet('Human Body',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);