import React, { useEffect, useState } from 'react';
import { setTheme } from '../internal';
import { FaRegMoon, FaMoon } from 'react-icons/fa'

import {
  ConditionalRender
} from '../internal'

export const DarkModeToggle = () => {
  const [togClass, setTogClass] = useState('dark');
  let theme = localStorage.getItem('theme');

  const handleOnClick = () => {
    if (localStorage.getItem('theme') === 'theme-dark') {
      setTheme('theme-light');
      setTogClass('light')
    } else {
      setTheme('theme-dark');
      setTogClass('dark')
    }
  }

  useEffect(() => {
    if (localStorage.getItem('theme') === 'theme-dark') {
      setTogClass('dark')
    } else if (localStorage.getItem('theme') === 'theme-light') {
      setTogClass('light')
    }
  }, [theme])

  return (
    <div className="dark-mode-toggle-container">
      <ConditionalRender visible={togClass === "light"}>
        <FaRegMoon onClick={handleOnClick} />
      </ConditionalRender>

      <ConditionalRender visible={togClass !== "light"}>
        <FaMoon onClick={handleOnClick} />
      </ConditionalRender>
    </div>
  )
}