import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "breezy",
  "chilly",
  "crisp",
  "fall",
  "foggy",
  "frosty",
  "golden",
  "harvest",
  "leaf",
  "leaves",
  "maple",
  "rainy",
  "rake",
  "raked",
  "scarf",
  "season",
  "sweater",
  "windy",
  "acorn ",
  "apple",
  "chilly",
  "cobweb",
  "corn",
  "fall",
  "feast",
  "football",
  "Halloween",
  "leaf ",
  "leaves",
  "nuts",
  "pine cone",
  "pumpkin ",
  "pumpkin pie",
  "rake",
  "season",
  "Thanksgiving",
  "turkey",
  "family",
  "sweet potatoes",
  "beans",
  "feast",
  "apple pie",
  "parade",
])


const medium = new Set([
  "September",
  "October",
  "November",
  "abundant",
  "amber",
  "autumnal",
  "brilliant",
  "brisk",
  "cozy",
  "crackling",
  "crunchy",
  "earthy",
  "hibernate",
  "rustling",
  "autumn",
  "autumnal equinox",
  "bale of hay",
  "chest nuts",
  "cider",
  "cornucopia",
  "corn maze",
  "cranberry",
  "crisp",
  "falling leaves",
  "gourd",
  "harvest",
  "hay",
  "harvest moon",
  "hay ride",
  "haystack",
  "maize",
  "November",
  "October",
  "persimmon",
  "quilt",
  "raincoat",
  "reap",
  "scarecrow",
  "sleet",
  "spider",
  "squash",
  "pilgrims",
  "turkey day",
  "reunion",
  "gathering",
  "give thanks",
  "stuffing",
  "sweet potatoe pie",
  "yams",
  "roast",
  "decorations",
  "thanksgiving day parade",
  "apple cider",
  "marching band",
  "giant balloon",
  "fall festival",
  "pumpkin beer",
  "pumpkin bread",
  "time change"
])

const hard = new Set([

])


export default new ThemeSet('Autumn',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });