import React, { FC, useState, useEffect, useContext, useRef } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Visible } from 'react-grid-system';
import { useParams } from "react-router-dom";
import {
  Button,
  FormInput
} from 'shards-react'
import { FaQuestionCircle, FaAngleDoubleUp } from 'react-icons/fa'
import {
  sfx, ESoundName,
  ConditionalRender,
  Game,
  Team,
  Word,
  ITWord,
  TW,
  BW,
  CW,
  GameContext,
  graphQLQuery,
  graphQLSubscription,
  getMyTeamAsGuesser,
  getTeamAsOpposition,
  onCreateCW,
  useMap,
} from '../../internal';


interface IProps {

}

export const WritePhase: FC<IProps> = ({ }) => {
  var { state: { game, myTeam, oppTeam, helpOn }, dispatch } = useContext(GameContext);
  // use references inside subscriptions
  var latestMyTeam = useRef(myTeam);
  latestMyTeam.current = myTeam;
  var latestOppTeamTargetWords = useRef<Map<string, ITWord>>(new Map());
  var latestFullAssociationTracker = useRef<Map<string, number>>(new Map());

  const [currWord, setCurrWord] = useState<string>();
  const [associatedWords, setAssociatedWords] = useMap(new Map<string, TW>(new Map()));

  useEffect(() => {
    if (game?.getMyTeam) {

      (async () => {
        // fetch the data for this view so that we're never without it
        var myT = await graphQLQuery(getMyTeamAsGuesser, 'getTeam', { id: game!.getMyTeam()!.id })
        var myTeam = new Team(myT);
        // get the opposing team's Ttw
        var oppT = await graphQLQuery(getTeamAsOpposition, 'getTeam', { id: game!.getOppositeTeam()!.id })
        var oppTeam = new Team(oppT);

        dispatch({ type: 'setMyTeam', team: myTeam })
        dispatch({ type: 'setOppTeam', team: oppTeam })


        // this next block is really dumb. find a better way
        // setup the target word map since the subscriptions wont go deep enough. (figure out why later)
        latestOppTeamTargetWords.current = oppTeam?.round?.tws.reduce((fullTwMap: Map<string, ITWord>, currTw: TW): any => {
          var currTwJson: any = {
            id: currTw.id,
            text: currTw.text,
            teamId: currTw.teamId,
            roundId: currTw.roundId,
          };
          fullTwMap.set(currTw.id, currTwJson);
          // to map from the twids to the number of my team's associations (0 initially)
          latestFullAssociationTracker.current.set(currTw.id, 0);

          return fullTwMap;
        }, new Map<string, ITWord>()) ?? new Map<string, ITWord>();

      })();

      // setup subscription for myTeam's incoming cws
      var cwSubscription = graphQLSubscription(onCreateCW, { teamId: game!.getMyTeam()!.id }, cwCreated);
      return () => cwSubscription.unsubscribe();
    }
  }, [game?.id])

  const cwCreated = async ({ onCreateWord: word }) => {
    // const word = onCreateWord;
    const myTeam = latestMyTeam.current;
    // console.log('created word includes this many associations - ' + word.associations.items.length);
    // console.log('possible target words: ' + [...latestOppTeamTargetWords.current.values()].toString())
    word.associations.items = word.associations?.items?.map((assoc, i: number) => {

      var latestNumAssocationsForWord = latestFullAssociationTracker.current.get(assoc.twId) || 0;
      latestFullAssociationTracker.current.set(assoc.twId, ++latestNumAssocationsForWord);
      latestFullAssociationTracker.current = latestFullAssociationTracker.current;
      return {
        ...assoc,
        // ad the tw manually since the subscription wont grab it
        tw: { id: assoc.twId }// latestOppTeamTargetWords.current!.get(assoc.twId)
      }
    })

    myTeam!.addCW(new CW(word));
    // plays a different sound depending on red vs blue team
    sfx.play(ESoundName.cwCreated, myTeam?.name?.length);
    dispatch({ type: 'modifyMyTeam', team: myTeam });
  }

  const modifyAssociatedWordState = (desiredAssociation) => {
    setAssociatedWords(desiredAssociation);
  };

  const toggleTWAssocaition = (tw: TW) => {
    if (!associatedWords!.has(tw.id)) {
      associatedWords!.set(tw.id, tw);
    }
    else {
      associatedWords!.delete(tw.id);
    }
    modifyAssociatedWordState(associatedWords);
    // re-focus the input
    document.getElementById('cw-input')?.focus();
  }

  const saveNewCw = () => {
    if (/\S+/.test(currWord || '')) {
      var newCw = new CW({
        text: currWord?.trim().toLowerCase()!,
        teamId: myTeam?.id,
        roundId: myTeam?.round?.id
      });

      // only save if the new word does not match an exisitng CW or one of teh TWs
      if (
        !myTeam?.round?.cws?.some((existingCw: CW) => existingCw.text === newCw.text) 
        && !oppTeam?.round?.tws?.some((target: TW) => target.text === newCw.text)
      ) {
        if (!newCw.roundId) {
          console.log('couldnt find round id for new word. saving and trying again.');
          return;
        }
        newCw.setAssociatedTWs([...associatedWords.values()]);
        CW.saveNew(newCw);
      }
      // reset form
      setCurrWord('');
      // trying something different. might be easier...
      // keep the association until it is untoggled instead
      // associatedWords.clear();
      modifyAssociatedWordState(associatedWords);
      // re-focus the input
      document.getElementById('cw-input')?.focus();
    }
  }

  const updateCwText = (text: string) => {
    // dont allow people to save more than 50 chars
    if (text.length < 40) {
      setCurrWord(text);
    }
  }

  const handlePossibleEnterKey = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      saveNewCw();
    }
  }

  const toggleHelp = () => {
    dispatch({ type: 'setHelp', help: !helpOn });
  };

  return (
    <div className={classnames('writePhase-container')}>
      <Container>
        {/* instructions */}
        <ConditionalRender visible={helpOn || (helpOn === undefined && latestMyTeam.current?.rounds?.length === 1)}>
          <Row className={classnames('instruction-block')}>
            <Col xs={2} md={1} onClick={() => toggleHelp()}>
              <FaQuestionCircle />
            </Col>
            <Col xs={9} md={10}>
              <p>The opposing team will try to guess the 5 <em>Target Words</em> presented below during their guessing phase.</p>
              <p>Enter words you think they will use while either guessing or giving clues for these words. Your team will earn one point for every <em>Banned Word</em> they mention.</p>
              <p>Tip: Tag your words with <em>Target Word(s)</em> to let your team know what you're thinking during the voting phase.</p>
            </Col>
          </Row>
        </ConditionalRender>

        <Row justify="center" className={classnames('opposing-team-word-list')}>
          {oppTeam?.round?.tws?.map((tw: TW, i: number) => (
            <Col xs={6} sm={'content'} key={`tw-${i}`}>
              <Button
                className={classnames('word-button', 'tw', oppTeam?.getStyleName(), { 'active': associatedWords?.has(tw.id) })}
                onClick={() => toggleTWAssocaition(tw)}>
                <>
                  <Row justify="between">
                    <Col>
                      <div className="word-text">{tw.text}</div>
                    </Col>
                    <Col>
                      <div className={classnames('counter counter-right')}>
                        {latestFullAssociationTracker.current.get(tw.id) ?? 0}
                      </div>
                    </Col>
                  </Row>
                  <ConditionalRender visible={tw?.isDoubleValue()}>
                    <div className="double-value-word-flag-container">
                      <div className={classnames('double-value-word-flag')}>
                        <FaAngleDoubleUp />
                      </div>
                    </div>
                  </ConditionalRender>
                </>
              </Button>
            </Col>
          ))}
        </Row>

        <Row justify="center">
          <Col xs={12}>
            <h2 className={classnames('text-center')}>Enter a new <em>Banned Word</em></h2>
          </Col>
          <Visible xs sm>
              <h5 className={classnames('text-center', 'xs-mobile-keyboard-helper')}>
                Tap 'Return' on <b><em>Mobile Keyboards</em></b> to submit a word
              </h5>
            </Visible>
          <Col xs={12} md={10} lg={8} xxl={6}>
            <FormInput
              id="cw-input"
              className={classnames('huge')}
              autoFocus={true}
              autoComplete="off"
              value={currWord}
              placeholder=""
              onChange={(ev) => updateCwText(ev.target.value)}
              onKeyUp={handlePossibleEnterKey} />

            <Visible xs>
              <h5 className={classnames('text-center', 'xs-helper-header')}>
                For: {[...associatedWords.values()].map(w => w.text).join(', ')}
              </h5>
            </Visible>
          </Col>

          <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>
        </Row>

        {/* my team's cws */}
        <Row>
          <Col xs={12}>
            <h2 className={classnames('text-center')}>Your Team's <em>Banned Words</em></h2>
            <ConditionalRender visible={myTeam?.round?.cws?.length === 0}>
              <em><h4 className={classnames('text-center')}>None</h4></em>
            </ConditionalRender>
          </Col>
          {myTeam?.round?.cws?.map((cw: CW, i: number) => (
            <Col xs={12} sm={6} md={4} lg={3} xxl={2} key={`cw-${i}`}>
              <Button className={classnames('word-button', 'cw', myTeam?.getStyleName(), 'active')}
                disabled>
                <Row>
                  <Col xs={12}>
                    <div className="word-text">{cw.text}</div>
                  </Col>
                </Row>
                <Row justify="end" className={classnames('associated-list')}>
                  <Col>
                    {cw?.associations?.map(tw => (
                      <span className="inline word" key={`word-${tw.id}`}>
                        {latestOppTeamTargetWords.current.get(tw.id)?.text}
                      </span>
                    ))}
                  </Col>
                </Row>
              </Button>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
