import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

export const medium = new Set([
  "Tampa Bay Lightning",
  "Penguins",
  "Ice Skates",
  "Skate",
  "hockey stick",
  "Hockey Puck",
  "penalty box",
]);

const hard = new Set([
  "Boston Bruins",
  "Vegas Golden Kinghts",
  "New York Islanders",
  "Hurricanes",
  "Detroit Red Wings",
  "icing",
  "high-sticking",
  "Wayne Gretzky",
  "Sidney Crosby",
])


export default new ThemeSet('NHL',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });