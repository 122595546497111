import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import {
  ConditionalRender,
  Footer,
  Header
} from '../../internal';

import dogecoinImg from '../../assets/dogecoin.png'
import tetherImg from '../../assets/tether.png'
import twitterImg from '../../assets/twitter.png'

interface IProps {

}


export const Support: FC<IProps> = ({ }) => {

  return (
    <>
      <Header />
      <Container>
        <div className="pages-container">
          <Row className={classnames('support-container')}>
            <Col xs={12}>
              <h2>Support the Developer</h2>
              <h4><span>Hi! I'm Alex.</span> <span className="emoji-text">&#128075;</span></h4>
              {/* <h4>Software engineer by day. Board Game Enthusiast by night.</h4> */}
              <h4>I created this game as a fun side project to play with friends and family who aren't always within arm's reach on game night.</h4>
              <h4>Keeping this game online and available costs money every month. I would love to pay for it using donations from players like you rather than having ads.</h4> 
              <h4>If you're enjoying the game, please consider supporting my work by donating or sharing the game with new players. Cheers!</h4>
            </Col>

            <Col xs={12}>

              <Row debug>
                <Col xs={4} md={2}>
                  <div className="support-graphic">
                    <img className={classnames('buy-me-coffee-img')}
                      src="https://img.buymeacoffee.com/api/?name=ahoran3&size=300&bg-image=bmc&background=" />
                  </div>
                </Col>

                <Col xs={8} md={10}>
                  <div className="support-text">
                    <h4 className="upper">
                      <a href="https://www.buymeacoffee.com/ahoran3" target="_blank" className="coffee-link">
                        Send a $1 Donation
                      </a>
                    </h4>
                    <h5 className="lower">
                      Please help cover my monthly cloud costs and keep the game available online :)
                    </h5>
                  </div>
                </Col>
              </Row>


              <Row>
                <Col xs={4} md={2}>
                  <div className="support-graphic">
                    <img src={dogecoinImg} />
                  </div>
                </Col>

                <Col xs={8} md={10}>
                  <div className="support-text">
                    <h4 className="upper">
                      Send me dogecoin <em>TO THE MOON!</em>
                    </h4>
                    <h5 className="lower">
                      $DOGE Wallet: DTBcazrq4vqbpv9hiqykbPtUAUGSAHMyHE
                    </h5>
                  </div>
                </Col>
              </Row>

              {/* tether not needed */}
              {/* <Row>
                <Col xs={4} md={2}>
                  <div className="support-graphic">
                    <img src={tetherImg} />
                  </div>
                </Col>

                <Col xs={8} md={10}>
                  <div className="support-text">
                    <h4 className="upper">
                      Tether
                    </h4>
                    <h5 className="lower">
                      $USDT Wallet: 0x0A575b8b830E88F5A3C129c0B508f3f57E5E18b6
                    </h5>
                  </div>
                </Col>
              </Row> */}



              <Row debug>
                <Col xs={4} md={2}>
                  <div className="support-graphic">
                    <img src={twitterImg} />
                  </div>

                </Col>
                <Col xs={8} md={10}>
                  <div className="support-text">
                    <h4 className="upper">
                      <a href="https://twitter.com/BannedWordsFun" target="_blank">
                        Send me a message!
                      </a>
                    </h4>
                    <h5 className="lower">
                      Let me know your thoughts on the game and/or help spread the word.
                    </h5>
                  </div>
                </Col>
              </Row>

            </Col>
          </Row>

        </div>
      </Container>
      <Footer />
    </>
  )
}
