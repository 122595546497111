import { Promise } from 'bluebird'

import {
  ITimeSync,
  TimeSync,
  IModelIncludeOpts,
} from '../internal';

export class MultiSync {
  syncs: TimeSync[];
  mostRecentPing: number;
  
  // the value that should be added to the 
  // client's time to maintain a cross client sync
  correction?: number; 

  constructor() {
    this.syncs = [];
    this.correction = 0;
    this.mostRecentPing = 0;
  }

  addRecent(sync: TimeSync): MultiSync {
    // keep the most recent 3 pings
    const recentSyncs = this.syncs;
    this.mostRecentPing = sync.ping;
    recentSyncs.unshift(sync);
    this.syncs = recentSyncs.slice(0, 8);

    return this;
  }

  getAvgPing(): number {
    return Math.round(this.syncs.reduce((pingSum: number, currSync: TimeSync) => {
      return pingSum + currSync.ping;
    }, 0) / (this.syncs.length > 1 ? this.syncs.length : 1));
  }

  getAvgOffset(): number {
    return Math.round(this.syncs.reduce((offSum: number, currSync: TimeSync) => {
      return offSum + currSync.offset;
    }, 0) / (this.syncs.length > 1 ? this.syncs.length : 1));
  }
}