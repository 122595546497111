import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "age",
  "balloon",
  "birthday",
  "birthday cake",
  "blow out",
  "born",
  "bow",
  "cake",
  "candle",
  "candy",
  "card",
  "celebrate",
  "celebration",
  "child",
  "children",
  "chocolate",
  "clown",
  "cookie",
  "cupcake",
  "food",
  "friends",
  "frosting",
  "fun",
  "games",
  "gift",
  "happy",
  "happy birthday",
  "ice cream",
  "icing",
  "party",
  "party favors",
  "party hat",
  "play",
  "pizza",
  "thank you",
  "toys",
  "wish",
  "wrapping paper",
  "year",
]);


const medium = new Set([
  "banner",
  "bash",
  "birth",
  "birth date",
  "confetti",
  "entertain",
  "event",
  "festive",
  "gathering",
  "gift wrap",
  "goodie bags",
  "greeting card",
  "guests",
  "invite",
  "invitation",
  "jubilee",
  "juice",
  "lollipop",
  "noise maker",
  "package",
  "paper plate",
  "pinata",
  "popsicle",
  "present",
  "receive",
  "ribbon",
  "RSVP",
  "sparkler",
  "sweets",
  "treat bags",
  "treats",
  "wrapped",
]);


const hard = new Set([

]);

export default new ThemeSet('Birthday',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);