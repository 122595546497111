import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "Seinfeld",
  "Parks and Recreation",
  "Who Wants to Be a Millionaire",
  "Stranger Things",
  "Friends",
  "Sherlock",
  "Lost",
  "The Office",
  "Modern Family",
  "Malcolm in the Middle",
  "30 Rock",
  "The Office",
  "Ted Lasso",
  "SpongeBob SquarePants",
  "The King of Queens",
  "Dragon Ball Z",
  "Bob's Burgers",
  "MythBusters",
  "Hollywood Squares",
  "Family Feud",
  "Wheel of Fortune",
  "Jeopardy!",
  "The Price Is Right",
  "Survivor",
  "America's Got Talent",
  "American Idol",
])

const medium = new Set([
  "The Great British Bake off",
  "The Impractical Jokers",
  "The Voice",
  "CNN", 
  "Fox News",
  "MSNBC",
  "CBS",
  "NBC",
  "Sunday Morning",
  "CNBC",
  "Mad Money",
  "13 Reasons Why",
  "Brooklyn Nine-Nine",
  "Attack on Titan",
  "Firefly",
  "Two and a Half Men",
  "Scrubs",
  "Mindhunter",
  "Community",
  "Mad Men",
  "Gotham",
  "The Blacklist",
  "Agents of S.H.I.E.L.D.",
  "Doctor Who",
  "It's Always Sunny",
  "Loki",
  "Twin Peaks",
  "The Umbrella Academy",
  "Downton Abbey",
  "Criminal Minds",
  "The Crown",
  "Gossip Girl",
  "Game of Thrones",
  "Breaking Bad",
  "The Walking Dead",
  "The Big Bang Theory",
  "Dexter",
  "How I Met Your Mother",
  "True Detective",
  "Prison Break",
  "House of Cards",
  "Black Mirror",
  "Vikings",
  "Westworld",
  "Rick and Morty",
  "House",
  "Arrow",
  "Peaky Blinders",
  "Supernatural",
  "Money Heist",
  "Narcos",
  "Daredevil",
  "The Simpsons",
  "Suits",
  "Better Call Saul",
  "The Mandalorian",
  "Mr. Robot",
  "Fargo",
  "South Park",
  "The Sopranos",
  "Homeland",
  "The Flash",
  "Family Guy",
  "The Boys",
  "The Wire",
  "American Horror Story",
  "Arrested Development",
  "Orange Is the New Black",
  "Sons of Anarchy",
  "Grey's Anatomy",
  "Avatar: The Last Airbender",
  "Bones",
  "Archer",
  "Glee",
  "The IT Crowd",
  "Silicon Valley",
  "The Good Place",
  "NCIS",
  "BoJack Horseman",
  "Luther",
  "The Fresh Prince of Bel-Air",
  "Smallville",
  "Iron Fist",
  "Dragon Ball Z",
  "Fear the Walking Dead",
  "This Is Us",
  "Supergirl",
  "Top Gear",
  "Curb Your Enthusiasm",
  "Gilmore Girls",
  "Mr. Bean",
  "Sex and the City",
  "After Life",
  "Orphan Black",
  "The Man in the High Castle",
  "2 Broke Girls",
  "Banshee",
  "Law & Order: SVU",
  "The Marvelous Mrs. Maisel",
  "Making a Murderer",
  "Lupin",
  "The Bachelor",
  "The Bachelorette",
  "The Masked Singer",
  "Queer Eye",
  "Psych",
  "Bridgerton",
  "Stargate SG-1",
  "CSI",
  "Frasier",
  "Merlin",
  "The Good Doctor",
  "The Twilight Zone",
  "The Clone Wars",
  "Master of None",
  "The Morning Show",
  "Hawaii Five-0",
  "The West Wing",
  "Good Omens",
  "Unbreakable Kimmy Schmidt",
  "Everybody Loves Raymond",
  "Star Trek",
  "Twin Peaks",
  "A Series of Unfortunate Events",
  "Pushing Daisies",
  "Full House",
  "ER",
  "Deadliest Catch",
  "Will & Grace",
  "M*A*S*H",
  "Cheers",
  "NCIS: Los Angeles",
  "Veep",
  "King of the Hill",
  "Match Game",
  "Master Chef",
  "Man vs Food",
  "Bar Rescue",
  "Love it or List it",
]);


const hard = new Set([
  "Futurama",
  "Hannibal",
  "True Blood",
  "Heroes",
  "Shameless",
  "Once Upon a Time",
  "Ozark",
  "The X-Files",
  "New Girl",
  "The Handmaid's Tale",
  "Jessica Jones",
  "The Punisher",
  "Boardwalk Empire",
  "Big Little Lies",
  "The Mentalist",
  "Entourage",
  "The Witcher",
  "The Vampire Diaries",
  "Lucifer",
  "Battlestar Galactica",
  "Pretty Little Liars",
  "Altered Carbon",
  "Sense8",
  "How to Get Away with Murder",
  "Buffy the Vampire Slayer",
  "Teen Wolf",
  "Freaks and Geeks",
  "Cobra Kai",
  "Six Feet Under",
  "Love, Death & Robots",
  "White Collar",
  "The Expanse",
  "Too hot to handle",
  "American Pickers",
  "Dirty Jobs",
  "Naked and Afraid",
  "Man vs Wild",
  "Fleabag",
  "Luke Cage",
  "Desperate Housewives",
  "American Dad!",
  "Lie to Me",
  "The Newsroom",
  "Weeds",
  "Elementary",
  "Love Island",
  "The Circle",
  "Dog the Bounty Hunter",
  "Ghost Hunters",
  "The Killing",
  "Under the Dome",
  "Broadchurch",
  "Bates Motel",
  "My Name Is Earl",
  "The Last Kingdom",
  "Schitt's Creek",
  "Misfits",
  "Falling Skies",
  "American Crime Story",
  "Legion",
  "One Piece",
  "Gravity Falls",
  "The O.C.",
  "Adventure Time",
  "The Inbetweeners",
  "Naruto",
  "Designated Survivor",
  "Sacred Games",
  "Billions",
  "Atypical",
  "One Tree Hill",
  "Russian Doll",
  "The Good Wife",
  "Preacher",
  "Burn Notice",
  "Monk",
  "Scandal",
  "Blindspot",
  "The Grand Tour",
  "Squid Game",
  "Fullmetal Alchemist",
  "Shadow and Bone",
  "His Dark Materials",
  "Shadowhunters",
  "The Last Man on Earth",
  "Nip/Tuck",
  "3rd Rock from the Sun",
  "Hell on Wheels",
  "Samurai Jack",
  "Workaholics",
])




export default new ThemeSet('TV Shows',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);