import { ThemeSet, EDifficulty } from '../index';


export const easyNouns = new Set([
  'pillow',
  'boot',
  'seal',
  'bother',
  'holiday',
  'onion',
  'dish',
  'bulb',
  'grow',
  'pie',
  'far',
  'list',
  'time',
  'root',
  'nose',
]);

export const medNouns = new Set([
  'handy',
  'national',
  'sympathetic',
  'revolution',
  'suitcase',
  'infinite',
  'channel',
  'provide',
  'dough',
  'rule',
  'decade',
  'skeleton',
  'strategic',
  'sweater',
  'engagement',
  'invasion',
  'sector',
  'drug',
  'mutter',
  'helpless',
  'eavesdrop',
  'headquarters',
  'unfortunate',
  'innocent',
  'curl',
  'bishop',
  'rumor',
  'retain',
  'sustain',
  'constant',
  'address',
  'opera',
  'magnitude',
  'rehabilitation',
  'salmon',
  'straighten',
  'deadly',
  'scramble',
  'cream',
  'sensation',
  'stake',
  'admiration',
  'summary',
  'behavior',
  'disgrace',
  'update',
  'dynamic',
  'graduate',
  'principle',
  'governor',
  'warn',
  'confusion',
  'dangerous',
  'dealer',
  'suitcase',
  'burst',
  'systematic',
  'space',
  'response',
  'nursery',
  'rotate',
  'pair',
  'fortune',
  'peak',
  'liver',
  'person',
  'athlete',
  'charge',
  'mouse',
  'connection',
  'telephone',
  'index',
  'moment',
  'attitude',
  'fling',
  'far',
  'bean',
  'window',
  'quarter',
  'employee',
  'network',
  'clothes',
  'transparent',
  'heel',
  'merchant',
  'debate',
  'ranch',
  'management',
  'poor',
  'discreet',
  'hospitality',
  'clothes',
  'insist',
  'impress',
  'nonremittal',
  'storage',
  'crevice',
  'casualty',
  'sin',
  'well',
  'factory',
  'school',
  'aisle',
  'museum',
  'pier',
  'reproduction',
  'flourish',
  'contract',
  'voucher',
  'chair',
  'swipe',
  'mask',
  'overeat',
  'surgeon',
  'minority',
  'fashionable',
  'gem',
  'brain',
  'tired',
  'owl',
  'safe',
  'circle',
  'inspector',
  'steward',
  'embryo',
  'formal',
  'piece',
  'relinquish',
  'cabinet',
  'terrace',
  'channel',
  'hay',
  'hurt'
]);

export default new ThemeSet('Base Nouns',
  easyNouns,
  medNouns,
  new Set<string>(),
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);