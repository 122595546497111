import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import Countdown from 'react-countdown';
import {
  ConditionalRender,
  GameContext,
  EGamePhases

} from '../internal';

interface IProps {

}

export const Header: FC<IProps> = ({ }) => {
  return (
    <div className={classnames('header')}>
      <Container>
        <Row justify="between">
          <Col xs={7}>
            <h3><Link to="/">Banned Words Online</Link></h3>
          </Col>
          <Col xs={5}>
            <h4 className="play-now-link">
              <Link to="/">Play Now</Link>
            </h4>
          </Col>
        </Row >
      </Container>
    </div>
  )
}
