import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  "academy",
  "admiral",
  "advance",
  "Air Force",
  "aircraft",
  "aircraft carrier",
  "ally",
  "ammunition",
  "amphibious vehicle",
  "armor",
  "armored vehicle",
  "armory",
  "army",
  "arrow",
  "arsenal",
  "artillery",
  "assault",
  "attack",
  "attention",
  "ballistic",
  "barracks",
  "base",
  "battle",
  "battlefield",
  "battleship",
  "bayonet",
  "bomb",
  "bow and arrow",
  "bullet",
  "camouflage",
  "camp",
  "cannon ball",
  "canteen",
  "captain",
  "capture",
  "carrier",
  "catapult",
  "cavalry",
  "coast guard",
  "combat",
  "commander",
  "company",
  "conflict",
  "conquest",
  "crew",
  "decode",
  "defeat",
  "defend",
  "defense",
  "destroyer",
  "detonate",
  "dog tags",
  "draft",
  "encode",
  "enemy",
  "engage",
  "enlist",
  "evacuate",
  "explosive",
  "fight",
  "fire",
  "formation",
  "fort",
  "general",
  "grenade",
  "grenade launcher",
  "grunt",
  "guerrilla",
  "gun",
  "gunner",
  "gunnery sergeant",
  "gunpowder",
  "headquarters",
  "helmet",
  "honor",
  "hospital",
  "infantry",
  "injury",
  "intelligence",
  "invade",
  "invasion",
  "jeep",
  "jet",
  "lose",
  "machete",
  "major",
  "maneuver",
  "marines",
  "marksman",
  "medal",
  "medic",
  "mess hall",
  "MIA",
  "midshipman",
  "military",
  "mine",
  "missile",
  "mortar",
  "musket",
  "national security",
  "navy",
  "neutral",
  "night - vision",
  "offense",
  "parachute",
  "paratrooper",
  "peace treaty",
  "pentagon",
  "plane",
  "prisoner of war",
  "Purple Heart",
  "radar",
  "rank",
  "recruit",
  "rescue",
  "reserves",
  "retreat",
  "ribbon",
  "rifle",
  "rocket",
  "sabotage",
  "sailor",
  "salute",
  "section",
  "sergeant",
  "service",
  "shell",
  "shoot",
  "shot",
  "siege",
  "sniper",
  "soldier",
  "spear",
  "staff",
  "star",
  "submarine",
  "surrender",
  "tactical",
  "tank",
  "task force",
  "theater",
  "torpedo",
  "troops",
  "truce",
  "uniform"
]);

const hard = new Set([

])


export default new ThemeSet('Military',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);