

import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "armor",
  "castle",
  "crown",
  "dragon",
  "drawbridge",
  "fort",
  "king",
  "kingdom",
  "knight",
  "moat",
  "prince",
  "princess",
  "queen",
  "royal",
  "shield",
  "sword",
  "wall"
])

const medium = new Set([
  "baron",
  "baroness",
  "battering ram",
  "battle axe",
  "birthright",
  "catapult",
  "chain mail",
  "chivalry",
  "count",
  "countess",
  "court jester",
  "crest",
  "crossbow",
  "crown jewels",
  "duchess",
  "duke",
  "dungeon",
  "dynasty",
  "earl",
  "empire",
  "fortress",
  "gatehouse",
  "great hall",
  "hereditary",
  "highness",
  "honor",
  "jester",
  "jousting",
  "lady",
  "lance",
  "lord",
  "maid",
  "maiden",
  "manor",
  "manor house",
  "Middle Ages",
  "monarch",
  "nobility",
  "noble",
  "palace",
  "realm",
  "reign",
  "Renaissance",
  "royalty",
  "scepter",
  "serf",
  "titles",
  "tower",
  "trebuchet",
  "turret",
  "unicorn",
  "viscount",
  "watchtower"
]);


const hard = new Set([
  "aristocracy",
  "ballista",
  "baronet",
  "bastion",
  "chateau",
  "chieftain",
  "crenelations",
  "curtain wall",
  "donjon",
  "feudal",
  "grand duke",
  "heraldry",
  "lady-in-waiting",
  "mangonel",
  "marquis",
  "siege",
  "sovereign",
  "imperial"
])




export default new ThemeSet('Medieval Era',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);
