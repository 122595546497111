import { v4 as uuid } from 'uuid'


export interface IModelIncludeOpts {
  save?: boolean,
  includeTeams?: boolean,
  includeRound?: boolean,
  includeBws?: boolean,
  includeCws?: boolean,
  includeTws?: boolean,
  includeVotes?: boolean,
  includeAssociations?: boolean,
  withoutDoubleValue?: boolean
}

export class DbEntity {
  id: string;
  createdAt?: String;
  updatedAt?: String;

  constructor(opts?: any) {
    this.id = opts?.id ?? uuid();
    this.createdAt = opts?.createdAt;
    this.updatedAt = opts?.updatedAt;
  }

  newEntity() {
    var now = new Date().toISOString();
    // this.id = uuid();
    this.createdAt = now;
    this.updatedAt = now;
    return this;
  }

  async forApi(opts?: IModelIncludeOpts) {
    return {
      id: this.id,
      // createdAt: this.createdAt,
      // updatedAt: this.updatedAt
    };
  }

  async saveNew() {
    console.log('not implemented');
  }

  async saveUpdate() {
    console.log('not implemented');
  }

  async saveDelete() {
    console.log('not implemented');
  }
}