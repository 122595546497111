import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);

const medium = new Set([
  "Africa",
  "altitude",
  "Antarctic Circle",
  "Antarctica",
  "Arctic Circle",
  "area",
  "Asia",
  "atlas",
  "Australia",
  "border",
  "capital",
  "capital city",
  "cartographer",
  "cartography",
  "chart",
  "cliff",
  "volcano",
  "city",
  "rapids",
  "upstream",
  "reef",
  "plateau",
  "iceberg",
  "globe",
  "glacier",
  "harbor",
  "marsh",
  "savanna",
  "altitude",
  "inlet",
  "seacoast",
  "tides",
  "tropical",
  "port",
  "channel",
  "mountain range",
  "oasis",
  "weather",
  "lowlands",
  "tundra",
  "lake",
  "compass rose",
  "continent",
  "contour line",
  "country",
  "Earth",
  "east",
  "Eastern Hemisphere",
  "elevation",
  "equator",
  "Europe",
  "geographic coordinates",
  "geography",
  "global",
  "globe",
  "GPS",
  "great circle",
  "hemisphere",
  "island",
  "key",
  "kilometers",
  "land",
  "landform",
  "latitude",
  "legend",
  "longitude",
  "magnetic pole",
  "map",
  "map projection",
  "map maker",
  "meridian",
  "miles",
  "mountain",
  "valley",
  "horse ranch",
  "farm",
  "mountain range",
  "nation",
  "nautical chart",
  "north",
  "North America",
  "Magnetic Pole",
  "tundra",
  "northeast",
  "Northern Hemisphere",
  "northwest",
  "ocean",
  "parallel",
  "peak",
  "pole",
  "political map",
  "projection",
  "ratio",
  "region",
  "relief map",
  "river",
  "delta",
  "diagram",
  "draft",
  "chart",
  "blueprint",
  "design",
  "social studies",
  "geologist",
  "geology",
  "diamond",
  "iron",
  "ore",
  "gemstone",
  "pearl",
  "white pearl",
  "amethyst",
  "diamond",
  "jade",
  "rose",
  "scale",
  "scale bar",
  "sea",
  "sea level",
  "south",
  "South America",
  "South Pole",
  "southeast",
  "Southern Hemisphere",
  "southwest",
  "street map",
  "territory",
  "time zone",
  "title",
  "topographic map",
  "topography",
  "tributary",
  "Tropic of Cancer",
  "Tropic of Capricorn",
  "tropics",
  "weather map",
  "west",
  "Western Hemisphere",
  "atmosphere",
  "stratosphere",
  "crust",
  "earth's crust",
  "earth's core",
  "earth's mantle",
  "spring",
  "aquifer",
]);

const hard = new Set([

]);



export default new ThemeSet('Geography',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);