import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  "Allen wrench",
  "anvil",
  "axe",
  "bellows",
  "bevel",
  "block plane",
  "bolt",
  "bolt cutter",
  "brad",
  "brush",
  "calipers",
  "carpenter",
  "chalk line",
  "chisel",
  "circular saw",
  "clamp",
  "clippers",
  "coping saw",
  "countersink",
  "crowbar",
  "cutters",
  "drill",
  "drill bit",
  "drill press",
  "edger",
  "electric drill",
  "fastener",
  "glass cutter",
  "glue",
  "glue gun",
  "grinder",
  "hacksaw",
  "hammer",
  "handsaw",
  "hex wrench",
  "hoe",
  "hone",
  "jig",
  "jigsaw",
  "knife",
  "ladder",
  "lathe",
  "level",
  "lever",
  "machete",
  "mallet",
  "measuring tape",
  "miter box",
  "monkey wrench",
  "nail",
  "nail set",
  "needle-nose pliers",
  "nut",
  "Phillips screwdriver",
  "pickaxe",
  "pin",
  "pincer",
  "pinch",
  "pitchfork",
  "plane",
  "pliers",
  "plow",
  "plumb bob",
  "poker",
  "pruning shears",
  "pry bar",
  "pulley",
  "putty knife",
  "rasp",
  "ratchet",
  "razor",
  "roller",
  "rope",
  "router",
  "ruler",
  "safety glasses",
  "sand paper",
  "sander",
  "saw",
  "sawhorse",
  "scalpel",
  "scissors",
  "scraper",
  "screw",
  "screwdriver",
  "scythe",
  "sharpener",
  "shovel",
  "sickle",
  "snips",
  "spade",
  "spear",
  "sponge",
  "square",
  "squeegee",
  "staple",
  "stapler",
  "tack",
  "tiller",
  "tongs",
  "toolbox",
  "toolmaker",
  "torch",
  "trowel",
  "utility knife",
  "vise",
  "wedge",
  "wheel",
  "woodworker",
  "workbench",
  "wrench"
]);

const hard = new Set([

])

export default new ThemeSet('Tools',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);