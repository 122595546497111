import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import { PieChart } from 'react-minimal-pie-chart';

import {
  ConditionalRender,
  Footer,
  Header,
  listRecentGames,
  IGame,
  EGameMode,
  useMap,
  graphQLQuery,
  graphQLMutation,
  deleteGame
} from '../../internal';

interface IExtendedGame extends IGame {
  showWordSets?: boolean,
  duration?: number
}

interface IProps {

}

const msInDay = 24 * 60 * 60 * 1000;

export const RecentGames: FC<IProps> = ({ }) => {
  const [recentlyPlayed, setRecentlyPlayed] = useMap(new Map<string, IExtendedGame>(new Map()));
  const [showGamesThroughLive, setShowGamesThroughLive] = useState(false);
  const [showAllGamesThroughLive, setShowAllGamesThroughLive] = useState(false);

  useEffect(() => {
    const startBetween = new Date(Date.now() - 30 * msInDay).toISOString();
    // through updated 4 hours ago
    const endBetween = new Date(Date.now() - 1 * msInDay / 6).toISOString();
    // "now" - tomorrow to account for time zone weirdness
    const now = new Date(Date.now() + 1 * msInDay).toISOString();

    (async () => {
      const recentGames: IGame[] = await graphQLQuery(listRecentGames, 'listGames', {
        limit: 100,
        filter: {
          updatedAt: {
            between: [startBetween, showGamesThroughLive ? now : endBetween]
          }
        }
      });

      // sorted descending by time map of recent games
      const recent = recentGames.sort((gameA: IGame, gameB: IGame) => {
        return sortableDateTime(gameB) - sortableDateTime(gameA);
      }).reduce((all: Map<string, IExtendedGame>, currGame: IGame) => {
        var extendedGame: IExtendedGame = currGame;
         extendedGame.duration = calcGameDuration(extendedGame, true) as number;
        extendedGame.showWordSets = false;
        if (showAllGamesThroughLive || extendedGame.duration >= 1) {
          all.set(extendedGame.id, extendedGame);
        }
        return all;
      }, new Map<string, IExtendedGame>());

      setRecentlyPlayed(recent);
    })();

  }, [showGamesThroughLive, showAllGamesThroughLive]);

  useEffect(() => {
    const oldGamesBeforeDate = new Date(Date.now() - 60 * msInDay).toISOString();
    (async () => {
      const oldGames: IGame[] = await graphQLQuery(listRecentGames, 'listGames', {
        filter: {
          updatedAt: {
            lt: oldGamesBeforeDate
          }
        }
      });

      oldGames.map((game: IGame, index: number) => {
        setTimeout(() => {
          console.log(`\n\ndeleting old game (${index}/${oldGames.length}) \nid: ${game.id} \ncode: ${game.code} \nupdatedAt: ${game.updatedAt}`)
          return graphQLMutation(deleteGame, {
            id: game.id,
          })
        }, 100 * index);
      });

    })();
  }, []);

  const clickHiddenButton = () => {
    if (showGamesThroughLive) {
      setShowAllGamesThroughLive(true)
    } else {
      setShowGamesThroughLive(true)
    }
  }


  const sortableDateTime = (dbItem: any): number => {
    return new Date(dbItem.createdAt).getTime();
  }

  const calcGameDuration = (game: IGame, inMins: boolean = false) => {
    const minMs = 60 * 1000;
    const hrMs = 60 * minMs;
    const durationMs = new Date(game.updatedAt!).getTime() - new Date(game.createdAt!).getTime();
    const hrs = Math.floor(durationMs / hrMs);
    const mins = Math.floor((durationMs % hrMs) / minMs);

    if (inMins) {
      // desire to return a number of minutes 
      // instead of a descriptor string
      return Math.floor(durationMs / minMs);
    }

    if (hrs > 24) {
      return `> 24 hours`;
    } else if (hrs > 12) {
      return `${hrs} hours`;
    } else if (hrs < 12 && hrs > 0) {
      return `${hrs} hours ${mins} mins`;
    } else {
      return `${mins} mins`;
    }
  }

  const calcGameDifficulty = (enabledSets: string[]) => {
    // calculate the difficulty based on the allowed words
    // (possibly change it to store allowed vs off-word sets)
    // and populate the difficultyChartData to update the chart
    const difficultyCounts = enabledSets.reduce((emhCounts: any, currSetName: string) => {
      const emh = currSetName.match(/.*-(\w*)$/)?.[1] ?? 'unknown';
      emhCounts[emh]++;
      return emhCounts;
    }, { easy: 0, medium: 0, hard: 0, unknown: 0 });

    return [
      { title: 'Easy', value: difficultyCounts.easy / enabledSets.length, color: 'lightgreen' },
      { title: 'Medium', value: difficultyCounts.medium / enabledSets.length, color: '#e6e600' },
      { title: 'Hard', value: difficultyCounts.hard / enabledSets.length, color: 'orange' },
    ];
  }

  const _toggleGameSetVisibility = (gameId: string) => {
    var foundGame = recentlyPlayed.get(gameId);
    foundGame.showWordSets = !foundGame.showWordSets;
    recentlyPlayed.set(gameId, foundGame);

    setRecentlyPlayed(recentlyPlayed);
  }

  const recentGameTableItem = (game: IExtendedGame, i: number) => (
    <Row justify="around" className={classnames('pages-row-item', 'recent-game-row')} key={`recent-game-item-${game?.id ?? i}`}>
      <Col xs={2} md={1}>
        <h4>
          <Link to={`/${game?.code}?team=s&phase=results`}>{game?.code}</Link>
        </h4>
      </Col>
      <Col xs={3} md={2}>
        {/* show start and end below */}
        <Row>
          <Col xs={12}><h4>{new Date(game?.createdAt!).toLocaleString()}</h4></Col>
          <ConditionalRender visible={showGamesThroughLive}>
            <Col xs={12}><div className="through-dash"> ---- </div></Col>
            <Col xs={12}><h4 className="end-date">{new Date(game?.updatedAt!).toLocaleString()}</h4></Col>
          </ConditionalRender>
        </Row>
      </Col>
      <Col xs={1}><h4>{game?.teams?.items?.[0]?.rounds?.items?.length}</h4></Col>
      <Col xs={2} md={1} className="tb-pad">
        <div className="donut-chart-container small">
          <h4 className="theme-count">({game?.wordSetsOn?.length ?? 0})</h4>
          {/* Insert difficult donut chart here */}
          <PieChart
            data={calcGameDifficulty(game?.wordSetsOn ?? [])}
            lineWidth={50}
            paddingAngle={0}
            animate={true}
          />
        </div>
      </Col>
      <Hidden xs sm md lg>
        <ConditionalRender visible={!!game?.showWordSets}>
          <Col xl={3} className="tb-pad">
            <div className="show-hide-sets-btn" onClick={_toggleGameSetVisibility.bind(this, game?.id)}>
              Hide Sets
            </div>
            {game?.wordSetsOn?.map((theme: string, i: number) => (<div className="theme-name" key={`word-set-${i}`}>{theme}</div>))}
            {game?.customTargets?.map((customWord: string, j: number) => (<div className="theme-name" key={`custom-word-${j}`}>{`CUSTOM-${customWord}`}</div>))}
          </Col>
        </ConditionalRender>
        <ConditionalRender visible={!game?.showWordSets}>
          <Col xl={3} className="tb-pad">
            <div className="show-hide-sets-btn" onClick={_toggleGameSetVisibility.bind(this, game?.id)}>
              Show Sets
            </div>
          </Col>
        </ConditionalRender>
      </Hidden>

      <Hidden xs>
        <Col xs={2} sm={1} md={2} className="tb-pad">
          {game?.modes?.map((mode: EGameMode, i: number) => (
            <div key={i}>
              <ConditionalRender visible={mode === EGameMode.bombWords}>
                <div className="mode-name">Bomb</div>
              </ConditionalRender>
              <ConditionalRender visible={mode === EGameMode.doubleValueWords}>
                <div className="mode-name">Double</div>
              </ConditionalRender>
              <ConditionalRender visible={mode === EGameMode.oneAtATime}>
                <div className="mode-name">OneAtATime</div>
              </ConditionalRender>
              <ConditionalRender visible={mode === EGameMode.earnTime}>
                <div className="mode-name">EarnTime</div>
              </ConditionalRender>
              <ConditionalRender visible={mode === EGameMode.sacrifice}>
                <div className="mode-name">Sacrifice</div>
              </ConditionalRender>
            </div>
          ))}
        </Col>
      </Hidden>

      <Col xs={2} md={3} xl={2}>
        <h4>{calcGameDuration(game)}</h4>
        <h4>{game.location}</h4>
      </Col>
    </Row>
  );

  return (
    <>
      <Header />
      <div className="pages-container recent-games-list-container">
        <Row justify="around">
          <Col xs={10}>
            <h2>
              <span>Recently Played Games </span>
              <ConditionalRender visible={showGamesThroughLive}>
                <span> - live</span>
              </ConditionalRender>
              <ConditionalRender visible={showAllGamesThroughLive}>
                <span> - all</span>
              </ConditionalRender>
            </h2>
          </Col>
          <Col xs={2}>
            <button
              className="invisible-option"
              onClick={clickHiddenButton}>
            </button>
          </Col>

          <Col xs={12}>
            <Row justify="around" debug>
              <Col xs={2} md={1}><h4>Room</h4></Col>
              <Col xs={3} md={2}><h4>Started</h4></Col>
              <Col xs={1}><h4>Rounds</h4></Col>
              <Col xs={2} md={1}><h4>Difficulty</h4></Col>
              <Hidden xs sm md lg>
                <Col xl={3}><h4>Word Sets</h4></Col>
              </Hidden>
              <Hidden xs>
                <Col xs={2} sm={1} md={2}><h4>Mode</h4></Col>
              </Hidden>
              <Col xs={2} md={3} xl={2}><h4>Duration</h4></Col>
            </Row>

            {[...recentlyPlayed.values()].map(recentGameTableItem)}
          </Col>

        </Row>
      </div>
      <Footer />
    </>
  )
}
