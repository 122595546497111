import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  "April",
  "bloom",
  "blossom",
  "born",
  "breeze",
  "bud",
  "bulbs",
  "bunny",
  "butterfly",
  "chick",
  "Easter",
  "eggs",
  "flowers",
  "grass",
  "grow",
  "hatch",
  "June",
  "lamb",
  "life cycle",
  "March",
  "May",
  "melt",
  "nest",
  "puddles",
  "rabbit",
  "rain",
  "rainbow",
  "raincoat",
  "rebirth",
  "robin",
  "season",
  "showers",
  "slicker",
  "snowdrop",
  "spring",
  "spring break",
  "spring cleaning",
  "sprout",
  "sunshine",
  "tadpole",
  "thaw",
  "tulip",
  "umbrella",
  "warming",
  "weather",
  "windy",
  "worm",
]);

const hard = new Set([

])


export default new ThemeSet('Spring',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);