import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
]);

const medium = new Set([
  "asparagus",
  "apples",
  "avacado",
  "alfalfa sprouts",
  "acorn squash",
  "almond",
  "arugala",
  "artichoke",
  "applesauce",
  "asian noodles",
  "Apple juice",
  "Avocado roll",
  "Bruscetta",
  "bacon",
  "black beans",
  "bagels",
  "baked beans",
  "BBQ",
  "bison",
  "barley",
  "beer",
  "bisque",
  "bluefish",
  "bread",
  "broccoli",
  "buritto",
  "Cabbage",
  "cake",
  "carrots",
  "celery",
  "cheese",
  "chicken",
  "catfish",
  "chips",
  "chocolate",
  "chowder",
  "clams",
  "coffee",
  "cookies",
  "corn",
  "cupcakes",
  "crab",
  "curry",
  "cereal",
  "chimichanga",
  "dates",
  "dips",
  "duck",
  "dumplings",
  "donuts",
  "eggs",
  "enchilada",
  "eggrolls",
  "English muffins",
  "sushi",
  "fajita",
  "falafel",
  "fish",
  "franks",
  "fondu",
  "French toast",
  "French dip",
  "Garlic",
  "ginger",
  "gnocchi",
  "granola",
  "grapes",
  "green beans",
  "Guacamole",
  "gumbo",
  "grits",
  "Graham crackers",
  "ham",
  "hamburger",
  "cheeseburger",
  "honey",
  "hash browns",
  "hot dogs",
  "hummus",
  "ice cream",
  "Irish stew",
  "Indian food",
  "Italian bread",
  "jambalaya",
  "jelly",
  "jam",
  "beef jerky",
  "jalapeño",
  "kale",
  "kabobs",
  "ketchup",
  "kiwi",
  "kidney beans",
  "kingfish",
  "lobster",
  "Lamb",
  "Linguine",
  "Lasagna",
  "Meatballs",
  "Moose",
  "Milk",
  "Milkshake",
  "Noodles",
  "Ostrich",
  "Pizza",
  "Pepperoni",
  "Porter",
  "Pancakes",
  "Quesadilla",
  "Quiche",
  "Reuben",
  "Spinach",
  "Spaghetti",
  "Tater tots",
  "Toast",
  "Venison",
  "Waffles",
  "Wine",
  "Walnuts  ",
  "Yogurt",
  "Ziti",
  "Zucchini"
])

const hard = new Set([

]);



export default new ThemeSet('Food',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);