import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])


const medium = new Set([
  "afford",
  "ATM",
  "auction",
  "bailout",
  "balance",
  "bank",
  "bankrupt",
  "bill",
  "borrow",
  "bought",
  "budget",
  "business",
  "buy",
  "cash",
  "cent",
  "change",
  "cheap",
  "check",
  "coupon",
  "credit",
  "credit card",
  "deposit",
  "dime",
  "discount",
  "dollar",
  "donate",
  "donation",
  "donor",
  "earn",
  "economy",
  "expense",
  "expensive",
  "income",
  "interest",
  "invest",
  "investment",
  "investor",
  "lend",
  "loan",
  "loss",
  "market",
  "money",
  "nickel",
  "note",
  "pay",
  "payment",
  "penny",
  "poor",
  "profit",
  "quarter",
  "rate",
  "receipt",
  "rent",
  "rich",
  "risk",
  "sale",
  "save",
  "savings",
  "security",
  "sell",
  "seller",
  "sold",
  "spend",
  "swap",
  "tax",
  "teller",
  "trade",
  "value",
  "vault",
  "wallet",
  "withdraw"
]);


const hard = new Set([
  "bankruptcy",
  "bargain",
  "bonds",
  "capital",
  "collateral",
  "commodity",
  "compound",
  "currency",
  "debt",
  "deficit",
  "depression",
  "diversify",
  "dividend",
  "down payment",
  "earnings",
  "economics",
  "economist",
  "equity",
  "estate",
  "Euro",
  "exchange",
  "finance",
  "financier",
  "fund",
  "lender",
  "margin",
  "mortgage",
  "overdrawn",
  "pence",
  "percentage",
  "portfolio",
  "Pound",
  "purse",
  "rebate",
  "recession",
  "reserve",
  "stocks",
  "surplus",
  "thrifty",
  "treasury",
  "wealth",
  "yield",
])

export default new ThemeSet('Money',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);