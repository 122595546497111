import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);

const medium = new Set([
  'shin',
  'nun',
  'gimel',
  'Hanukkah',
  'shamash',
  'shammes',
  'candle',
  'candelabrum',
  'menorah',
  'oil',
  'eight',
  'mitzvah',
  'Feast of Dedication',
  'Festival of Lights',
  'dreidel',
  'gelt',
  'latke',
  'sufganiyah',
  'tzedakah',
  'chanukkiah',
  'Hallel',
  'Maccabeus',
])

const hard = new Set([
  
])



export default new ThemeSet('Hanukkah',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [
      // EDifficulty.Easy, EDifficulty.Medium,
      EDifficulty.Hard, EDifficulty.Pro
    ]
  });