import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "brisk",
  "cap",
  "chill",
  "chilly",
  "chimney",
  "coat",
  "cold",
  "cough",
  "December",
  "eggnog",
  "fire",
  "fireplace",
  "firewood",
  "fog",
  "freezing",
  "frozen",
  "gingerbread",
  "gingerbread man",
  "gingerbread house",
  "gingerbread woman",
  "gloves",
  "heat",
  "hot chocolate",
  "ice",
  "iceberg",
  "jacket",
  "January",
  "February",
  "reindeer",
  "scarf",
  "skate",
  "ski",
  "sled",
  "sneeze",
  "sniffle",
  "snow",
  "snowball",
  "snowboard",
  "snowfall",
  "snowflake",
  "snowman",
  "snowy",
  "storm",
  "stove",
  "thermometer",
  "wind",
  "windy",
  "winter",
]);

const medium = new Set([
  "arctic",
  "below zero",
  "beret",
  "biting",
  "bitter cold",
  "black ice",
  "blanket",
  "blizzard",
  "boots",
  "chills",
  "cocoon",
  "cold snap",
  "comforter",
  "curling",
  "dog sled",
  "drafty",
  "earmuffs",
  "evergreen",
  "flannel",
  "fleece",
  "flu",
  "flurries",
  "freezing rain",
  "frigid",
  "frostbite",
  "frosty",
  "fruitcake",
  "furnace",
  "gale",
  "glacier",
  "gust",
  "harsh",
  "heater",
  "hibernate",
  "hockey",
  "hoodie",
  "hypothermia",
  "ice cap",
  "ice crystal",
  "ice dam",
  "ice fishing",
  "ice hockey",
  "ice scraper",
  "ice skates",
  "ice storm",
  "icicle",
  "icy",
  "insulation",
  "Jack Frost",
  "log",
  "longjohns",
  "luge",
  "melt",
  "mittens",
  "muffler",
  "nippy",
  "overcast",
  "overcoat",
  "overshoes",
  "parka",
  "pinecone",
  "polar",
  "pullover",
  "quilt",
  "radiator",
  "raw",
  "shiver",
  "show shoe",
  "sledge",
  "sleet",
  "slippery",
  "slush",
  "snow shovel",
  "snowbound",
  "snowplow",
  "snowstorm",
  "socks",
  "solstice",
  "sugarplum",
  "sweater",
  "thaw",
  "toboggan",
  "turtleneck",
  "whiteout",
  "wind chill",
  "wintry",
  "wood stove",
  "wool",
  "zero degrees"
])

const hard = new Set([
  "anorak",
  "balaclava",
  "bleak",
  "blustery",
  "down coat",
  "dreary",
  "duvet",
  "earflap hat",
  "frostbitten",
  "glacial",
  "hailstone",
  "lake effect",
  "woolens",
])

export default new ThemeSet('Winter',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });