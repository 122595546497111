import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-grid-system';
import { useParams, useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';

import {
  Button,
  FormInput
} from 'shards-react'

import {
  ConditionalRender,
  Game,
  Team,
  GameContext,
} from '../../internal';


interface IProps {
}

export const TeamSelect: FC<IProps> = ({ }) => {
  const { search } = useLocation();
  let history = useHistory();
  var { state: { game }, dispatch } = useContext(GameContext)

  const spectatorTeam = new Team({ name: 'spectator', gameId: game?.id || '' });

  // set the team from the query param
  useEffect(() => {
    var teamParam = search.match(/\?.*team=([^\&]*)/)?.[1];
    var intendedTeam: Team;
    var redTeam: Team;
    var blueTeam: Team;

    // get the teams by name
    game?.teams?.forEach((t: Team) => {
      if (t.getStyleName() === 'red-team') {
        redTeam = t;
      } else if (t.getStyleName() === 'blue-team') {
        blueTeam = t;
      }
    })

    if (teamParam === 's') {
      // spectate
      intendedTeam = spectatorTeam;
    } else if (teamParam == 'r') {
      intendedTeam = redTeam!;
    } else if (teamParam == 'b') {
      intendedTeam = blueTeam!;
    }

    if (intendedTeam!) {
      selectTeam(intendedTeam!);
    }

  }, [search, game?.id, game?.teams?.[0]?.id])

  const selectTeam = (t: Team) => {
    game?.setMyTeam?.(t);
    dispatch({ type: 'modifyGame', game });
    dispatch({ type: 'setMyTeam', team: t });
    dispatch({ type: 'setOppTeam', team: game?.getOppositeTeam?.() });
  }

  return (
    <div className={classnames('team-select-container')}>
      <Row justify="center">
        <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>
        <h2 className={classnames('text-center')}>Select Your Team</h2>
      </Row>

      <Row justify="center" className={classnames('team-select-buttons')}>
        {game?.teams?.map((t: Team, i: number) => (
          <Col xs={6} sm={5} lg={4} key={`team-select-${t.name}`}>
            <Button
              key={`team-select-button-${i}`}
              className={classnames('huge', t?.getStyleName?.())}
              onClick={() => selectTeam(t)} >
              {t.name}
            </Button>
          </Col>
        ))}
      </Row>

      <Row justify="center">
        <Col xs={8} sm={6} lg={4} key={`team-select-spectator`}>
          <Button
            key={`team-select-button-spectate`}
            className={classnames('huge')}
            onClick={() => selectTeam(spectatorTeam)} >
            Spectate
          </Button>
        </Col>
      </Row>

      {/* <Row justify="center">
        <Col xs={5} sm={4} md={3} xxl={2}>
          <Link to={`/pages/howToPlay`}>
            <h4 style={{ textAlign: 'center' }}><b>How To Play</b></h4>
          </Link>
        </Col>
      </Row> */}

      <div className="slide-in-message how-to-play-link">
        <Link to={`/pages/howToPlay`}>
          <b>How To Play</b>
        </Link>
      </div>

    </div>
  )
}
