import { ThemeSet, EDifficulty } from '../../themeSet';

export const easy = new Set([

])

export const medium = new Set([
  "adoption",
  "ancestor",
  "aunt",
  "bachelor",
  "bride",
  "brother",
  "brotherhood",
  "care-giver",
  "child",
  "childhood",
  "children",
  "connection",
  "cousin",
  "dad",
  "daddy",
  "daughter",
  "descendant",
  "devoted",
  "divorce",
  "eligible",
  "engaged",
  "engagement",
  "extended family",
  "faithful",
  "family",
  "family tree",
  "father",
  "fiancee",
  "first born",
  "flesh and blood",
  "folks",
  "friend",
  "grampa",
  "grandchild",
  "grandchildren",
  "granddaughter",
  "grandfather",
  "grandma",
  "grandmother",
  "grandpa",
  "grandparent",
  "grandson",
  "groom",
  "grownup",
  "history",
  "home",
  "household",
  "husband",
  "identical twin",
  "in-law",
  "infant",
  "inherit",
  "inheritance",
  "juvenile",
  "love",
  "loyalty",
  "maiden name",
  "mama",
  "marriage",
  "mate",
  "maternal",
  "matrimony",
  "minor",
  "Miss",
  "mom",
  "mommy",
  "mother",
  "nana",
  "nest",
  "newlywed",
  "nurture",
  "offspring",
  "orphan",
  "papa",
  "parent",
  "partner",
  "paternal",
  "pop",
  "quadruplets",
  "quintuplets",
  "relative",
  "senior",
  "separation",
  "sibling",
  "single",
  "sister",
  "sisterhood",
  "son",
  "spouse",
  "support",
  "tribe",
  "triplets",
  "trust",
  "trustworthy",
  "twin brother",
  "twin sister",
  "twins",
  "uncle",
  "wedding",
  "wife",
  "youngster",
  "youth",
])


const hard = new Set([
  "brother-in-law",
  "daughter-in-law",
  "estranged",
  "father-in-law",
  "first cousin",
  "foster",
  "fraternal",
  "fraternal twin",
  "genealogy",
  "half-brother",
  "half-sister",
  "heir",
  "heiress",
  "hereditary",
  "heritage",
  "kindred",
  "kinfolk",
  "kinship",
  "lineage",
  "monogamy",
  "mother-in-law",
  "nephew",
  "niece",
  "nuptial",
  "sister-in-law",
  "son-in-law",
  "stepbrother",
  "stepchild",
  "stepchildren",
  "stepdad",
  "stepdaughter",
  "stepfather",
  "stepmom",
  "stepmother",
  "stepsister",
  "stepson",
]);


export default new ThemeSet('Family',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);