import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  "Walmart",
  "Amazon",
  "Etsy",
  "Ebay",
  "CVS",
  "UnitedHealth",
  "AT&T",
  "Cigna",
  "Ford Motor Company",
  "Costco",
  "Microsoft",
  "General Motors",
  "Walgreens",
  "Verizon",
  "Kroger",
  "Bank of America",
  "The Home Depot",
  "Wells Fargo",
  "General Electric",
  "Dell",
  "Johnson & Johnson",
  "State Farm",
  "Target Corporation",
  "U.P.S.",
  "Lowe's",
  "Intel",
  "Facebook",
  "Pepsi",
  "FedEx",
  "Disney",
  "Albertsons",
  "HP",
  "Morgan Stanley",
  "Caterpillar",
  "Cisco",
  "American Express",
  "Delta Air Lines",
  "Allstate",
  "Nationwide",
  "Best Buy",
  "United Airlines",
  "Tyson",
  "John Deere",
  "Nike, Inc.",
  "Progressive",
  "Publix",
  "Coca-Cola",
  "Honeywell",
  "USAA",
  "Capital One",
  "Heinz",
  "Hewlett Packard",
  "Dollar General",
  "Starbucks",
  "Macy's",
  "Kraft",
  "Tesla",
  "Dollar Tree",
  "Visa",
  "Southwest",
  "Aflac",
  "McDonald's",
  "Whirlpool",
  "Netflix",
  "Kohl's",
  "CarMax",
  "PayPal",
  "Mastercard",
  "General Mills",
  "Colgate",
  "Goodyear Tires",
  "Texas Instruments",
  "Uber",
  "Kellogg's",
  "Dish Network",
  "Farmers Insurance",
  "Bed Bath & Beyond",
  "LabCorp",
  "Fox News",
  "Expedia ",
  "AutoZone",
  "MGM Resorts",
  "Land O'Lakes",
  "Nordstrom",
  "Lowe's",
  "Ross",
]);

const hard = new Set([
  "Berkshire Hathaway",
  "Salesforce",
  "Oracle",
  "General Dynamics",
  "Lockheed Martin",
  "Pfizer",
  "Goldman Sachs",
  "Western Digital",
  "IBM",
  "Boeing",
  "Humana",
  "CenturyLink",
  "Sherwin-Williams",
  "Comcast",
  "Alphabet Inc.",
  "JPMorgan Chase",
])

export default new ThemeSet('Companies',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);