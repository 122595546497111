import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import {
  Button,
} from 'shards-react'

import {
  ConditionalRender,
  Footer,
  Header
} from '../../internal';

import teamUpImg from '../../assets/playguide/teamup.png'
import devicesImg from '../../assets/playguide/devices.png'
import step0Img from '../../assets/playguide/0.png'
import step1Img from '../../assets/playguide/1.png'
import step2Img from '../../assets/playguide/2.png'
import step3Img from '../../assets/playguide/3.png'
import step4Img from '../../assets/playguide/4.png'
import step5Img from '../../assets/playguide/5.png'
import step6Img from '../../assets/playguide/6.png'
import step7Img from '../../assets/playguide/7.png'
import step8Img from '../../assets/playguide/8.png'
import step9Img from '../../assets/playguide/9.png'
import step10Img from '../../assets/playguide/10.png'

export interface IPlayGuideInstructionStep {
  stepTitle: string,
  texts: string[],
  img: string
}

export const guideInstructions: IPlayGuideInstructionStep[] = [
  {
    stepTitle: 'Setup: Divide into 2 Teams',
    texts: [
      'You and your group should divide into two teams (Red Team and Blue Team) with at least 2 players on each side.',
      'Designate one player from each team the captain (clue-giver) for the first round. The captain position will rotate through the team after each round.'
    ],
    img: teamUpImg
  },
  {
    stepTitle: 'Setup: Grab a Device',
    texts: [
      'It is recommended for each player to open the game on their own device -- mobile, tablet, or computer so that each player can contribute on their own.',
      'Open the game on your device, enter the room code and select your team.'
    ],
    img: devicesImg
  },
  {
    stepTitle: 'Game Phase 1: Create Banned Words',
    texts: [
      'The first phase of the game is the write phase. In this phase, your team creates the Banned Words for the opposite team\'s Guessing Phase.',
      'The top of the screen shows the Target Words the opposite team will attempt.',
      'You and your team must enter words you expect the opposing team will use while describing and guessing their Target Words.',
    ],
    img: step0Img
  },
  {
    stepTitle: 'Game Phase 1: Create Banned Words',
    texts: [
      'In this example, you\'ve decided to make a new Banned Word for the Target Word \'Tiger\'. The goal is to write a word you think the Red Team\'s captain (or their team) will use when describing and guessing the \'Tiger\'.',
      "You enter the word 'Tail' and hit 'Enter'."
    ],
    img: step1Img
  },
  {
    stepTitle: 'Game Phase 1: Create Banned Words',
    texts: [
      "'Tail' now shows below in the list of possible Banned Words."
    ],
    img: step2Img
  },
  {
    stepTitle: 'Game Phase 1: Create Banned Words',
    texts: [
      "As you are entering words, your team is doing the same. You can see all your team's entries in the list at the bottom."
    ],
    img: step3Img
  },
  {
    stepTitle: 'Game Phase 2: Vote on Best Entries',
    texts: [
      "After the time expires for writing new Banned Words, the Vote Phase begins.",
      'Only the top 16 words will be used as the chosen Banned Words.',
      "Each player can vote on the words they think are most likely to earn their team points during the other team's Guessing Phase.",
    ],
    img: step4Img
  },
  {
    stepTitle: 'Game Phase 2: Vote on Best Entries',
    texts: [
      "Your votes are highlighted and the total votes for each word is shown in the vote counter circles.",
      "The 16 words with the most votes when the time expires will be used during the opposite team's Guessing Phase."
    ],
    img: step5Img
  },
  {
    stepTitle: 'Game Phase 3: Guess Phase',
    texts: [
      "Next, each team has a Guess Phase.",
      "During each team's guess phase, the captain will describe the Target Words to their team. Their team tries to guess the Target Word without saying one of the hidden Banned Words setup by the opposite team.",
    ],
    img: step6Img
  },
  {
    stepTitle: 'Game Phase 3: Guess Phase',
    texts: [
      "Each target word successfully guessed earns one point for the guessing team (red here).",
    ],
    img: step7Img
  },
  {
    stepTitle: 'Game Phase 3: Guess Phase',
    texts: [
      "Each Banned Word mentioned by the guessing team -- regardless of who says it -- earns one point for the non-guessing team.",
      "Here, the blue team earns one point for the word 'air', which was mentioned by the red team's captain.",
    ],
    img: step8Img
  },
  {
    stepTitle: 'Game Phase 3: Guess Phase',
    texts: [
      "Be sure to select the words mentioned to earn your points.",
    ],
    img: step9Img
  },
  {
    stepTitle: 'Round Results',
    texts: [
      "After each team takes a turn at the Guess Phase, the round ends on the results screen.",
      "View all the points earned and near-misses by both teams!",
      "When you are ready, you can start a new round to keep playing.",
    ],
    img: step10Img
  },
];

interface IProps {

}

export const HowToPlay: FC<IProps> = ({ }) => {

  const [currGuideStep, setCurrGuideStep] = useState<number>(0);
  const [viewSummary, setViewSummary] = useState<boolean>(false);

  return (
    <>
      <Header />
      <Container>
        <div className="pages-container">
          <Row justify="center" className="playguide">
            <h2 className="text-center">How To Play: A Step-by-Step Guide</h2>

            <Col xs={12}>

              <Row justify="center">
                <Col xs={6} md={4}>
                  <Button
                    className={classnames('not-huge')}
                    onClick={setCurrGuideStep.bind(null, currGuideStep - 1)}
                    disabled={currGuideStep <= 0}>
                    <b>Prev</b>
                  </Button>
                </Col>
                {/* image */}
                <Col xs={6} md={4}>
                  <Button
                    className={classnames('not-huge')}
                    onClick={setCurrGuideStep.bind(null, currGuideStep + 1)}
                    disabled={currGuideStep >= guideInstructions.length - 1}>
                    <b>Next</b>
                  </Button>
                </Col>
              </Row>

              <Row justify="center">

                {/* image */}
                <ConditionalRender visible={!!guideInstructions[currGuideStep]?.img}>
                  <Col xs={11} sm={8} md={6} lg={4}>
                    <img className="playguide-img" src={guideInstructions[currGuideStep]?.img} />
                  </Col>
                </ConditionalRender>

                <Col xs={12} sm={12} md={6} lg={4}>
                  {/* step title */}
                  <h4>{guideInstructions[currGuideStep]?.stepTitle}</h4>
                  {/* paragraphs */}
                  {guideInstructions[currGuideStep]?.texts?.map((text: string) => (
                    <p>{text}</p>
                  ))}
                </Col>

              </Row>


            </Col>
          </Row>


          <Row justify="center">
            <Col sm={6} md={4} lg={3} xxl={2}>
              <Button className={classnames('not-huge')} onClick={() => setViewSummary(!viewSummary)}>
                <b>View Full Game Summary</b>
              </Button>
            </Col>
          </Row>
          <ConditionalRender visible={viewSummary}>
            <Row className={classnames('instruction-block')}>
              <Col xs={2} md={1}>
                {/* <FaQuestionCircle /> */}
              </Col>
              <Col xs={9} md={10}>

                <h4 className={classnames('text-center')}>How to Play:</h4>
                <p>
                  One player from your group should create a game using the button above. Once the game is created, all other players should select join a game and type in the game code.
                </p>
                <p>
                  Divide players into two teams (Red Team and Blue Team).</p>
                <p>
                  Select a player from each team to be the designated Captain for the round.
                </p>
                <p>
                  During the round's guess phase, the Captain is presented with (5) <em>Target Words</em>.
                  The Captain gives verbal clues to their team so that the <em>Target Words</em> are guessed.
                  Each time the Captain's team says one of the <em>Target Words</em> before the time expires, their team earns one point.</p>
                <p>
                  But there's a catch! Before each Captain's guess phase begins, the opposing teams have a chance to create a secret list of <em>Banned Words</em> they think will be used by the Captain or their team during the guessing phase.
                  Each team earns one point whenever their <em>Banned Words</em> are mentioned by the opposing team.
                </p>
                <p>
                  After both teams have a chance to guess, the round's winner is determined by the team with the most total points earned. Play at least 3 rounds for a full game.
                </p>
                <p>
                  <em>Note: For the optimal experience, each player should open the game on their own device. For remote play with friends, join a video call and screen-share the spectator view.</em>
                </p>
                <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>

              </Col>
            </Row>
          </ConditionalRender>

        </div>


      </Container >
      <Footer />
    </>
  )
}
