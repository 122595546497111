import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  "football",
  "Stadium",
  "50 yard line",
  "first down",
  "second down",
  "third down",
  "fourth down",
  "endzone",
  "fieldgoal",
  "touchdown",
  "kickoff",
  "water break",
  "pig skin",
  "New England Patriots",
  "Tom Brady",
  "OJ Simpson"
]);

const hard = new Set([
  "goal line",
  "targeting",
  "pass interference",
  "holding",
  "roughing the passer",
  "move the chains",
  "uprights",
  "Bill Belichick",
  "Jerry Rice",
  "Peyton Manning",
  "Dan Marino",
  "Brett Farve",
  "49ers",
  "Philadelphia Eagles",
  "Seattle Seahawks",
  "Green Bay Packers",
  "Pitsburg Steelers",
  "Minnesota Vikings",
  "Denver Broncos",
  "Chicago Bears",
  "Miami Dolphins",
  "Buffalo Bills",
  "Buccaneers",
  "Baltimore Ravens",
  "Cardinals",
  "LA Rams",
  "Falcons",
  "Jaguars",
  "Jacksonville",
  "Cowboys",
  "New York Jets",
])

export default new ThemeSet('NFL',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);