import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "clown",
  "corn dog",
  "dance",
  "fair",
  "food",
  "fun",
  "games",
  "hat",
  "party",
]);

const medium = new Set([
  "amusement",
  "banquet",
  "bash",
  "beads",
  "block party",
  "booth",
  "bumper cars",
  "candy apple",
  "carnival",
  "celebrate",
  "celebration",
  "cheerful",
  "cheery",
  "circus",
  "concessions",
  "contest",
  "costume",
  "cotton candy",
  "county fair",
  "enjoyable",
  "entertain",
  "entertainment",
  "event",
  "exciting",
  "extravaganza",
  "fairground",
  "feast",
  "Ferris wheel",
  "festival",
  "festive",
  "float",
  "fun house",
  "funfair",
  "funnel cakes",
  "gala",
  "gathering",
  "holiday",
  "hot dog",
  "house of mirrors",
  "ice cream",
  "joyous",
  "juggle",
  "juggler",
  "lemonade",
  "Lent",
  "marching band",
  "Mardi Gras",
  "mask",
  "merriment",
  "merry-go-round",
  "music",
  "occasion",
  "parade",
  "performances",
  "picnic",
  "popcorn",
  "prance",
  "regale",
  "rides",
  "roller coaster",
  "romp",
  "show",
  "sideshow",
  "snow cone",
  "spectacle",
  "spin art",
  "state fair",
  "street fair",
  "world's fair"
])

const hard = new Set([
  "cavalcade",
  "cavort",
  "exhibition",
  "festivity",
  "fiesta",
  "fried dough",
  "frolic",
  "gleeful",
  "hijinks",
  "jamboree",
  "masquerade",
  "midway",
  "observance",
  "procession",
  "revel",
  "revelry",
  "rollick",
  "shindig",
  "wingding",
])



export default new ThemeSet('Carnival',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });