import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "animals",
  "backpack",
  "boots",
  "cabin",
  "camp",
  "camper",
  "campfire",
  "campground",
  "canoe",
  "cap",
  "climb",
  "compass",
  "day pack",
  "equipment",
  "fishing",
  "flashlight",
  "forest",
  "gear",
  "hammock",
  "hat",
  "hike",
  "lake",
  "lantern",
  "map",
  "moon",
  "mountain",
  "nature",
  "oar",
  "outdoors",
  "outside",
  "park",
  "path",
  "rope",
  "scenery",
  "sleeping bag",
  "stars",
  "state park",
  "sun",
  "sunscreen",
  "tent",
  "trail",
  "trail mix",
  "trees",
  "trip",
  "vest",
  "walking",
  "water bottle",
]);

const medium = new Set([
  "adventure",
  "canteen",
  "caravan",
  "dehydrated food",
  "dugout",
  "evergreen",
  "hiking boots",
  "hunting",
  "hut",
  "insect",
  "insect repellent",
  "kayak",
  "knapsack",
  "national park",
  "natural",
  "paddle",
  "tarp",
  "trailer",
  "waterfall",
  "wildlife",
  "woods",
])

const hard = new Set([

]);


export default new ThemeSet('Camping',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);