import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set<string>([
  // marvel
  "Iron Man",
  "Tony Stark",
  "Captain America",
  "Hulk",
  "Thor",
  "Black Widow",
  "Hawkeye",
  "Nick Fury",
  "Dr Strange",
  "Spider Man",
  "Peter Parker",
  "Mary Jane",
  "JARVIS",
  "Black Panther",
  "Vision",
  "Star-Lord",
  "Gamora",
  "Drax",
  "Rocket Raccoon",
  "Groot",
  "Falcon",
  "Winter Soldier",
  "Loki",
  "Aunt May",
  "Uncle Ben",
  "Dr Octopus",
  "Venom",
  "Captain Marvel",
  "Wakanda",
  "Guardians of the Galaxy",
  "Fantastic Four",
  "Avengers",
  "Ant-Man",
  "x-men",
  "Professor Xavier",
  "Cyclops",
  "Iceman",
  "Beast",
  "Wolverine",
  "Deadpool",

  // DC
  "Batman",
  "Bruce Wayne",
  "Batmobile",
  "Scarecrow",
  "Mr Freeze",
  "The Riddler",
  "Superman",
  "Clark Kent",
  "Lex Luthor",
  "The Joker",
  "Batwoman",
  "Aquaman",
  "Wonder Woman",
  "Lois Lane",
  "Scarecrow",
  "Harley Quinn",
  "Poison Ivy",
  "Bane",
  "Bizarro",
  "Mr Freeze",
  "Catwoman",
  "The Penguin",

  // animated,
  "The Incredibles",
  "Mr. Incredible",
  "Elastigirl",
  "Syndrome",
  "Frozone",
  "Spiderverse",
  "Marvel Multiverse",
  
])


const hard = new Set([
  "Quantomania",
  "Love and Thunder",
  "Eternals",
  "Shang Chi",
  "Infinity War",
  "Ragnarok",
  "Age of Ultron",
  "Whiplash",
  "Ultron",
  "Thanos",
  "Magneto",
  "Rogue",
  "Mantis",
  "Vulture",
  "Pepper Potts",
  "Quicksilver",
  "Bob Parr",
  "Dash",
  "Violet Parr",
  "Edna Mode",
  "Agent Mobius"
])


export default new ThemeSet('Superheroes',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);