import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);

export const medium = new Set([
  "actor",
  "actress",
  "banker",
  "accountant",
  "advisor",
  "aide",
  "animator",
  "archer",
  "artist",
  "astronaut",
  "astronomer",
  "athlete",
  "attorney",
  "auctioneer",
  "author",
  "babysitter",
  "baker",
  "ballerina",
  "barber",
  "bellhop",
  "biologist",
  "blacksmith",
  "bowler",
  "builder",
  "butcher",
  "butler",
  "cab driver",
  "captain",
  "cardiologist",
  "carpenter",
  "cartoonist",
  "cashier",
  "catcher",
  "chauffeur",
  "chef",
  "chemist",
  "clerk",
  "coach",
  "composer",
  "contractor",
  "cook",
  "cop",
  "custodian",
  "dancer",
  "dentist",
  "deputy",
  "dermatologist",
  "designer",
  "detective",
  "dictator",
  "director",
  "disc jockey",
  "diver",
  "doctor",
  "doorman",
  "driver",
  "drummer",
  "dry cleaner",
  "economist",
  "editor",
  "educator",
  "electrician",
  "emperor",
  "empress",
  "engineer",
  "entertainer",
  "entrepreneur",
  "executive",
  "explorer",
  "exporter",
  "exterminator",
  "farmer",
  "firefighter",
  "fisherman",
  "football player",
  "game designer",
  "garbage man",
  "gardener",
  "general",
  "geographer",
  "geologist",
  "golfer",
  "governor",
  "grocer",
  "guide",
  "hairdresser",
  "handyman",
  "hobo",
  "hunter",
  "illustrator",
  "importer",
  "instructor",
  "intern",
  "interpreter",
  "inventor",
  "investigator",
  "janitor",
  "jester",
  "jeweler",
  "jockey",
  "journalist",
  "judge",
  "karate teacher",
  "laborer",
  "landlord",
  "landscaper",
  "lawyer",
  "lecturer",
  "lifeguard",
  "locksmith",
  "lyricist",
  "magician",
  "maid",
  "mail carrier",
  "manager",
  "manufacturer",
  "marine",
  "marketer",
  "mason",
  "mathematician",
  "mayor",
  "mechanic",
  "messenger",
  "midwife",
  "miner",
  "model",
  "monk",
  "musician",
  "navigator",
  "negotiator",
  "notary",
  "novelist",
  "nun",
  "nurse",
  "optician",
  "oracle",
  "orderly",
  "painter",
  "paralegal",
  "park ranger",
  "pediatrician",
  "performer",
  "pharmacist",
  "philosopher",
  "photographer",
  "physicist",
  "pianist",
  "pilot",
  "pitcher",
  "plumber",
  "poet",
  "police",
  "policeman",
  "policewoman",
  "politician",
  "president",
  "prince",
  "princess",
  "principal",
  "private detective",
  "producer",
  "professor",
  "programmer",
  "psychiatrist",
  "psychologist",
  "publisher",
  "quarterback",
  "rancher",
  "ranger",
  "receptionist",
  "referee",
  "reporter",
  "researcher",
  "retailer",
  "sailor",
  "salesperson",
  "samurai",
  "saxophonist",
  "scholar",
  "scientist",
  "scout",
  "scuba diver",
  "seamstress",
  "security guard",
  "senator",
  "sheriff",
  "singer",
  "soldier",
  "spy",
  "star",
  "statistician",
  "stockbroker",
  "street sweeper",
  "student",
  "surgeon",
  "surveyor",
  "tailor",
  "tax collector",
  "taxi driver",
  "taxidermist",
  "teacher",
  "technician",
  "tennis player",
  "test pilot",
  "toolmaker",
  "trader",
  "trainer",
  "translator",
  "trash collector",
  "travel agent",
  "treasurer",
  "truck driver",
  "tutor",
  "typist",
  "umpire",
  "undertaker",
  "valet",
  "veteran",
  "veterinarian",
  "waitress",
  "warden",
  "warrior",
  "watchmaker",
  "welder",
  "writer",
  "zookeeper",
  "zoologist"
]);



const hard = new Set([
  "bookkeeper",
  "calligrapher",
  "caterer",
  "cartographer",
  "cellist",
  "chaplain",
  "concierge",
  "coroner",
  "cryptographer",
  "ecologist",
  "entomologist",
  "foreman",
  "gatherer",
  "internist",
  "jailer",
  "laundress",
  "percussionist",
  "vicar",
]);


export default new ThemeSet('Jobs',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);