import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "animals",
  "audience",
  "balloons",
  "band",
  "banner",
  "bear",
  "bicycle",
  "bow",
  "bumper cars",
  "cage",
  "candy",
  "cannon",
  "cape",
  "carnival",
  "cashier",
  "clown",
  "corn dog",
  "costume",
  "cotton candy",
  "dog",
  "elephant",
  "entertainer",
  "feat",
  "Ferris wheel",
  "fun",
  "fun house",
  "games",
  "giant",
  "hoop",
  "horse",
  "lion",
  "juggler",
  "magic",
  "magician",
  "maze",
  "monkey",
  "music",
  "net",
  "parade",
  "performer",
  "petting zoo",
  "popcorn",
  "poster",
  "prizes",
  "ride",
  "ring",
  "roller coaster",
  "seal",
  "show",
  "star",
  "state fair",
  "stuffed animals",
  "swing",
  "sword swallower",
  "tent",
  "thrill ride",
  "ticket",
  "tiger",
  "train",
  "trainer",
  "trick",
  "trunk",
  "water park"
]);

const medium = new Set([
  "admission",
  "amusement park",
  "applaud",
  "attractions",
  "balancing act",
  "big top",
  "booth",
  "calliope",
  "fair",
  "fairground",
  "carousel",
  "county fair",
  "festival",
  "festive",
  "fire breather",
  "fire eater",
  "grandstand",
  "gymnast",
  "house of mirrors",
  "knife thrower",
  "leotard",
  "lion tamer",
  "livestock",
  "log flume",
  "merry-go-round",
  "ring toss",
  "ringmaster",
  "somersault",
  "spectator",
  "spinning plates",
  "stagehand",
  "stilts",
  "strong man",
  "tightrope",
  "top hat",
  "trampoline",
  "trapeze artist",
  "unicycle",
  "vanishing act",
  "troupe",
  "swag",
  "side show",
]);

const hard = new Set([

]);

export default new ThemeSet('Circus',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);