import { EWordType } from '../internal';

export const gameByCodeWithTeam = /* GraphQL */ `
  query GameByCode(
    $code: String
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameByCodeSorted(
      code: $code
      sortDirection: DESC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        phase
        phaseEndTime
        modes
        voteMs
        writeMs
        guessMs
        earnTimeMs
        wordSetsOn
        customTargets
        usedTargets
        updater
        teams {
          items {
            id
            name
            roundId
            gameId
            round {
              id
              gameId
              teamId
              sacrificed
            }
            rounds{ 
              items {
                id
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getFullGameResultsByCode = /* GraphQL */ `
query GameByCode(
  $code: String
  $filter: ModelGameFilterInput
  $limit: Int
  $nextToken: String
) {
  gameByCodeSorted(
    code: $code
    sortDirection: DESC
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      code
      phase
      phaseEndTime
      voteMs
      writeMs
      guessMs
      earnTimeMs
      wordSetsOn
      customTargets
      usedTargets
      updater
      teams {
        items { 
          id
          name
          roundId
          gameId
          round {
            id
            sacrificed
            bws (filter: { type: {eq:${EWordType.bw}}, selected: {eq: true} }) {
              items {
                id
                text
                type
                selected
                skipped
                adjusted
                bombText
              }
              nextToken
            }
            tws (filter: { type: {eq: ${EWordType.tw}}, selected: {eq: true} }) {
              items {
                id
                text
                type
                selected
                skipped
                adjusted
              }
              scoreAdjustment
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;

export const wordByRoundIdAndTypeWithVotes = /* GraphQL */ `
  query WordByRoundIdAndType(
    $roundId: ID
    $type: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wordByRoundIdAndType(
      roundId: $roundId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        type
        selected
        doubleValue
        bombText
        votes {
          items {
            id
          }
          nextToken
        }
        associations {
          nextToken
        }
        teamId
        roundId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listRecentGames = `
  query RecentGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    )  {
    items { 
      id
      code
      modes
      wordSetsOn
      customTargets
      location
      createdAt
      updatedAt
      teams {
        items {
          rounds {
            items {
              id
              createdAt
            }
          }
        }
      }
    }
  }
}
`;

export const getBombWordsForRound = /* GraphQL */ `
  query WordByRoundIdAndBomb(
    $roundId: ID
    $bombText: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wordByRoundIdAndBomb(
      roundId: $roundId
      bombText: $bombText
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        selected
        bombText
      }
      nextToken
    }
  }
`;


export const getMyTeamAsGuesser = /* GraphQL */ `
query getMyTeamAsGuesser($id: ID!) {
  getTeam(id: $id) {
    id
    name
    gameId
    rounds {
      items {
        id
      }
      nextToken
    }
    roundId
    round {
      id
      sacrificed
      cws (filter: { type: { eq: ${EWordType.cw} } }) {
        items {
          id
          text
          type
          teamId
          votes {
            items {
              id
            }
            nextToken
          }
          associations {
            items {
              id
              tw {
                id
                text
              }
            }
            nextToken
          }
        }
        nextToken
      }
      bws (filter: { type: { eq: ${EWordType.bw} } }) {
        items {
          id
          text
          type
          selected
          bombText
        }
        nextToken
      }
      tws (filter: { type: { eq: ${EWordType.tw} }, selected: { eq: "true" } }) {
        items {
          id
          text
          type
          selected
        }
        nextToken
      }
    }
  }
}
`;

export const getTeamAsChecker = /* GraphQL */ `
query getTeamAsChecker($id: ID!) {
  getTeam(id: $id) {
    id
    name
    gameId
    rounds {
      items {
        id
      }
      nextToken
    }
    roundId
    round {
      id
      sacrificed
      bws (filter: { type: { eq: ${EWordType.bw} } }) {
        items {
          id
          text
          type
          teamId
          doubleValue
          bombText
          votes {
            items {
              id
            }
            nextToken
          }
          associations {
            items {
              id
              tw {
                id
                text
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
}
`;

export const getTeamAsClueGiver = /* GraphQL */ `
query getTeamAsClueGiver($id: ID!) {
  getTeam(id: $id) {
    id
    name
    gameId
    rounds {
      items {
        id
      }
      nextToken
    }
    roundId
    round {
      id
      sacrificed
      tws (filter: { type: {eq: ${EWordType.tw}} }) {
        items {
          id
          text
          type
          selected
        }
        nextToken
      }
    }
  }
}
`;

export const getTeamAsOpposition = /* GraphQL */ `
query getTeamAsOpposition($id: ID!) {
  getTeam(id: $id) {
    id
    name
    gameId
    roundId
    rounds {
      items {
        id
      }
      nextToken
    }
    round {
      id
      tws (filter: { type: {eq: ${EWordType.tw}} }) {
        items {
          id
          text
          type
          selected
          doubleValue
          bombText
        }
        nextToken
      }
    }
  }
}
`;

export const getTeamForRoundResults = `
query getTeamForRoundResults($id: ID!) {
  getTeam(id: $id) {
    id
    name
    gameId
    roundId
    rounds (sortDirection: DESC) {
      items {
        id
        sacrificed
        scoreAdjustment
        tws (filter: { type: { eq: ${EWordType.tw} } }, sortDirection: DESC) {
          items {
            id
            text
            type
            selected
            skipped
            doubleValue
            bombText
            adjusted
          }
          nextToken
        }
        bws (filter: { type: { eq: ${EWordType.bw} } }, sortDirection: DESC) {
          items {
            id
            text
            type
            selected
            doubleValue
            bombText
            adjusted
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;

export const getTeamAsSpectator = /* GraphQL */ `
query getTeamAsSpectator($id: ID!) {
  getTeam(id: $id) {
    id
    name
    gameId
    roundId
    round {
      id
      sacrificed
      cws (filter: { type: { eq: ${EWordType.cw} } }) {
        items {
          id
          teamId
          votes {
            items {
              id
            }
            nextToken
          }
          associations {
            items {
              id
              tw {
                id
              }
            }
            nextToken
          }
        }
        nextToken
      }
      bws (filter: { type: { eq: ${EWordType.bw} }, selected: { eq: "true" } }) {
        items {
          id
          text 
          doubleValue
          bombText
        }
        nextToken
      }
      tws (filter: { type: { eq: ${EWordType.tw} }, selected: { eq: "true" } }) {
        items {
          id
          text
          skipped
          doubleValue
          bombText
        }
        nextToken
      }
    }
  }
}
`;
