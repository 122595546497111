export enum EDifficulty {
  Unknown = 'unknown',
  Easy = 'easy',
  Medium = 'medium',
  Hard = 'hard',
  Pro = 'pro'
}

export interface ISetOfWords {
  id: string,
  name: string,
  words: Set<string>,
  difficulty: EDifficulty,
  disabledByDefault: boolean,
  showAsNew: boolean
}

export interface IThemeOptions {
  disabledByDefault?: EDifficulty[],
  showAsNew?: EDifficulty[],
  hidden?: boolean
}

export class ThemeSet {

  static idifySet = (themeName: string, difficulty: EDifficulty) => {
    return `${themeName!.toLowerCase().replace(/\s/g, '-')}-${difficulty}`
  }

  static tagFromDifficulty = (difficulty: EDifficulty) => {
    switch (difficulty) {
      case EDifficulty.Easy:
        return 'EASY'
      case EDifficulty.Hard:
        return 'HARD';
      case EDifficulty.Medium:
        return 'MED';
      case EDifficulty.Pro:
        return 'PRO';
      default:
        return '';
    }
  }

  // lots of good word lists here
  // https://www.enchantedlearning.com/wordlist/
  static allWordSets = [];
  static easyWordSets = [];
  static mediumWordSets = [];
  static hardWordSets = [];
  static proWordSets = [];

  hidden: boolean;
  name: string;
  easy: ISetOfWords;
  medium: ISetOfWords;
  hard: ISetOfWords;
  pro: ISetOfWords;

  constructor(themeName: string, easySet: Set<string>, mediumSet: Set<string>, hardSet: Set<string>, proSet: Set<string>, options?: IThemeOptions) {
    this.hidden = options?.hidden ?? false;
    this.name = themeName;

    this.easy = {
      id: ThemeSet.idifySet(themeName, EDifficulty.Easy),
      name: themeName,
      difficulty: EDifficulty.Easy,
      disabledByDefault: options?.disabledByDefault?.indexOf(EDifficulty.Easy) != -1 ?? false,
      words: easySet,
      showAsNew: options?.showAsNew?.indexOf(EDifficulty.Easy) != -1 ?? false,
    };

    this.medium = {
      id: ThemeSet.idifySet(themeName, EDifficulty.Medium),
      name: themeName,
      difficulty: EDifficulty.Medium,
      disabledByDefault: options?.disabledByDefault?.indexOf(EDifficulty.Medium) != -1 ?? false,
      words: mediumSet,
      showAsNew: options?.showAsNew?.indexOf(EDifficulty.Medium) != -1 ?? false,
    };

    this.hard = {
      id: ThemeSet.idifySet(themeName, EDifficulty.Hard),
      name: themeName,
      difficulty: EDifficulty.Hard,
      disabledByDefault: options?.disabledByDefault?.indexOf(EDifficulty.Hard) != -1 ?? false,
      words: hardSet,
      showAsNew: options?.showAsNew?.indexOf(EDifficulty.Hard) != -1 ?? false,
    };

    this.pro = {
      id: ThemeSet.idifySet(themeName, EDifficulty.Pro),
      name: themeName,
      difficulty: EDifficulty.Pro,
      disabledByDefault: options?.disabledByDefault?.indexOf(EDifficulty.Pro) != -1 ?? false,
      words: proSet,
      showAsNew: options?.showAsNew?.indexOf(EDifficulty.Pro) != -1 ?? false,
    };

  }

  addAllowedWords(words: Set<string>, allowedWordSetIds: string[]): Set<string> {
    // easy
    if (allowedWordSetIds.indexOf(this.easy.id) >= 0) {
      console.log(`adding word set: ${this.easy.id} to allowed word sets`);
      this.easy.words.forEach((w: string) => {
        words?.add(w);
      });
    } else {
      console.log(`skipping disallowed word set: ${this.easy.id} `);
    }

    // med
    if (allowedWordSetIds.indexOf(this.medium.id) >= 0) {
      console.log(`adding word set: ${this.medium.id} to allowed word sets`);
      this.medium.words.forEach((w: string) => {
        words?.add(w);
      });
    } else {
      console.log(`skipping disallowed word set: ${this.medium.id} `);
    }

    // hard
    if (allowedWordSetIds.indexOf(this.hard.id) >= 0) {
      console.log(`adding word set: ${this.hard.id} to allowed word sets`);
      this.hard.words.forEach((w: string) => {
        words?.add(w);
      });
    } else {
      console.log(`skipping disallowed word set: ${this.hard.id} `);
    }

    // hard
    if (allowedWordSetIds.indexOf(this.pro.id) >= 0) {
      console.log(`adding word set: ${this.pro.id} to allowed word sets`);
      this.pro.words.forEach((w: string) => {
        words?.add(w);
      });
    } else {
      console.log(`skipping disallowed word set: ${this.pro.id} `);
    }

    return words;
  }


}