
import {
  getRandomNumBetween,
  DbEntity,
  Game,
  Word,
  IBWord,
  ICWord,
  ITWord,
  IWord,
  ITeam,
  IRound,
  BW,
  TW,
  CW,
  Round,
  graphQLMutation,
  createTeam,
  updateTeam,
  IModelIncludeOpts
} from '../internal';

export interface IScore {
  points: number,
  bombsHit: number,
  numSacrificed: number
}

export class Team extends DbEntity {
  name: string;
  gameId: string;
  roundId?: string;
  round?: Round;
  rounds?: Round[];

  constructor(opts: ITeam) {
    super(opts);
    this.name = opts.name ?? ``; // `${Word.getRandomAdj()} ${Word.getRandomNoun()}`;
    this.gameId = opts.gameId;
    this.roundId = opts.roundId;

    this.round = opts?.round ? new Round(opts!.round!) : undefined;
    this.rounds = opts?.rounds?.items?.map((r: IRound) => new Round(r)) ?? [];
  }

  // stuff to do when we're building a new entity for the db
  newEntity() {
    super.newEntity();
    return this;
  }

  addCW(word: CW) {
    this.round?.cws?.push?.(word);
    return this;
  }

  // This would require a pretty large change to have a different display name for the team
  // getDisplayName(): string {
  //   if (this.isRedTeam()) {
  //     return Game.redTeamDisplayName || Game.redTeamName;
  //   } else {
  //     return Game.blueTeamDisplayName || Game.blueTeamName;
  //   }
  // }

  getStyleName(): string {
    return this.name.toLowerCase().replace(' ', '-');
  }

  isRedTeam(): boolean {
    return new RegExp(/red/, 'i').test(this.name) || this.name == Game.redTeamName;
  }

  isBlueTeam() {
    return !this.isRedTeam();
  }

  private addScores(score1: IScore, score2: IScore): IScore {
    return {
      points: score1.points + score2.points,
      bombsHit: score1.bombsHit + score2.bombsHit,
      numSacrificed: score1.numSacrificed + score2.numSacrificed 
    }
  }

  // calculates the team's score for a single round 
  // or the whole game if no round provided 
  getScore(desiredRound?: number): IScore {
    var emptyScore = { points: 0, bombsHit: 0, numSacrificed: 0 };
    // points from banned words
    const scoreFromBws = this.rounds?.reduce?.((fullScore: IScore, currRound: Round, currRoundNum: number) => {
      // only add the points from this round up if it is the desired round,
      // or if we are calcualting the full game score
      var roundTally = Object.assign({}, emptyScore);
      if (desiredRound === undefined || desiredRound === currRoundNum) {
        roundTally = currRound?.bws?.reduce((total: IScore, bw: BW) => {
          if (bw.mentioned) {
            var tempScore = {
              points: bw.isDoubleValue() ? 2 : 1,
              bombsHit: bw.isBombWord() ? 1 : 0,
              numSacrificed: 0
            };
            return this.addScores(total, tempScore);
          } else {
            return total;
          }
        }, Object.assign({}, emptyScore));
        // account for a sacrifice this 
        roundTally.numSacrificed = currRound.sacrificed ? 1 : 0;
      }
      return this.addScores(fullScore, roundTally);
    }, Object.assign({}, emptyScore)) ?? emptyScore;

    // points from target words
    const scoreFromTws = this.rounds?.reduce?.((fullScore: IScore, currRound: Round, currRoundNum: number) => {
      // only add the points from this round up if it is the desired round,
      // or if we are calcualting the full game score
      var roundTally = Object.assign({}, emptyScore);
      if (desiredRound === undefined || desiredRound === currRoundNum) {
        roundTally = currRound?.tws?.reduce((total: IScore, tw: TW) => {
          if (tw.guessed) {
            var tempScore = {
              points: tw.isDoubleValue() ? 2 : 1,
              bombsHit: 0,
              numSacrificed: 0,
            };
            return this.addScores(total, tempScore);
          } else {
            return total;
          }
        }, Object.assign({}, emptyScore));
      }
      return this.addScores(fullScore, roundTally);
    }, Object.assign({}, emptyScore)) ?? emptyScore;

    return this.addScores(scoreFromBws, scoreFromTws);
  }

  async forApi(opts?: IModelIncludeOpts): Promise<any> {
    const superJson = await super.forApi(opts);
    let toApi: ITeam = {
      ...(superJson as any),
      name: this.name,
      gameId: this.gameId
    };
    if (this.round && opts?.includeRound && opts?.save) {
      var createId = await Round.saveNew(this.round!);
      toApi.roundId = createId;
    }
    return toApi;

  }

  private genTeamName() {
    return `team-${getRandomNumBetween(0, 10)}`;
  }

  static async saveNew(teamToSave: Team): Promise<string> {
    // console.log('[save] create team')
    const teamEntity = await teamToSave!.forApi({ save: true, includeRound: true })
    await graphQLMutation(createTeam, teamEntity);
    return teamEntity.id;
  }

  static async saveUpdate(teamToSave: Team): Promise<string> {
    // console.log('[save] update team')
    const teamEntity = await teamToSave!.forApi({ save: true, includeRound: true })
    await graphQLMutation(updateTeam, teamEntity);
    return teamEntity.id;
  }
}
