/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGame = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      id
      code
      phase
      phaseEndTime
      voteMs
      writeMs
      guessMs
      earnTimeMs
      modes
      teams {
        items {
          id
          name
          gameId
          roundId
          createdAt
          updatedAt
        }
        nextToken
      }
      updater
      newRoundRequest
      customTargets
      usedTargets
      wordSetsOn
      location
      createdAt
      updatedAt
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        phase
        phaseEndTime
        voteMs
        writeMs
        guessMs
        earnTimeMs
        modes
        teams {
          nextToken
        }
        updater
        newRoundRequest
        customTargets
        usedTargets
        wordSetsOn
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      round {
        id
        bws {
          nextToken
        }
        cws {
          nextToken
        }
        tws {
          nextToken
        }
        gameId
        teamId
        scoreAdjustment
        createdAt
        updatedAt
      }
      rounds {
        items {
          id
          gameId
          teamId
          scoreAdjustment
          createdAt
          updatedAt
        }
        nextToken
      }
      gameId
      roundId
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        round {
          id
          gameId
          teamId
          scoreAdjustment
          createdAt
          updatedAt
        }
        rounds {
          nextToken
        }
        gameId
        roundId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRound = /* GraphQL */ `
  query GetRound($id: ID!) {
    getRound(id: $id) {
      id
      bws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      cws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      tws {
        items {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        nextToken
      }
      gameId
      teamId
      scoreAdjustment
      createdAt
      updatedAt
    }
  }
`;
export const listRounds = /* GraphQL */ `
  query ListRounds(
    $filter: ModelRoundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRounds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bws {
          nextToken
        }
        cws {
          nextToken
        }
        tws {
          nextToken
        }
        gameId
        teamId
        scoreAdjustment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWord = /* GraphQL */ `
  query GetWord($id: ID!) {
    getWord(id: $id) {
      id
      text
      type
      selected
      skipped
      doubleValue
      bombText
      votes {
        items {
          id
          wordId
          teamId
          createdAt
          updatedAt
        }
        nextToken
      }
      associations {
        items {
          id
          cwId
          twId
          createdAt
          updatedAt
        }
        nextToken
      }
      teamId
      roundId
      adjusted
      createdAt
      updatedAt
    }
  }
`;
export const listWords = /* GraphQL */ `
  query ListWords(
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        type
        selected
        skipped
        doubleValue
        bombText
        votes {
          nextToken
        }
        associations {
          nextToken
        }
        teamId
        roundId
        adjusted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWordAssociation = /* GraphQL */ `
  query GetWordAssociation($id: ID!) {
    getWordAssociation(id: $id) {
      id
      cwId
      twId
      tw {
        id
        text
        type
        selected
        skipped
        doubleValue
        bombText
        votes {
          nextToken
        }
        associations {
          nextToken
        }
        teamId
        roundId
        adjusted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listWordAssociations = /* GraphQL */ `
  query ListWordAssociations(
    $filter: ModelWordAssociationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWordAssociations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cwId
        twId
        tw {
          id
          text
          type
          selected
          skipped
          doubleValue
          bombText
          teamId
          roundId
          adjusted
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVote = /* GraphQL */ `
  query GetVote($id: ID!) {
    getVote(id: $id) {
      id
      wordId
      teamId
      createdAt
      updatedAt
    }
  }
`;
export const listVotes = /* GraphQL */ `
  query ListVotes(
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        wordId
        teamId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTimeSync = /* GraphQL */ `
  query GetTimeSync($id: ID!) {
    getTimeSync(id: $id) {
      id
      clientUTC
      createdAt
      updatedAt
    }
  }
`;
export const listTimeSyncs = /* GraphQL */ `
  query ListTimeSyncs(
    $filter: ModelTimeSyncFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeSyncs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientUTC
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gameByCodeSorted = /* GraphQL */ `
  query GameByCodeSorted(
    $code: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameByCodeSorted(
      code: $code
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        phase
        phaseEndTime
        voteMs
        writeMs
        guessMs
        earnTimeMs
        modes
        teams {
          nextToken
        }
        updater
        newRoundRequest
        customTargets
        usedTargets
        wordSetsOn
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamByGameId = /* GraphQL */ `
  query TeamByGameId(
    $gameId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByGameId(
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        round {
          id
          gameId
          teamId
          scoreAdjustment
          createdAt
          updatedAt
        }
        rounds {
          items {
            id
          }
          nextToken
        }
        gameId
        roundId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roundByTeamIdSorted = /* GraphQL */ `
  query RoundByTeamIdSorted(
    $teamId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roundByTeamIdSorted(
      teamId: $teamId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bws {
          nextToken
        }
        cws {
          nextToken
        }
        tws {
          nextToken
        }
        gameId
        teamId
        scoreAdjustment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roundByTeamId = /* GraphQL */ `
  query RoundByTeamId(
    $teamId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRoundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roundByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bws {
          nextToken
        }
        cws {
          nextToken
        }
        tws {
          nextToken
        }
        gameId
        teamId
        scoreAdjustment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roundByGameId = /* GraphQL */ `
  query RoundByGameId(
    $gameId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRoundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roundByGameId(
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bws {
          nextToken
        }
        cws {
          nextToken
        }
        tws {
          nextToken
        }
        gameId
        teamId
        scoreAdjustment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const wordByRoundIdAndBomb = /* GraphQL */ `
  query WordByRoundIdAndBomb(
    $roundId: ID
    $bombText: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wordByRoundIdAndBomb(
      roundId: $roundId
      bombText: $bombText
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        type
        selected
        skipped
        doubleValue
        bombText
        votes {
          nextToken
        }
        associations {
          nextToken
        }
        teamId
        roundId
        adjusted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const wordByRoundIdAndType = /* GraphQL */ `
  query WordByRoundIdAndType(
    $roundId: ID
    $type: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wordByRoundIdAndType(
      roundId: $roundId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        type
        selected
        skipped
        doubleValue
        bombText
        votes {
          nextToken
        }
        associations {
          nextToken
        }
        teamId
        roundId
        adjusted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const wordByRoundIdAndTypeAndSelected = /* GraphQL */ `
  query WordByRoundIdAndTypeAndSelected(
    $roundId: ID
    $typeSelected: ModelWordByRoundIdAndTypeAndSelectedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wordByRoundIdAndTypeAndSelected(
      roundId: $roundId
      typeSelected: $typeSelected
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        type
        selected
        skipped
        doubleValue
        bombText
        votes {
          nextToken
        }
        associations {
          nextToken
        }
        teamId
        roundId
        adjusted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const wordByRoundIdAndTypeAndSelectedSorted = /* GraphQL */ `
  query WordByRoundIdAndTypeAndSelectedSorted(
    $roundId: ID
    $typeSelectedCreatedAt: ModelWordByRoundIdAndTypeAndSelectedSortedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wordByRoundIdAndTypeAndSelectedSorted(
      roundId: $roundId
      typeSelectedCreatedAt: $typeSelectedCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        type
        selected
        skipped
        doubleValue
        bombText
        votes {
          nextToken
        }
        associations {
          nextToken
        }
        teamId
        roundId
        adjusted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const voteByWordId = /* GraphQL */ `
  query VoteByWordId(
    $wordId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voteByWordId(
      wordId: $wordId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        wordId
        teamId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
