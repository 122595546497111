import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);

const medium = new Set([
  'accordion',
  'acoustic guitar',
  'alto saxophone',
  'anvil',
  'baby grand piano',
  'bagpipe',
  'banjo',
  'baritone horn',
  'bass',
  'bass clarinet',
  'bass drum',
  'bass guitar',
  'bell',
  'bongo drum',
  'bow',
  'brass instruments',
  'bugle',
  'cello',
  'chimes',
  'clarinet,',
  'classical guitar',
  'contrabass',
  'cornet',
  'cowbell',
  'cymbals',
  'didgeridoo',
  'drum',
  'drumsticks',
  'electric guitar',
  'electric organ',
  'English horn',
  'fiddle',
  'flute',
  'French horn',
  'gong',
  'grand piano',
  'guitar',
  'harmonica',
  'harp',
  'horn',
  'instrument',
  'kazoo',
  'keyboard',
  'mallets',
  'maracas',
  'marimba',
  'mellophone',
  'musical instruments',
  'mute',
  'oboe',
  'organ',
  'pan pipes',
  'penny whistle',
  'percussion',
  'piano',
  'piccolo',
  'pipe organ',
  'player piano',
  'rainstick',
  'rattle',
  'recorder',
  'reed',
  'saw',
  'saxophone',
  'sitar',
  'slide whistle',
  'snare drum',
  'sousaphone',
  'spoons',
  'steel drum',
  'steel guitar',
  'string bass',
  'string instruments',
  'strings',
  'synthesizer',
  'tambourine',
  'triangle',
  'trombone',
  'trumpet',
  'tuba',
  'ukulele',
  'upright piano',
  'valve',
  'violin',
  'washboard',
  'whistle',
  'wind chime',
  'wind instruments',
  'woodwind instruments',
  'xylophone',
])

const hard = new Set([
  'balalaika',
  'Aeolian harp',
  'Alphorn',
  'bandoneon',
  'bandura',
  'bassoon',
  'bouzouki',
  'calliope',
  'carillon',
  'castanets',
  'celesta',
  'Celtic harp',
  'cimbalom',
  'clavichord',
  'clavier',
  'concertina',
  'conch',
  'conga drum',
  'double bass',
  'dulcimer',
  'euphonium',
  'fife',
  'flugelhorn',
  'glockenspiel',
  'hammered dulcimer',
  'harmonium',
  'harpsichord',
  'helicon',
  'jaw harp',
  'kettledrum',
  'lute',
  'lyre',
  'mandolin',
  'melodeon',
  'Moog synthesizer',
  'musical saw',
  'ocarina',
  'pipa',
  'pump organ',
  'spinet',
  'tabla',
  'theremin',
  'thumb piano',
  'timpani',
  'tin whistle',
  'tom-tom drum',
  'tubular bells',
  'vibraphone',
  'viola',
  'viola da gamba',
  'violoncello',
  'vuvuzela',
  'Wagner tuba',
])



export default new ThemeSet('Musical Instruments',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });