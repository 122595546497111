import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);


const medium = new Set([
  "Charms",
  "Daily Prophet",
  "Dark Arts",
  "Defense Against the Dark Arts",
  "Ghost",
  "Golden Snitch",
  "Heir of Slytherin",
  "Hogwarts Express",
  "Knight Bus",
  "Muggle",
  "Order of the Phoenix",
  "Patronus",
  "Philosopher’s Stone",
  "Poltergeist",
  "Polyjuice Potion",
  "Potions",
  "Prophecy",
  "Quidditch",
  "Triwizard Tournament",
  "Werewolf",
  "Wizards Chess",
  "Harry Potter",
  "Draco Malfoy",
  "Lord Voldemort",
  "Ron Weasley",
  "Draco Malfoy",
  "Albus Dumbledore",
  "Severus Snape",
  "Hagrid",
  "Dobby",
  "Sorting Hat",
  "Hedwig",
  "Mad Eye Moody",
  "Moaning Myrtle",
  "Azkaban",
  "Chocolate Frogs",
  "Chamber of Secrets",
  "Goblins",
  "Invisibility Cloak",
  "Phoenix",
])

const hard = new Set([
  "Bertie Botts Beans",
  "Beater",
  "Chaser",
  "Seeker",
  "Quaffle",
  "Keeper",
  "Bludger",
  "Boggart",
  "Butterbeer",
  "Cauldron Cake",
  "Dark Mark",
  "Firebolt",
  "Deathly Hallows",
  "Dementors Kiss",
  "Dementor",
  "Galleon",
  "Gillyweed",
  "Half Blood",
  "Mudblood",
  "Horcrux",
  "House Cup",
  "Howler",
  "Mandrake",
  "Mirror of Erised",
  "Nimbus 2000",
  "Time Turner",
  "Transfiguration",
  "Weasley’s Wizarding Wheezes",
  "Whomping Willow",
  "Tom Riddle",
  "Tom Felton",
  "Daniel Radcliffe",
  "Lucius Malfoy",
  "Ginny Weasley",
  "Luna Lovegood",
  "Rubeus Hagrid",
  "Bellatrix Lestrange",
  "Neville Longbottom",
  "Molly Weasley",
  "Remus Lupin",
  "Sirius Black",
  "Fred Weasley",
  "Narcissa Malfoy",
  "Dolores Umbridge",
  "Lily Potter",
  "James Potter",
  "Oliver Wood",
  "Animagus",
  "Apparate",
  "Astronomy",
  "Auror",
  "Death Eaters",
  "Parsletounge",
  "Dudley Dursley",
  "Basilisk",
  "The Burrow",
  "Cleansweep",
  "Unforgiveable Curses",
  "Cruciatus Curse",
  "Deathday Party",
  "Dervish and Banges",
  "Devil's Snare",
  "Diagon Alley",
  "Disapparate",
  "Dumbledore’s Army",
  "Expelliarmus",
  "Floo powder",
  "Floo network",
  "Godric's Hollow",
  "Grimmauld Place",
  "Gringotts",
  "Herbology",
  "Hippogriff",
  "Incendio",
  "Impervius",
  "Hufflepuff",
  "Knockturn Alley",
  "Kwikspell",
  "The Leaky Cauldron",
  "Marauder's Map",
  "Ollivander's",
  "Obliviate",
  "N.E.W.T.s",
  "O.W.L.s",
  "Parselmouth",
  "Parseltongue",
  "Patronus",
  "Pensieve",
  "Portkey",
  "Peeves",
  "Peter Pettigrew",
  "Ravenclaw",
  "Reducio",
  "Room of Requirement",
  "Reparo",
  "Remembrall",
])


export default new ThemeSet('Harry Potter',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);