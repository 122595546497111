import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  'apple pie',
  'apple',
  'bake',
  'beans',
  'bread',
  'buns',
  'cook',
  'corn',
  'dessert',
  'eat',
  'Fall',
  'family',
  'fish',
  'football',
  'friends',
  'holiday',
  'home',
  'leaf',
  'meal',
  'meat',
  'napkin',
  'oven',
  'pans',
  'pie',
  'rolls',
  'salad',
  'sauce',
  'serve',
  'thankful',
]);

const medium = new Set([
  'acorn',
  'American',
  'baste',
  'autumn',
  'canoe',
  'carve',
  'casserole',
  'celebrate',
  'centerpiece',
  'Colonists',
  'cornbread',
  'cornucopia',
  'cranberries',
  'cranberry',
  'cranberry sauce',
  'delicious',
  'dine',
  'dinner',
  'dish',
  'drumstick',
  'feast',
  'giblets',
  'gobble',
  'grandparents',
  'grateful',
  'gratitude',
  'gravy',
  'ham',
  'harvest',
  'Indian',
  'Indian corn',
  'leaves',
  'leftovers',
  'Massachusetts',
  'Massasoit',
  'Mayflower',
  'melting pot',
  'native',
  'Native American',
  'New World',
  'November',
  'parents',
  'pecan',
  'Pilgrim',
  'platter',
  'Plymouth',
  'Plymouth Rock',
  'potato',
  'pots',
  'pumpkin',
  'pumpkin pie',
  'recipe',
  'roast',
  'sail',
  'settlers',
  'Squanto',
  'squash',
  'stuffing',
  'sweet corn',
  'sweet potato',
  'tablecloth',
  'taste',
  'tasty',
  'Thanksgiving Day',
  'Thursday',
  'tom turkey',
  'tradition',
  'turkey',
  'vegetables',
  'voyage',
  'wishbone',
  'yam',
  'Sacagawea',
  'Pocahontas',
  'Christopher Columbus',
])

const hard = new Set([
  'Patuxet Indians',
  'Tisquantum',
  'Wampanoag Indians',
  'Squanto',
  'Sitting Bull'

])



export default new ThemeSet('Thanksgiving',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });