import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  'Alarm clock',
  'Backpack',
  'Books',
  'Bookcase',
  'Chair',
  'Clock',
  'Comb',
  'Couch',
  'Computer',
  'Cup',
  'Desk',
  'Fan',
  'Flowers',
  'Microwave',
  'Mop',
  'Paper',
  'Pen',
  'Pencil',
  'Towels',
  'TV',
  'Forks',
  'Plates',
  'Lamp',
  'Dryer',
  'Oven',
  'Scissors',
]);

const medium = new Set([
  'Armoire',
  'Bedding',
  'Bedspread',
  'Binders',
  'Blankets',
  'Blinds',
  'Broom',
  'Brush',
  'Bucket',
  'Calendar',
  'Candles',
  'Carpet',
  'Chairs',
  'China',
  'Coffee table',
  'Comforter',
  'Containers',
  'Credenza',
  'Curtains',
  'Cushions',
  'Dish towel',
  'Dishwasher',
  'Door stop',
  'Drapes',
  'Dressers',
  'Drill',
  'Dust pan',
  'Duvet',
  'End tables',
  'Extension cord',
  'Figurine',
  'File cabinet',
  'Fire extinguisher',
  'Flashlight',
  'Furnace',
  'Games',
  'Glasses',
  'Hammer',
  'Heater',
  'Houseplant',
  'IPhone',
  'IPod',
  'Iron',
  'Ironing board',
  'Jewelry',
  'Knives',
  'Light bulbs',
  'Light switch',
  'Linens',
  'Magnets',
  'Markers',
  'Medicine',
  'Mementos',
  'Mugs',
  'Musical instruments',
  'Napkins',
  'Nick-knacks',
  'Note paper',
  'Paintings',
  'Pans',
  'Pants',
  'Phones',
  'Photographs',
  'Piano',
  'Pictures',
  'Pillows',
  'Pitcher',
  'Plants',
  'Plastic plates',
  'Pliers',
  'Pots',
  'Prescriptions',
  'Radiator',
  'Radio',
  'Rags',
  'Refrigerator',
  'Rugs',
  'Saucer',
  'Saw',
  'Screw driver',
  'Settee',
  'Shades',
  'Sheets',
  'Shelf',
  'Shirts',
  'Shoes',
  'Smoke detector',
  'Sneakers',
  'Socks',
  'Sofa',
  'Speakers',
  'Spoons',
  'Suitcases',
  'Supplies',
  'Sweeper',
  'Tablecloth',
  'Tables',
  'Telephone',
  'Timers',
  'Tissues',
  'Toaster',
  'Toilet paper',
  'Toothbrush',
  'Toothpaste',
  'Toys',
  'Vacuum',
  'Vase',
  'Washer',
  'Washing machine',
]);


const hard = new Set([

]);


export default new ThemeSet('Household Items',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);