import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);

const medium = new Set([
  "Australia",
  "Brazil",
  "Canada",
  "China",
  "Costa Rica",
  "Egypt",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "Honduras",
  "Iceland",
  "India",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "North Korea",
  "South Korea",
  "Mexico",
  "Panama",
  "Russia",
  "Saudi Arabia",
  "South Africa",
  "Spain",
  "Sweden",
  "Switzerland",
  "Syria",
  "Turkey",
  "United Kingdom",
  "United States of America",
  "Vatican City",
  "Venezuela",
  "Vietnam",
]);


const hard = new Set([
  "Philippines",
  "Poland",
  "Portugal",
  "Ukraine",
  "United Arab Emirates",
  "Madagascar",
  "New Zealand",
]);



export default new ThemeSet('Countries',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);