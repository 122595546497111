import { ThemeSet, EDifficulty } from '../../index';

export const easy = new Set([

])

export const medium = new Set([
  "New York Knicks",
  "Chicago Bulls",
  "Miami Heat",
  "Orlando Magic",
  "Oklahoma City Thunder",
  "Los Angeles Lakers",
  "Stats",
  "Schedule",
  "Playoffs",
  "Tickets",
  "Season Tickets",
  "Lebron James",
  "Shaq",
  "Tip-off",
  "Buzzer-Beater",
  "3-point shot",
  "free-throw",
  "timeout",
  "announcer",
  "foul",
  "Kobe Bryant",
  "Michael Jordan",
  "Larry Bird",
  "Kevin Durant",
  "M.V.P."
])

const hard = new Set([
  "back court violation",
  "double dribble",
  "traveling",
  "technical foul",
  "James Harden",
  "Stephen Curry",
  "Dwight Howard",
  "Chris Paul",
  "Wilt Chamberlain",
  "Blake Griffin",
  "Damian Lillard",
  "Steven Adams",
  "Tim Duncan",
  "Russell Westbrook",
  "Kawhi Leonard",
  "Giannis Antetokounmpo",
  "Kyrie Irving",
  "Jimmy Butler",
  "Trae Young",
  "Paul George",
  "Anthony Davis",
  "Klay Thompson",
  "Brooklyn Nets",
  "Philadelphia 76ers",
  "Toronto Raptors",
  "Cleveland Cavaliers",
  "Memphis Grizzlies",
  "Detroit Pistons",
  "Indiana Pacers",
  "Milwaukee Bucks",
  "Atlanta Hawks",
  "Charlotte Hornets",
  "Washington Wizards",
  "Denver Nuggets",
  "Portland Trail Blazers",
  "Utah Jazz",
  "Golden State Warriors",
  "LA Clippers",
  "Phoenix Suns",
  "Sacramento Kings",
  "Dallas Mavericks",
  "Houston Rockets",
  "New Orleans Pelicans",
  "San Antonio Spurs"
])



export default new ThemeSet('NBA',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });