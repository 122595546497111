/* tslint:disable */
/* eslint-disable */
import { API, graphqlOperation } from 'aws-amplify'

export const graphQLQuery = async (query: string, queryName: string, options: any = {}) => {
  const { data }: any = await API.graphql(graphqlOperation(query, options))
  
  if (data[queryName].items) {
    return data[queryName].items
  }
  return data[queryName]
}

export const graphQLMutation = async (mutation: string, input: any) => {
  return await API.graphql(graphqlOperation(mutation, { input }))
}

export const graphQLSubscription = (subscription: string, options: any, callback: (d: any) => void) => {
  // ignoring for type issue with `.subscribe`
  // @ts-ignore
  return API.graphql(graphqlOperation(subscription, options)).subscribe({
    next: ({ value: { data } }) => callback(data)
  })
}
