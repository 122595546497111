import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  "Gringos Loco",
  "Tin & Taco",
  "Greenbeats",
  "Tamarind",
  "The Boheme",
  "Olives",
  "Harp & Celt",
  "Artisan's Table",
  "Super Rico",
  "Hamburger Mary's",
  "Jimmy Hula's",
  "Ace Cafe",
  "Bento",
  "Harry Buffalo's",
  "Kres Chophouse",
  "Dapper Duck",
  "The Hammered Lamb",
  "World of Beer",
  "Neighborhood Eatery",
  "Thornton Park",
  "Lake Eola",
  "Eola Coffee",
  "Church Street",
  "Orange Ave",
  "Lynx",
  "SunRail",
  "Farmer's Market",
  "Church Street Station",
  "Mad Cow Theatre",
  "Orlando City Soccer Club",
  "Milk District",
  "Mills 50",
  "Parramore",
  "Amway Center",
  "Orlando Magic",
  "Citrus Bowl",
  "Science Center",
  "East End Market",
  "Wall Street",
  "Buddy Dyer",
  "Dr Phillips Center",
  "408",
  "407",
  "I-4",
  "Tampa",
  "St. Augustine",
  "Publix",
  "Chicken Tender Sub",
  "Lime Bike",
  "Rental Scooter",
  "The Social",
  "The Beacham",
  "Concert",
  "Will's Pub",
  "Howl at the Moon",
  "House of Blues"
])

const hard = new Set([

])

export default new ThemeSet('Orlando Scene',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    // manually added to unikey games
    hidden: false,
    showAsNew: [],
    disabledByDefault: []
  }
);