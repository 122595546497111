import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  'backpack',
  'book',
  'calculator',
  'calendar',
  'chalk',
  'chalk board',
  'class clown',
  'classroom',
  'clip board',
  'colored pencils',
  'crayons',
  'dictionary',
  'eraser',
  'folder',
  'glue',
  'glue stick',
  'homework',
  'learn',
  'markers',
  'math',
  'notebook',
  'paper',
  'paper clip',
  'pen',
  'pencil',
  'pencil sharpener',
  'ruler',
  'stapler',
  'student',
  'teacher',
  'test',
  'whiteboard',
]);

const medium = new Set([
  'answer',
  'arithmetic',
  'assignment',
  'atlas',
  'ballpoint pen',
  'binder',
  'blackboard',
  'bookcase',
  'bookmark',
  'compass',
  'composition book',
  'computer',
  'construction paper',
  'desk',
  'dividers',
  'dry-erase board',
  'easel',
  'encyclopedia',
  'English',
  'exam',
  'examination',
  'experiment',
  'file folder',
  'flash cards',
  'gel pen',
  'geography',
  'globe',
  'glossary',
  'grades',
  'gym',
  'highlighter',
  'history',
  'hole punch',
  'ink',
  'intelligent',
  'keyboard',
  'language',
  'laptop',
  'lesson',
  'library',
  'looseleaf paper',
  'lunch',
  'lunch box',
  'map',
  'mathematics',
  'mechanical pencil',
  'memo pad',
  'memorize',
  'mortar board',
  'pad of paper',
  'paper punch',
  'paste',
  'pencil pouch',
  'physical education',
  'portfolio',
  'poster paint',
  'principal',
  'printer',
  'project',
  'protractor',
  'pupil',
  'push pin',
  'question',
  'quiz',
  'reading',
  'recess',
  'rubber bands',
  'science',
  'scissors',
  'sharpener',
  'smart',
  'spiral notebook',
  'staple remover',
  'tape',
  'thesaurus',
  'think',
  'thumb tack',
  'vocabulary',
  'watercolors',
  'word problems',
  'word processor',
  'world map',
  'writing',
  'yardstick'
])

const hard = new Set([

])



export default new ThemeSet('School',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });