import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import ReactTooltip from 'react-tooltip';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';

// scary faces background used for halloween theem
import scaryFace1 from './assets/halloween/face-1.svg'
import scaryFace2 from './assets/halloween/face-2.svg'
import scaryFace3 from './assets/halloween/face-3.svg'
import scaryFace4 from './assets/halloween/face-4.svg'
import scaryFace5 from './assets/halloween/face-5.svg'
import scaryFace6 from './assets/halloween/face-6.svg'
import scaryFace7 from './assets/halloween/face-7.svg'
import scaryFace8 from './assets/halloween/face-8.svg'

import {
  keepTheme,
  GameRoom,
  Lobby,
  GameHeader,
  GameFooter,
  Support,
  HowToPlay,
  RecentChanges,
  ComingSoon,
  RecentGames,
  Supporters,
  MaintenanceAlert,
  store,
  GameProvider,
  ConditionalRender
} from './internal'
import { getRandomNumBetween } from 'helpers';


// Init
const App = () => {

  useEffect(() => {
    keepTheme();
  }, [])

  return (
    <React.StrictMode>
      <Provider store={store}>
        <>
        {/* scary face svg background used for halloween theme */}
        {/* <ReactSVG className={classnames('background-svg-img')}
          src={[scaryFace1, scaryFace2, scaryFace3, scaryFace4, scaryFace5, scaryFace6, scaryFace7, scaryFace8][getRandomNumBetween(0,7)]} /> */}
          <ConditionalRender visible={false}>
            <MaintenanceAlert />
          </ConditionalRender>
          <Router>
            <Switch>
              <Route exact path='/pages/changes'>
                <RecentChanges />
              </Route>
              <Route exact path='/pages/future'>
                <ComingSoon />
              </Route>
              <Route exact path='/pages/howToPlay'>
                <HowToPlay />
              </Route>
              <Route exact path='/pages/recent'>
                <RecentGames />
              </Route>
              <Route exact path='/pages/support'>
                <Support />
              </Route>
              <Route exact path='/pages/supporters'>
                <Supporters />
              </Route>
              <Route exact path='/:gameCode'>
                <GameProvider>
                  <ReactTooltip effect="solid" multiline />
                  {/* sacrifice tooltip */}
                  <ReactTooltip id="sacrifice" effect="solid">
                    Sacrifice: 1 point deduction
                  </ReactTooltip>
                  {/* bomb word tooltip */}
                  <ReactTooltip id="bomb" effect="solid">
                    Bomb: 2 point penalty
                  </ReactTooltip>
                  {/* double value tooltip */}
                  <ReactTooltip id="double-value" effect="solid">
                    Double Value: 2 point word
                  </ReactTooltip>
                  <GameHeader />
                  <GameRoom />
                  <GameFooter />
                </GameProvider>
              </Route>
              <Lobby />
            </Switch>
          </Router>
          {/* <Footer /> */}
        </>
      </Provider>
    </React.StrictMode >
  )
}

export default App
