import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  'blog',
  'caps lock',
  'CD',
  'CD-ROM',
  'clip art',
  'clip board',
  'computer',
  'cookie',
  'copy',
  'DVD',
  'delete',
  'graphics',
  'desktop',
  'Internet',
  'keyboard',
  'laptop',
  'podcast',
  'scroll',
  'save',
  'file',
  'bookmark',
  'print',
  'icon',
])

const medium = new Set([
  'app',
  'World Wide Web',
  'analog',
  'digital',
  'computer program',
  'backup',
  'blogger',
  'boot',
  'browser',
  'bug',
  'bus',
  'CPU',
  'dashboard',
  'data',
  'development',
  'disk',
  'document',
  'download',
  'dot',
  'drag',
  'email',
  'enter',
  'FAQ',
  'floppy disk',
  'flowchart',
  'folder',
  'font',
  'format',
  'frame',
  'hack',
  'hacker',
  'hardware',
  'home page',
  'host',
  'inbox',
  'joystick',
  'junk mail',
  'key',
  'keyword',
  'laser printer',
  'link',
  'log out',
  'logic',
  'login',
  'Macintosh',
  'mirror',
  'monitor',
  'mouse',
  'notebook computer',
  'offline',
  'online',
  'option',
  'page',
  'password',
  'paste',
  'path',
  'platform',
  'pop-up',
  'portal',
  'printer',
  'privacy',
  'program',
  'programmer',
  'queue',
  'reboot',
  'resolution',
  'restore',
  'root',
  'scan',
  'scanner',
  'screen',
  'screenshot',
  'script',
  'scroll bar',
  'search engine',
  'security',
  'server',
  'shift',
  'shift key',
  'snapshot',
  'social networking',
  'software',
  'spam',
  'spammer',
  'spreadsheet',
  'status bar',
  'storage',
  'supercomputer',
  'surf',
  'table',
  'tag',
  'template',
  'lurking',
  'media',
  'memory',
  'modem',
  'text editor',
  'thread',
  'finder',
  'flaming',
  'flash',
  'flash drive',
  'firewall',
  'toolbar',
  'trash',
  'undo',
  'upload',
  'URL',
  'user',
  'user interface',
  'username',
  'utility',
  'version',
  'virtual',
  'virus',
  'web',
  'host',
  'website',
  'widget',
  'wiki',
  'window',
  'Windows',
  'wireless',
  'word processor',
  'workstation',
  'worm',
  'algorithm',
  'application',
  'array',
  'process',
  'QWERTY',
  'terminal',
  'bandwidth',
  'binary',
  'bit',
  'bite',
  'dynamic',
  'gigabyte',
  'emoticon',
  'decompress',
  'multimedia',
  'net',
  'network',
  'node',
  'open source',
  'operating system',
  'shell',
  'IP address',
  'output',
  'piracy',
  'pirate',
  'broadband',
  'buffer',
  'byte',
  'cache',
  'captcha',
  'client',
  'cloud computing',
  'command',
  'compile',
  'compress',
  'cyberspace',
  'data mining',
  'configure',
  'database',
  'debug',
  'domain',
  'domain name',
  'encrypt',
  'encryption',
  'hyperlink',
  'html',
  'integer',
  'interface',
  'iteration',
  'Java',
  'macro',
  'motherboard',
  'typeface',
  'webmaster',
  'artificial intelligence',
  'robotics'
]);

const hard = new Set([
  'Unix',
  'bitmap',
  'virtual memory',
  'phishing',
  'router',
  'runtime',
  'spyware',
  'syntax',
  'terabyte',
  'exabyte',
  'firmware',
  'freeware',
  'kernel',
  'Linux',
  'DNS',
  'Trojan horse',
  'cybercrime',
  'hypertext',
  'mainframe',
  'protocol',
  'malware',
  'javascript',
  'java',
  '.net',
  '.net core',
  'azure',
  'aws',
  'google cloud',
  'machine learning',
  'resistor',
  'capacitor',
  'inductor',
  'logic gates',
  'efficiency',
  'performance',
  'scalability',
  'dynamodb',
  'redis',
  'SQL',
  'postgres',
]);


export default new ThemeSet('Technology',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);