import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-grid-system';
import { useParams } from "react-router-dom";
import {
  Button,
  FormInput
} from 'shards-react'
import {
  FaCheck,
  FaTimes,
  FaQuestionCircle,
  FaAngleDoubleUp,
  FaBomb
} from 'react-icons/fa'

import {
  sfx, ESoundName,
  EGamePhases,
  ConditionalRender,
  Game,
  Team,
  Word,
  ITWord,
  TW,
  BW,
  CW,
  GameContext,
  graphQLQuery,
  graphQLSubscription,
  getMyTeamAsGuesser,
  getBombWordsForRound,
  onBwMentioned,
  onTwGuessed,
  onTwSkipped,
  useMap

} from '../../../internal';

interface IProps {

}

export const VerbalGuess: FC<IProps> = ({ }) => {
  var { state: { game, myTeam, oppTeam, helpOn }, dispatch } = useContext(GameContext);

  var [guessedTws, setGuessedTws] = useMap(new Map());
  var [skippedTws, setSkippedTWs] = useMap(new Map());
  var [mentionedBws, setMentionedBws] = useMap(new Map());
  var [bombWordClue, setBombWordClue] = useState<BW>();
  var [bombWordMentioned, setBombWordMentioned] = useState(false);

  useEffect(() => {
    if (game?.getMyTeam) {
      (async () => {
        var myT = await graphQLQuery(getMyTeamAsGuesser, 'getTeam', { id: myTeam!.id })
        var guessTeam = new Team(myT);

        // fetch bomb word if there is one
        var bombWords = await graphQLQuery(getBombWordsForRound, 'wordByRoundIdAndBomb', {
          roundId: oppTeam!.round?.id,
          bombText: { ne: undefined }
        });
        if (bombWords?.length > 0) {
          const bomb = new BW(bombWords?.[0]);
          setBombWordClue(bomb);
        }

        dispatch({ type: 'setMyTeam', team: guessTeam })

      })();
      // setup subscription for oppTeam's mentioned bws
      var mentionedBwSubscription = graphQLSubscription(onBwMentioned, { teamId: oppTeam!.id }, bwMentioned);
      var guessedTwSubscription = graphQLSubscription(onTwGuessed, { teamId: myTeam!.id }, twGuessed);
      var skippedTwSubscription = graphQLSubscription(onTwSkipped, { teamId: myTeam!.id }, twSkipped);

      return () => {
        mentionedBwSubscription.unsubscribe();
        guessedTwSubscription.unsubscribe();
        skippedTwSubscription.unsubscribe();
      }
    }
  }, [game?.id])

  const bwMentioned = ({ onUpdateWord }) => {
    const bw = new BW(onUpdateWord);
    mentionedBws.set(bw.id, bw);
    if (bw.isBombWord()) {
      setBombWordClue(bw);
      setBombWordMentioned(true);
    }
    setMentionedBws(mentionedBws);
  }

  const twGuessed = ({ onUpdateWord }) => {
    const guessedWord = new TW(onUpdateWord);
    const alreadyGuessed = guessedTws.has(guessedWord.id);
    guessedTws.set(guessedWord.id, guessedWord);
    setGuessedTws(guessedTws);

    if (guessedTws.size === 5 && !alreadyGuessed) {
      // all earned sound
      sfx.play(ESoundName.allTwsEarned, guessedWord.text?.length);
    }
    // not else if play sound and cheer if 5
    if (!alreadyGuessed) {
      if (guessedWord.isDoubleValue()) {
        // double sound
        sfx.play(ESoundName.twDouble, guessedWord.text?.length);
      } else {
        // single sound
        sfx.play(ESoundName.twEarned, guessedWord.text?.length);
      }
    }
  }

  const twSkipped = ({ onUpdateWord }) => {
    const tw = new TW(onUpdateWord);
    skippedTws.set(tw.id, tw);
    setSkippedTWs(skippedTws);
  }

  return (
    <div className={classnames('verbalPhase-container')}>
      {/* instructions */}
      <ConditionalRender visible={helpOn || (helpOn === undefined && myTeam?.rounds?.length === 1)}>
        <Row className={classnames('instruction-block')}>
          <Col xs={2} md={1}>
            <FaQuestionCircle />
          </Col>
          <Col xs={9} md={10}>
            <p>Guess your team's target words based on clues from your captain.</p>
            <p>Your team will earn one point for every correctly guessed <em>Target Word</em>.</p>
            <p>The opposing team will earn one point for every <em>Banned Word</em> said by your team while guessing.</p>
          </Col>
        </Row>
      </ConditionalRender>

      <Row justify="center">
        <h2 className={classnames('text-center')}>Guess for your team</h2>
      </Row>

      {/* Bomb Clue */}
      <ConditionalRender visible={!!bombWordClue}>
        <Row justify="center">
          <Col xs={12}>
            <h4 className={classnames('text-center', 'bomb-sub-title')}>Watch out for this BOMB Word!</h4>
          </Col>
          <Col xs={8} sm={6} md={5} lg={4} xxl={3} key={`tw-bomb`}>
            <Button
              className={classnames('word-button', 'bw', 'text-center', { shake: bombWordMentioned })}
              disabled>
              <>
                <Row>
                  <Col xs={12}>
                    <div className="word-text">{bombWordClue?.mentioned ? bombWordClue?.text : bombWordClue?.bombText}</div>
                  </Col>
                </Row>
                <div className="bomb-word-indicator-container" data-tip data-for="bomb">
                  <div className={classnames('bomb-word-indicator')}>
                    <FaBomb />
                  </div>
                </div>
              </>
            </Button>
          </Col>
        </Row>
      </ConditionalRender>

      {/* <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col> */}

      <Container>
        <Row>
          {/* My Points */}
          <Col xs={6}>
            <h2 className={classnames('text-center')}>Our Guessed Words</h2>
            <ConditionalRender visible={guessedTws.size === 0}>
              <em><h4 className={classnames('text-center')}>None</h4></em>
            </ConditionalRender>
            {[...skippedTws.values()]?.map((tw: TW, i: number) => (
              <Col xxl={6} key={`tw-${i}`} className="remove-lr-padding">
                <Button
                  className={classnames('word-button', 'tw', 'skipped', 'text-center', myTeam?.getStyleName())}
                  disabled>
                  <>
                    <Row>
                      <Col xs={12}>
                        <div className="word-text">{tw.text}</div>
                      </Col>
                    </Row>
                    <ConditionalRender visible={tw?.isDoubleValue?.()}>
                      <div className="double-value-word-flag-container"  data-tip data-for="double-value">
                        <div className={classnames('double-value-word-flag')}>
                          <FaAngleDoubleUp />
                        </div>
                      </div>
                    </ConditionalRender>
                  </>
                </Button>
              </Col>
            ))}
            {[...guessedTws.values()]?.map((tw: TW, i: number) => (
              <Col xxl={6} key={`tw-${i}`} className="remove-lr-padding">
                <Button
                  className={classnames('word-button', 'tw', 'active', 'text-center', myTeam?.getStyleName())}
                  disabled>
                  <>
                    <Row>
                      <Col xs={12}>
                        <div className="word-text">{tw.text}</div>
                      </Col>
                    </Row>
                    <ConditionalRender visible={tw?.isDoubleValue?.()}>
                      <div className="double-value-word-flag-container"  data-tip data-for="double-value">
                        <div className={classnames('double-value-word-flag')}>
                          <FaAngleDoubleUp />
                        </div>
                      </div>
                    </ConditionalRender>
                  </>
                </Button>
              </Col>
            ))}
          </Col>

          {/* Their Points */}
          <Col xs={6}>
            <h2 className={classnames('text-center')}>Banned Words Mentioned</h2>
            <ConditionalRender visible={mentionedBws.size === 0}>
              <em><h4 className={classnames('text-center')}>None</h4></em>
            </ConditionalRender>
            {[...mentionedBws.values()]?.map((bw: BW, i: number) => (
              <Col xxl={6} key={`tw-${i}`} className="remove-lr-padding">
                <Button
                  className={classnames('word-button', 'bw', 'active', 'text-center', oppTeam?.getStyleName())}
                  disabled>
                  <>
                    <Row>
                      <Col xs={12}>
                        <div className="word-text">{bw.text}</div>
                      </Col>
                    </Row>
                    <ConditionalRender visible={bw?.isDoubleValue()}>
                      <div className="double-value-word-flag-container"  data-tip data-for="double-value">
                        <div className={classnames('double-value-word-flag')}>
                          <FaAngleDoubleUp />
                        </div>
                      </div>
                    </ConditionalRender>
                    <ConditionalRender visible={bw?.isBombWord()}>
                      <div className="bomb-word-indicator-container" data-tip data-for="bomb">
                        <div className={classnames('bomb-word-indicator')}>
                          <FaBomb />
                        </div>
                      </div>
                    </ConditionalRender>
                  </>
                </Button>
              </Col>
            ))}

          </Col>
        </Row>
      </Container >
    </div >
  )
}
