// declare module '*.mp3'
import { Howl, Howler } from 'howler';

import sound_ticking from '../assets/audio/transition/ticking.mp3';
import sound_bomb1 from '../assets/audio/bomb/bomb_cartoon.mp3'
import sound_bomb2 from '../assets/audio/bomb/bomb_fun8bit.mp3'
import sound_bomb3 from '../assets/audio/bomb/bomb_toon.mp3'
import sound_bomb4 from '../assets/audio/bomb/bomb_toon2.mp3'
import sound_startGame from '../assets/audio/transition/startTheme.mp3'
import sound_startGuess from '../assets/audio/transition/startGuess.mp3'
import sound_round1 from '../assets/audio/round/round1.mp3'
import sound_round2 from '../assets/audio/round/round2.mp3'
import sound_round3 from '../assets/audio/round/round3.mp3'
import sound_round4 from '../assets/audio/round/round4.mp3'
import sound_round5 from '../assets/audio/round/round5.mp3'
import sound_voteUp from '../assets/audio/cw/boop.mp3'
import sound_voteDown from '../assets/audio/vote/blip_down.mp3'
import sound_createWordTeam1 from '../assets/audio/vote/douuuu.mp3'
import sound_createWordTeam2 from '../assets/audio/cw/tsssk.mp3'
import sound_bwMentioned from '../assets/audio/bw/blip_error.mp3'
import sound_doubleBwMentioned from '../assets/audio/bw/mentioned_double.mp3'
import sound_twEarned from '../assets/audio/tw/chime1.mp3'
import sound_doubleTwEarned from '../assets/audio/bonus/bonus.mp3'
import sound_allTwsEarned1 from '../assets/audio/tw/applause1.mp3'
import sound_allTwsEarned2 from '../assets/audio/tw/applause2.mp3'
import sound_allTwsEarned3 from '../assets/audio/tw/applause3.mp3'
import sound_ladyScreamPain from '../assets/audio/lady_scream_pain.mp3'


export enum ESoundName {
  gameStart = 'gameStart',
  roundStart = 'roundStart',
  cwCreated = 'cwCreated',
  voteCast = 'voteCast',
  voteRemoved = 'voteRemoved',
  guessStart = 'guessStart',
  bwMentioned = 'bwMentioned',
  twEarned = 'twEarned',
  allTwsEarned = 'allTwsEarned',
  ticking = 'ticking',
  twDouble = 'twDouble',
  bwDouble = 'bwDouble',
  bomb = 'bomb',
  sacrifice = 'sacrifice'
}

export interface ICustomSfxOptions {
  fadeDuration?: number,
  fadeFrom?: number,
  fadeTo?: number,
  volume?: number

}

export class SfxManager {

  static defaultVolume = 0.1;

  bomb: Howl[];
  ticking: Howl[];
  twEarned: Howl[];
  allTwsEarned: Howl[];
  voteCast: Howl[];
  cwCreated: Howl[];
  gameStart: Howl[];
  guessStart: Howl[];
  roundStart: Howl[];
  voteRemoved: Howl[];
  bwMentioned: Howl[];
  twDouble: Howl[];
  bwDouble: Howl[];
  sacrifice: Howl[];

  constructor() {
    //get volume previously stored in the cache
    const prevSetVol = sessionStorage.getItem('volume');
    if (prevSetVol) {
      const volVal = Number(prevSetVol);
      Howler.volume(volVal);
    } else {
      // default volume = 30%;
      Howler.volume(SfxManager.defaultVolume);
    }


    this.ticking = [
      this.setupClip(sound_ticking, {
        fadeDuration: 8000,
        fadeFrom: 0,
        fadeTo: .6
      })
    ]

    this.bomb = [
      this.setupClip(sound_bomb1),
      this.setupClip(sound_bomb2),
      this.setupClip(sound_bomb3),
      this.setupClip(sound_bomb4),
    ]

    this.gameStart = [
      this.setupClip(sound_startGame),
    ]

    this.guessStart = [
      this.setupClip(sound_startGuess, {
        volume: 0.8
      }),
    ];

    this.roundStart = [
      this.setupClip(sound_round1),
      this.setupClip(sound_round2),
      this.setupClip(sound_round3),
      this.setupClip(sound_round4),
      this.setupClip(sound_round1),
      this.setupClip(sound_round5),
    ]

    this.voteCast = [
      this.setupClip(sound_voteUp, {
        volume: 1.0
      }),
    ]

    this.voteRemoved = [
      this.setupClip(sound_voteDown),
    ]

    this.cwCreated = [
      // red
      this.setupClip(sound_createWordTeam1, {
        volume: 0.6
      }),
      // blue
      this.setupClip(sound_createWordTeam2, {
        volume: 1.0
      })
    ]

    this.bwMentioned = [
      this.setupClip(sound_bwMentioned),
    ]

    this.bwDouble = [
      this.setupClip(sound_doubleBwMentioned),
    ]

    this.twEarned = [
      this.setupClip(sound_twEarned),
    ]

    this.twDouble = [
      this.setupClip(sound_doubleTwEarned),
    ]

    this.allTwsEarned = [
      this.setupClip(sound_allTwsEarned1, {
        fadeDuration: 1000,
        fadeFrom: 0,
        fadeTo: 1
      }),
      this.setupClip(sound_allTwsEarned2, {
        fadeDuration: 1000,
        fadeFrom: 0,
        fadeTo: 1
      }),
      this.setupClip(sound_allTwsEarned3, {
        fadeDuration: 1000,
        fadeFrom: 0,
        fadeTo: 1
      }),
    ];

    this.sacrifice = [
      this.setupClip(sound_ladyScreamPain, {
        volume: 0.7
      })
    ]
  }

  volume(vol: number) {
    // Change global volume.
    // 0.0 - 1.0
    Howler.volume(vol);

    sessionStorage.setItem('volume', `${vol}`);
  }

  stopAll() {
    Howler.stop();
  }

  stopSound(soundId: string) {
    console.log(`stoppingSound w id: ${soundId}`);
    Howler.stop(soundId);
  }

  play(sfxName: ESoundName, selectorVal: number = 0, stopTimeMs?: number) {
    const sounds = this[sfxName];
    if (Array.isArray(sounds)) {
      var clip = sounds[selectorVal % sounds.length];

      // console.log(`state of ${sfxName} is ${clip.state()}`);
      // play the clip
      var playId = clip?.customPlay?.(clip) ?? clip?.play?.();

      // if we have a stop time in mind, stop it then
      var timeoutRef;
      if (Number.isInteger(stopTimeMs)) {
        timeoutRef = setTimeout(() => {
          this.stopSound(playId);
        }, stopTimeMs);
      }

      return {
        ref: timeoutRef,
        stopMe: () => {
          clearTimeout(timeoutRef);
          this.stopSound(playId);
        },
        playId,
      };
    }
  }

  private setupClip(clipSrc: string, opts: ICustomSfxOptions = {}) {
    const clip = new Howl({
      src: [clipSrc],
      ...opts
    });

    clip.on('load', function () {
      console.log('howler - Loaded sound!');
    });

    clip.on('loaderror', function (err, err1) {
      console.log('howler - error loading sound!');
    });

    // add a custom play method with fade 
    if (!!opts.fadeDuration) {
      clip.customPlay = () => {
        const playId = clip.play();
        // fade clip based on options
        clip.fade(
          opts.fadeFrom ?? 0,
          opts.fadeTo ?? 1,
          opts.fadeDuration,
          playId);
        return playId;
      };
    }
    return clip;
  }


}
