import React, { FC, useState, useEffect, useContext, useRef } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-grid-system';
import { useParams } from "react-router-dom";
import { ReactSVG } from 'react-svg'
import {
  Button,
  FormInput
} from 'shards-react'

import {
  sfx, ESoundName,
  ConditionalRender,
  Game,
  Team,
  Word,
  ITWord,
  TW,
  BW,
  CW,
  EGameMode,
  GameContext,
  graphQLQuery,
  graphQLSubscription,
  getMyTeamAsGuesser,
  getTeamAsOpposition,
  onChangeVote,
  WordVote,
  useMap

} from '../../internal';
import { FaQuestionCircle, FaAngleDoubleUp } from 'react-icons/fa';
import top16 from '../../assets/top16.svg'

// TODO: move this to a shared place
const numBWsPerRound = 16;

interface IProps {

}

export const VotePhase: FC<IProps> = ({ }) => {
  var { state: { game, myTeam, oppTeam, helpOn }, dispatch } = useContext(GameContext);

  // use references inside subscriptions
  var latestMyTeam = useRef(myTeam);
  latestMyTeam.current = myTeam;
  var latestOppTeamTargetWords = useRef<Map<string, ITWord>>(new Map());

  // word id - vote id
  const [myVotedWords, setMyVotedWords] = useMap(new Map<string, string>(new Map()));
  const [top16VoteThreshold, setTop16VoteThreshold] = useState(1);
  const [numCWsInTop16, setNumCWsInTop16] = useState(16);
  const [wordVoteThresholds, setWordVoteThresholds] = useState<string[][]>([]);

  useEffect(() => {
    console.log('calculating top 16 threshold');
    var numWordsSecured = 0;
    var voteIndex = (wordVoteThresholds?.length ?? 0) - 1;
    // from highest vote-count to lowest vote count add the number of words to to numWordsSecured counter until we have 16 words.
    while (numWordsSecured < 16 && voteIndex >= 0) {
      numWordsSecured += wordVoteThresholds[voteIndex]?.length;
      voteIndex--;
    }

    setNumCWsInTop16(numWordsSecured);
    if (numWordsSecured === 16) {
      // use this vote count since we secured exactly 16 words
      setTop16VoteThreshold(voteIndex + 1);
    }
    else if (numWordsSecured > 16) {
      //  ex: 14 words secured at vote count 3
      // 20 words secured at vote count 2, which is too many
      // use the vote count that did not overflow the 16 limit
      setTop16VoteThreshold(voteIndex + 2);
    }
  }, [wordVoteThresholds]);

  useEffect(() => {
    if (game?.getMyTeam) {
      (async () => {
        // fetch the data for this view so that we're never without it
        var myT = await graphQLQuery(getMyTeamAsGuesser, 'getTeam', { id: game!.getMyTeam()!.id })
        var myTeam = new Team(myT);
        // get the opposing team's Ttw
        var oppT = await graphQLQuery(getTeamAsOpposition, 'getTeam', { id: game!.getOppositeTeam()!.id })
        var oppTeam = new Team(oppT);

        dispatch({ type: 'setMyTeam', team: myTeam })
        dispatch({ type: 'setOppTeam', team: oppTeam })

        // this next block is really dumb. find a better way
        // setup the target word map since the subscriptions wont go deep enough. (figure out why later)
        latestOppTeamTargetWords.current = oppTeam?.round?.tws.reduce((fullTwMap: Map<string, ITWord>, currTw: TW): any => {
          if (!fullTwMap.has(currTw.id)) {
            // cant use forApi since its async
            var currTwJson: any = {
              id: currTw.id,
              text: currTw.text,
              teamId: currTw.teamId,
              roundId: currTw.roundId,
            };
            fullTwMap.set(currTw.id, currTwJson);
          }
          return fullTwMap;
        }, new Map<string, ITWord>()) ?? new Map<string, ITWord>();

        calcTop16Thresholds(myTeam?.round?.cws)

      })();

      // setup subscription for myTeam's incoming cws
      var cwSubscription = graphQLSubscription(onChangeVote, { teamId: game!.getMyTeam()!.id }, voteChanged);

      return () => cwSubscription.unsubscribe();
    }
  }, [game?.id])

  const voteChanged = async ({ onChangeVote: vote }) => {
    // const vote = onChangeVote;
    const myTeam = latestMyTeam.current;
    myTeam?.round?.cws.some((cw: CW) => {
      if (cw.id === vote.wordId) {
        var matchedExisting = false;

        var reconciledVotes = cw.votes?.reduce((allVotesForWord: WordVote[], existingVote: WordVote) => {
          if (existingVote.id === vote.id) {
            matchedExisting = true;
            // return allVotesForWord;
          } else {
            allVotesForWord.push(existingVote);
          }
          return allVotesForWord;
        }, []);
        // if the vote from the subscription didnt 
        // match an existing one, it must be a new vote
        // add it to the votes array
        if (!matchedExisting) {
          reconciledVotes.push(new WordVote(vote))

          // figure out how to determin if this is an up vote or a down vote
          // sfx.play(ESoundName.voteRemoved, myTeam?.name?.length);
          sfx.play(ESoundName.voteCast, myTeam?.name?.length);
        }
        // update the word as having the new votes array
        cw.votes = reconciledVotes;


        return true;
      }
      return false;
    });
    dispatch({ type: 'modifyMyTeam', team: myTeam });

    calcTop16Thresholds(myTeam?.round?.cws)
  }

  const calcTop16Thresholds = (myTeamCws?: CW[]) => {
    // calculate teh vote threshold needed for top16 words
    var foundWordVoteThresholds: string[][] = myTeamCws?.reduce((wordsByVoteCount: string[][], cw: CW) => {
      // wordsByVoteCount structured as:
      // [
      //   [ cwId, cwId, cwId ]           // word Ids with 0 votes
      //   [ cwId, cwId ]                 // word Ids with 1 votes
      //   [ cwId, cwId, cwId, cwId ]     // word Ids with 2 votes
      //   [ cwId ]                       // word Ids with 3 votes
      //   undefined                      // word Ids with 4 votes
      //   [ cwId, cwId, cwId, cwId ]     // word Ids with 5 votes
      // 
      // ]

      const numVotesForCurrWord = cw.votes.length ?? 0;
      if (!Array.isArray(wordsByVoteCount[numVotesForCurrWord])) {
        // if this many votes has not been encountered, then set it up
        wordsByVoteCount[numVotesForCurrWord] = [];
      }
      wordsByVoteCount[numVotesForCurrWord].push(cw.id);
      return wordsByVoteCount;
    }, [[]]) ?? [[]];

    setWordVoteThresholds(foundWordVoteThresholds);
  }

  const modifyMyVotedWords = (desiredVotes) => {
    // set map and size separately so that we have re-renders
    setMyVotedWords(desiredVotes);
  };


  const toggleMyWordVote = (cw: CW) => {
    if (myVotedWords!.has(cw.id)) {
      deleteWordVote(cw);
    }
    else {
      createWordVote(cw);
    }
  }

  const createWordVote = async (cw: CW) => {
    var myVote = new WordVote({
      wordId: cw.id,
      teamId: cw.teamId
    });
    myVotedWords!.set(cw.id, myVote.id);
    modifyMyVotedWords(myVotedWords);

    await WordVote.saveNew(myVote);
    return;
  }

  const deleteWordVote = async (cw: CW) => {
    var voteId = myVotedWords!.get(cw.id)!;
    myVotedWords!.delete(cw.id);
    modifyMyVotedWords(myVotedWords);

    await WordVote.saveDelete(voteId);
    return;
  }

  const toggleHelp = () => {
    dispatch({ type: 'setHelp', help: !helpOn });
  };

  return (
    <div className={classnames('vote-phase-container')}>
      <Container>

        {/* instructions */}
        <ConditionalRender visible={helpOn || (helpOn === undefined && latestMyTeam.current?.rounds?.length === 1)}>
          <Row className={classnames('instruction-block')}>
            <Col xs={2} md={1} onClick={() => toggleHelp()}>
              <FaQuestionCircle />
            </Col>
            <Col xs={9} md={10}>
              <p>Vote for your team's best words by selecting them below.</p>
              <p>Only the {numBWsPerRound} words with the most votes will be used as this round's <em>Banned Words</em>.</p>
              <ConditionalRender visible={!!game?.hasGameMode(EGameMode.doubleValueWords)}>
                <p>The highest voted of your <em>Banned Words</em> is used as the <em>Double Value Word</em>.</p>
              </ConditionalRender>
            </Col>
          </Row>
        </ConditionalRender>

        <Row justify="center" className={classnames('opposing-team-word-list')}>
          {oppTeam?.round?.tws?.map((tw: TW, i: number) => (
            <Col key={`tw-${i}`}>
              <Button
                className={classnames('word-button', 'tw', { 'active': myVotedWords?.has(tw.id) })}
                disabled>
                <>
                  <Row>
                    <Col xs={12}>
                      <div className="word-text">{tw.text}</div>
                    </Col>
                  </Row>
                  <ConditionalRender visible={tw?.isDoubleValue()}>
                    <div className="double-value-word-flag-container">
                      <div className={classnames('double-value-word-flag')}>
                        <FaAngleDoubleUp />
                      </div>
                    </div>
                  </ConditionalRender>
                </>
              </Button>

            </Col>
          ))}
        </Row>

        <Row>
          <Col xs={12}>
            <h2 className={classnames('text-center')}>Vote for your Team's best <em>Banned Words</em></h2>
            <h4 className={classnames('text-center', 'sub-title')}>Top 16 words with the hightest vote count will be used</h4>
          </Col>

        </Row>
        <Row>

          {myTeam?.round?.cws?.map((cw: CW, i: number) => (
            <Col xs={12} sm={6} md={4} lg={3} xxl={2} key={`cw-${i}`}>
              <Button
                className={classnames('word-button', 'cw', myTeam?.getStyleName(), { 'active': myVotedWords?.has(cw.id) })}
                onClick={() => toggleMyWordVote(cw)}>
                <>

                  <Row>
                    <Col xs={12}>
                      <div className={classnames('word-text', {
                        'not-top-16-guaranteed': (cw.votes?.length ?? 0) < top16VoteThreshold
                      })} >
                        {cw.text}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <div className={classnames('counter')}>
                        {cw.votes?.length ?? 0}
                      </div>

                      <ReactSVG src={top16} className={classnames('icon-svg top16', {
                        'top-16-guaranteed': (cw.votes?.length ?? 0) >= (top16VoteThreshold),
                        'top-16-possibility': (cw.votes?.length ?? 0) >= (top16VoteThreshold - (numCWsInTop16 > numBWsPerRound ? 1 : 0)),
                      })} />

                      <div className={classnames('associated-list')}>
                        {cw?.associations?.map((tw: TW, j: number) => (
                          <span className="inline word" key={`cw-${i}-associations-${j}`}>{tw.text}</span>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </>
              </Button>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
