import {
  ITWord,
  DbEntity,
  IModelIncludeOpts,
  EWordType,
  TW,
  graphQLMutation,
  createWordAssociation
} from '../../internal';

export interface IAssociatedWord {
  twId: string;
  cwId: string
  tw?: ITWord;
}

export class WordAssociation extends DbEntity {
  cwId?: string;
  twId?: string;
  tw?: TW;

  constructor(opts?: IAssociatedWord) {
    super(opts);
    this.cwId = opts?.cwId;
    this.twId = opts?.twId;
    this.tw = opts?.tw ? new TW(opts!.tw) : undefined;

  }

  async forApi(opts?: IModelIncludeOpts): Promise<any> {
    const superJson = await super.forApi(opts);
    var json = {
      ...superJson,
      cwId: this.cwId,
      twId: this.twId
    }
    return json;
  }

  static async saveNew(associationToSave: WordAssociation): Promise<string> {
    const associationEntity = await associationToSave!.forApi({ save: true })
    await graphQLMutation(createWordAssociation, associationEntity);
    return associationEntity.id;
  }
}
