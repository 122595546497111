import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  'Gum',
  'M&M\'s',
  'Skittles',
  'Hershey\'s Kisses',
])

const medium = new Set([
  'Cotton candy',
  'Candy canes',
  'Salt water taffy',
  'Tootsie Roll',
  'Candy Buttons',
  'Jawbreakers',
  'Jolly Rancher',
  'Life Savers',
  'PEZ',
  'Ribbon candy',
  'Sweethearts',
  'Pop Rocks',
  'Warheads',
  'Sour Patch Kids',
  'Red Vines',
  'Twizzlers',
  'Dark chocolate',
  'Hershey Bar',
  'Kit Kat',
  'Milk Duds',
  'Milky Way',
  'Reese\'s Peanut Butter Cup',
  'Snickers',
  'Twix',
  'Almond Joy',
  'Reese\'s Pieces',
  'Jolly Rancher'
]);

const hard = new Set([
  'Pocky',
  'Ferrero Rocher',
  'Maltesers',
  'Take 5',
  'Horehound candy',
  'Whoppers',
  'Butterscotch',
  'Abba-Zaba',
  'Cadbury Egg',
  'Gumdrop',
  'Almond Roca',
  'Brittle',
  'Caramac',
  'Jelly Tots',
  'Mallo Cups',
  'Peach Blossoms',
  'Rocky Road Candy',
  'Sky Bar',
  'Toffee',
  'Peppermint bark',
  'Rolo',
])

export default new ThemeSet('Candy',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);