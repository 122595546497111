import React, { FC, useState, useEffect, useContext, useRef } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Visible } from 'react-grid-system';
import { useParams } from "react-router-dom";
import {
  Button,
  FormInput
} from 'shards-react'
import { FaQuestionCircle } from 'react-icons/fa'
import {
  ConditionalRender,
  Game,
  Team,
  Word,
  ITWord,
  TW,
  BW,
  CW,
  GameContext,
  graphQLQuery,
  graphQLSubscription,
  getTeamAsSpectator,
  onCreateCW,
  useMap

} from '../../internal';


interface IProps {

}

export const SpectateWordEntry: FC<IProps> = ({ }) => {
  var { state: { game, myTeam, oppTeam, helpOn }, dispatch } = useContext(GameContext);
  var [blueTeamId, setBlueTeamId] = useState<string>('');
  var [redTeamId, setRedTeamId] = useState<string>('');

  // RED
  // use references inside subscriptions
  var latestMyTeam = useRef(myTeam);
  latestMyTeam.current = myTeam;

  // BLUE
  // use references inside subscriptions
  var latestOppTeam = useRef(oppTeam);
  latestOppTeam.current = oppTeam;

  var latestOppTeamTargetWords = useRef<Map<string, ITWord>>(new Map());
  var latestFullAssociationTracker = useRef<Map<string, number>>(new Map());

  useEffect(() => {
    if (game?.getMyTeam) {

      (async () => {
        // label the teams for spectator lookup
        const teamLookupMap = game?.teams?.reduce((teamDict: any, currTeam: Team): any => {
          if (!teamDict[currTeam.name]) {
            teamDict[currTeam.name] = currTeam;
          };
          return teamDict;
        }, {});
        setRedTeamId(teamLookupMap[Game.redTeamName]?.id);
        setBlueTeamId(teamLookupMap[Game.blueTeamName]?.id);

        // fetch the data for this view so that we're never without it
        var red = await graphQLQuery(getTeamAsSpectator, 'getTeam', { id: teamLookupMap[Game.redTeamName]?.id })
        var redT = new Team(red);

        // get the opposing team's Ttw
        var blue = await graphQLQuery(getTeamAsSpectator, 'getTeam', { id: teamLookupMap[Game.blueTeamName]?.id })
        var blueT = new Team(blue);

        // for spectator, red team == myTeam
        // for spectator, blue team == oppTeam
        dispatch({ type: 'setMyTeam', team: redT })
        dispatch({ type: 'setOppTeam', team: blueT })

        // // this next block is really dumb. find a better way
        // // setup the target word map since the subscriptions wont go deep enough. (figure out why later)
        // latestOppTeamTargetWords.current = oppTeam?.round?.tws.reduce((fullTwMap: Map<string, ITWord>, currTw: TW): any => {
        //   var currTwJson = {
        //     id: currTw.id,
        //     text: currTw.text,
        //     teamId: currTw.teamId,
        //     roundId: currTw.roundId,
        //   };
        //   fullTwMap.set(currTw.id, currTwJson);
        //   // to map from the twids to the number of my team's associations (0 initially)
        //   latestFullAssociationTracker.current.set(currTw.id, 0);

        //   return fullTwMap;
        // }, new Map<string, ITWord>()) ?? new Map<string, ITWord>();

      })();
    }
  }, [game?.id])

  // red subscriptions
  useEffect(() => {
    if (redTeamId) {
      // setup subscription for myTeam's incoming cws
      var cwSubscription = graphQLSubscription(onCreateCW, { teamId: redTeamId }, cwCreated.bind(null, 'red'));
      return () => cwSubscription.unsubscribe();
    }
  }, [redTeamId])

  // blue subscriptions
  useEffect(() => {
    if (blueTeamId) {
      // setup subscription for myTeam's incoming cws
      var cwSubscription = graphQLSubscription(onCreateCW, { teamId: blueTeamId }, cwCreated.bind(null, 'blue'));
      return () => cwSubscription.unsubscribe();
    }
  }, [blueTeamId])

  const cwCreated = async (redOrBlue: string, { onCreateWord: word }) => {
    // setup add-to-team
    // for spectator, red = myteam
    // for spectator, blue = oppTeam
    const addToTeam = redOrBlue === 'red' ? latestMyTeam.current : latestOppTeam.current;
    // console.log('created word includes this many associations - ' + word.associations.items.length);

    word.associations.items = word.associations?.items?.map((assoc, i: number) => {

      var latestNumAssocationsForWord = latestFullAssociationTracker.current.get(assoc.twId) || 0;
      latestFullAssociationTracker.current.set(assoc.twId, ++latestNumAssocationsForWord);
      latestFullAssociationTracker.current = latestFullAssociationTracker.current;
      return {
        ...assoc,
        // ad the tw manually since the subscription wont grab it
        tw: { id: assoc.twId }
      }
    })

    addToTeam!.addCW(new CW(word));

    dispatch({
      type: redOrBlue == 'red' ? 'modifyMyTeam' : 'modifyOppTeam',
      team: addToTeam
    });
  }

  const displayTeamStats = (t: Team) => (
    <Row>
      {/* team name */}
      <Col xs={12}>
        <h2 className={classnames('text-center')}>{t?.name}</h2>
      </Col>
      {/* team word count */}
      <Col xs={12}>
        <h3 className={classnames('text-center')}> Words Entered</h3>
        <h1 className={classnames('text-center')}>
          {t?.round?.cws?.length ?? 0}
        </h1>
      </Col>
      {/* team associations count */}
      <Col xs={12}>
        <h3 className={classnames('text-center')}>
          Associations Created
        </h3>
        <h1 className={classnames('text-center')}>
          {t?.round?.cws?.reduce((totalAssociations: number, currWord: CW) => {
            return totalAssociations + (currWord?.associations?.length ?? 0);
          }, 0) ?? 0}
        </h1>
      </Col>
    </Row>
  );

  return (
    <div className={classnames('spectateWordEntryPhase-container')}>
      <Container>

        <Row justify="center">
          <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>

          <Col xs={6}>
            {displayTeamStats(myTeam!)}
          </Col>

          <Col xs={6}>
            {displayTeamStats(oppTeam!)}
          </Col>

          <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>
        </Row>
      </Container>
    </div>
  )
}
