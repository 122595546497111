import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "athlete",
  "ball",
  "baseball",
  "basketball",
  "bat",
  "bicycle",
  "bike",
  "bowling",
  "canoe",
  "catch",
  "coach",
  "dive",
  "exercise",
  "field",
  "football",
  "skateboard",
  "game",
  "golf",
  "gym",
  "hockey",
  "jump",
  "quarterback",
  "racket",
  "roller skates",
  "run",
  "skate",
  "sled",
  "sledding",
  "snowboard",
  "skydive",
  "soccer",
  "surfing",
  "swimming",
  "team",
  "teammate",
  "tennis",
  "volleyball",
  "walking",
  "Frisbee",
  "jog",
  "kite",
  "Olympics",
  "paddle",
  "pitcher",
  "player",
  "pole",
  "race",
  "referee",
  "scoreboard",
  "silver medal",
  "snorkeling",
  "softball",
  "stadium",
  "swim",
  "target",
  "throw",
  "tie",
  "trampoline",
  "tug of war",
  "umpire",
  "unicycle",
  "beach volleyball",
  "weights",
  "win",
])

const medium = new Set([
  "archery",
  "arena",
  "arrow",
  "athletics",
  "axel",
  "badminton",
  "base",
  "baton",
  "batter",
  "batting",
  "biathlon",
  "bicycling",
  "biking",
  "billiards",
  "bocce",
  "boomerang",
  "boules",
  "bow",
  "bowler",
  "boxer",
  "boxing",
  "bronze medal",
  "bunt",
  "canoeing",
  "catcher",
  "champion",
  "championship",
  "cleats",
  "club",
  "compete",
  "competing",
  "competition",
  "competitor",
  "crew",
  "cricket",
  "croquet",
  "cross country",
  "cue ball",
  "curling",
  "cycle",
  "cycling",
  "cyclist",
  "dart",
  "dartboard",
  "deadlifting",
  "decathlon",
  "defense",
  "diamond",
  "discus",
  "diver",
  "diving",
  "dodgeball",
  "doubleheader",
  "dugout",
  "equipment",
  "fencing",
  "field hockey",
  "fielder",
  "fielding",
  "figure skating",
  "fitness",
  "forward",
  "freestyle",
  "skateboarding",
  "kick flip",
  "world record",
  "olympic record",
  "free throw",
  "gear",
  "geocaching",
  "goal",
  "goalie",
  "gold medal",
  "golfer",
  "golfing",
  "guard",
  "gymnasium",
  "gymnast",
  "gymnastics",
  "halftime",
  "hammer throw",
  "handball",
  "hang gliding",
  "hardball",
  "helmet",
  "heptathlon",
  "high jump",
  "hitter",
  "hole-in-one",
  "home",
  "home plate",
  "home run",
  "home team",
  "hoop",
  "horseshoes",
  "huddle",
  "hurdle",
  "ice hockey",
  "ice rink",
  "ice skates",
  "ice skating",
  "infield",
  "infielder",
  "inline skates",
  "inning",
  "javelin",
  "jogger",
  "jump rope",
  "jumper",
  "jumping",
  "karate",
  "kayak",
  "kayaker",
  "kayaking",
  "kickball",
  "kneepads",
  "kung fu",
  "lacrosse",
  "lawn bowling",
  "league",
  "long jump",
  "lose",
  "loser",
  "luge",
  "lutz",
  "major league",
  "mallet",
  "martial arts",
  "mat",
  "medal",
  "minor league",
  "mitt",
  "mouthguard",
  "move",
  "movement",
  "MVP",
  "net",
  "no-hitter",
  "offense",
  "out",
  "outfield",
  "outfielder",
  "paddleball",
  "paddling",
  "paintball",
  "parasailing",
  "parkour",
  "pickleball",
  "ping pong",
  "pitch",
  "play",
  "playing",
  "playoffs",
  "pogo stick",
  "pole vault",
  "polo",
  "pool",
  "puck",
  "quarter",
  "quiver",
  "racer",
  "racewalking",
  "racing",
  "racquetball",
  "rafting",
  "relay race",
  "ride",
  "riding",
  "rink",
  "rock climbing",
  "roller skating",
  "row",
  "rugby",
  "runner",
  "running",
  "sailing",
  "score",
  "scuba",
  "scull",
  "sculling",
  "shortstop",
  "shot put",
  "skating rink",
  "skeleton",
  "ski",
  "skier",
  "skiing",
  "slalom",
  "sledder",
  "snowboarder",
  "snowboarding",
  "snowshoeing",
  "somersault",
  "speed skating",
  "sport",
  "sportsmanship",
  "squash",
  "stick",
  "strike",
  "stroke",
  "Sumo wrestling",
  "surfer",
  "swimmer",
  "table tennis",
  "taekwondo",
  "tag",
  "tee",
  "tetherball",
  "throwing",
  "toboggan",
  "track and field",
  "triathlete",
  "triathlon",
  "triple jump",
  "triple play",
  "uniform",
  "vault",
  "vaulter",
  "vaulting",
  "visiting team",
  "volley",
  "bmx",
  "wakeboarding",
  "walk",
  "walker",
  "water polo",
  "water ski",
  "water skier",
  "water skiing",
  "weightlifter",
  "weightlifting",
  "wetsuit",
  "windsurfer",
  "windsurfing",
  "winner",
  "winning",
  "World Cup",
  "World Series",
  "wrestler",
  "wrestling",
  "super bowl",
  "word series",
  "world cup",
  "FIFA",
  "iron man",
  "marathon",
  "half marathon",
  "Michael Phelps",
  "Katie Ledecky",
  "Simone Biles",
  "Usain Bolt",
  "Muhammad Ali",
  "Michael Jordan",
  "Lebron James",
  "rowing",
  "tournament",
  "lap pool",
  "mountain bike"
]);

const hard = new Set([
  "qualifying",
  "semi-finals",
  "finals",
  "tokyo 2020",
  "Apolo Ohno",
  "Shawn White",
  "Serena Williams",
  "Keri Walsh Jennings",
  "Allyson Felix",
  "Ryan Lochte",
  "Caeleb Dressel",
  "Ariarne Titmus",
  "Emma McKeon",
  "Adam Peaty",
  "breast stroke",
  "synchronized swimming",
  "synchronized diving",
  "biathlon",
  "pentathlon",
  "equestrian",
  "jai-alai",
  "judo",

])



export default new ThemeSet('Sports',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);