import React, { FC, useState, useEffect, useContext, useRef } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-grid-system';
import { Promise } from 'bluebird';
import ReactTooltip from 'react-tooltip'
import { FaSkull, FaFlushed, FaDragon, FaHippo, FaFish, FaFrog, FaSpider, FaCrow } from "react-icons/fa";
import {
  Button,
  FormInput
} from 'shards-react'

import {
  sfx, ESoundName,
  EGameMode,
  EGamePhases,
  ConditionalRender,
  Game,
  Team,
  Word,
  ITWord,
  TW,
  BW,
  CW,
  EWordType,
  GameContext,
  graphQLQuery,
  graphQLSubscription,
  onRoundSacrificed,
  wordByRoundIdAndTypeWithVotes,
} from '../../../internal';

const numBWsPerRound = 16;
const preSacrificedIcons = [
  (<FaFrog/>),
  (<FaSpider/>),
  (<FaCrow/>),
  (<FaHippo/>),
  (<FaFish/>),
  (<FaFrog/>),
  (<FaDragon/>),
  (<FaSpider/>),
  (<FaFlushed/>)
];

interface IProps {

}

export const VerbalReady: FC<IProps> = ({ }) => {
  var nextPhaseFromPhaseStart = useRef<number>();
  var { state: { game, myTeam, oppTeam, redTeam, blueTeam, clientSync }, dispatch } = useContext(GameContext);
  var [isMyTeamsTurn, setIsMyTeamsTurn] = useState<boolean>(false);
  var [isFirstTwoSeconds, setIsFirstTwoSeconds] = useState<boolean>(true);
  var [isStarting, setIsStarting] = useState<boolean>(false);
  var [activeTeam, setActiveTeam] = useState<Team>();
  var [inactiveTeam, setInactiveTeam] = useState<Team>();


  useEffect(() => {
    setTimeout(() => {
      setIsFirstTwoSeconds(false);
    }, 1400)
  }, [])

  useEffect(() => {
    var myTeamsTurn = game?.selectedTeam?.name === activeTeam?.name;
    setIsMyTeamsTurn(myTeamsTurn);

    // setup subscription for the active team's sacrifice choice 
    // only needed for sacrifice game mode
    if (game?.hasGameMode(EGameMode.sacrifice)) {
      var roundSubscription = graphQLSubscription(onRoundSacrificed, { id: activeTeam?.roundId }, handleRoundSacrificed);
      return () => roundSubscription?.unsubscribe?.();
    }
  }, [activeTeam?.name]);

  useEffect(() => {
    if (game?.getMyTeam) {
      // set the next phase number so that we can fire it form anywhere later
      nextPhaseFromPhaseStart.current = game?.phase + 1;
    }
    
  }, [game?.id])

  useEffect(() => {
    if (Number.isInteger(game?.phase)) {
      // all browsers should set clue giver to false
      dispatch({ type: 'changePlayerClueGiver', isClueGiver: false });

      // determine teams
      var redTeam, blueTeam;
      game!.teams?.forEach((team: Team) => {
        if (team.name === Game.redTeamName) {
          redTeam = team;
        } else if (team.name === Game.blueTeamName) {
          blueTeam = team;
        } else {
          console.warn(`unrecognized team name [${team.name}]`)
        }
      });
      dispatch({ type: 'setRedTeam', team: redTeam });
      dispatch({ type: 'setBlueTeam', team: blueTeam });
      // set active and inactive teams based on the phase
      if (game!.phase === EGamePhases.preRedGuess) {
        setActiveTeam(redTeam);
        setInactiveTeam(blueTeam);
      } else {
        setActiveTeam(blueTeam);
        setInactiveTeam(redTeam);
      }

    }
  }, [game?.phase]);

  const handleRoundSacrificed = async ({ onChangeRound }) => {
    var updatedRound;
    if (onChangeRound.id === redTeam?.round?.id) {
      updatedRound = redTeam!.round!.updateFromSub(onChangeRound);
      dispatch({ 
        type: 'setRedTeamRoundSacrificeVal',
        sacrificed: updatedRound.sacrificed
      });
    } else if (onChangeRound.id === blueTeam?.round?.id) {
      updatedRound = blueTeam!.round!.updateFromSub(onChangeRound);
      dispatch({
        type: 'setBlueTeamRoundSacrificeVal',
        sacrificed: updatedRound.sacrificed
      });
    } else {
      console.warn('unrecognized round');
    }

    if (updatedRound?.sacrificed) {
      sfx.play(ESoundName.sacrifice);
    }
  }

  const toggeSacrificed = async () => {
    await myTeam?.round?.toggleSacrificed();
  }

  // remove duplicate words without losing votes
  const deDupe = (listWithPossibleDupes: CW[]) => {
    const deDupedMap = listWithPossibleDupes.reduce((deDupedList: Map<string, CW>, currCw: CW) => {
      if (deDupedList.has(currCw.text)) {
        var foundCw = deDupedList.get(currCw.text)!;
        var deDupedVotes = [].concat((foundCw.votes ?? []) as any, (currCw.votes ?? []) as any);
        foundCw.votes = deDupedVotes;
        // console.log(`found dupelicate word: ${foundCw!.text}. combined vote tally: ${foundCw.votes.length}.`)
        deDupedList.set(currCw.text, foundCw);
      } else {
        deDupedList.set(currCw.text, currCw);
      }
      return deDupedList;
    }, new Map<string, CW>());
    return [...deDupedMap.values()];
  };

  const startGuessPhase = async () => {
    // only allow this action once so that more than 16 banned words get created for the game
    setIsStarting(true);
    if (!isStarting) {
      const useDoubleValueWords = game?.hasGameMode(EGameMode.doubleValueWords);
      const useBombWords = game?.hasGameMode(EGameMode.bombWords);

      // TODO: add in a confirm dialog so that nobody spoils the round
      // set the clicking player to the cluegiver
      // UPDATE: after 6 months of use ^ this does not seem necessary
      dispatch({ type: 'changePlayerClueGiver', isClueGiver: true });
      const searchParams = {
        roundId: oppTeam?.roundId,
        type: { eq: EWordType.cw }
      };
      // fetch top (16) cws based on number of votes from oppTeam's round
      var cws = await graphQLQuery(wordByRoundIdAndTypeWithVotes, 'wordByRoundIdAndType', searchParams);
      var sortedCWs = deDupe(cws.map(w => new CW(w))).sort((a: CW, b: CW) => (b?.votes?.length || 0) - (a?.votes?.length || 0))
      var chosenWords = sortedCWs.slice(0, numBWsPerRound);
      
      // change the type of those words to bws and save
      // set it as the double value word if it is the first in the list (pre-sorted by vote count)
      // set it as the bomb word if it is the last in the list (pre-sorted by vote count)
      await Promise.map(chosenWords, async (w: CW, index: number) => {
        return await BW.saveConvertFromCw(w, useDoubleValueWords && index === 0, useBombWords && index === chosenWords.length - 1);
      });
      // change the new phase value
      // use the nextPhaseFromPhaseStart value to prevent double cick advancing twice
      await game?.setNewPhase(nextPhaseFromPhaseStart.current ?? (game?.phase + 1), clientSync, myTeam?.round?.sacrificed);
    }
  }

  return (
    <div className={classnames('verbalPhase-container')}>
      <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>
      <h2 className={classnames('text-center')}>{activeTeam?.name}'s Turn to Guess</h2>

      <ConditionalRender visible={isMyTeamsTurn}>
        <Row justify="center">
          <Col sm={10}>
            <h2 className={classnames('text-center')}>{activeTeam?.name}'s captain should click the button when your team is ready to start.</h2>
          </Col>
          <Col sm={10} md={8} xxl={6}>
            <Button
              className={classnames('huge', 'green')}
              onClick={startGuessPhase}
              disabled={isFirstTwoSeconds || isStarting}>
                I'm captain and {activeTeam?.name} is ready!
            </Button>
          </Col>
        </Row>

        {/* Sacrifice button */}
        <ConditionalRender visible={!!game?.hasGameMode(EGameMode.sacrifice)}>
          <Row justify="center">
            <Col sm={10} md={6} xxl={4}>
              <h4 className={classnames('text-center')}>You may choose to sacrifice a game point to gain {(game?.sacrificeBonusMs || Game?.defaultSacrificeBonusMs) / 1000} extra guessing seconds.</h4>
            </Col>
          </Row>

          <Row justify="center">
            <Col xs={10} sm={8} md={4} xxl={3}>
              <Button
                onClick={toggeSacrificed}
                disabled={isFirstTwoSeconds || isStarting}
                className={classnames('word-button', { 'active': !!activeTeam?.round?.sacrificed })}>
                <>
                  <Row>
                    <Col>
                      <div className="word-text">
                        { !activeTeam?.round?.sacrificed ? 'Sacrifice?' : 'Sacrificed!' }
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="sacrifice-indicator-container" data-tip data-place="bottom" data-for="sacrifice">
                      <div className={classnames('sacrifice-round-indicator')}>
                        <ConditionalRender visible={!activeTeam?.round?.sacrificed}> 
                          {/* display a pre-sacrifice icon based on the round number */}
                          {preSacrificedIcons[(activeTeam?.rounds?.length ?? 0) % preSacrificedIcons.length]} 
                        </ConditionalRender>
                        <ConditionalRender visible={!!activeTeam?.round?.sacrificed}> 
                          <FaSkull /> 
                        </ConditionalRender>
                      </div>
                    </div>
                  </Row>
                </>
              </Button>
            </Col>
          </Row>
        </ConditionalRender>

      </ConditionalRender>

      <ConditionalRender visible={!isMyTeamsTurn}>
        <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>
        <Row justify="center">
          <Col sm={10}>
            <h2 className={classnames('text-center')}>Waiting for {activeTeam?.name} to start</h2>
          </Col>
        </Row>

        {/* Sacrifice indicator */}
        <ConditionalRender visible={!!game?.hasGameMode(EGameMode.sacrifice)}>
          <Row justify="center">
            <Col sm={10} md={6} xxl={4}>
              <h4 className={classnames('text-center')}>The {activeTeam?.name} may sacrifice a point to gain {(game?.sacrificeBonusMs || Game?.defaultSacrificeBonusMs) / 1000} additional seconds during guessing.</h4>
            </Col>
          </Row>

          <Row justify="center">
            <Col xs={10} sm={8} md={4} xxl={3}>
              <Button
                disabled={true}
                className={classnames('word-button', { 'active': !!activeTeam?.round?.sacrificed })}>
                <>
                  <Row>
                    <Col>
                      <div className="word-text">
                        { !activeTeam?.round?.sacrificed ? 'Not Sacrificed' : 'Sacrificed!' }
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="sacrifice-indicator-container" data-tip data-place="bottom" data-for="sacrifice">
                      <div className={classnames('sacrifice-round-indicator')}>
                        <ConditionalRender visible={!activeTeam?.round?.sacrificed}> 
                            {/* display a pre-sacrifice icon based on the round number */}
                            {preSacrificedIcons[(activeTeam?.rounds?.length ?? 0) % preSacrificedIcons.length]} 
                          </ConditionalRender>
                          <ConditionalRender visible={!!activeTeam?.round?.sacrificed}> 
                            <FaSkull /> 
                          </ConditionalRender>
                      </div>
                    </div>
                  </Row>
                </>
              </Button>
            </Col>
          </Row>
        </ConditionalRender>

      </ConditionalRender>

    </div >
  )
}
