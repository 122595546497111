import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
]);

const medium = new Set([
  "NCAA",
  "Athletics",
  "LSU Tigers",
  "Georgia Bulldogs",
  "Spartans",
  "Oregon",
  "Ducks",
  "Ohio State",
  "Buckeyes",
  "Cornhuskers",
  "Auburn",
  "Gators",
  "FSU",
  "Seminoles",
  "UCF",
  "Knights",
  "Longhorns",
  "Gophers",
  "Wildcats",
  "Stanford",
  "Colorado Buffalos",
  "Big 10",
  "Big 12",
  "Conference USA",
  "PAC-12",
  "Crimson Tide",
  "Alabama",
  "March Madness",
  "Duke University",
  "Division 1",
  "Blue Devils",
  "Villanova",
  "Tar Heels",
  "Hoosiers",
  "UConn Huskies",
])

const hard = new Set([

]);


export default new ThemeSet('College Sports',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Medium, EDifficulty.Hard, EDifficulty.Pro]
  });

