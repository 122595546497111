import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  "Robert F. Kennedy",
  "Robert Downey Jr.",
  "Elvis Presley",
  "Barack Obama",
  "Brad Pitt",
  "Taylor Swift",
  "Britney Spears",
  "Daniel Radcliffe",
  "J. K. Rowling",
  "50 Cent",
  "Johnny Depp",
  "Hillary Clinton",
  "MLK Jr.",
  "John Cena",
  "Donald Trump",
  "Ed Sheeran",
  "Justin Bieber",
  "Michelle Obama",
  "Bill Gates",
  "Michael Jackson",
  "Tony Hawk",
  "Lil Wayne",
  "Stephen Hawking",
  "Leonardo DiCaprio",
  "Sandra Bullock",
  "Jamie Foxx",
  "Tom Cruise",
  "Arnold Schwarzenegger",
  "Angelina Jolie",
  "Princess Diana",
  "Steve Jobs",
  "Jackie Chan",
  "Ellen DeGeneres",
  "Steven Spielberg",
  "Muhammad Ali",
  "Vladimir Putin",
  "George W. Bush",
  "Oprah Winfrey",
  "Christian Bale",
  "Kanye West",
  "Al Pacino",
  "Kobe Bryant",
  "Beyoncé",
  "Rowan Atkinson",
  "Katy Perry",
  "Bill Clinton",
  "Adele",
  "Paul McCartney",
  "Jennifer Lopez",
  "Vin Diesel",
  "Rihanna",
  "Jennifer Aniston",
  "Angela Merkel",
  "Selena Gomez",
  "Megan Fox",
  "Eminem",
  "Pope Francis",
  "Neil Armstrong",
  "David Beckham",
  "Usain Bolt",
  "Matt Damon",
  "Liam Hemsworth",
  "Dwayne Johnson",
  "Mila Kunis",
  "David Bowie",
  "John Lennon",
  "Tom Brady",
  "George Clooney",
  "Bruno Mars",
  "Natalie Portman",
  "Jason Statham",
  "Bruce Springsteen",
  "LeBron James",
  "Kevin Hart",
  "Jimmy Fallon",
  "Julia Roberts",
  "Chris Evans",
  "Gordon Ramsay",
  "Elton John",
  "Tiger Woods",
  "Shakira",
  "Jennifer Lawrence",
  "Benedict Cumberbatch",
  "Justin Timberlake",
  "Johnny Cash",
  "Ice Cube",
  "Daniel Craig",
  "Mark Zuckerberg",
  "Steve Harvey",
  "Cher",
  "Shaquille O'Neal",
  "Magic Johnson",
  "Demi Lovato",
  "Elon Musk",
  "Kim Kardashian",
  "Joe Biden",
  "Reese Witherspoon",
  "Babe Ruth",
  "Adam Sandler",
  "Ozzy Osbourne",
  "Mariah Carey",
  "Jay Z",
  "Lionel Messi",
  "Jack Nicholson",
  "Macaulay Culkin",
  "Aretha Franklin",
  "Mitt Romney",
  "Lionel Richie",
  "O. J. Simpson",
  "Chris Rock",
  "Miley Cyrus",
  "Mark Hamill",
  "Drew Barrymore",
  "Roger Federer",
  "Jerry Seinfeld",
  "Amy Adams",
  "Pope John Paul II",
  "Bono",
  "Kevin Spacey",
  "Anne Frank",
]);


const hard = new Set([
  "Tina Turner",
  "Cameron Diaz",
  "Lana Del Rey",
  "Elizabeth II",
  "Marilyn Monroe",
  "Enrique Iglesias",
  "Martin Freeman",
  "Prince Harry",
  "Johnny Galecki",
  "Neil Patrick Harris",
  "Ian McKellen",
  "Drake",
  "Diana Ross",
  "Ben Affleck",
  "Madonna",
  "Gwyneth Paltrow",
  "Mike Tyson",
  "Mick Jagger",
  "Patrick Stewart",
  "Dr Dre",
  "Andy García",
  "Winston Churchill",
  "Amy Winehouse",
  "Monica Lewinsky",
  "Celine Dion",
  "Hayden Christensen",
  "Conor McGregor",
  "Hugh Grant",
  "Sean Penn",
]);


export default new ThemeSet('Famous People',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });