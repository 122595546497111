import {
  DbEntity,
  IModelIncludeOpts,
  graphQLMutation,
  createVote,
  deleteVote
} from '../../internal';

export interface IWordVote {
  wordId: string;
  teamId?: string
}

export class WordVote extends DbEntity {
  wordId?: string;
  teamId?: string;

  constructor(opts?: IWordVote) {
    super(opts);
    this.wordId = opts?.wordId;
    this.teamId = opts?.teamId;
  }

  async forApi(opts?: IModelIncludeOpts): Promise<any> {
    const superJson = await super.forApi(opts);
    var json = {
      ...superJson,
      wordId: this.wordId,
      teamId: this.teamId
    }
    return json;
  }

  static async saveNew(voteToSave: WordVote): Promise<string> {
    const voteEntity = await voteToSave!.forApi({ save: true })
    await graphQLMutation(createVote, voteEntity);
    return voteEntity.id;
  }

  static async saveDelete(voteIdToDelete: string): Promise<void> {
    await graphQLMutation(deleteVote, { id: voteIdToDelete });
    return;
  }



}
