import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import Countdown from 'react-countdown';
import {
  ConditionalRender,
  GameContext,
  EGamePhases,
  DarkModeToggle
} from '../internal';

interface IProps {

}

export const Footer: FC<IProps> = ({ }) => {
  return (
    <Row justify="around" className={classnames('footer')}>
      <Col xs={5} sm={4}>
        <Row>
          <Col xs={12}>
            <h4>Enjoy the game!</h4>
          </Col>
          <Col xs={12}>
            <h4>
              <Link to="/pages/support">
                Support the Developer
              </Link>
            </h4>
          </Col>
          <Col xs={12}>
            <h4><a href="https://twitter.com/BannedWordsFun" target="_blank">
              Follow on "Twitter"
            </a></h4>
          </Col>
        </Row>
      </Col>

      <Col xs={5} sm={4} className={classnames('text-right')}>
        <Row>
          <Col xs={12}>
            <h4>
              <Link to="/pages/howToPlay">
                How To Play
              </Link>
            </h4>
          </Col>
          <Col xs={12}>
            <h4><Link to="/pages/changes">
              Recent Updates
            </Link></h4>
          </Col>
          <Col xs={12}>
            <h4>
              <Link to="/pages/future">
                Coming Soon
              </Link>
            </h4>
          </Col>

        </Row>
      </Col>

      <Col xs={12}>
        <Row justify="center">
          <h4>
            <span className="mini-margin">
              <DarkModeToggle />
            </span>
            <span className="mini-margin">
              <Link to="/">Banned Words Online</Link>
            </span>
            <span className="mini-margin">
              {new Date().getFullYear()}
            </span>
          </h4>
        </Row>
      </Col>
    </Row >
  )
}
