import { ThemeSet } from '../../themeSet'

import baseNouns from '../nounSet';
import baseAdjs from '../adjSet';

// word themes
import celebrities from './celebrities';
import technology from './technology';
import countries from './countries';
import family from './family';
import holidays from './holidays';
import household from './household';
import movies from './movies';
import sports from './sports';
import irish from './stPattys';
import transportation from './transportation';
import jobs from './jobs';
import circus from './circus';
import historicalFigures from './historicalFigures';
import historicalEvents from './historicalEvents';
import backyard from './backyard';
import money from './money';
import tools from './tools';
import summer from './summer';
import pictionary from './pictionary';
import birthday from './birthday';
import cooking from './cooking';
import camping from './camping';
import spring from './spring';
import science from './science';
import humanBody from './humanBody';
import time from './time';
import containers from './containers';
import military from './military';
import animals from './animals';
import food from './food';
import harryPotter from './harryPotter';
import starWars from './starWars';
import disney from './disney';
import nintendo from './nintendo';
import christmas from './christmas';
import companies from './companies';
import resturants from './resturants';
import weather from './weather';
import superheroes from './superheroes';
import geography from './geography';
import collegeSports from './collegeSports';
import nba from './nba';
import mlb from './mlb';
import mls from './mls';
import nhl from './nhl';
import nfl from './nfl';
import business from './business';
import candies from './candies';
import pirates from './pirates';
import school from './school';
import musicalInstruments from './musicalInstruments';
import carnivals from './carnivals';
import clothing from './clothing';
import biology from './biology';
import medieval from './medieval';
import tvshows from './tvshows';
import halloween from './halloween';
import autumn from './autumn';
import thanksgiving from './thanksgiving';
import hanukkah from './hanukkah';
import winter from './winter';
import spa from './spa';
import medical from './medical';
import shapes from './shapes';

export const themes = [
  baseNouns,
  baseAdjs,
  family,
  celebrities,
  technology,
  countries,
  holidays,
  household,
  movies,
  sports,
  irish,
  transportation,
  jobs,
  circus,
  historicalFigures,
  historicalEvents,
  backyard,
  money,
  tools,
  summer,
  pictionary,
  birthday,
  cooking,
  camping,
  spring,
  science,
  humanBody,
  time,
  containers,
  military,
  animals,
  food,
  harryPotter,
  starWars,
  disney,
  nintendo,
  christmas,
  companies,
  resturants,
  weather,
  superheroes,
  geography,
  collegeSports,
  nba,
  mlb,
  mls,
  nhl,
  nfl,
  business,
  candies,
  pirates,
  school,
  musicalInstruments,
  carnivals,
  clothing,
  biology,
  medieval,
  tvshows,
  halloween,
  autumn,
  thanksgiving,
  hanukkah,
  winter,
  spa,
  medical,
  shapes,
].sort((a: ThemeSet, b: ThemeSet) => {
  // alphabatize for visual niceties
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
});