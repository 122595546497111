import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "circle",
  "cone",
  "cube",
  "heart",
  "dot",
  "triangle",
  "kite",
  "line",
  "point",
  "ray",
  "rectangle",
  "square"
])

export const medium = new Set([
  "acute triangle",
  "arc",
  "asymmetry",
  "crescent",
  "curve",
  "cylinder",
  "decagon",
  "disc",
  "disk",
  "ellipse",
  "equilateral triangle",
  "helix",
  "hexagon",
  "hyperbola",
  "interval",
  "isosceles triangle",
  "möbius strip",
  "obtuse triangle",
  "octagon",
  "orb",
  "parabola",
  "parallelogram",
  "pentagon",
  "plane",
  "polygon",
  "prism",
  "quadrilateral",
  "rhombus",
  "right triangle",
  "semicircle",
  "symmetry",
  "trapezoid",
  "wedge",
]);

const hard = new Set([
  "cardioid",
  "concave",
  "convex",
  "cuboid",
  "dodecahedron",
  "heptagon",
  "hexaflexagon",
  "hexahedron",
  "Klein bottle",
  "line segment",
  "nonagon",
  "paraboloid",
  "parallelepiped",
  "Platonic solid",
  "regular polygon",
  "polyhedra",
  "scalene triangle",
  "sector",
  "shapeless",
  "spheroid",
  "tesseract",
  "tetrahedron",
  "trapezium"
])


export default new ThemeSet('Shapes',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });
  
  
