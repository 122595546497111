import { ThemeSet, EDifficulty } from '../../index';

export const easy = new Set([
  "Big Hero 6",
  "Zootopia",
  "Bambi",
  "Coco",
  "Frozen",
  "Shrek",
  "The Lion King",
  "Aladdin",
  "Mickey Mouse",
  "Minnie Mouse",
  "Donald Duck",
  "Snow White",
  "Captain Hook",
  "Mary Poppins",
  "Mr. Banks",
  "Winnie the Pooh",
  "Ariel",
  "Aladdin",
  "Princess Jasmine",
  "The Genie",
  "Magic Carpet",
  "Pocahontas",
  "Beauty and the Beast",
  "Toy Story",
  "Woody",
  "Buzz Lightyear",
  "Mr. Potato Head",
  "Mulan",
  "Tarzan",
  "Monsters, Inc.",
  "Monsters University",
  "Lilo & Stitch",
  "Finding Nemo",
  "Ellen",
  "Dory",
  "Nemo",
  "Gill",
  "Pirates of the Caribbean",
  "Jack Sparrow",
  "The Incredibles",
  "Mr. Incredible",
  "Chicken Little",
  "Tangled",
  "Brave",
  "Wreck-It Ralph",
  "Fix-It Felix",
  "Inside Out",
  "Moana",
]);

const medium = new Set([
  "Scrooge McDuck",
  "Goofy",
  "Pluto",
  "Oswald the Lucky Rabbit",
  "Dopey",
  "Grumpy",
  "Sleepy",
  "Sneezy",
  "Christopher Robin",
  "Jafar",
  "Simba",
  "Stinky Pete",
  "Bo Peep",
  "Bullseye",
  "Slinky dog",
  "Hercules",
  "A Bug's Life",
  "The Emperor's New Groove",
  "Elastigirl",
  "Syndrome",
  "Bob Parr",
  "Violet Parr",
  "Edna Mode",
  "Frozone",
  "Lightning McQueen",
  "Ratatouille",
  "The Princess and the Frog",
  "Vanellope Von Schweetz",
  "Judy Hopps",
  "King Candy",
  "Kristoff",
  "Baymax",
  "Maui",
  "Ernesto De La Cruz"

]);

const hard = new Set([

]);



export default new ThemeSet('Disney',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);