import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  "age",
  "bake",
  "barbecue",
  "batter",
  "beat",
  "bind",
  "blacken",
  "blend",
  "boil",
  "bone",
  "bread",
  "brew",
  "broil",
  "brown",
  "brush",
  "burn",
  "butterfly",
  "can",
  "char",
  "char-broil",
  "chill",
  "chop",
  "chunk",
  "churn",
  "clarify",
  "combine",
  "cool",
  "core",
  "cream",
  "cured",
  "cut",
  "deep fry",
  "devil",
  "dice",
  "dip",
  "dough",
  "drain",
  "drizzle",
  "dry",
  "ferment",
  "filter",
  "flavor",
  "flip",
  "fold",
  "freeze",
  "French fry",
  "frost",
  "froth",
  "fry",
  "gel",
  "glaze",
  "grate",
  "grease",
  "grill",
  "grind",
  "hard boil",
  "hash",
  "heat",
  "hull",
  "ice",
  "juice",
  "knead",
  "layer",
  "marinate",
  "mash",
  "measure",
  "melt",
  "microwave",
  "mix",
  "mold",
  "oil",
  "oven fry",
  "overcook",
  "pan fry",
  "peel",
  "pickle",
  "pit",
  "poach",
  "pop",
  "pour",
  "precook",
  "prepare",
  "preserve",
  "press",
  "pressure-cook",
  "process",
  "pulp",
  "puree",
  "quarter",
  "raw",
  "reduce",
  "refrigerate",
  "render",
  "rise",
  "roast",
  "roll",
  "rub",
  "salt",
  "saute",
  "scald",
  "scallop",
  "scoop",
  "score",
  "scramble",
  "sear",
  "season",
  "separate",
  "serve",
  "shell",
  "shred",
  "shuck",
  "sift",
  "simmer",
  "skewer",
  "skim",
  "skin",
  "slice",
  "sliver",
  "slow cook",
  "smoke",
  "snip",
  "soak",
  "soft boil",
  "sprinkle",
  "steam",
  "steep",
  "stew",
  "stir",
  "stir fry",
  "strain",
  "stuff",
  "sweat",
  "sweeten",
  "tenderize",
  "thicken",
  "thin",
  "toast",
  "top",
  "toss",
  "trim",
  "turn",
  "warm",
  "wash",
  "wedge",
  "whip",
  "whisk",
  "zap",
  "zest",
])

const hard = new Set([
  "aerate",
  "baste",
  "braise",
  "caramelize",
  "congeal",
  "fillet",
  "garnish",
  "infuse",
  "parboil",
  "pare",
  "percolate",
  "sieve",
  "temper",
  "truss",
  "uncured",
  "unmold",
])


export default new ThemeSet('Cooking',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);