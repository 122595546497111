import { useState, useEffect } from 'react';

export const useSet = (initialS: any) => {
  var [myS, setMyS] = useState<any>(initialS);
  var [sUpdator, setSUpdator] = useState(0);

  // useEffect(() => {
  //   function handleStatusChange(status) {
  //     setIsOnline(status.isOnline);
  //   }

  // });

  const customSetMyS = (s: any) => {
    setSUpdator(Date.now());
    setMyS(s);
  }

  return [myS, customSetMyS, sUpdator];
}