import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import {
  ConditionalRender,
  Footer,
  Header
} from '../../internal';



import dailyPlanetImg from '../../assets/membership/dailyplanet.jpeg'
import robinImg from '../../assets/membership/robin.webp'
import alfredImg from '../../assets/membership/alfred.webp'
import oracleImg from '../../assets/membership/oracle.webp'
import batmanImg from '../../assets/membership/batman.webp'
import wayneImg from '../../assets/membership/wayne.webp'

interface IProps {

}

interface ISupporter {
  name: string,
  linkTo?: string
}

const oneTimeSupporters = [
  { name: 'lionoil', linkTo: '' },
  { name: '@theshywizard', linkTo: 'https://twitter.com/theshywizard' }
];
const robinSupporters = [];
const alfredSupporters = [];
const oracleSupporters = [];
const batmanSupporters = [];
const wayneSupporters = [];


export const Supporters: FC<IProps> = ({ }) => {


  const supportersDisplay = (currSupporter: ISupporter, sIndex: number) => {
    return (
      <div className="supporter-person-container" key={`${sIndex}-supporter`}>
        <ConditionalRender visible={!!currSupporter.linkTo}>
          <a href={currSupporter.linkTo} target="blank">
            <div className="supporter-person-name">
              {currSupporter.name}
            </div>
          </a>
        </ConditionalRender>
        <ConditionalRender visible={!currSupporter.linkTo}>
          <div className="supporter-person-name">
            {currSupporter.name}
          </div>
        </ConditionalRender>
        
      </div>
    )
  }

  return (
    <>
      <Header />
      <Container>
        <div className="pages-container">
          <Row className={classnames('support-container')}>
            <Col xs={12}>
              <h2>Supporters</h2>
              <h4>I want to take a moment to thank all the supporters of this project. I am truly grateful to everyone who shares the game, provides feedback/encouragement, or financial support.</h4> 
              <h4>Thank you!</h4>
            </Col>

            <Col xs={12}>

              {/* WAYNE SUPPORTERS */}
              <ConditionalRender visible={wayneSupporters.length > 0}>
                <Row className="support-tier-block">
                  <Col xs={12}>
                    <div className="membership-title">
                      <h3 className="upper">Wayne Enterprises Level Recurring Supporters</h3>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="membership-banner-graphic">
                      <img src={wayneImg} />
                    </div>
                  </Col>

                  <Col xs={12}>
                    {wayneSupporters.map(supportersDisplay)}
                  </Col>
                </Row>
              </ConditionalRender>

              {/* BATMAN SUPPORTERS */}
              <ConditionalRender visible={batmanSupporters.length > 0}>
                <Row className="support-tier-block">
                  <Col xs={12}>
                    <div className="membership-title">
                      <h3 className="upper">Batman Level Recurring Supporters</h3>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="membership-banner-graphic">
                      <img src={batmanImg} />
                    </div>
                  </Col>

                  <Col xs={12}>
                    {batmanSupporters.map(supportersDisplay)}
                  </Col>
                </Row>
              </ConditionalRender>


              {/* ORACLE SUPPORTERS */}
              <ConditionalRender visible={oracleSupporters.length > 0}>
                <Row className="support-tier-block">
                  <Col xs={12}>
                    <div className="membership-title">
                      <h3 className="upper">Oracle Level Recurring Supporters</h3>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="membership-banner-graphic">
                      <img src={oracleImg} />
                    </div>
                  </Col>

                  <Col xs={12}>
                    {oracleSupporters.map(supportersDisplay)}
                  </Col>
                </Row>
              </ConditionalRender>


              {/* ALFRED SUPPORTERS */}
              <ConditionalRender visible={alfredSupporters.length > 0}>
                <Row className="support-tier-block">
                  <Col xs={12}>
                    <div className="membership-title">
                      <h3 className="upper">Alfred Level Recurring Supporters</h3>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="membership-banner-graphic">
                      <img src={alfredImg} />
                    </div>
                  </Col>

                  <Col xs={12}>
                    {alfredSupporters.map(supportersDisplay)}
                  </Col>
                </Row>
              </ConditionalRender>

              {/* ROBIN SUPPORTERS */}
              <ConditionalRender visible={robinSupporters.length > 0}>
                <Row className="support-tier-block">
                  <Col xs={12}>
                    <div className="membership-title">
                      <h3 className="upper">Robin Level Recurring Supporters</h3>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="membership-banner-graphic">
                      <img src={robinImg} />
                    </div>
                  </Col>

                  <Col xs={12}>
                    {robinSupporters.map(supportersDisplay)}
                  </Col>
                </Row>
              </ConditionalRender>

              {/* ONE-TIME SUPPORTERS */}
              <ConditionalRender visible={oneTimeSupporters.length > 0}>
                <Row className="support-tier-block">
                  <Col xs={12}>
                    <div className="membership-title">
                      <h3 className="upper">Supporters</h3>
                    </div>
                  </Col>
                  
                  <Col xs={12}>
                    <div className="membership-banner-graphic">
                      <img src={robinImg} />
                    </div>
                  </Col>

                  <Col xs={12}>
                    {oneTimeSupporters.map(supportersDisplay)}
                  </Col>
                </Row>
              </ConditionalRender>


            </Col>
          </Row>

        </div>
      </Container>
      <Footer />
    </>
  )
}
