import {
  IGqlCollection,
  ITeam
} from '../internal';

export enum EGamePhases {
  unknown,
  cwEntry,
  voting,
  preRedGuess,
  redGuess,
  preBlueGuess,
  blueGuess,
  results,
  preNewRound
}

export enum EGameMode {
  unknown = 'unknown',
  doubleValueWords = 'doubleValueWords',
  bombWords = 'bombWords',
  earnTime = 'earnTime',
  oneAtATime = 'oneAtATime',
  sacrifice = 'sacrifice'
}

export interface IGame {
  id: string,
  code: string,
  phase: EGamePhases,
  phaseEndTime?: number,
  writeMs?: number,
  voteMs?: number,
  guessMs?: number,
  earnTimeMs?: number,
  sacrificeBonusMs?: number,
  modes?: EGameMode[],
  teams?: IGqlCollection<ITeam>,
  wordSetsOn?: string[],
  usedTargets?: number[],
  customTargets?: string[],
  newRoundRequest?: number,
  updater?: number,
  location?: string,
  createdAt?: string,
  updatedAt?: string
}

export enum EWordType {
  cw,
  bw,
  tw,
}

