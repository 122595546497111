import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "Mario",
  "Luigi",
  "Donkey Kong",
  "Bowser",
  "Princess Peach",
  "Pokémon",
  "Mario Party",
  "Mario Kart",
])

const medium = new Set([
  "Toad",
  "Mega Man",
  "Yoshi",
  "Ice Climbers",
  "Princess Daisy",
  "Diddy Kong",
  "Toadette",
  "Birdo",
  "Bowser Jr.",
  "Wario",
  "Waluigi",
  "Koopa",
  "Item Box",
  "The Legend of Zelda",
  "Super Smash Bros.",
  'Metroid Prime',
  'Super Mario Sunshine',
  'Tetris',
  'GoldenEye',
  '007',
  'Street Fighter',
  'Star Fox',
  "metroid",
  "Sonic",
  "GameBoy Color",
  "GameBoy Advance",
  "Game Cube",
  "Mario Tennis",
  "Wii",
  "Wii U",
  "Switch",
  "Mushroom",
  "Mega Mushroom",
  "mini Mushroom",
  "gold coin",
  "star coin",
  "poke ball",
  "Home-run bat",
  "soccer ball",
  "barrel",
  "bullet bill",
  "warp star",
  "sandbag",
  "bomb-omb",
  "red shell",
  "green shell",
  "blue shell",
]);


const hard = new Set([
  "Ocarina of Time",
  'Super Mario 64',
  "Joycon",
  "Toadsworth",
  "Maxim Tomato",
  "Mario Kart Double Dash",
  "Mario Kart 64",
  "fire flower",
  "Breath of the Wild",
  'A Link to the Past',
  "Super Metroid",
  "Donkey Kong Country",
  "Dr. Mario",
  "Bayonetta",
  "Duck Hunt",
  "Fire Emblem",
  "F-Zero",
  "Luigi's Mansion",
  "Mario Super Sluggers",
])


export default new ThemeSet('Nintendo',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);