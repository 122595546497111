import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "flowers",
  "ice cream",
  "ocean",
  "outside",
  "park",
  "picnic",
  "play",
  "popsicle",
  "sunscreen",
  "swimsuit",
  "trip",
  "vacation",
  "watermelon",
])

const medium = new Set([
  "air conditioner",
  "August",
  "backpacking",
  "beach",
  "berries",
  "bikini",
  "boating",
  "camp",
  "camping",
  "canoeing",
  "daisy",
  "diving",
  "fan",
  "fresh fruit",
  "Frisbee",
  "gardening",
  "grass",
  "heat",
  "hiking",
  "holiday",
  "hot",
  "humidity",
  "Independence Day",
  "journey",
  "July",
  "July fourth",
  "June",
  "lightning",
  "muggy",
  "outdoors",
  "outings",
  "recreation",
  "relax",
  "rest",
  "road trip",
  "rose",
  "sailing",
  "sandals",
  "sandcastle",
  "sea",
  "seashore",
  "season",
  "shorts",
  "showers",
  "sightseeing",
  "summer",
  "summer solstice",
  "sundress",
  "sunflower",
  "sunhat",
  "sunny",
  "sweltering",
  "swimming cap",
  "tan",
  "thunder",
  "thunderstorm",
  "travel",
  "trunks",
  "visit",
  "voyage",
  "warm weather",
  "waterpark",
  "waterski",
]);

const hard = new Set([

])


export default new ThemeSet('Summer',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);