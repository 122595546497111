import React, { createContext, useReducer, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Promise from 'bluebird';
import {
  Game,
  Team,
  IGame,
  TimeSync,
  MultiSync
} from '../internal';

export interface IGameContext {
  state: IGameState,
  dispatch: any
}
export interface IGameState {
  game?: Game,
  myTeam?: Team,
  oppTeam?: Team,
  redTeam?: Team,
  blueTeam?: Team,
  isPlayerClueGiver: boolean,
  helpOn: boolean,
  clientSync: MultiSync
}
// export const GameContext = createContext<IGameContext>({
//     currGame: undefined
// });

const initialState: any = {
  isPlayerClueGiver: false,
  helpOn: undefined,
  clientSync: new MultiSync()
};
export const GameContext = createContext<IGameContext>(initialState);

export const GameProvider = ({ children }) => {
  const { gameCode } = useParams<any>();
  const [state, dispatch] = useReducer((state, action) => {

    // console.log(`[reducer] action: ${action.type}`);
    switch (action.type) {
      case 'setGame':
        // console.log('\t[set] is game type: ' + !!(action.game?.getOppositeTeam));
        return {
          ...state,
          game: action.game
        }
      case 'modifyGame':
        // console.log('\t[modify] is game type: ' + !!(action.game?.getOppositeTeam));
        return {
          ...state,
          game: action.game,
        }
      // case 'updateGamePhase':
      //   // only update the phase and the new end time
      //   // game.setPhaseData(action.phase, action.phaseEndTime);
      //   return {
      //     ...state,
      //     game: action.game
      //   }
      case 'setMyTeam':
      case 'modifyMyTeam':
        return {
          ...state,
          myTeam: action.team,
        }
      case 'setRedTeamRoundSacrificeVal':
        var redTeam = state.redTeam;
        redTeam.round.sacrificed = action.sacrificed;
        return {
          ...state,
          redTeam
        }
      case 'setOppTeam':
      case 'modifyOppTeam':
        return {
          ...state,
          oppTeam: action.team
        }
      case 'setBlueTeamRoundSacrificeVal':
        var blueTeam = state.blueTeam;
        blueTeam.round.sacrificed = action.sacrificed;
        return {
          ...state,
          blueTeam
        }
      case 'changePlayerClueGiver':
        return {
          ...state,
          isPlayerClueGiver: action.isClueGiver
        }
      case 'setHelp':
        return {
          ...state,
          helpOn: !!action.help
        }
      // used for round results and spectators only
      case 'setRedTeam':
        return {
          ...state,
          redTeam: action.team
        }
      // used for round results and spectators only
      case 'setBlueTeam':
        return {
          ...state,
          blueTeam: action.team
        }
      case 'addSync':
        return {
          ...state,
          clientSync: state.clientSync.addRecent(action.sync)
        }
      default:
        return state;
    };
  }, initialState);


  useEffect(() => {
    (async () => {
      var timeSync = await checkPing();
      var initialMultiSyncForTimeOffset = new MultiSync().addRecent(timeSync!);
      const foundGame = await Game.findByCode(gameCode, initialMultiSyncForTimeOffset);
      dispatch({ type: 'setGame', game: foundGame });
      calcTimeSync();
    })();
  }, [gameCode])

  const calcTimeSync = () => {
    // first one is always longer so it will be
    // thrown out when only considering the 3 most recent
    Promise.mapSeries([...Array(4)], checkPing)

    // check the ping every 30 seconds
    setInterval(checkPing, 1000*30);
  }

  const checkPing = async () => {
    const sentSyncId = await TimeSync.saveNew();
    const foundSync = await TimeSync.findById(sentSyncId);
    dispatch({ type: 'addSync', sync: foundSync });
    return foundSync;
  }

  return (
    <GameContext.Provider value={{ state, dispatch }}>
      {children}
    </GameContext.Provider>
  );
};