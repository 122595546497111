import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import Countdown from 'react-countdown';

import {
  FaHome
} from 'react-icons/fa'

import {
  ConditionalRender,
  GameContext,
  EGamePhases,
  DarkModeToggle
} from '../internal';
import { getRandomNumBetween } from 'helpers';

interface IProps {

}

export const GameFooter: FC<IProps> = ({ }) => {
  const { gameCode } = useParams<any>();

  var { state: { game, clientSync }, dispatch } = useContext(GameContext);

  return (
    <Row justify="between" className={classnames('game-footer')}>
      <Col xs={9} sm={6}>
        <Row justify="start">
          {/* unikey vame - smaller support link */}
          <ConditionalRender visible={(game?.code?.indexOf('unikey') ?? -1) !== -1}>
            <Col xs={3} sm={2}>
              <Link to="/" className="coffee-link home-icon">
                <FaHome />
              </Link>
            </Col>
            <Col xs={9} sm={10}>
              <Link to="/">
                <h4 style={{ marginLeft: '-8px' }}><em>Banned Words <Hidden xs>Online</Hidden></em></h4>
              </Link>
            </Col>
          </ConditionalRender>

          {/* footer for non-unikey games  - larger suppot link */}
          <ConditionalRender visible={(game?.code?.indexOf('unikey') ?? -1) === -1}>
            <Col xs={3} sm={2}>
              <Link to="/" className="coffee-link home-icon">
                <FaHome />
              </Link>
            </Col>
            <Col xs={9} sm={10}>
              <Link to="/pages/support" className="coffee-link">
                <h4 className={classnames('donate', { attention: game?.phase === EGamePhases.results })}>
                  <em>
                    {/* <Hidden xs sm md lg>Send the developer a buck! (Please!)</Hidden> */}
                    <Visible xl xxl>Donate $1 to the Developer</Visible>
                    <Visible lg>Buy the Developer a Coffee</Visible>
                    <Visible md>Support the Developer</Visible>
                    <Visible sm>Share some love</Visible>
                    <Visible xs>Donate $1</Visible>
                  </em>
                </h4>
              </Link>
            </Col>
          </ConditionalRender>

        </Row>
      </Col>
      <Col xs={2} sm={5} className={classnames('text-right', 'no-pad')}>
        <h4>
          {/* <span>p[{clientSync?.getAvgPing()}]o[{clientSync.getAvgOffset()}] </span> */}
          <ConditionalRender visible={!!game?.selectedTeam && game?.selectedTeam?.name !== 'spectator'}>
            <span><Hidden sm xs>You are on the </Hidden><strong>{game?.selectedTeam?.name?.split(' ')?.[0]}<Hidden xs> Team</Hidden></strong></span>
          </ConditionalRender>

          <ConditionalRender visible={!!game?.selectedTeam && game?.selectedTeam?.name === 'spectator'}>
            <Hidden xs sm>bannedwords.fun/</Hidden>{game?.code}
          </ConditionalRender>
        </h4>
      </Col>

      <Col xs={1} className="no-pad">
        <DarkModeToggle />
      </Col>
    </Row>
  )
}
