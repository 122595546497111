import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "afraid",
  "bat",
  "black cat",
  "blood",
  "bone",
  "boo",
  "broomstick",
  "candy",
  "cape",
  "cat",
  "crown",
  "dark",
  "face paint",
  "fairy",
  "fall",
  "firefighter",
  "flashlight",
  "ghost",
  "hat",
  "magic",
  "make-believe",
  "moon",
  "night",
  "October",
  "orange",
  "owl",
  "pumpkin",
  "scarecrow",
  "scream",
  "shadow",
  "skull",
  "spider",
  "spider web",
])

const medium = new Set([
  "afterlife",
  "alarming",
  "alien",
  "angel",
  "ballerina",
  "beast",
  "bizarre",
  "bogeyman",
  "cackle",
  "cadaver",
  "carve",
  "casket",
  "cauldron",
  "cemetery",
  "chilling",
  "cloak",
  "clown",
  "cobweb",
  "coffin",
  "creepy",
  "corpse", 
  "costume",
  "crypt",
  "darkness",
  "dead",
  "death",
  "demon",
  "devil",
  "devilish",
  "disguise",
  "dreadful",
  "dress-up",
  "eerie",
  "elf",
  "enchant",
  "apparition",
  "bloodcurdling",
  "evil",
  "eyeballs",
  "eyepatch",
  "fangs",
  "fantasy",
  "fear",
  "fog",
  "fright",
  "frighten",
  "frightening",
  "frightful",
  "genie",
  "ghastly",
  "ghostly",
  "ghoul",
  "ghoulish",
  "goblin",
  "goodies",
  "gory",
  "gown",
  "grave",
  "gravestone",
  "grim",
  "Grim Reaper",
  "grisly",
  "gruesome",
  "hair-raising",
  "Halloween",
  "haunt",
  "hayride",
  "haunted house",
  "headstone",
  "hobgoblin",
  "hocus pocus",
  "horrible",
  "horrify",
  "howl",
  "imp",
  "jack-o'-lantern",
  "jumpsuit",
  "kimono",
  "king",
  "lantern",
  "macabre",
  "magic wand",
  "make-up",
  "mask",
  "masquerade",
  "mausoleum",
  "midnight",
  "mist",
  "monster",
  "moonlight",
  "moonlit",
  "morbid",
  "mummy",
  "mysterious",
  "nightmare",
  "ninja",
  "ogre",
  "otherworldly",
  "party",
  "petrify",
  "phantasm",
  "phantom",
  "pitchfork",
  "poltergeist",
  "potion",
  "prank",
  "pretend",
  "pumpkin pie",
  "pumpkin patch",
  "pumpkin carving",
  "repulsive",
  "revolting",
  "RIP",
  "robe",
  "robot",
  "scare",
  "scary",
  "shadowy",
  "shock",
  "shocking",
  "skeleton",
  "soldier",
  "specter",
  "spell",
  "spirit",
  "spook",
  "spooky",
  "startling",
  "strange",
  "superhero",
  "supernatural",
  "superstition",
  "sweets",
  "tarantula",
  "terrible",
  "terrify",
  "thirty-first",
  "thrilling",
  "tiara",
  "toga",
  "tomb",
  "tombstone",
  "treat",
  "treats",
  "trick",
  "trick-or-treat",
  "troll",
  "Halloween Horror Nights",
  "Mickey's Not-So-Scary Halloween",
  "Howl-O-Scream",
  "witch",
  "witchcraft",
  "The Addams Family",
  "Hocus Pocus",
  "Casper: ",
  "Friday the 13th",
  "Ghostbusters"
]);


const hard = new Set([
  "spine-chilling",
  "Michael Myers",
  "Jason Voorhees",
  "Freddy Krueger",
  "Leatherface",
  "Jigsaw",
  "Pennywise",
  "Chucky",
  "Pinhead",
  "The Thing",
  "Ghostface",
  "Candyman",
  "A Nightmare on Elm Street",
  "The Haunted Mansion",
  "Labryinth",
  "Beetlejuice",
  "Edward Scissorhands",
  "The Exorcist",
  "Rocky Horror Picture Show",
  "The Blair Witch Project",
  "Paranormal Activity",
  "The Nightmare Before Christmas",
  "Sleepy Hollow",
  "The Silence of the Lambs",
  "Psycho"
]);


export default new ThemeSet('Halloween',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);