import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "April Fool's Day",
  "Christmas",
  "Easter",
  "Election Day",
  "Father's Day",
  "Groundhog Day",
  "Halloween",
  "July 4",
  "Labor Day",
  "Mother's Day",
  "New Year's Day",
  "New Year's Eve",
  "Pi Day",
  "Presidents' Day",
  "St. Patrick's Day",
  "Thanksgiving",
  "Valentine's Day",
]);


const medium = new Set([
  "Juneteenth",
  "Arbor Day",
  "Chinese New Year",
  "Cinco de Mayo",
  "Columbus Day",
  "Day of the Dead",
  "Flag Day",
  "Hanukkah",
  "Independence Day",
  "Lincoln's Birthday",
  "Mardi Gras",
  "MLK Jr. Day",
  "Memorial Day",
  "Passover",
  "Veteran's Day",
]);


const hard = new Set([

]);



export default new ThemeSet('Holidays',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);