import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);

const medium = new Set([
  "backpack",
  "bag",
  "baggage",
  "barrel",
  "basket",
  "beaker",
  "bin",
  "bottle",
  "bowl",
  "box",
  "briefcase",
  "bucket",
  "cabinet",
  "can",
  "canteen",
  "carrier",
  "carton",
  "cask",
  "chest",
  "cone",
  "container",
  "cooler",
  "crate",
  "crock",
  "cube",
  "dish",
  "drawer",
  "drum",
  "enclosure",
  "envelope",
  "sewer",
  "file folder",
  "flask",
  "folder",
  "garbage can",
  "glass",
  "goblet",
  "grocery bag",
  "hamper",
  "handbag",
  "holder",
  "hopper",
  "hutch",
  "ice bucket",
  "jar",
  "jug",
  "knapsack",
  "locker",
  "luggage",
  "lunchbox",
  "milk crate",
  "packet",
  "pail",
  "pan",
  "paper bag",
  "plastic bag",
  "plate",
  "pocket",
  "pod",
  "pot",
  "pouch",
  "purse",
  "rack",
  "sack",
  "saucer",
  "suitcase",
  "test tube",
  "tin",
  "tote",
  "trash bin",
  "tray",
  "trunk",
  "tub",
  "tube",
  "vase",
  "vat",
  "vault",
  "vesicle",
  "vessel",
  "vial",
  "water bottle"
])

const hard = new Set([
  "canister",
  "saddlebag",
  "satchel",
  "carafe",
  "cruet",
  "depository",
  "basin",
])

export default new ThemeSet('Containers',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);