import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);

const medium = new Set([
  "LA galaxy",
  "Seattle Sounders",
  "Save the Crew",
  "Chicago Fire",
  "Houston Dynamo",
  "field",
  "Goal",
  "Foul",
  "penalty kick",
  "whistle",
  "halftime",
  "goal kick",
  "soccer ball",
])

const hard = new Set([
  "Throw-in",
  "Pitch",
  "Corner Kick",
  "Free Kick",
  "Penalty Kick",
  "Futbol",
  "Orlando City SC",
  "Atlanta United",
  "Toronto FC",
  "Philadelphia Union",
  "Portland Timbers",
  "Columbus Crew",
  "Sporting Kansas City",
  "New England Revolution",
  "DC United",
  "Inter Miami",
  "Nashville FC",
  "Vancouver Whitecaps",
  "Clint Dempsey",
  "Landon Donovan",
  "David Villa",
  "Kaká",
  "Wayne Rooney",
  "Zlatan Ibrahimovic",
  "David Beckham",
])



export default new ThemeSet('MLS',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });