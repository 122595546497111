import { Promise } from 'bluebird'

import {
  sfx, ESoundName,
  EGamePhases,
  EGameMode,
  IGame,
  ITeam,
  IModelIncludeOpts,
  Team,
  Word,
  MultiSync,
  getRandomNumBetween,
  DbEntity,
  graphQLMutation,
  createGame,
  updateGame,
  graphQLQuery,
  gameByCodeWithTeam,
  Round
} from '../internal';

// non-mistakable characters
const gameCodeChars = 'abcdefghijkmnpqrstuvwxyz23456789'.split('');
export class Game extends DbEntity {
  code: string;
  phase: EGamePhases;
  modes: EGameMode[];
  wordSetsOn: string[];
  usedTargets: number[];
  customTargets: string[];
  writeMs?: number;
  voteMs?: number;
  guessMs?: number;
  earnTimeMs?: number;
  sacrificeBonusMs?: number;
  teams?: Team[];
  updater?: number;
  phaseEndTime?: number;
  adjustedPhaseEndTime?: number;
  newRoundRequest?: number;
  location?: string;

  private numTeams: number;
  public selectedTeam?: Team;

  public static defaultVoteMs = 1000 * 60;
  public static defaultWriteMs = 1000 * 70;
  public static defaultGuessMs = 1000 * 90;
  public static defaultEarnTimeAdditionMs = 1000 * 15;
  public static defaultEarnTimeStartMs = 1000 * 55;
  public static defaultSacrificeBonusMs = 1000 * 30;
  public static preNewRoundWait = 1000 * 3;
  public static bombDeduction = 2;
  public static redTeamName = 'Red Team';
  public static blueTeamName = 'Blue Team';
  // halloween mode
  // public static redTeamDisplayName = 'Orange Team';
  // public static blueTeamDisplayName = 'Purple Team';


  constructor(clientSync: MultiSync,opts?: IGame) {
    super(opts);
    this.numTeams = 2;

    this.code = opts?.code ?? this.genGameCode(4);
    this.phase = opts?.phase ?? EGamePhases.unknown;
    this.wordSetsOn = opts?.wordSetsOn ?? [];
    this.usedTargets = opts?.usedTargets ?? [];
    this.customTargets = opts?.customTargets ?? [];
    this.writeMs = opts?.writeMs ?? Game.defaultWriteMs;
    this.voteMs = opts?.voteMs ?? Game.defaultVoteMs;
    this.guessMs = opts?.guessMs ?? Game.defaultGuessMs;
    this.earnTimeMs = opts?.earnTimeMs ?? Game.defaultEarnTimeAdditionMs;
    this.modes = opts?.modes ?? [];
    this.createdAt = opts?.createdAt ?? '';

    this.updater = opts?.updater;
    this.newRoundRequest = opts?.newRoundRequest;
    this.phaseEndTime = opts?.phaseEndTime;
    this.adjustedPhaseEndTime = Game.adjustIncomingPhaseEndTime(clientSync, opts?.phaseEndTime ?? 0);

    this.teams = opts?.teams?.items?.map((t: ITeam) => new Team(t)) ?? [];
  }

  setCode(desiredCode: string) {
    this.code = desiredCode;
    return this;
  }

  setMyTeam(team: Team) {
    this.selectedTeam = team;
    return this;
  }

  getMyTeam(): Team | undefined {
    return this.selectedTeam;
  }

  getOppositeTeam(): Team | undefined {
    var oppTeam;
    this.teams?.some((t: Team) => {
      oppTeam = t;
      return t.id != this.selectedTeam!.id;
    })
    return oppTeam;
  }

  hasGameMode(mode: EGameMode) {
    return this.modes.indexOf(mode) >= 0;
  }

  getPrettyGameModeNames(): string[] {
    return this.modes.map((mode: EGameMode) => {
      switch (mode) {
        case EGameMode.bombWords:
          return 'Bomb Words';
        case EGameMode.doubleValueWords:
          return 'Double Value';
        case EGameMode.earnTime:
          return 'Earn Time';
        case EGameMode.oneAtATime:
          return 'Single Target';
        case EGameMode.sacrifice:
          return 'Sacrifice';
        default: 
          return '(?)';
      }
    })
  }

  updateFromSub(update: IGame, clientSync: MultiSync) {
    // change the important things from the subscription update 
    this.phase = update.phase;
    this.phaseEndTime = update.phaseEndTime;
    this.adjustedPhaseEndTime = Game.adjustIncomingPhaseEndTime(clientSync, update.phaseEndTime);
    this.updater = update.updater;
    this.newRoundRequest = update.newRoundRequest;
    return this;
  }

  // stuff to do when we're building a
  // new entity of this type for the db
  newEntity() {
    super.newEntity();
    // build teams for game
    for (let i = 0; i < this.numTeams; i++) {
      var t = new Team({
        gameId: this.id,
        name: [Game.redTeamName, Game.blueTeamName]?.[i]
      }).newEntity();
      this.teams?.push(t);
    }
    return this;
  }

  // dont specify return type
  async forApi(opts?: IModelIncludeOpts) {
    const superJson = await super.forApi(opts);
    var toApi: IGame = {
      ...superJson,
      code: this.code,
      phase: this.phase,
      phaseEndTime: this.phaseEndTime,
      modes: this.modes,
      writeMs: this.writeMs,
      voteMs: this.voteMs,
      guessMs: this.guessMs,
      earnTimeMs: this.earnTimeMs,
      usedTargets: this.usedTargets,
      customTargets: this.customTargets,
      wordSetsOn: this.wordSetsOn,
      location: this.location,
      updater: this.updater,
      newRoundRequest: this.newRoundRequest
    };
    // optional includes of deeper models
    if (opts?.includeTeams && this.teams) {

      // clean this up!
      // return Promise.reduce(this.teams!, (allTeams: ITeam[], t: Team) => {
      //     var saved = t.forApi(opts);
      //     allTeams.push(saved);
      //     return allTeams;
      // }, [])
      // .then((createdTeamsJson) => {
      //     json.teams = this.teams;
      //     return json;
      // });

      var teamIds = await Promise.reduce(this.teams, async (teamIds: string[], t: Team) => {
        const createdId = await Team.saveNew(t);
        teamIds.push(createdId);
        return teamIds;
      }, []);
      // toApi.teams = teamIds;
    }
    return toApi;
  }

  async attemptStartNewRound(newRR: number, clientSync: MultiSync) {
    // only allow creation of new round if the request matches what we expect
    if (this.newRoundRequest !== newRR) {
      return await this;
    }

    const playingWithDoubleValueWords = this.hasGameMode(EGameMode.doubleValueWords);
    // disassociate all old tws, cws, bws
    // build up new list of tws for each team
    await Promise.mapSeries(this.teams!, async (t: Team) => {
      var teamRound = new Round({ gameId: this.id, teamId: t.id });
      await teamRound.setNewTwList(this.usedTargets, this.wordSetsOn, this.customTargets, playingWithDoubleValueWords);
      t.round = teamRound;
      t.roundId = teamRound.id;
      await Team.saveUpdate(t);
      return t;
    });

    // set the game phase to wordEntry
    this.phase = EGamePhases.cwEntry;
    this.phaseEndTime = Game.adjustOutgoingPhaseEndTime(clientSync, Date.now() + (this.writeMs || 0));
    this.newRoundRequest = 0; // intentionally falsey
    await Game.saveUpdate(this);
    return this;
  }

  // dont apply time correction here since based on the server game time
  async attemptUpdateEndPhaseTimeWithoutCorrection(serverSetEndTime: number) {
    this.phaseEndTime = serverSetEndTime;
    this.newRoundRequest = 0; // intentionally falsey
    await Game.saveUpdate(this);
    return this;
  }

  // all clients need to adjust their time to the server time when sending phase end times
  // all clients need to adust the server time to their local time when receiving phase end times
  static adjustIncomingPhaseEndTime(timeSyncCorrection: MultiSync, incomingTime?: number) {
    return (incomingTime ?? 0) - timeSyncCorrection.getAvgOffset();
  }

  static adjustOutgoingPhaseEndTime(timeSyncCorrection: MultiSync, desiredTimeAccordingToClient?: number) {
    return (desiredTimeAccordingToClient ?? 0) + timeSyncCorrection.getAvgOffset();
  }

  async preStartNewRound(requestVal: number) {
    this.newRoundRequest = requestVal;
    await Game.saveUpdate(this);
    return this;
  }

  async setNewPhase(phase: EGamePhases, clientSync: MultiSync, withSacrificeBonus: boolean = false) {
    // set the game phase to wordEntry
    this.phase = phase;
    var allottedPhaseTime;
    switch (phase) {
      case EGamePhases.cwEntry:
        allottedPhaseTime = this.writeMs;
        break;
      case EGamePhases.voting:
        allottedPhaseTime = this.voteMs;
        break;
      case EGamePhases.preRedGuess:
      case EGamePhases.preBlueGuess:
      case EGamePhases.unknown:
        // will cause Date.now() + undefined to error 
        // and set phaseEndTime to NaN which is what we want
        allottedPhaseTime = undefined
        break;
      case EGamePhases.redGuess:
      case EGamePhases.blueGuess:
        // play the new round sfx based on the round number
        // TODO: Would be nice if this played 
        // on all devices vs just the captain's
        sfx.play(ESoundName.guessStart);
        allottedPhaseTime = this.guessMs;
        if (withSacrificeBonus) {
          allottedPhaseTime += this.sacrificeBonusMs || Game.defaultSacrificeBonusMs;
        }
        break;
      default:
        console.log('default case triggered. sending game phase back to results from attempted: ' + phase);
        // will cause Date.now() + undefined to error 
        // and set phaseEndTime to NaN which is what we want
        allottedPhaseTime = undefined;
        // force back to results if phase is too high
        this.phase = EGamePhases.results;
        break;
    }
    this.phaseEndTime = Game.adjustOutgoingPhaseEndTime(clientSync, Date.now() + allottedPhaseTime);
    await Game.saveUpdate(this);
    return this;
  }

  // send the updater to a unique val so that 
  // clients can be alerted of a game change
  async triggerUpdater() {
    this.updater = Date.now();
    await Game.saveUpdate(this);
    return this;
  }

  private genGameCode(desiredSize: number): string {
    var buildCode: string[] = [];
    for (var i = 0; i < desiredSize; i++) {
      let randCharIndex = getRandomNumBetween(0, gameCodeChars.length);
      buildCode.push(gameCodeChars[randCharIndex]);
    }
    return buildCode.join('');
  }

  static async findByCode(code: string, timeSync: MultiSync) {
    try {
      const foundGames: IGame = await graphQLQuery(gameByCodeWithTeam, 'gameByCodeSorted', {
        code
      });
      // sorted by most recently created
      return new Game(timeSync, foundGames[0]);
    } catch (err) {
      console.log(err);
    }
  }

  static async saveNew(gameToSave: Game): Promise<string> {
    // console.log('[save] create game');
    const gameEntity = await gameToSave!.forApi({ includeTeams: true })
    // set the game creator location on the game
    gameEntity.location = window.sessionStorage.getItem('approx_loc') ?? undefined;
    await graphQLMutation(createGame, gameEntity)
    Word.allowedGameWords = undefined;
    return gameEntity.id;
  }

  static async saveUpdate(gameToSave: Game): Promise<string> {
    // console.log('[save] update game')
    const gameEntity = await gameToSave!.forApi()
    await graphQLMutation(updateGame, gameEntity)
    return gameEntity.id;
  }

}

