import React, { FC, useState, useEffect, useContext, useRef } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-grid-system';

import {
  Button,
  FormInput
} from 'shards-react'

import {
  FaCheck,
  FaAngleDoubleUp,
  FaBomb
} from 'react-icons/fa';

import {
  sfx, ESoundName,
  ConditionalRender,
  EGamePhases,
  EGameMode,
  Game,
  Team,
  Word,
  ITWord,
  EWordType,
  TW,
  BW,
  CW,
  GameContext,
  graphQLQuery,
  graphQLSubscription,
  getBombWordsForRound,
  getTeamAsClueGiver,
  onBwMentioned,
  onTwGuessed,
  onTwSkipped,
  useMap
} from '../../../internal';

interface IProps {
}

export const VerbalCaptain: FC<IProps> = ({ }) => {
  var { state: { game, myTeam, oppTeam }, dispatch } = useContext(GameContext);

  var [guessedTws, setGuessedTws] = useMap(new Map());
  var [skippedTws, setSkippedTWs] = useMap(new Map());
  var [mentionedBws, setMentionedBws] = useMap(new Map());
  var [bombWordClue, setBombWordClue] = useState<BW>();
  var [bombWordMentioned, setBombWordMentioned] = useState(false);
  var [lessThan1sSinceLastSkip, setLessThan1sSinceLastSkip] = useState(false);

  useEffect(() => {
    if (game?.getMyTeam) {

      (async () => {
        var myT = await graphQLQuery(getTeamAsClueGiver, 'getTeam', { id: myTeam!.id })
        var captainsTeam = new Team(myT);

        // fetch bomb word if there is one
        var bombWords = await graphQLQuery(getBombWordsForRound, 'wordByRoundIdAndBomb', {
          roundId: oppTeam!.round?.id,
          bombText: { ne: undefined }
        })
        if (bombWords?.length > 0) {
          const bomb = new BW(bombWords?.[0]);
          setBombWordClue(bomb);
        }

        dispatch({ type: 'setMyTeam', team: captainsTeam })

      })();
      // setup subscription for oppTeam's mentioned bws
      var mentionedBwSubscription = graphQLSubscription(onBwMentioned, { teamId: oppTeam!.id }, bwMentioned);
      var guessedTwSubscription = graphQLSubscription(onTwGuessed, { teamId: myTeam!.id }, twGuessed);
      var skippedTwSubscription = graphQLSubscription(onTwSkipped, { teamId: myTeam!.id }, twSkipped);
      

      return () => {
        mentionedBwSubscription.unsubscribe();
        guessedTwSubscription.unsubscribe();
        skippedTwSubscription.unsubscribe();
      }
    }
  }, [game?.id])

  const bwMentioned = ({ onUpdateWord }) => {
    const bw = new BW(onUpdateWord);
    const alreadyMentioned = mentionedBws.has(bw.id);
    mentionedBws.set(bw.id, bw);
    if (!alreadyMentioned) {
      if (bw.isBombWord()) {
        setBombWordClue(bw);
        setBombWordMentioned(true);

        // bomb sound
        sfx.play(ESoundName.bomb, bw.text?.length);
      } else {
        // not bomb sounds
        if (bw.isDoubleValue()) {
          sfx.play(ESoundName.bwDouble, bw.text?.length);
        } else {
          sfx.play(ESoundName.bwMentioned, bw.text?.length);
        }
      }
      setMentionedBws(mentionedBws);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLessThan1sSinceLastSkip(false);
    }, 1000)
  }, [lessThan1sSinceLastSkip])

  const twGuessed = ({ onUpdateWord }) => {
    const tw = new TW(onUpdateWord);
    const alreadyGuessed = guessedTws.has(tw.id);
    // second value is true or false depending on if is double value word
    guessedTws.set(tw.id, tw.isDoubleValue());
    setGuessedTws(guessedTws);
    if (guessedTws.size === 5 && !alreadyGuessed) {
      // all earned sound
      sfx.play(ESoundName.allTwsEarned, tw.text?.length);
    }
    // not else if play sound and cheer if 5
    if (!alreadyGuessed) {
      if (tw.isDoubleValue()) {
        // double sound
        sfx.play(ESoundName.twDouble, tw.text?.length);
      } else {
        // single sound
        sfx.play(ESoundName.twEarned, tw.text?.length);
      }
    }
  }

  const markAsGuessed = (tw: TW) => {
    Word.saveUpdate(tw.markAsGuessed());

    const alreadyGuessed = guessedTws.has(tw.id);
    if (!alreadyGuessed) {
      // also push back end end phase time 
      // if the earn time mode is turned on
      if (game?.hasGameMode(EGameMode.earnTime)) {
        var earnedBonus = game.earnTimeMs ?? 0;
        // no need to add time if it is the last TW.
        // guessed size < 4 since this map does not
        // include the new guessed word at this time.
        if (earnedBonus && guessedTws.size < 4) {
          game.attemptUpdateEndPhaseTimeWithoutCorrection(game?.phaseEndTime! + earnedBonus);
        }
      }
    }
  }

  const twSkipped = ({ onUpdateWord }) => {
    const tw = new TW(onUpdateWord);
    const alreadySkipped = skippedTws.has(tw.id);
    // second value is true or false depending on if is double value word
    skippedTws.set(tw.id, tw.isSkipped());
    setSkippedTWs(skippedTws);

    if (!alreadySkipped) {
      // single sound
      sfx.play(ESoundName.voteCast, tw.text?.length);
    }
  }

  const markCurrentTargetAsSkipped = () => {
    const currTw = myTeam?.round?.tws?.[skippedTws.size + guessedTws.size];
    setLessThan1sSinceLastSkip(true);
    if (currTw) {
      Word.saveUpdate(currTw.markAsSkipped());
    }

    const alreadySkipped = skippedTws.has(currTw?.id);
    if (!alreadySkipped) {
      // also push back end end phase time 
      // if the earn time mode is turned on
      if (game?.hasGameMode(EGameMode.earnTime)) {
        var earnedBonus = game.earnTimeMs ?? 0;
        // no need to add time if it is the last TW.
        // guessed size < 4 since this map does not
        // include the new guessed word at this time.
        if (earnedBonus && guessedTws.size < 4) {
          // get 75% the earn-time bonus if you skip the word
          game.attemptUpdateEndPhaseTimeWithoutCorrection(game?.phaseEndTime! + earnedBonus * .75);
        }
      }
    }
  }

  return (
    <div className={classnames('verbalPhase-container')}>
      <Container>
        <Row justify="center">
          <Col xs={12}>
            <h2 className={classnames('text-center')}>Captain, give clues to your team for the <em>Target Words</em> below.</h2>
            <h4 className={classnames('text-center', 'sub-title')}>Select each word that your team correctly guesses</h4>
          </Col>
        </Row>

        {/* Bomb Clue */}
        <ConditionalRender visible={!!bombWordClue}>
          <Row justify="center">
            <Col xs={12}>
              <h4 className={classnames('text-center', 'bomb-sub-title')}>Watch out for this BOMB Word!</h4>
            </Col>
            <Col xs={8} sm={6} md={5} lg={4} xxl={3} key={`tw-bomb`}>
              <Button
                className={classnames('word-button', 'bw', 'text-center', { shake: bombWordMentioned })}
                disabled>
                <>
                  <Row>
                    <Col xs={12}>
                      <div className="word-text">{bombWordClue?.mentioned ? bombWordClue?.text : bombWordClue?.bombText}</div>
                    </Col>
                  </Row>
                  <div className="bomb-word-indicator-container">
                    <div className={classnames('bomb-word-indicator')}>
                      <FaBomb />
                    </div>
                  </div>
                </>
              </Button>
            </Col>
          </Row>
        </ConditionalRender>

        <ConditionalRender visible={!!game?.hasGameMode(EGameMode.oneAtATime)}>
          <Row>
            <Col md={6}>
              <Button
                className={classnames('word-button', 'text-center', 'active')}
                onClick={() => markCurrentTargetAsSkipped()}
                disabled={lessThan1sSinceLastSkip || skippedTws.size + guessedTws.size >= 4}>
                  Skip Current Target
              </Button>
            </Col>
          </Row>
        </ConditionalRender>

        <Row>
          <Col md={6}> 
            <h2 className={classnames('text-center')}>Our Target Words</h2>
            {myTeam?.round?.tws?.map((tw: TW, i: number) => (
              <Col xxl={6} key={`tw-${i}`}>
                <Button
                  className={classnames('word-button', 'tw', 'text-center', myTeam?.getStyleName(), { 'active': guessedTws?.has(tw.id), 'skipped': skippedTws?.has(tw.id) || tw.isSkipped() })}
                  onClick={() => markAsGuessed(tw)}
                  disabled={bombWordMentioned || (game?.hasGameMode(EGameMode.oneAtATime) && i > guessedTws.size + skippedTws.size) || skippedTws?.has(tw.id)}>
                  <>
                    <ConditionalRender visible={bombWordMentioned && !guessedTws?.has(tw.id)}>
                      <FaBomb />
                    </ConditionalRender>
                    <ConditionalRender visible={!(bombWordMentioned && !guessedTws?.has(tw.id))}>
                      <Row>
                        <Col xs={12}>
                          <div className="word-text">
                            <ConditionalRender visible={!game?.hasGameMode(EGameMode.oneAtATime) || i <= guessedTws.size + skippedTws.size}>
                              {tw.text}
                            </ConditionalRender>
                            <ConditionalRender visible={!(!game?.hasGameMode(EGameMode.oneAtATime) || i <= guessedTws.size + skippedTws.size)}>
                              <em>{tw.text.replace(/\S/g, '*')}</em>
                            </ConditionalRender>
                          </div>
                        </Col>
                      </Row>
                    </ConditionalRender>
                    {/* value of the map is doule word true/false */}
                    <ConditionalRender visible={guessedTws.get(tw.id)}>
                      <div className="double-value-word-flag-container">
                        <div className={classnames('double-value-word-flag')}>
                          <FaAngleDoubleUp />
                        </div>
                      </div>
                    </ConditionalRender>
                  </>
                </Button>
              </Col>
            ))}
          </Col>

          {/* Their Points */}
          <Col md={6}>
            <h2 className={classnames('text-center')}>Banned Words Mentioned</h2>
            <ConditionalRender visible={mentionedBws.size === 0}>
              <em><h4 className={classnames('text-center')}>None</h4></em>
            </ConditionalRender>

            {[...mentionedBws.values()]?.map((bw: BW, i: number) => (
              <Col xxl={6} key={`tw-${i}`}>
                <Button
                  className={classnames('word-button', 'bw', 'text-center', 'active', oppTeam?.getStyleName())}
                  disabled>
                  <>
                    <Row>
                      <Col xs={12}>
                        <div className="word-text">{bw.text}</div>
                      </Col>
                    </Row>

                    {/* Double value */}
                    <ConditionalRender visible={bw?.isDoubleValue()}>
                      <div className="double-value-word-flag-container">
                        <div className={classnames('double-value-word-flag')}>
                          <FaAngleDoubleUp />
                        </div>
                      </div>
                    </ConditionalRender>

                    {/* Bomb */}
                    <ConditionalRender visible={bw?.isBombWord()}>
                      <div className="bomb-word-indicator-container">
                        <div className={classnames('bomb-word-indicator')}>
                          <FaBomb />
                        </div>
                      </div>
                    </ConditionalRender>
                  </>
                </Button>
              </Col>
            ))}
          </Col>

        </Row>
      </Container>
    </div>
  )
}
