import React, { FC, useState, useEffect, useContext, useRef } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Visible } from 'react-grid-system';
import { useParams } from "react-router-dom";
import {
  Button,
  FormInput
} from 'shards-react'
import { FaQuestionCircle, FaBomb } from 'react-icons/fa'
import {
  ConditionalRender,
  Game,
  Team,
  Word,
  ITWord,
  TW,
  BW,
  CW,
  GameContext,
  graphQLQuery,
  graphQLSubscription,
  getTeamAsSpectator,
  getBombWordsForRound,
  onCreateCW,
  onBwMentioned,
  onTwGuessed,
  onTwSkipped,
  useMap,
  EWordType, EGamePhases
} from '../../../internal';


interface IProps {

}

export const SpectateVerbal: FC<IProps> = ({ }) => {
  var { state: { game, myTeam, oppTeam, helpOn }, dispatch } = useContext(GameContext);
  var [blueTeamId, setBlueTeamId] = useState<string>('');
  var [redTeamId, setRedTeamId] = useState<string>('');
  var [bombWordClue, setBombWordClue] = useState<Word>();
  var [bombWordMentioned, setBombWordMentioned] = useState(false);

  var redTws = useMap(new Map<string, Word>());
  var redBws = useMap(new Map<string, Word>());
  var blueTws = useMap(new Map<string, Word>());
  var blueBws = useMap(new Map<string, Word>());

  var latestRedTws = useRef<any>(redTws);
  var latestRedBws = useRef<any>(redBws);
  var latestBlueTws = useRef<any>(blueTws);
  var latestBlueBws = useRef<any>(blueBws);

  var [updater, setUpdater] = useState<number>(0);

  const mapifyWords = (ws: Word[] | undefined) => {
    return ws?.reduce((fullMap: Map<string, Word>, w: Word): Map<string, Word> => {
      fullMap.set(w.id, w);
      return fullMap;
    }, new Map<string, Word>());
  }
  useEffect(() => {
    if (game?.getMyTeam) {

      (async () => {
        // label the teams for spectator lookup
        const teamLookupMap = game?.teams?.reduce((teamDict: any, currTeam: Team): any => {
          if (!teamDict[currTeam.name]) {
            teamDict[currTeam.name] = currTeam;
          };
          return teamDict;
        }, {});

        // fetch the data for this view so that we're never without it
        var red = await graphQLQuery(getTeamAsSpectator, 'getTeam', { id: teamLookupMap[Game.redTeamName]?.id })
        var redT = new Team(red);
        // get the opposing team's Ttw
        var blue = await graphQLQuery(getTeamAsSpectator, 'getTeam', { id: teamLookupMap[Game.blueTeamName]?.id })
        var blueT = new Team(blue);

        // fetch bomb word if there is one
        var bombWords = await graphQLQuery(getBombWordsForRound, 'wordByRoundIdAndBomb', {
          // get bomb word for the opposite team's round
          roundId: game?.phase === EGamePhases.blueGuess ? redT!.round?.id : blueT!.round?.id,
          bombText: { ne: undefined }
        });
        if (bombWords?.length > 0) {
          const bomb = new Word(bombWords?.[0]);
          setBombWordClue(bomb);
        }

        // for spectator, red team == myTeam
        // for spectator, blue team == oppTeam
        dispatch({ type: 'setMyTeam', team: redT })
        dispatch({ type: 'setOppTeam', team: blueT })

        latestRedTws.current = mapifyWords(redT?.round?.tws);
        latestRedBws.current = mapifyWords(redT?.round?.bws);

        latestBlueTws.current = mapifyWords(blueT?.round?.tws);
        latestBlueBws.current = mapifyWords(blueT?.round?.bws);

        setRedTeamId(teamLookupMap[Game.redTeamName]?.id);
        setBlueTeamId(teamLookupMap[Game.blueTeamName]?.id);

      })();
    }
  }, [game?.id])

  // red subscriptions
  useEffect(() => {
    if (redTeamId) {
      // setup subscription for myTeam's incoming cws
      var bwSubscription = graphQLSubscription(onBwMentioned, { teamId: redTeamId }, wordMentioned.bind(null, 'red'));
      var twSubscription = graphQLSubscription(onTwGuessed, { teamId: redTeamId }, wordMentioned.bind(null, 'red'));
      var skippedTwSubscription = graphQLSubscription(onTwSkipped, { teamId: redTeamId }, wordMentioned.bind(null, 'red'));
      return () => {
        bwSubscription.unsubscribe();
        twSubscription.unsubscribe();
        skippedTwSubscription.unsubscribe();
      }
    }
  }, [redTeamId])

  // blue subscriptions
  useEffect(() => {
    if (blueTeamId) {
      // setup subscription for myTeam's incoming cws
      var bwSubscription = graphQLSubscription(onBwMentioned, { teamId: blueTeamId }, wordMentioned.bind(null, 'blue'));
      var twSubscription = graphQLSubscription(onTwGuessed, { teamId: blueTeamId }, wordMentioned.bind(null, 'blue'));
      var skippedTwSubscription = graphQLSubscription(onTwSkipped, { teamId: blueTeamId }, wordMentioned.bind(null, 'blue'));
      return () => {
        bwSubscription.unsubscribe();
        twSubscription.unsubscribe();
        skippedTwSubscription.unsubscribe();
      }
    }
  }, [blueTeamId]);


  const wordMentioned = async (redOrBlue: string, { onUpdateWord }) => {
    const word = new Word(onUpdateWord);
    if (redOrBlue === 'red') {
      if (word.type === EWordType.tw) {
        latestRedTws.current?.set(word.id, word);
      }
      else if (word.type === EWordType.bw) {
        latestRedBws.current?.set(word.id, word);
      }
    } else {
      if (word.type === EWordType.tw) {
        latestBlueTws.current?.set(word.id, word);
      }
      else if (word.type === EWordType.bw) {
        latestBlueBws.current?.set(word.id, word);
      }
    }

    if (word.isBombWord()) {
      setBombWordClue(word);
      setBombWordMentioned(true);
    }

    setUpdater(Date.now())
  }

  const displayWordTemplate = (teamStyleName: string) => {
    return (w: Word, i: number) => (
      <Col xxl={6} key={`${teamStyleName}-word-${i}`}>
        <Button
          className={classnames('word-button', 'text-center', teamStyleName, {'active': !w?.isSkipped?.(), 'skipped': w?.isSkipped?.()})}
          disabled>
          <>
            <Row>
              <Col xs={12}>
                <div className="word-text">{w?.text}</div>
              </Col>
            </Row>
            <ConditionalRender visible={w?.isDoubleValue?.()}>
              <div className="double-value-word-flag-container">
                <div className={classnames('double-value-word-flag')}>
                </div>
              </div>
            </ConditionalRender>
            <ConditionalRender visible={w?.isBombWord?.()}>
              <div className="bomb-word-indicator-container">
                <div className={classnames('bomb-word-indicator')}>
                  <FaBomb />
                </div>
              </div>
            </ConditionalRender>
          </>
        </Button>
      </Col>
    );
  };

  return (
    <div className={classnames('spectateWordEntryPhase-container')}>
      <Container>
        <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>

        {/* Bomb Clue */}
        <ConditionalRender visible={!!bombWordClue}>
          <Row justify="center">
            <Col xs={12}>
              <h4 className={classnames('text-center', 'bomb-sub-title')}>Watch out for this BOMB Word!</h4>
            </Col>
            <Col xs={8} sm={6} md={5} lg={4} xxl={3} key={`tw-bomb`}>
              <Button
                className={classnames('word-button', 'bw', 'text-center', { shake: bombWordMentioned })}
                disabled>
                <>
                  <Row>
                    <Col xs={12}>
                      <div className="word-text">{bombWordClue?.bombText}</div>
                    </Col>
                  </Row>
                  <div className="bomb-word-indicator-container">
                    <div className={classnames('bomb-word-indicator')}>
                      <FaBomb />
                    </div>
                  </div>
                </>
              </Button>
            </Col>
          </Row>
        </ConditionalRender>

        {/* blue guess phase */}
        <ConditionalRender visible={game?.phase === EGamePhases.blueGuess}>
          <Row>
            {/* Blue Team Tws */}
            <Col xs={6}>
              <h2 className={classnames('text-center')}>{Game.blueTeamName}'s Guessed Words</h2>
              <ConditionalRender visible={latestBlueTws.current?.size === 0}>
                <em><h4 className={classnames('text-center')}>None</h4></em>
              </ConditionalRender>
              {[...latestBlueTws.current?.values()]?.map(displayWordTemplate('blue-team'))}
            </Col>
            {/* Red Team Bws */}
            <Col xs={6}>
              <h2 className={classnames('text-center')}>{Game.redTeamName}'s Mentioned Words</h2>
              <ConditionalRender visible={latestRedBws.current?.size === 0}>
                <em><h4 className={classnames('text-center')}>None</h4></em>
              </ConditionalRender>
              {[...latestRedBws.current?.values()]?.map(displayWordTemplate('red-team'))}
            </Col>

          </Row>
        </ConditionalRender>

        {/* red guess phase */}
        <ConditionalRender visible={game?.phase === EGamePhases.redGuess}>
          <Row>
            {/* Blue Team Tws */}
            <Col xs={6}>
              <h2 className={classnames('text-center')}>Red Team's Guessed Words</h2>
              <ConditionalRender visible={latestRedTws.current?.size === 0}>
                <em><h4 className={classnames('text-center')}>None</h4></em>
              </ConditionalRender>
              {[...latestRedTws.current?.values()]?.map(displayWordTemplate('red-team'))}
            </Col>
            {/* Red Team Bws */}
            <Col xs={6}>
              <h2 className={classnames('text-center')}>Blue Team's Mentioned Words</h2>
              <ConditionalRender visible={latestBlueBws.current?.size === 0}>
                <em><h4 className={classnames('text-center')}>None</h4></em>
              </ConditionalRender>
              {[...latestBlueBws.current?.values()]?.map(displayWordTemplate('blue-team'))}
            </Col>

          </Row>
        </ConditionalRender>

        <Col xs={12} className={classnames('vert-spacer', 'huge')}></Col>
      </Container>
    </div>
  )
}
