import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "Penguin",
  "Bear",
  "Owl",
  "Bumblebee",
  "Bird",
  "Butterfly",
  "Cat",
  "Chicken",
  "Cow",
  "Deer",
  "Dog",
  "Dolphin",
  "Donkey",
  "Duck",
  "Eagle",
  "Gorilla",
  "Fish",
  "Fox",
  "Frog",
  "Giraffe",
  "Horse",
  "Human",
  "Jellyfish",
  "Kangaroo",
  "Leopard",
  "Lion",
  "Monkey",
  "Mouse",
  "Octopus",
  "Parrot",
  "Penguin",
  "Pig",
  "Poodle",
  "Rat",
  "Seal",
  "Shark",
  "Sheep",
  "Shrimp",
  "Snail",
  "Snake",
  "Squid",
  "Squirrel",
  "Swan",
  "Tuna",
  "Turkey",
  "Wasp",
  "Wolf",
  "Zebra"
]);

export const medium = new Set([
  "Koala",
  "Ant",
  "Elephant",
  "Black Bear",
  "Cheetah",
  "Rhinoceros",
  "Beaver",
  "Beetle",
  "Camel",
  "Goat",
  "Honey Bee",
  "Moth",
  "Walrus",
  "Cockroach",
  "Crab",
  "Ladybug",
  "Alligator",
  "Salmon",
  "Platypus",
  "Seahorse",
  "Blue Whale",
  "Stingray",
  "Crocodile",
  "Tortoise",
  "Snapping Turtle",
]);

const hard = new Set([
  "Water Buffalo",
  "Bulldog",
  "Foxhound",
  "Terrier",
  "Anteater",
  "Peacock",
  "Pigeon",
  "Antelope",
  "Armadillo",
  "Giant Hornet",
  "Starfish",
  "Toucan",
  "Labradoodle",
  "Baboon",
  "Badger",
  "Barnacle",
  "Basset Hound",
  "Beagle",
  "Pelican",
  "Ostrich",
  "Panther",
  "Narwhal",
  "Moose",
  "Mule",
  "Lizard",
  "Llama",
  "Lobster",
  "Krill",
  "Labradoodle",
  "Liger",
  "Coral",
  "Cougar",
  "Killer Whale",
  "King Cobra",
  "Kiwi",
  "Goose",
  "Gopher",
  "Grasshopper",
  "Hamster",
  "Hippopotamus",
  "Honey Badger",
  "Jaguar",
  "Coyote",
  "Crane",
  "Dalmatian",
  "Chihuahua",
  "Chimpanzee",
  "Chipmunk",
  "Falcon",
  "Flamingo",
  "Gecko",
  "Carp",
  "Caterpillar",
  "Chameleon",
  "Birds Of Paradise",
  "Bison",
  "Marlin",
  "Rhinoceros",
  "Black Widow",
  "Blobfish",
  "Bloodhound",
  "Blue Jay",
  "Buffalo",
  "Bulldog",
  "Bullfrog",
  "Albatross",
  "Giant Tortoise",
  "Aardvark",
  "Alpaca",
  "Beta fish",
  "Mole",
]);

export default new ThemeSet('Animals',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);