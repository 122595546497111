import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "belt",
  "bow",
  "bracelet",
  "button",
  "cap",
  "cape",
  "coat",
  "crown",
  "diaper",
  "gloves",
  "hat",
  "jacket",
  "necklace",
  "pants",
  "pocket",
  "shoe",
  "shorts",
  "sock",
  "suit"
])

const medium = new Set([
  "apparel",
  "apron",
  "denim",
  "attire",
  "ball gown",
  "bandanna",
  "baseball cap",
  "bathing suit",
  "beanie",
  "bedclothes",
  "bell-bottoms",
  "bib",
  "bikini",
  "blazer",
  "blouse",
  "boot",
  "bow tie",
  "boxer shorts",
  "boxers",
  "bra",
  "breeches",
  "briefs",
  "buckle",
  "button-down shirt",
  "camouflage",
  "cap and gown",
  "capris",
  "cardigan",
  "chemise",
  "cloak",
  "clogs",
  "clothes",
  "clothing",
  "collar",
  "corset",
  "costume",
  "coveralls",
  "cowboy boots",
  "cowboy hat",
  "cravat",
  "cuff",
  "cuff links",
  "cummerbund",
  "dinner jacket",
  "drawers",
  "dress",
  "dress shirt",
  "duds",
  "earmuffs",
  "earrings",
  "elastic",
  "evening gown",
  "fashion",
  "fedora",
  "fez",
  "flannel shirt",
  "flip-flops",
  "formal wear",
  "frock",
  "fur",
  "fur coat",
  "garb",
  "garment",
  "gear",
  "glasses",
  "gown",
  "handbag",
  "handkerchief",
  "Hawaiian shirt",
  "hazmat suit",
  "headscarf",
  "helmet",
  "hem",
  "high heels",
  "hoodie",
  "hose",
  "hosiery",
  "hospital gown",
  "houndstooth",
  "housecoat",
  "jeans",
  "jersey",
  "jewelry",
  "jumper",
  "jumpsuit",
  "khakis",
  "kilt",
  "kit",
  "knickers",
  "lab coat",
  "lapel",
  "leather jacket",
  "leg warmers",
  "leotard",
  "leggings",
  "life jacket",
  "loafers",
  "loincloth",
  "long johns",
  "long underwear",
  "miniskirt",
  "mittens",
  "moccasins",
  "nightgown",
  "nightshirt",
  "onesies",
  "outerwear",
  "outfit",
  "overalls",
  "pajamas",
  "pantsuit",
  "pleat",
  "pocketbook",
  "polo shirt",
  "poncho",
  "poodle skirt",
  "pork pie hat",
  "pullover",
  "pumps",
  "purse",
  "raincoat",
  "ring",
  "robe",
  "rugby shirt",
  "sandals",
  "scarf",
  "school uniform",
  "scrubs",
  "shift",
  "shirt",
  "shoulder pads",
  "shrug",
  "singlet",
  "skirt",
  "slacks",
  "slip",
  "slippers",
  "smock",
  "snaps",
  "sneakers",
  "sombrero",
  "spacesuit",
  "stockings",
  "stole",
  "sun hat",
  "sunbonnet",
  "sundress",
  "sunglasses",
  "suspenders",
  "sweater",
  "sweatpants",
  "sweatshirt",
  "swimsuit",
  "T-shirt",
  "tank top",
  "teddy",
  "threads",
  "tiara",
  "tie",
  "tie clip",
  "tights",
  "toga",
  "top coat",
  "top hat",
  "train",
  "trench coat",
  "trousers",
  "trunks",
  "tube top",
  "turban",
  "turtleneck",
  "turtleneck shirt",
  "tutu",
  "tux",
  "tuxedo",
  "twin set",
  "umbrella",
  "undershirt",
  "underwear",
  "uniform",
  "veil",
  "Velcro",
  "vest",
  "visor",
  "waders",
  "waistcoat",
  "wear",
  "wedding gown",
  "wetsuit",
  "white tie",
  "top",
  "wig",
  "windbreaker",
  "wrap",
  "zipper",
]);


const hard = new Set([
  "abaya",
  "anorak",
  "ascot tie",
  "balaclava",
  "battledress",
  "beret",
  "Bermuda shorts",
  "boa",
  "bonnet",
  "brassiere",
  "caftan",
  "culottes",
  "dashiki",
  "dirndl",
  "dungarees",
  "pinafore",
  "flak jacket",
  "flannel nightgown",
  "fatigues",
  "gabardine",
  "gaiters",
  "galoshes",
  "garters",
  "getup",
  "gilet",
  "girdle",
  "halter top",
  "hook and eye",
  "jodhpurs",
  "kerchief",
  "kimono",
  "lingerie",
  "muffler",
  "muumuu",
  "neckerchief",
  "overcoat",
  "overshirt",
  "panama hat",
  "parka",
  "pea coat",
  "bloomers",
  "camisole",
  "pantyhose",
  "peplum",
  "petticoat",
  "sari",
  "sarong",
  "shawl",
  "sheath dress",
  "Stetson hat",
  "sweatsuit",
  "tam",
  "togs",
  "tunic",
  "tweed jacket",
  "twill",
  "underclothes",
  "zoris",
  "vestments",
  "yoke",
  "Wellingtons",
  "woolens",
]);


export default new ThemeSet('Clothing',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });