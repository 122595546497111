/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import {
  EWordType
} from '../internal';

export const onCreateCW = /* GraphQL */ `
  subscription OnCreateCW($teamId: ID!) {
    onCreateWord(teamId: $teamId, type: ${EWordType.cw}) {
      id
      text
      type
      teamId
      associations {
        items {
          id
          twId
          tw {
            id
            text
          }
        }
        nextToken
      }
      votes {
        items {
          id
        }
        nextToken
      }
    }
  }
`;

export const onCreateBW = /* GraphQL */ `
  subscription OnCreateBW($teamId: ID!) {
    onCreateWord(teamId: $teamId, type: ${EWordType.bw}) {
      id
      text
      type
      selected
      skipped
      teamId
    }
  }
`;


export const onCreateTW = /* GraphQL */ `
  subscription OnCreateTW($teamId: ID!) {
    onCreateWord(teamId: $teamId, type: ${EWordType.tw}) {
      id
      text
      type
      selected
      skipped
      teamId
    }
  }
`;

export const onChangeVote = /* GraphQL */ `
  subscription OnChangeVote($teamId: ID!) {
    onChangeVote(teamId: $teamId) {
      id
      wordId
    }
  }
`;

export const onGameChange = /* GraphQL */ `
  subscription OnGameUpdate($id: ID!) {
    onChangeGame(id: $id) {
      id
      code
      phase
      phaseEndTime
      newRoundRequest
      wordSetsOn
      usedTargets
      updater
    }
  }
`;

export const onRoundSacrificed = /* GraphQL */ `
  subscription OnRoundUpdate($id: ID!) {
    onChangeRound(id: $id) {
      id
      sacrificed
    }
  }
`;

export const onBwMentioned = /* graphQL */ `
  subscription OnUpdateWord($teamId: ID!) {
    onUpdateWord(teamId: $teamId, type: ${EWordType.bw}, selected: "true" ) {
      id
      text
      type
      selected
      skipped
      doubleValue
      bombText
      teamId
    }
  }
`;

export const onTwGuessed = /* graphQL */ `
  subscription OnUpdateWord($teamId: ID!) {
    onUpdateWord(teamId: $teamId, type: ${EWordType.tw}, selected: "true") {
      id
      text
      type
      selected
      skipped
      doubleValue
      bombText
      teamId
    }
  }
`;


export const onTwSkipped = /* graphQL */ `
  subscription OnUpdateWord($teamId: ID!) {
    onUpdateWord(teamId: $teamId, type: ${EWordType.tw}, selected: "false", skipped: "true") {
      id
      text
      type
      selected
      skipped
      doubleValue
      bombText
      teamId
    }
  }
`;

