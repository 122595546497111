import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import Countdown from 'react-countdown';
import {
  ConditionalRender,
  GameContext,
  EGamePhases

} from '../internal';

interface IProps {

}

export const MaintenanceAlert: FC<IProps> = ({ }) => {
  return (
    <div className={classnames('maintenance-alert')}>
      <h4>Currently performing game maintenance. You may encounter issues playing games during the maintenance period. Please come back soon.</h4>
    </div>
  )
}
