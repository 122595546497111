import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);

const medium = new Set([
  "y chromosome",
  "chromosome",
  "mutation",
  "dominant",
  "recessive",
  "gene",
  "punnett square",
  "evolution",
  "natural selection",
]);

const hard = new Set([
  "independent assortment",
  "genetic variation",
  "mitosis",
  "zygote",
  "gametes",
  "pedigree chart",
  "homozygous",
  "heterozygous",
  "meiosis",
  "allele",
  "mutagen",
  "genotype",
  "phenotype"
]);

const pro = new Set([
  "phenotype",
  "codominant",
  "additive",
  "Polymorphism",
  "mutation",
  "Complex trait",
  "multifactorial",
  "polygenic",
  "monogenic",
  "Haplotype",
  "multilocus genotype",
  "Epistasis",
  "interaction",
  "pleiotropy",
  "Allelic association",
  "linkage disequilibrium"
]);


export default new ThemeSet('Biology',
  easy,
  medium,
  hard,
  pro,
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);

