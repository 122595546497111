import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

])

const medium = new Set([
  "after",
  "afternoon",
  "afterwards",
  "alarm clock",
  "AM",
  "analog clock",
  "annual",
  "anytime",
  "autumn",
  "bedtime",
  "before",
  "before hand",
  "belated",
  "bell",
  "biennial",
  "calendar",
  "calendar year",
  "century",
  "chronological",
  "chronology",
  "clock",
  "clock face",
  "cuckoo clock",
  "day",
  "daylight",
  "daylight savings time",
  "days of the week",
  "daytime",
  "decade",
  "delay",
  "delayed",
  "dial",
  "digital clock",
  "early",
  "evening",
  "everyday",
  "clock face",
  "fall",
  "fiscal year",
  "fortnight",
  "future",
  "grandfather clock",
  "clock hands",
  "high noon",
  "hour",
  "hour hand",
  "hourglass",
  "jiffy",
  "late",
  "later",
  "leap second",
  "leap year",
  "lunar month",
  "meridian",
  "microsecond",
  "midafternoon",
  "midmorning",
  "midnight",
  "millennium",
  "millisecond",
  "minute",
  "minute hand",
  "moment",
  "momentarily",
  "month",
  "morning",
  "night",
  "nighttime",
  "noon",
  "now",
  "o'clock",
  "on time",
  "overtime",
  "past",
  "pendulum clock",
  "PM",
  "pocket watch",
  "premature",
  "present",
  "punctual",
  "quarter hour",
  "quartz",
  "schedule",
  "season",
  "second",
  "second hand",
  "semester",
  "shift",
  "someday",
  "sometime",
  "soon",
  "spring",
  "stopwatch",
  "summer",
  "sundial",
  "sunrise",
  "sunset",
  "tardy",
  "tempo",
  "timekeeper",
  "timer",
  "timetable",
  "today",
  "tomorrow",
  "tonight",
  "twilight",
  "watch",
  "week",
  "winter",
  "wristwatch",
  "year",
  "yesterday",
  "time zone"
]);

const hard = new Set([
  "ante meridian",
  "autumnal equinox",
  "bicentennial",
  "chronometer",
  "decennium",
  "DST",
  "eon",
  "epoch",
  "equinox",
  "era",
  "galactic year",
  "geologic time",
  "gnomon",
  "half - life",
  "nanosecond",
  "post meridian",
  "prime meridian",
  "quaver",
  "solstice",
  "summer solstice",
  "synchronized",
  "timepiece",
  "UTC",
  "winter solstice",
])


export default new ThemeSet('Time',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);