import { ThemeSet, EDifficulty } from '../../index';

const medium = new Set([
  'sr3',
  'Phil Dumas',
  'Jefferson',
  'proximity',
  'Will Holderness',
  'Ashley Smith',
  'Dan Gardner',
  'Alan Stone',
  'Alex Horan',
  'Anders',
  'Keith Goldberg',
  'Shoaib',
  // 'MoHa',
  // 'Matt Dupre',
  'Pat Hanavan',
  'Aaron Bond',
  'bluetooth',
  'ultra wide band',
  'lock',
  'app',
  'smartphone',
  'kevo',
  'haven',
  'remotelock',
  'nortek',
  'honeywell',
  'spectrum',
  'ffVC',
  'portal',
  'scooter',
  'Winner Winner',
  'covid-19',
  'pandemic',
  'remote',
  'work from home',
  'Prince',
  'access control',
  'leader',
  'badges',
  'quality',
]);

const easy = new Set([

]);

const hard = new Set([

]);


export default new ThemeSet('UniKey',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: true,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);