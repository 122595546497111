import React, { FC, useState, useEffect, useContext, useRef } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-grid-system';
import { useParams } from "react-router-dom";
import {
  Button,
  FormInput
} from 'shards-react'
import {
  FaCheck,
  FaQuestionCircle,
  FaAngleDoubleUp,
  FaBomb
} from 'react-icons/fa'

import {
  sfx, ESoundName,
  ConditionalRender,
  Game,
  Team,
  Word,
  ITWord,
  TW,
  BW,
  CW,
  GameContext,
  graphQLQuery,
  graphQLSubscription,
  getTeamAsChecker,
  getTeamAsOpposition,
  onBwMentioned,
  onTwGuessed,
  onTwSkipped,
  useMap
} from '../../../internal';


interface IProps {

}

export const VerbalCheck: FC<IProps> = ({ }) => {
  var { state: { game, myTeam, oppTeam, helpOn, clientSync }, dispatch } = useContext(GameContext);
  var nextPhaseFromPhaseStart = useRef<number>();
  var [bombWordMentioned, setBombWordMentioned] = useState(false);
  // use references inside subscriptions
  var latestMyTeam = useRef(myTeam);
  latestMyTeam.current = myTeam;

  useEffect(() => {
    if (game?.getMyTeam) {
      // set the next phase number so that we can fire it form anywhere later
      nextPhaseFromPhaseStart.current = game?.phase + 1;

      (async () => {
        var myT = await graphQLQuery(getTeamAsChecker, 'getTeam', { id: game!.getMyTeam()!.id })
        var myTeam = new Team(myT);
        // get the opposing team's Ttw
        var oppT = await graphQLQuery(getTeamAsOpposition, 'getTeam', { id: game!.getOppositeTeam()!.id })
        var oppTeam = new Team(oppT);

        dispatch({ type: 'setMyTeam', team: myTeam })
        dispatch({ type: 'setOppTeam', team: oppTeam })

      })();
    }
  }, [game?.id])

  // SHARED FROM CAPTAIN
  var [guessedTws, setGuessedTws, guessedUpdator] = useMap(new Map());
  var [skippedTws, setSkippedTWs, skippedUpdator] = useMap(new Map());
  var [mentionedBws, setMentionedBws, mentionedUpdator] = useMap(new Map());

  useEffect(() => {
    if (game?.getMyTeam) {

      (async () => {
        // var myT = await graphQLQuery(getTeamAsClueGiver, 'getTeam', { id: myTeam!.id })
        // var captainsTeam = new Team(myT);        
        // dispatch({ type: 'setMyTeam', team: captainsTeam })

      })();
      // setup subscription for oppTeam's mentioned bws
      var mentionedBwSubscription = graphQLSubscription(onBwMentioned, { teamId: myTeam!.id }, bwMentioned);
      var guessedTwSubscription = graphQLSubscription(onTwGuessed, { teamId: oppTeam!.id }, twGuessed);
      var skippedTwSubscription = graphQLSubscription(onTwSkipped, { teamId: oppTeam!.id }, twSkipped);


      return () => {
        mentionedBwSubscription.unsubscribe();
        guessedTwSubscription.unsubscribe();
        skippedTwSubscription.unsubscribe();
      }
    }
  }, [game?.id])

  const bwMentioned = ({ onUpdateWord }) => {
    const bw = new BW(onUpdateWord);
    const alreadyMentioned = mentionedBws.has(bw.id);
    mentionedBws.set(bw.id, bw);
    if (!alreadyMentioned) {

      if (bw.isBombWord()) {
        setBombWordMentioned(true);
        // part of the bomb bomb word functionality should trigger
        // the phase jump. wait 2 second then go.
        // only need to do this from the check team since they are 
        // the only ones that can actually click the bomb word
        if (Number.isInteger(nextPhaseFromPhaseStart.current)) {
          setTimeout(async () => {
            await game!.setNewPhase(nextPhaseFromPhaseStart.current!, clientSync);
          }, 2000);
        }
        sfx.play(ESoundName.bomb, bw.text?.length);
      } else {
        if (bw.isDoubleValue()) {
          sfx.play(ESoundName.bwDouble, bw.text?.length);
        } else {
          sfx.play(ESoundName.bwMentioned, bw.text?.length);
        }
      }
    }

    setMentionedBws(mentionedBws);
  }

  const twGuessed = ({ onUpdateWord }) => {
    const tw = new TW(onUpdateWord);
    const alreadyGuessed = guessedTws.has(tw.id);
    guessedTws.set(tw.id, tw.isDoubleValue());
    setGuessedTws(guessedTws);

    if (guessedTws.size === 5 && !alreadyGuessed) {
      // all earned sound
      sfx.play(ESoundName.allTwsEarned, tw.text?.length);
    }
    // not else if play sound and cheer if 5
    if (!alreadyGuessed) {
      if (tw.isDoubleValue()) {
        // double sound
        sfx.play(ESoundName.twDouble, tw.text?.length);
      } else {
        // single sound
        sfx.play(ESoundName.twEarned, tw.text?.length);
      }
    }
  }

  const markAsMentioned = (bw: BW) => {
    Word.saveUpdate(bw.markAsMentioned());
  }

  const twSkipped = ({ onUpdateWord }) => {
    const tw = new TW(onUpdateWord);
    const alreadySkipped = skippedTws.has(tw.id);
    // second value is true or false depending on if is double value word
    skippedTws.set(tw.id, tw);
    setSkippedTWs(skippedTws);

    if (!alreadySkipped) {
      // single sound
      sfx.play(ESoundName.voteCast, tw.text?.length);
    }
  }
  // END SHARED FROM CAPTAIN

  const toggleHelp = () => {
    dispatch({ type: 'setHelp', help: !helpOn });
  };

  return (
    <div className={classnames('verbalPhase-container')}>
      <Container>

        {/* instructions */}
        <ConditionalRender visible={helpOn || (helpOn === undefined && latestMyTeam.current?.rounds?.length === 1)}>
          <Row className={classnames('instruction-block')}>
            <Col xs={2} md={1} onClick={() => toggleHelp()}>
              <FaQuestionCircle />
            </Col>
            <Col xs={9} md={10}>
              <p>The opposing team is trying to guess the <em>Target Words</em> shown.</p>
              <p>They earn one point for each correct guess.</p>
              <p>When the opposing team says one of your <em>Banned Words</em>, select it to earn a point.</p>
            </Col>
          </Row>
        </ConditionalRender>

        <Row justify="center" className={classnames('opposing-team-word-list')}>
          {oppTeam?.round?.tws?.map((tw: TW, i: number) => (
            <Col key={`tw-${i}`}>
              <Button
                className={classnames('word-button', 'tw', { [oppTeam?.getStyleName()!]: guessedTws?.has(tw.id), 'active': guessedTws?.has(tw.id), 'skipped': skippedTws?.has(tw.id) || tw.isSkipped() })}
                disabled>
                <>
                  <Row>
                    <Col xs={12}>
                      <div className="word-text">{tw.text}</div>
                    </Col>
                  </Row>
                  <ConditionalRender visible={tw?.isDoubleValue()}>
                    <div className="double-value-word-flag-container" data-tip data-for="double-value">
                      <div className={classnames('double-value-word-flag')}>
                        <FaAngleDoubleUp />
                      </div>
                    </div>
                  </ConditionalRender>
                </>
              </Button>
            </Col>
          ))}
        </Row>

        <Row justify="center">
          <Col xs={12}>
            <h2 className={classnames('text-center')}>Listen for <em>Banned Words</em></h2>
            <h4 className={classnames('text-center', 'sub-title')}>Select the <em>Banned Words</em> as you hear them mentioned by the opposite team</h4>
          </Col>
        </Row>

        <Row>
          {myTeam?.round?.bws?.map((bw: BW, i: number) => (
            <Col xs={12} sm={6} md={4} lg={3} xxl={2} key={`bw-${i}`}>
              <Button
                className={classnames('word-button', 'bw', myTeam?.getStyleName(), { 'active': mentionedBws?.has(bw.id) })}
                onClick={() => markAsMentioned(bw)}>
                <>
                  <Row>
                    <Col xs={12}>
                      <div className="word-text">{bw.text}</div>
                    </Col>
                  </Row>
                  {/* Double Value */}
                  <ConditionalRender visible={bw?.isDoubleValue()}>
                    <div className="double-value-word-flag-container" data-tip data-for="double-value">
                      <div className={classnames('double-value-word-flag')}>
                        <FaAngleDoubleUp />
                      </div>
                    </div>
                  </ConditionalRender>
                  {/* Bomb Wrod */}
                  <ConditionalRender visible={bw?.isBombWord()}>
                    <div className="bomb-word-indicator-container" data-tip data-for="bomb">
                      <div className={classnames('bomb-word-indicator')}>
                        <FaBomb />
                      </div>
                    </div>
                  </ConditionalRender>
                </>
              </Button>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
