import React, { FC, useState, useEffect, useContext } from "react";
import classnames from "classnames";
import { Container, Row, Col, Hidden, ScreenClassRender } from "react-grid-system";
import { useParams, Link } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import { Button, FormInput } from "shards-react";
import { FaQuestion, FaVolumeMute, FaVolumeDown, FaShareAltSquare, FaFastForward } from "react-icons/fa";
import Loader from "react-loader-spinner";

import {
  SfxManager,
  sfx, ESoundName,
  ConditionalRender,
  GameContext,
  EGamePhases,
  IGameContext,
  Game,
  Team
} from '../internal';

interface IProps { }

export const GameHeader: FC<IProps> = ({ }) => {
  var { state: { game, myTeam, oppTeam, helpOn, clientSync }, dispatch } = useContext<IGameContext>(GameContext);
  const { gameCode } = useParams<any>();

  const [endIsNear, setEndIsNear] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [stopTickingFn, setStopTickingFn] = useState<(() => void)>(() => { return; });
  const [showJoinInstructions, setShowJoinInstructions] = useState<boolean>(false);
  const [hideGameCode, setHideGameCode] = useState<boolean>(false);
  var [newRoundTimeoutRef, setNewRoundTimeoutRef] = useState<any>(null);
  var [teamRoundSacrificed, setTeamRoundSacrificed] = useState<boolean>(false);
  var [forceEnableNewRoundBtn, setForceEnableNewRoundBtn] = useState<boolean>(false);

  useEffect(() => {
    // find the active team for sacrifice boolean
    var activeTeamName;
    // setTeamRoundSacrificed(false);
    // TODO: there is a bug here with showing the sacrificed text 
    // below the countdown timer on the clue-giver view
    // need to look into this and fix it
    if (game?.phase === EGamePhases.redGuess) {
      activeTeamName = Game.redTeamName;
    } else if (game?.phase === EGamePhases.blueGuess) {
      activeTeamName = Game.blueTeamName;
    }
    
    if (myTeam?.name == activeTeamName) {
      if (myTeam?.round?.sacrificed !== undefined) {
        setTeamRoundSacrificed(myTeam?.round?.sacrificed);
      }
    }
    else if (oppTeam?.name == activeTeamName) {
      if (oppTeam?.round?.sacrificed !== undefined) {
        setTeamRoundSacrificed(oppTeam?.round?.sacrificed);
      }
    }
  }, [myTeam?.round?.sacrificed, oppTeam?.round?.sacrificed, game?.phase])

  useEffect(() => {
    return () => {
      if (newRoundTimeoutRef) {
        clearTimeout(newRoundTimeoutRef);
      }
      setEndIsNear(false);
    };

  }, [game?.id, game?.phase])

  useEffect(() => {
    // if the game phase end time changes due to 
    // extra time or new phase, stop the ticking noise
    stopTickingFn?.();
    setEndIsNear(false);
  }, [game?.phaseEndTime])

  useEffect(() => {
    if (newRoundTimeoutRef) {
      // play the new round sfx
      sfx.play(ESoundName.roundStart, game?.usedTargets?.length);
    }
  }, [newRoundTimeoutRef])

  useEffect(() => {
    if (endIsNear) {
      const tickingRef = sfx.play(ESoundName.ticking);
      console.log('ticking ref ' + tickingRef?.playId);
      setStopTickingFn(() => {
        return () => {
          console.log('stopping ticking w/ ref ' + tickingRef?.playId!);
          if (tickingRef?.playId) {
            sfx?.stopSound(tickingRef?.playId!);
          }
          clearTimeout(tickingRef?.ref);
        }
      });
    }
  }, [endIsNear])

  const nextGamePhase = async () => {
    // console.log(`moving from ${EGamePhases[game!.phase]} (${game!.phase}) to ${EGamePhases[game!.phase + 1]} (${game!.phase + 1})`);
    await game!.setNewPhase(game!.phase + 1, clientSync);
    // subscription catches phase changes from game room
    // dispatch({ type: 'modifyGame', game });
  };

  const checkForEndIsNear = ({ total, days, hours, minutes, seconds, milliseconds, completed }) => {
    if (total <= 8000) {
      setEndIsNear(true);
    }
  }

  const getPhaseTitle = (phase: EGamePhases) => {
    switch (phase) {
      case EGamePhases.cwEntry:
        return "Create Banned Words";
      case EGamePhases.voting:
        return "Banned Word Voting";
      case EGamePhases.preRedGuess:
        return `${Game.redTeamName} Getting Ready`;
      case EGamePhases.redGuess:
        return `${Game.redTeamName} Guessing`;
      case EGamePhases.preBlueGuess:
        return `${Game.blueTeamName} Getting Ready`;
      case EGamePhases.blueGuess:
        return `${Game.blueTeamName} Guessing`;
      case EGamePhases.results:
        return "Round Results";
      case EGamePhases.unknown:
        return "Team Select";
      default:
        return phase;
    }
  };

  const getShortPhaseTitle = (phase: EGamePhases) => {
    switch (phase) {
      case EGamePhases.cwEntry:
        return "Create Words";
      case EGamePhases.voting:
        return "Vote Top 16";
      case EGamePhases.preRedGuess:
        return `${Game.redTeamName} Start`;
      case EGamePhases.redGuess:
        return `${Game.redTeamName} Guessing`;
      case EGamePhases.preBlueGuess:
        return `${Game.blueTeamName} Start`;
      case EGamePhases.blueGuess:
        return `${Game.blueTeamName} Guessing`;
      case EGamePhases.results:
        return "Results";
      case EGamePhases.unknown:
        return "Team Select";
      default:
        return phase;
    }
  };

  const initiateStartNewRound = async () => {
    setForceEnableNewRoundBtn(false);
    const myNewRoundTimerRequest = Date.now();
    await game!.preStartNewRound(myNewRoundTimerRequest);

    const timeoutCanceller = setTimeout(async () => {
      await game!.attemptStartNewRound(myNewRoundTimerRequest, clientSync);
      setForceEnableNewRoundBtn(true);
    }, Game.preNewRoundWait)
    setNewRoundTimeoutRef(timeoutCanceller);
  };

  const toggleHelp = () => {
    dispatch({ type: 'setHelp', help: !helpOn });
  };

  const toggleVolume = () => {
    if (isMuted) {
      sfx.volume(SfxManager.defaultVolume);
      setIsMuted(false);
    } else {
      sfx.volume(0);
      setIsMuted(true);
    }
  };

  const confirmSkipPhase = () => {
    // quick addition. make this less ugly.
    if (window.confirm("Do you really want to skip to the next phase?")) {
      nextGamePhase();
    }
  };

  const toggleJoinInstructions = () => {
    setShowJoinInstructions(!showJoinInstructions);
  };

  return (
    <ScreenClassRender render={(screenClass: string) => (
      <>
        <Row className={classnames('game-header', game?.selectedTeam?.getStyleName?.())}>
          <Col xs={5} md={3} lg={3}>
            <ConditionalRender visible={!!myTeam}>
              <span className={classnames('')}>
                <Button onClick={toggleHelp} className={classnames('help-button', { white: helpOn || (helpOn === undefined && myTeam?.rounds?.length === 1) })}>
                  <FaQuestion />
                </Button>
              </span>
            </ConditionalRender>
            <div className={classnames('room-code-container', 'inline')} onClick={toggleJoinInstructions}>
              <h3>
                <span className={classnames('room-code', screenClass, { 'long': gameCode.length > 10, 'very-long': gameCode.length > 18 })}>
                  {EGamePhases.results && hideGameCode ? '' : gameCode}
                </span>
              </h3>
              <div className={classnames('share-icon')}>
                <FaShareAltSquare />
              </div>
            </div>
          </Col>
          <Hidden sm xs>
            <Col md={6} lg={6} className={classnames('text-center', 'phase-container')}>
              {/* <Hidden xs sm md>
                <Row>
                  <Col lg={3}>
                    <ConditionalRender visible={game?.phase !== undefined}>
                      <Hidden xs sm md>
                        <div className="phase-dot prev">PREV</div>
                      </Hidden>
                    </ConditionalRender>
                  </Col>
                  <Col xs={12} lg={6}>
                    <ConditionalRender visible={game?.phase !== undefined}>
                      <Hidden xs sm md>
                        <div className="phase-dot center">CURRENT</div>
                      </Hidden>
                    </ConditionalRender>
                  </Col>
                  <Col lg={3}>
                    <ConditionalRender visible={game?.phase !== undefined && game?.phase < EGamePhases.results}>
                      <Hidden xs sm md>
                        <div className="phase-dot next">NEXT</div>
                      </Hidden>
                    </ConditionalRender>
                  </Col>
                </Row>
              </Hidden> */}

              <Row>
                <ConditionalRender visible={game?.phase !== undefined}>
                      {/* previous phase name */}
                  {/* <ConditionalRender visible={(game?.phase || 0) < EGamePhases.results}>
                    <Hidden xs sm md>
                      <Col lg={3}>
                        <h6 className="inline-phase-title prev">{getShortPhaseTitle((game?.phase ?? 0) - 1)}</h6>
                      </Col>
                    </Hidden>
                  </ConditionalRender> */}

                  {/* current phase name */}
                  <Col xs={12} >
                    <ConditionalRender visible={(game?.phase || 0) < EGamePhases.results}>
                      <h6 className="phase-title next"><em>Next: {getShortPhaseTitle((game?.phase ?? 0 )+ 1)}</em></h6>
                    </ConditionalRender>
                    <h4 className="phase-title" onClick={() => setHideGameCode(!hideGameCode)}>{getPhaseTitle(game?.phase ?? 0)}</h4>
                  </Col>
                  
                      {/* next phase name */}
                  {/* <ConditionalRender visible={(game?.phase || 0) < EGamePhases.results}>
                    <Hidden xs sm md>
                      <Col lg={3}>
                        <h6 className="inline-phase-title next">{getShortPhaseTitle((game?.phase ?? 0 )+ 1)}</h6>
                      </Col>
                    </Hidden>
                  </ConditionalRender> */}
                </ConditionalRender>
              </Row>

            </Col>
          </Hidden>

          <Col xs={7} md={3} lg={3}>
            <h3 className={classnames('text-right', 'timer-and-helpToggle')}>
              <span className={classnames('text-right', 'inline')}>
                <ConditionalRender visible={!!game && !!game?.phaseEndTime}>
                  <div className={'countdown'} onClick={confirmSkipPhase}>
                    <Countdown
                      key={game?.adjustedPhaseEndTime}
                      date={game?.adjustedPhaseEndTime}
                      precision={1}
                      onTick={checkForEndIsNear}
                      onComplete={nextGamePhase}
                      renderer={({ minutes, seconds }) => (
                        <span>
                          {zeroPad(minutes)}:{zeroPad(seconds)}
                        </span>
                      )}
                    />
                    <div className={classnames('skip-icon')}>
                      <FaFastForward />
                    </div>
                    <ConditionalRender visible={teamRoundSacrificed && (game?.phase === EGamePhases.redGuess || game?.phase === EGamePhases.blueGuess)}>
                      <div className={classnames('bonus-time')}>sacrifice bonus</div>
                    </ConditionalRender>
                  </div>
                </ConditionalRender>
                <ConditionalRender visible={!!game && game?.phase === EGamePhases.results}>
                  <Button
                    className={classnames('new-round-btn')}
                    onClick={initiateStartNewRound}
                    disabled={!!game?.newRoundRequest && !forceEnableNewRoundBtn}>
                    <>
                      <div className="inline">
                        <ConditionalRender visible={!!game?.newRoundRequest && !forceEnableNewRoundBtn}>
                          <Loader
                            type="Oval"
                            color="#fff"
                            height={18}
                            width={18}
                          />
                        </ConditionalRender>
                      </div>
                      <div className="inline">
                        New Round
                      </div>
                    </>
                  </Button>
                </ConditionalRender>
              </span>

              <ConditionalRender visible={!!myTeam}>
                <span className={classnames("text-right", "inline")}>
                  <Button onClick={toggleVolume} className={classnames("help-button")}>
                    <ConditionalRender visible={isMuted}>
                      <FaVolumeMute />
                    </ConditionalRender>
                    <ConditionalRender visible={!isMuted}>
                      <FaVolumeDown />
                    </ConditionalRender>
                  </Button>
                </span>
              </ConditionalRender>
            </h3>
          </Col>
        </Row>

        <ConditionalRender visible={showJoinInstructions}>
          <div className={classnames("join-instructions-modal")}>
            <Row justify="center">
              <Col>
                <h2>To join the game</h2>
                <h2>open a browser and go to:</h2>
                <h1 className={classnames("join-url", screenClass, { 'long': gameCode.length > 10, 'very-long': gameCode.length > 18 })}>
                  bannedwords<wbr/>.fun<wbr/>/{gameCode}
                </h1>
                <ConditionalRender visible={game?.modes?.length ? game.modes.length > 0 : false}>
                  <Hidden xs>
                    <h4>Game Modes On:</h4>
                    <h4 className="game-modes">
                      {game?.getPrettyGameModeNames?.().join(', ')}
                    </h4>
                  </Hidden>
                </ConditionalRender>
                <Button
                  className={classnames("white")}
                  onClick={toggleJoinInstructions}>
                  Close
                </Button>
              </Col>
            </Row>
          </div>
        </ConditionalRender>
      </>
    )} />
  );
};
