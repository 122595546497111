import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([
  "Christmas",
  "merry",
  "festive",
  "Santa Claus",
  "elves",
  "jolly",
  "reindeer",
  "carols",
  "Xmas",
  "yuletide",
  "tinsel",
  "stocking",
  "presents",
  "chimney",
  "Jesus",
  "birth",
  "family",
  "candy",
  "Rudolph",
  "sleigh",
  "holiday",
  "sled",
  "snowball",
  "St. Nicks",
  "snowman",
  "Father Christmas",
  "Christmas Eve",
  "Christmas tree",
  "Jack Frost",
  "Santa's helpers",
  "Santa's workshop",
  "Christmas carol",
  "Christmas card",
  "Frosty the Snowman",
  "December 25",
  "North Pole",
  "season's greetings",
  "Home Alone",
  "A Christmas Story",
  "Elf",
  "The Grinch",
])


const medium = new Set([
  "holly",
  "ornaments",
  "Scrooge",
  "fruitcake",
  "Saint Nicholas",
  "Kris Kringle",
  "pinecone",
  "tidings",
  "tradition",
  "spirit",
  "Die Hard",
  "caroling",
  "carolers",
  "mistletoe",
  "frankincense",
  "myrrh",
  "sleigh bells",
  "gingerbread house",
  "nativity",
  "The Christmas Chronicles",
  "The Santa Clause",
  "It's a Wonderful Life",
  "A Charlie Brown Christmas",
  "The Polar Express",
  "Miracle on 34th Street",

])

const hard = new Set([

])


export default new ThemeSet('Christmas',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [
      // EDifficulty.Easy, EDifficulty.Medium, 
      EDifficulty.Hard, EDifficulty.Pro]
  });