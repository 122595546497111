import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);

const medium = new Set([
  "Jesus",
  "Napoleon",
  "Muhammad",
  "William Shakespeare",
  "Abraham Lincoln",
  "George Washington",
  "Adolf Hitler",
  "Aristotle",
  "Alexander the Great",
  "Thomas Jefferson",
  "Charles Darwin",
  "Karl Marx",
  "Julius Caesar",
  "Martin Luther",
  "Albert Einstein",
  "Christopher Columbus",
  "Isaac Newton",
  "Theodore Roosevelt",
  "Mozart",
  "Plato",
  "Ludwig van Beethoven",
  "Ulysses S Grant",
  "Leonardo da Vinci",
  "Monica Lewinsky"
]);

const hard = new Set([

]);



export default new ThemeSet('Historical Figures',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  }
);