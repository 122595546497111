import { ThemeSet, EDifficulty } from '../../index';

const easy = new Set([

]);

const medium = new Set([
  "Yankees",
  "LA Dodgers",
  "Chicago Cubs",
  "New York Mets",
  "Boston Red Sox",
  "Atlanta Braves",
  "St Louis Cardinals",
  "White Sox",
  "Tamba Bay",
  "Rays",
  "Arizona Diamondbacks",
  "Derek Jeter",
  "Babe Ruth",
  "World Series",
  "Homebase",
  "First base",
  "Second Base",
  "out field",
  "third base",
  "grand slam",
  "pitcher",
  "catcher",
  "fastball",
  "mound",
  "walk",
  "balk",
  "Strikeout",
  "umpire",
  "clay",
  "foul ball",
])

const hard = new Set([
  "curveball",
  "Center field",
  "shortstop",
  "dugout",
  "shortstop",
  "softball",
  "changeup",
  "overhand",
  "Mark McGwire",
  "Jackie Robinson",
  "Alex Rodriguez",
  "David Ortiz",
  "Barry Bonds",
  "relief pitcher",
  "Miami Marlins",
  "Kansas City Royals",
  "Toronto Blue Jays",
  "Houston Astros",
  "Cincinnati Reds",
  "Minnesota Twins",
  "Baltimore Orioles",
  "Washington Nationals",
  "Seattle Mariners",
  
])



export default new ThemeSet('MLB',
  easy,
  medium,
  hard,
  new Set<string>(),
  {
    hidden: false,
    showAsNew: [],
    disabledByDefault: [EDifficulty.Hard, EDifficulty.Pro]
  });