import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import {
  ConditionalRender,
  Footer,
  Header
} from '../../internal';

interface ISoonItem {
  complexity: string,
  name: string,
  desc: string,
  hidden?: boolean
}

const soonItems: ISoonItem[] = [
  {
    complexity: 'High',
    name: 'Display player count on team select page',
    desc: 'Display the count of players per team team on the team selection view.',
  },
  {
    complexity: 'Mid-Low',
    name: 'Display team scores during round',
    desc: 'Display the team scores in the footer of the page during the round instead of only at the round results screen.',
  },
  {
    complexity: 'Mid',
    name: 'Restart Phase Clock',
    desc: 'Ability to restart the phase clock during an active phase. Useful for the "oh wait. i wasn\'t ready" case. Probably should not be available during the guess phases.',
    hidden: true
  },
];


interface IProps {

}


export const ComingSoon: FC<IProps> = ({ }) => {

  const soonTableItem = (item: ISoonItem, i: number) => (
    <ConditionalRender visible={!item.hidden}>
      <Row justify="around" className={classnames('pages-row-item')} key={`change-item=${i}`}>
        <Col xs={2}><h4>{item.complexity}</h4></Col>
        <Col xs={8}>
          <Row>
            <Col xs={12}><h4>{item.name}</h4></Col>
            <Col xs={12}>{item.desc}</Col>
          </Row>
        </Col>
      </Row>
    </ConditionalRender>
  );

  return (
    <>
      <Header />
      <Container>
        <div className="pages-container">
          <Row justify="around" className={classnames('coming-soon-container')}>
            <Col xs={12}>
              <h2>Coming Soon</h2>
              <h4>More features, gameplay options and improvements in the works. Here's some of what I have in mind.</h4>
            </Col>

            <Col xs={12}>
              <Row justify="around" debug>
                <Col xs={2}><h4>Complexity</h4></Col>
                <Col xs={8}><h4>Description</h4></Col>
              </Row>

              {soonItems.map(soonTableItem)}
            </Col>

            <Col xs={12}>
              <h3>Have a suggestion? <a href="https://twitter.com/BannedWordsFun" target="_blank" className="coffee-link">Send me a note here</a>.</h3>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  )
}
