import { Promise } from 'bluebird';

import {
  Word,
  TW,
  IWord,
  ITWord,
  // IVote,
  IModelIncludeOpts,
  EWordType,
  graphQLMutation,
  createWord,
  IGqlCollection,
  IAssociatedWord,
  WordAssociation
} from '../../internal';

export interface ICWord extends IWord {
  votes?: IGqlCollection<any>,
  associations?: IGqlCollection<IAssociatedWord>
}

export class CW extends Word {
  votes: any[];
  associations?: TW[];

  constructor(opts: ICWord) {
    super(opts);
    this.votes = opts?.votes?.items ?? [];
    this.associations = opts?.associations?.items?.map((assoc: IAssociatedWord) => new TW(assoc.tw)) ?? undefined;
  }

  setAssociatedTWs(associatedTws: TW[]) {
    this.associations = associatedTws;
  }

  async forApi(opts?: IModelIncludeOpts): Promise<any> {
    const superJson = await super.forApi(opts);
    var json = {
      ...superJson,
      type: EWordType.cw,
      selected: 'false'
    }
    if (opts?.includeVotes) {
      // json.votes = this.votes;
    }
    if (opts?.includeAssociations) {
      const associations = this.associations ?? [];
      await Promise.mapSeries(associations, async (tw: TW) => {
        var assoc = new WordAssociation({
          twId: tw.id,
          cwId: this.id,
        });
        return await WordAssociation.saveNew(assoc);

      });
      // json.associations = this.associations;
    }
    return json;
  }


  static async saveNew(wordToSave: Word): Promise<string> {
    const wordEntity = await wordToSave!.forApi({ save: true, includeAssociations: true })
    await graphQLMutation(createWord, wordEntity);
    return wordEntity.id;
  }
}
