import { Promise } from 'bluebird';
import {
  getRandomNumBetween,
  DbEntity,
  Word,
  IBWord,
  ICWord,
  ITWord,
  IWord,
  ITeam,
  Team,
  Game,
  BW,
  TW,
  CW,
  IRound,
  graphQLMutation,
  updateRound,
  createRound,
  IModelIncludeOpts
} from '../internal';

export class Round extends DbEntity {
  teamId: string;
  gameId: string;
  bws: BW[];
  cws: CW[];
  tws: TW[];
  team?: Team;
  sacrificed?: boolean;

  constructor(opts: IRound) {
    super(opts);
    this.teamId = opts.teamId;
    this.gameId = opts.gameId;
    this.sacrificed = opts.sacrificed === undefined ? undefined : opts.sacrificed === 'true';
    this.bws = opts?.bws?.items?.map((bw: IBWord) => new BW(bw)) ?? [];
    this.cws = opts?.cws?.items?.map((cw: ICWord) => new CW(cw)) ?? [];
    this.tws = opts?.tws?.items?.map((tw: ITWord) => new TW(tw)) ?? [];
    // this.team = opts?.round ? new Round(opts!.round) : undefined;
  }

  async setNewTwList(usedWordIndexes: number[], onSets: string[], customTargetBank: string[], withDoubleValueWords?: boolean) {
    const sizeOfTwList = 5;
    const doubleValueWordIndex = getRandomNumBetween(0, sizeOfTwList);

    this.tws = await Promise.mapSeries([...Array(sizeOfTwList)], async (_: any, index: number) => {
      // delay slightly to improve word rng 
      await Promise.delay(5);

      // get a word in the allowed sets that we have not seen in this game before
      const text = await Word.getRandomWord(usedWordIndexes, onSets, customTargetBank);

      const twToCreate: ITWord = {
        text,
        roundId: this.id,
        teamId: this.teamId,
      }

      // make this TW the double value word if that setting is passed in
      if (withDoubleValueWords && doubleValueWordIndex === index) {
        // gql limitation. better boolean
        // support with strings for now :(
        twToCreate.doubleValue = 'true';
      }
      return new TW(twToCreate);
    });
    return this;
  }

  // stuff to do when we're building a new entity for the db
  newEntity() {
    super.newEntity();
    return this;
  }

  async toggleSacrificed() {
    this.sacrificed = !this.sacrificed;
    await this.forApi();
    await Round.saveUpdate(this);
    return this;
  }

  updateFromSub(update: IRound) {
    // change the important things from the subscription update 
    this.sacrificed = update.sacrificed === 'true';
    return this;
  }


  async forApi(opts?: IModelIncludeOpts): Promise<any> {
    const superJson = await super.forApi(opts);
    let toApi: IRound = {
      ...superJson,
      teamId: this.teamId,
      gameId: this.gameId,
      sacrificed: this.sacrificed ? 'true' : 'false'
    };
    if (opts?.includeTws && opts?.save) {
      var twIds = await this.tws.map(async (t: TW) => {
        const createdId = await CW.saveNew(t);
        return createdId;
      });
    }
    // toApi.tws = twIds;
    return toApi;

  }

  static async saveNew(roundToSave: Round): Promise<string> {
    const roundEntity = await roundToSave!.forApi({ save: true, includeTws: true })
    await graphQLMutation(createRound, roundEntity);
    return roundEntity.id!;
  }


  static async saveUpdate(roundToSave: Round): Promise<string> {
    // console.log('[save] update round')
    const roundEntity = await roundToSave!.forApi()
    await graphQLMutation(updateRound, roundEntity)
    return roundEntity.id;
  }

}
